import React, { useState, useEffect } from 'react'
import SecondaryButton from '../../components/atoms/SecondaryButton/index'
import { isEmpty, findIndex } from 'lodash'
import Timer from '../../components/molecules/Timer/index'
import { useHistory } from 'react-router-dom'
import Pagination from '@material-ui/lab/Pagination'
import { getPaginationCount } from '../../Utils/Utility'
import Swal from 'sweetalert2'
import './ActiveAuction.css'

const ActiveAuction = (props) => {
    const history = useHistory()
    return (
        <div>
            <div className="Auction-Table">
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Auction Name</th>
                            <th scope="col">Title</th>
                            {/* <th scope="col">Time left</th> */}
                            <th scope="col">Current Price</th>
                            <th scope="col">My Bid</th>
                            <th scope="col">My Max Bid</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                            {/* <th scope="col"></th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(props.data.results) ? (
                            props.data.results.map((data, index) => (
                                <tr>
                                    <th scope="row" className="frstHdrRow">
                                        {data.sku}
                                    </th>
                                    <td>{data.auctionlot_title}</td>
                                    <td>
                                        {' '}
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: data.title,
                                            }}
                                        ></p>
                                    </td>
                                    {/* <td className="timer-dashboard">
                                        <Timer
                                            productTime={{
                                                start: data.date_added,
                                                end: data.date_closed,
                                            }}
                                        />
                                    </td> */}
                                    <td style={{ whiteSpace: 'pre' }}>US $ {data.latestbid}</td>
                                    <td style={{ whiteSpace: 'pre' }}>$ {data.mybid}</td>
                                    <td style={{ whiteSpace: 'pre' }}>$ {data.lastproxtamt}</td>
                                    <td style={{ whiteSpace: 'pre' }}>
                                        {data.highest_bidder_user_id ==
                                        props.userDetails.userdetails.id
                                            ? 'Winning'
                                            : 'Losing'}
                                    </td>
                                    <td className="vwBtnWrpr">
                                        <SecondaryButton
                                            label="View"
                                            onClick={() =>
                                                history.push(
                                                    // encodeURI(`/auction/${data.lotof}?page=1`),
                                                    encodeURI(
                                                        `lotview/${data.lotof}/${data.id}/${
                                                            props.userDetails.userdetails.id || 0
                                                        }`,
                                                    ),
                                                )
                                            }
                                        />
                                    </td>
                                    {/* <td>
                                        {data.highest_bidder_user_id ==
                                            props.userDetails.userdetails.id && (
                                            <SecondaryButton
                                                label="Retract"
                                                onClick={() => {
                                                    Swal.fire({
                                                        title: 'Do you want to retract this bid?',
                                                        showCancelButton: true,
                                                        confirmButtonText: 'Retract',
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            props.retractBid(data)
                                                        }
                                                    })
                                                }}
                                            />
                                        )}
                                    </td> */}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colspan="12" className="border-none">
                                    <div className="card card-body text-center mt-2">
                                        <b>No Auction Found</b>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="active-pgn">
                <Pagination
                    onChange={(e, value) => {
                        props.paramsupd(value)
                    }}
                    count={
                        props.data.total_pagecnt
                            ? getPaginationCount(props.data.total_pagecnt, 10)
                            : '1'
                    }
                />
            </div>
        </div>
    )
}

export default ActiveAuction
