import { Category } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import Categories from '../../components/molecules/Categories'
import GridView from '../../components/molecules/ProductCard/GridView'
import './Home.css'
import getLotdata from '../../Utils/API/lotsearch'
import { Userdetails } from '../../actions/useraction'
import { useDispatch, connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import VerticalSlider from '../../components/organisms/VerticalSlider'
import ImageGallerySlider from '../../components/organisms/ImageGallerySlider'
import HomeTestimonial from '../../components/organisms/HomeTestimonial'
import HomeContact from '../Contactus/HomeContact'
import HomeAuctionCard from '../../components/molecules/ProductCard/HomeAuctionCard'
import HomeAuction from './HomeAuction'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import auctionSearchDetails from '../../actions/auctionSearchAction'
import { Button } from '@material-ui/core'

function Home(props) {
    const dispatch = useDispatch()
    const [pastAuctions, setPastAuctions] = useState([])
    useEffect(() => {
        props.User()
    }, [])

    const categoryList = [
        {
            label: 'Category 1',
            imgSrc: './images/cat.png',
        },
        {
            label: 'Category 2',
            imgSrc: './images/cat.png',
        },
        {
            label: 'Category 3',
            imgSrc: './images/cat.png',
        },
        {
            label: 'Category 4',
            imgSrc: './images/cat.png',
        },
        {
            label: 'Category 5',
            imgSrc: './images/cat.png',
        },
        {
            label: 'Category 6',
            imgSrc: './images/cat.png',
        },
    ]

    const [auctionSearch, setAuctionSearch] = useState([])
    const isMobile = !useMediaQuery('(min-width:768px)')

    useEffect(() => {
        // getData({ orderby: '2', page: 1, searchbar: '', sh_limit: '9', auc_type: 1 })
        getData({
            orderby: '2',
            page: 1,
            searchbar: '',
            auc_type: 1,
            sh_limit: '10',
            custom_field_3: [],
            sub_cat_id: [],
        })
    }, [])

    useEffect(() => {
        dispatch(
            auctionSearchDetails({
                // auc_type: '',
                is_auction: 1,
                auc_type: 2,
                auction_only: 1,
                lot_images: 1,
                orderby: '2',
                page: 1,
                searchbar: '',
                sh_limit: '10',
                type: 'closed',
                limit: 10,
            }),
        )
    }, [])

    useEffect(() => {
        if (props.auctionSearch.auctionData.length) {
            setPastAuctions(props.auctionSearch.auctionData)
        }
    }, [props.auctionSearch.auctionData])

    async function getData(body) {
        let { data, count } = await getLotdata(body)
        setAuctionSearch(Array.isArray(data) ? data : [])
    }

    const history = useHistory()

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        swipeToSlide: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    }

    const bannerSettings = {
        dots: true,
        fade: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
    }

    return (
        <div className="homeCnt">
            <div className="bnrCnt">
                {/* <div className="wlecomeNt mobilMarginBotton col-md-6 col-12 pr-0">
                    <div className="lftCntnr">
                        <h2>Welcome to Dalshire International</h2>
                        <p>Have Questions?</p>
                        {isMobile ? (
                            <div className="callUsLink">
                                <a href="tel:9725288847">Call us now!</a>
                            </div>
                        ) : (
                            <PrimaryButton
                                label="Call us now!"
                                btnSize="large"
                                onClick={() => history.push('/contactus')}
                            />
                        )}
                    </div>
                </div>
                <div className="col-md-6 col-12 pl-0">
                    <LazyLoadImage
                        className="bnrImg"
                        src={`/images/banner.webp`}
                        effect="blur"
                        placeholderSrc="/images/imageLoading.svg"
                        height="100%"
                        width="100%"
                        alt="Next Lot"
                    />
                </div> */}
                <div className="siteBanner multiple">
                    {
                        <Slider {...bannerSettings}>
                            <img
                                src="/images/bannerslider/Lot1.webp"
                                alt="banner"
                                onError={(e) => (e.target.src = '/images/bannerslider/Lot1.jpg')}
                            />
                            <img
                                src="/images/bannerslider/Lot2.webp"
                                alt="banner"
                                onError={(e) => (e.target.src = '/images/bannerslider/Lot2.jpg')}
                            />
                            <img
                                src="/images/bannerslider/Lot3.webp"
                                alt="banner"
                                onError={(e) => (e.target.src = '/images/bannerslider/Lot3.jpg')}
                            />
                        </Slider>
                    }
                </div>
            </div>
            <div className="auctionCnt mt-5 mb-5">
                <h3 className="auctionHeading">Upcoming Auctions</h3>
                <div className="hmcustomContainer">
                    <HomeAuction auctionSearch={auctionSearch} />
                </div>
            </div>

            <div className="auctionCnt mt-5">
                <h3 className="auctionHeading">Recently Closed Auctions</h3>
                <div className="hmcustomContainer">
                    <HomeAuction auctionSearch={pastAuctions} />
                </div>
                {pastAuctions.length != 0 ? (
                    <>
                        <Button
                            className="vwPstActns mt-5"
                            onClick={() => history.push('/past_auctions')}
                        >
                            View All <span className="material-icons">chevron_right</span>
                        </Button>
                    </>
                ) : (
                    ''
                )}
            </div>

            {/* <div className="auctionCnt mtp120">
                <h3 className="auctionHeading">Why Us</h3>
                <div className="hmcustomContainer whyUsCnt">
                    <div className="whyUsItem">
                        <span>
                            <img src="/images/easy.svg" alt="Simple" />
                        </span>
                        <h6>Simple</h6>
                        <p>
                            Dedicated Partner Manager Sell or retrieve your items at the click of a
                            button
                        </p>
                    </div>
                    <div className="whyUsItem">
                        <span>
                            <img src="/images/peace.svg" alt="Convenient" />
                        </span>
                        <h6>Convenient</h6>
                        <p>
                            Custom portal with digital photos to view your collection Ship for free
                            directly from our marketplace
                        </p>
                    </div>
                    <div className="whyUsItem">
                        <span>
                            <img src="/images/tax.svg" alt="Peace of mind, 24/7" />
                        </span>
                        <h6>Peace of mind, 24/7</h6>
                        <p>
                            Grow your collection with confidence - all assets are monitored by
                            onsite security, with a private portal for access and shipping.
                        </p>
                    </div>
                    <div className="whyUsItem">
                        <span>
                            <img src="/images/free.svg" alt="No sales tax" />
                        </span>
                        <h6>No sales tax</h6>
                        <p>
                            All items bought on our marketplace and sent directly to our vault are
                            exempt from sales tax.
                        </p>
                    </div>
                </div>
            </div> */}
            {/* <div className="hiwGrid hmcustomContainer mtp120">
                {hiwDtl.map((data, index) => (
                    <div className="hiwCard" key={index}>
                        <img src={data.image} />
                        <h5>{data.name}</h5>
                    </div>
                ))}
            </div> */}

            {/* <div className="categoriesCnt ">
                <p className="catHeading">Categories</p>
                <div className="category">
                    <Slider {...settings}>
                        {Array.isArray(props.category.category) &&
                            props.category.category.map((data, index) => (
                                <Categories
                                    filter={data.filters}
                                    label={data.name}
                                    id={data.id}
                                    imgSrc={'/images/cat_placeholder.png'}
                                />
                            ))}
                    </Slider>
                </div>
            </div> */}
            {/* <h3 className="auctionHeading mtp120">Meet Our Staff</h3>
            <div className="orStfWrpr mtp20 hmcustomContainer">
                {ourStaffs.map((data, index) => (
                    <>
                        <div className="ourSfCard" key={index}>
                            <img src={data.image} />
                            <h4>{data.name}</h4>
                        </div>
                    </>
                ))}
            </div> */}
            {/* <div className="imgGlryWrpr hmcustomContainer">
                <div className="d-flex align-items-center justify-content-between mb-5">
                    <h3 class="auctionHeading mtp120">Image Gallery</h3>
                </div>
                <ImageGallerySlider />
            </div> */}
            <h3 className="auctionHeading mtp120">See what our customers are saying</h3>
            <p>
                <strong>Over 1600 five star reviews</strong>
            </p>
            <div className="tstmnlCntnr hmcustomContainer pb-5">
                <HomeTestimonial />
                <div className="text-center">
                    <Link to="/allreviews" className="vwAllBtn">
                        See All Reviews
                    </Link>
                </div>
            </div>

            {/* <div className="imgGlryWrpr hmcustomContainer">
                <div className="d-flex align-items-center justify-content-between">
                    <h3 class="auctionHeading mtp120">About Us</h3>
                </div>
                <p>
                    DalShire International is focused on building consumer Internet businesses in
                    accessories that capitalize on the digital transformation of commerce. With its
                    portfolio of brands ranging from European Luxury to Mid-Tier products and
                    accessories, DalShire is well positioned to leverage all aspects of digital
                    commerce to inspire consumers to express their personal lifestyle. Dalshire
                    sources the best in quality watches and Jewelry from around the world and offers
                    them to the public through various auction platforms at incredible prices.
                    Dalshire are famous for their lines of Swiss movement watches in surgical
                    stainless steel with genuine leather and Stainless Steel straps. These watches
                    compare in Design and quality to the likes of TAG watches for a fraction of the
                    price with the same reliability and without the costly repair charges.
                </p>

                <div className="salesRecordCnt">
                    <h4>Record Breaking Sales!</h4>
                    <div className="salesRecordInner">
                        <div className="salesRecord">
                            <img src="images/item1.webp" alt="item 1" />
                            <h6 title="The Beatles Signed 3x5 Black and White Photograph">
                                The Beatles Signed 3x5 Black and White Photograph
                            </h6>
                           <h5>
                                Sold for <span>$4,000</span>
                            </h5> 
                        </div>
                        <div className="salesRecord">
                            <img src="images/item2.webp" alt="item 2" />
                            <h6 title="Ty Cobb Signed Rawlings Red Sticched Baseball">
                                Ty Cobb Signed Rawlings Red Sticched Baseball
                            </h6>
                             <h5>
                                Sold for <span>$1,500</span>
                            </h5> 
                        </div>
                        <div className="salesRecord">
                            <img src="images/item3.webp" alt="item 3" />
                            <h6 title="Albert Einstein Signed & Inscribed E=MC2 Book 1921">
                                Albert Einstein Signed & Inscribed E=MC2 Book 1921
                            </h6>
                            <h5>
                                Sold for <span>$4,250</span>
                            </h5> 
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="hmcustomContainer abtUsCnt mtp120">
                <h3 class="auctionHeading mtp120">About Us</h3>
                <div className="abtUsInner">
                    <img src="/images/about1.webp" alt="about 1" />
                    <div>
                        <h4>Who are we?</h4>
                        <h6>John House | CEO</h6>
                        <p>
                            John began his Internet career in 1997 by liquidating a computer
                            peripheral company on B-C Internet sites. Seeing the opportunity of
                            e-commerce John looked for other opportunity buys to capitalize on the
                            new channel. John pioneered the early sale of watches, jewelry,
                            collectibles, home goods and power tools on B-C auctions. John founded
                            Always at Market in 2001 where he guided the company to becoming the
                            industry leading third party supplier to B-C auction sites. Focusing on
                            non- computer categories John was the first to launch a watch brand on
                            the Internet in 2000 and launched the first Swiss Watch Brand in 2003 on
                            uBid.com. In 2008 John founded My School Auctions, a fund- raising
                            auction platform designed to unite communities. John has gone on to
                            launch numerous brands on the Internet and recently has acquired the
                            assets of Chapal Zenray, a leading manufacturer of Jewelry.{' '}
                        </p>

                        <h6>Eugene Peden | COO</h6>

                        <p>
                            Eugene began his career with Nissan where he learned the operational
                            disciplines that he has applied throughout his career. While with Nissan
                            Eugene was an integral member of the team that opened the first Nissan
                            manufacturing plants in England. Eugene has piloted numerous
                            manufacturing facilities and has headed up the operational side of both
                            public and private entities on three different continents. Recently
                            Eugene has been called upon by the venture capital community to manage
                            turnaround situations, the last of which leading to a sale to GE.{' '}
                        </p>
                    </div>
                </div>

                <div className="abtUsInner">
                    <div>
                        <h4>What do we do?</h4>
                        <p>
                            DalShire International is focused on building consumer Internet
                            businesses in accessories that capitalize on the digital transformation
                            of commerce. With its portfolio of brands ranging from European Luxury
                            to Mid-Tier products and accessories, DalShire is well positioned to
                            leverage all aspects of digital commerce to inspire consumers to express
                            their personal lifestyle. Dalshire sources the best in quality watches
                            and Jewelry from around the world and offers them to the public through
                            various auction platforms at incredible prices. Dalshire are famous for
                            their lines of Swiss movement watches in surgical stainless steel with
                            genuine leather and Stainless Steel straps. These watches compare in
                            Design and quality to the likes of TAG watches for a fraction of the
                            price with the same reliability and without the costly repair charges.
                        </p>
                    </div>
                    <img src="/images/about2.webp" alt="about 2" />
                </div>

                <div className="abtUsInner">
                    <img src="/images/about3.webp" alt="about 3" />
                    <div>
                        <h4>Where to buy?</h4>
                        <p>
                            Dalshire currently runs up to 9 Auctions per week in Jewelry, Watches
                            and Memorabilia. Check out our auctions on LiveAuctioneers where we have
                            a 4.6/5 star rating, to get started on creating your personal image.
                            These are "No reserve" auctions because we are committed to delivering
                            the best auction experience where everyone has a genuine chance to win.
                            Probably the greatest indicator of the Dalshire value proposition is the
                            make up of our customer base. Our Customer base has a very international
                            flavor to it. We have a high proportion of resellers who attend our
                            auctions to pick up bargins that they can subsequently sell on other
                            platforms. So whether you are a re-seller or a consummer, do yourself a
                            favor and cut out the middleman.
                        </p>
                    </div>
                </div>
            </div> */}

            {/* <h3 className="auctionHeading mtp120">Contact Us</h3>
            <div className="hmcustomContainer mt-5">
                <HomeContact />
            </div> */}
            <div className="btmCtctUsWrpr">
                {/* <p>Contact us for a quote or appointment today!</p> */}
                <PrimaryButton label="Contact Us" onClick={() => history.push('/contactus')} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    category: state.category ? state.category : {},
    auctionSearch: state.auctionSearch ? state.auctionSearch : '',
})

const mapDispatchToProps = (dispatch) => {
    return {
        User: () => dispatch(Userdetails()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
