import React from 'react'
import { Link } from 'react-router-dom'
import AboutSlider from '../../components/organisms/AboutSlider'
import VerticalSlider from '../../components/organisms/VerticalSlider'

const AboutUs = () => {
    return (
        <div className="aboutStaticPage">
            <div className="customContainer mnhtVw py-5">
                <h2 className="mb-4">About Us</h2>
                <p className="text-justify">
                    Dalshire International is an Internet-based Auction company devoted to providing
                    an ever-expanding catalog of meaningful if not iconic merchandise curated and
                    verified prior to presenting online for purchase by seasoned, knowledgeable
                    collectors. We strive to offer our discerning customers highly prized
                    collectibles and memorabilia as well as selections from new and established
                    brands that range from mid-tier to European Luxury products and accessories. 
                    Our team's leadership comes from the early eCommerce pioneers, tried and true
                    entrepreneurs with decades experience curating, sourcing, and marketing products
                    in combinations that attract thoughtful customers. Numerous product sources,
                    brands and patents have been developed toward this end through trial, error,
                    honest hard work and discovery.   Presentation isn't everything, but it
                    certainly preserves the dignity of everything, and everyone involved in every
                    transaction. Gracious and courteous handling of purchased items and of those who
                    purchase them is the second part of the equation for every
                    transaction. Our record of stellar reviews reflects this attitude. See for
                    yourself. Our favorite customer's comments make for some of our favorite
                    reading.  
                </p>
            </div>
        </div>
    )
}

export default AboutUs
