import axios from '.././Utils/Utility/axiosconfig'
import isAuthenticate from '.././Utils/Utility/auth'

export const auctionSearchAction = (list) => ({
    type: 'AUCTIONSEARCH',
    list,
})

const auctionSearchDetails = (body) => {
    return async (dispatch) => {
        try {
            const res = await axios.post('mobileapi/auctionsearch', body)
            console.log('auction search response', res)
            if (res.data.success === 'yes') {
                dispatch(auctionSearchAction(res.data.results))
            } else {
                return `something went wrong`
            }
        } catch (error) {
            console.log(error)
        }
    }
}

export default auctionSearchDetails
