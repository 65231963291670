import React from 'react'

const Privacypolicy = () => {
    return (
        <div className="staticContainer container">
            <h1>Privacy Policy</h1>
            <p> </p>
        </div>
    )
}

export default Privacypolicy
