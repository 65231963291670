import React, { useState, useEffect } from 'react'
import ActiveAuction from './ActiveAuction'
import WonAuction from './wonAuction'
import LostAuction from './LostAuction'
import BuyNow from './BuyNow'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Dashboard from '../Dashboard/Dashboard'
import { getbuyerDashboard } from '../../Utils/API/MyAuctions'
import { getbuynow } from '../../Utils/API/buynow'
import axios from '../../Utils/Utility/axiosconfig'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import './MyAuction.css'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
})

const MyAuction = (props) => {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)
    const [activeBody, setActiveBody] = useState([])
    const [bidwon, setSoldBody] = useState([])
    const [bidlost, setUnsoldBody] = useState([])
    const [buynow, setbuynow] = useState([])

    const [activebodyparams, setactivebodyparams] = useState({
        sh_limit: 10,
        page: 1,
        status: 'bidactive',
    })

    const [bidwonbodyparams, setbidwonbodyparams] = useState({
        sh_limit: 10,
        page: 1,
        status: 'bidwon',
    })

    const [bidlostbodyparams, setbidlostbodyparams] = useState({
        sh_limit: 10,
        page: 1,
        status: 'bidlost',
    })

    const [buynowbodyparams, setbuynowbodyparams] = useState({
        sh_limit: 10,
        page: 1,
        status: 'buynow',
    })

    useEffect(() => {
        console.log('props-------------', props.history.location.state)
        if (props.history.location.state != null || props.history.location.state != undefined) {
            setValue(props.history.location.state.value)
        }
    }, [])

    useEffect(() => {
        getData(activebodyparams, setActiveBody)
    }, [activebodyparams])

    useEffect(() => {
        getData(bidwonbodyparams, setSoldBody)
    }, [bidwonbodyparams])

    useEffect(() => {
        getData(bidlostbodyparams, setUnsoldBody)
    }, [bidlostbodyparams])

    useEffect(() => {
        getbuynowdata(buynowbodyparams, setbuynow)
    }, [buynowbodyparams])

    const getData = async (body, setData) => {
        let data = await getbuyerDashboard(body)
        if (body.status === 'bidactive') {
            data.results = data.results.reverse()
        }
        if (typeof data !== 'string') {
            setData(data)
        }
    }

    const getbuynowdata = async (body, setData) => {
        let data = await getbuynow(body)
        if (typeof data !== 'string') {
            setData(data)
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const retractBid = async (bid) => {
        console.log('retractBid', bid)
        if (bid.mybidid && props.userDetails) {
            const formdata = new FormData()

            formdata.append('pid', bid.id)
            formdata.append('id', bid.mybidid)
            formdata.append('uid', props.userDetails.userdetails.id)
            formdata.append('price', bid.mybid)
            formdata.append('auctionid', bid.auctionid)
            formdata.append('lotof', bid.lotof)
            formdata.append('is_buyer', true)
            console.log('retractBid', formdata)
            const res = await axios.post(`api_bidding/cancelbid_process`, formdata)
            if (res.data.msg === 'yes') {
                Swal.fire({
                    title: 'Bid Retracted Successfully',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
                getData(activebodyparams, setActiveBody)
            }
        }
    }

    return (
        <div>
            <Dashboard>
                <div className="Dashboard-Tabpanel">
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Active Lots" />
                        <Tab label="Won Lots" />
                        <Tab label="Lost Lots" />
                        {/* <Tab label="Buy Lots" /> */}
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <ActiveAuction
                            data={activeBody}
                            paramsupd={(data) => {
                                setactivebodyparams({ ...activebodyparams, page: data })
                            }}
                            retractBid={retractBid}
                            userDetails={props.userDetails}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <WonAuction
                            data={bidwon}
                            paramsupd={(data) => {
                                setbidwonbodyparams({ ...bidwonbodyparams, page: data })
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <LostAuction
                            data={bidlost}
                            paramsupd={(data) => {
                                setbidlostbodyparams({ ...bidlostbodyparams, page: data })
                            }}
                        />
                    </TabPanel>
                    {/* <TabPanel value={value} index={3}>
                        <BuyNow
                            data={buynow}
                            paramsupd={(data) => {
                                setbuynowbodyparams({ ...buynowbodyparams, page: data })
                            }}
                        />
                    </TabPanel> */}
                </div>
            </Dashboard>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.User ? state.User : {},
    }
}

export default connect(mapStateToProps, null)(MyAuction)
