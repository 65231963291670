import React, { useState, useEffect } from 'react'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import { Formik } from 'formik'
import * as yup from 'yup'
import axios from '../../Utils/Utility/axiosconfig'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import './resetpassword.css'

const ResetPassword = (props) => {
    const resetPassSchema = yup.object({
        email: yup.string().email('Invalid email format').required('Required!'),
    })

    const resetPass = async (values) => {
        console.log('asdasdas')
        try {
            const data = {
                email: values.email,
            }
            const res = await axios.post('mobileapi/forgot_password', data)
            if (res.data.success === 'yes') {
                Swal.fire({
                    icon: 'success',
                    title: 'Reset Link sent to Mail',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: res.data.message,
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <Formik
                initialValues={{ email: '' }}
                validationSchema={resetPassSchema}
                onSubmit={(values) => {
                    resetPass(values)
                }}
            >
                {({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="Banner">
                            <div className="coinImg">
                                <img src="/images/pdtimg.png" />
                            </div>
                            <div className="custom-container-resetpass">
                                <div className="coinLogo-resetpassword">
                                    <img src="/images/logo.jpg" />
                                </div>
                                <div className="resetPassword-text">
                                    <h3>Reset Password</h3>
                                </div>
                                <CustomInput
                                    label="Email Address"
                                    variant="standard"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="email"
                                    value={values.email}
                                    placeholder="Enter your email"
                                />
                                {errors.email && touched.email ? (
                                    <div className="ResetpassError">{errors.email}</div>
                                ) : null}
                                {/* <CustomInput
                                    label="Confirm Password"
                                    variant="standard"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="confirmpassword"
                                    value={values.confirmpassword}
                                    inputProps={{ maxLength: 12 }}
                                />
                                {errors.confirmpassword && touched.confirmpassword ? (
                                    <div className="ResetpassError1">{errors.confirmpassword}</div>
                                ) : null} */}
                                <PrimaryButton label="Send Reset Password Link" type="submit" />
                                <a href="/login">Sign in Instead</a>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default ResetPassword
