export const testimonial = [
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Marvin',
        location: 'Maple, CA',
        date: 'Apr 25, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/242962/125963057_1_x.jpg?height=94&quality=70&version=1',
        message: 'Love dealing with this ultra professional auction house.',
        short_msg: 'Always a pleasure!',
        userName: 'Angela',
        location: 'Mount Laurel, NJ',
        date: 'Apr 13, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/240050/124428312_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Shelley',
        location: 'Manchester, GB',
        date: 'Apr 13, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/242260/125566226_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Shelley',
        location: 'Manchester, GB',
        date: 'Apr 13, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/240934/124863332_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Patricia',
        location: 'North Myrtle Beach, SC',
        date: 'Apr 10, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/240934/124863327_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kathleen',
        location: 'Yuba City, CA',
        date: 'Apr 10, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/240934/124863327_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kathleen',
        location: 'Yuba City, CA',
        date: 'Apr 10, 2022',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Bowie',
        location: 'Lubbock, TX',
        date: 'Apr 07, 2022',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Apr 06, 2022',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/234314/121321663_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ron',
        location: 'Kensington, MD',
        date: 'Mar 28, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/238743/123768475_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Bill',
        location: 'Texarkana, TX',
        date: 'Mar 26, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/234315/121321715_1_x.jpg?height=94&quality=70&version=1',
        message:
            'BY far the best auction house you will find for quality items, super CSR, and bidding and product confidence you will not find elsewhere, these folks are terrific!',
        short_msg: 'Bid with total confidence, no worries here!',
        userName: 'Ronald',
        location: 'Chapin, SC',
        date: 'Mar 25, 2022',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Danville, CA',
        date: 'Mar 23, 2022',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'I have ordered several items from Dalshire. There was an initial problem with shipping. I called them and they quickly responded. They made sure the problem was addressed and handled. They were very professional and never stopped trying to solve the issue. I am very pleased with all my dealings with them. I highly recommend them. You will be pleased if you have any items that you buy from them from a description standpoint to a service issue.',
        short_msg: 'Service after the sale',
        userName: 'r',
        location: 'Oak Ridge, TN',
        date: 'Mar 23, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/237592/123140469_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Christian',
        location: 'Creston, IA',
        date: 'Mar 19, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/236075/122143796_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I have now purchased three or four items from Dalshire International. This purchase, like all the previous ones, went smoothly. I also appreciate the speed and efficiency Dalshire uses when it runs its online auctions. Highly recommended and I look forward to bidding with Dalshire international again. Dalshire international's auctions are fast and efficient and often have 2 auctions a day. Great stuff. They have some interesting lots, a lot of 'fashion' watches, and jewellery, all of which start with a competitive or no reserve. Well worth a look. Too often auctioneers take forever and you think your computer has died, or you've lost your connection, and if there are 700 + lots the auction takes forever! Instead, Finally, I mixed up some shipping/tracking numbers and had to go back and send a message asking for them. John at Dalshire was quick to answer, with all the info I needed.",
        short_msg: 'Always impressive!',
        userName: 'Stafford',
        location: 'Portland, OR',
        date: 'Mar 09, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/238743/123768491_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The pendant is lovely. colors are intense and compliment each other. The item arrived sooner than expected a nd was packaged beautifully. It also represents great value for the money. I look forward to future experiences with Dalshire Intrernational.',
        short_msg: 'Such beauty....',
        userName: 'Ronald',
        location: 'Port Huron, MI',
        date: 'Mar 15, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/235432/121915512_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I have now purchased three or four items from Dalshire International. This purchase, like all the previous ones, went smoothly. I also appreciate the speed and efficiency Dalshire runs their online auctions. Too often auctioneers take forever and you think your computer has died, or you've lost your connection, and if there are 700 + lots the auction takes forever! Instead, Dalshire international on the other hand is over and done with in less than an hour and often have 2 auctions a day. Great stuff. Dalshire International has some interesting lots, a lot of 'fashion' watches, and jewelry, all of which start with a competitive or no reserve. Well worth a look. Finally, I mixed up some shipping/tracking numbers and had to go back and send a message asking for them. John at Dalshire was quick to answer, with all the info I needed. Highly recommended and I look forward to bidding with Dalshire international again.",
        short_msg: 'Continued great service',
        userName: 'Stafford',
        location: 'Portland, OR',
        date: 'Mar 09, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/237152/122877271_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Alvaro',
        location: 'Pittsburgh, PA',
        date: 'Mar 06, 2022',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Daniel',
        location: 'Cornelius, NC',
        date: 'Mar 02, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/233095/120865725_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Robin',
        location: 'Brenham, TX',
        date: 'Feb 28, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/230034/119008420_1_x.jpg?height=94&quality=70&version=1',
        message: 'Aweasome',
        short_msg: 'The most Honest Business on LiveAuctioneers!!',
        userName: 'Robin',
        location: 'Brenham, TX',
        date: 'Feb 28, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/229387/118595642_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Robin',
        location: 'Brenham, TX',
        date: 'Feb 28, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/235433/121915914_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Have never been disappointed with these guys. Photos accurate and any questions answered very quickly and to the point. Great service. Live is fun and allows bids quickly so you don’t miss opportunities. Shipping so fast it’s amazing. Do not hesitate just do it!!!',
        short_msg: 'Great To Do Business With Reliable Trustworthy',
        userName: 'Gary',
        location: 'Cresco, PA',
        date: 'Feb 25, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/236075/122143785_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Gary',
        location: 'Cresco, PA',
        date: 'Feb 25, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/234315/121321706_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Stafford',
        location: 'Portland, OR',
        date: 'Feb 25, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/234306/121320515_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Stafford',
        location: 'Portland, OR',
        date: 'Feb 25, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/235117/121692167_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Arlington, VA',
        date: 'Feb 19, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/235117/121692034_1_x.jpg?height=94&quality=70&version=1',
        message: 'wonderful auction service',
        short_msg: 'excellent service',
        userName: 'Harold',
        location: 'West Palm Beach, FL',
        date: 'Feb 18, 2022',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Thomas',
        location: 'Elizabethtown, PA',
        date: 'Feb 12, 2022',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Mr. Dalshire contacted me in person to make sure everything was done right. Extremely pleased with the company. Very highly recommend them!!!!!',
        short_msg: 'Very pleased with Dalshire.',
        userName: 'r',
        location: 'Oak Ridge, TN',
        date: 'Feb 09, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/231189/119802387_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Feb 09, 2022',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Lawrence',
        location: 'Homestead, FL',
        date: 'Feb 09, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/232590/120402664_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great people to do business with am a return customer.',
        short_msg: 'Great people to do business with.',
        userName: 'Marqueta',
        location: 'Sanger, CA',
        date: 'Feb 05, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/231796/120084923_1_x.jpg?height=94&quality=70&version=1',
        message: 'High quality merchandise surpassed my expeditions. Very happy with my purchase.',
        short_msg: 'Outstanding quality delivered promptly',
        userName: 'Roman',
        location: 'Waterford, WI',
        date: 'Jan 29, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/232590/120402704_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: 'Great quality and fast shipping',
        userName: 'Melissa',
        location: 'Salt Lake City, UT',
        date: 'Jan 28, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/232590/120402684_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Great selection of goods and low starting prices. However would prefer a lower shipping and handling price especially when you win more than one item in an auction.',
        short_msg: 'Good Prices and Selection',
        userName: 'Ron',
        location: 'West Orange, NJ',
        date: 'Jan 27, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/230765/119483889_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joan',
        location: 'Chicago, IL',
        date: 'Jan 22, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/231192/119802628_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joyce',
        location: 'Ft Worth, TX',
        date: 'Jan 22, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/228416/118096367_1_x.jpg?height=94&quality=70&version=1',
        message: 'purchase matches necklace',
        short_msg: 'Earrings',
        userName: 'Holly',
        location: 'Mohave Valley, AZ',
        date: 'Jan 22, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/231593/119995673_1_x.jpg?height=94&quality=70&version=1',
        message: 'perfect, 5 star across the board!',
        short_msg:
            'Bid/Buy with complete confidence, no worries here, these folks are exceptional!',
        userName: 'Ronald',
        location: 'Chapin, SC',
        date: 'Jan 20, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/231644/120009317_1_x.jpg?height=94&quality=70&version=1',
        message: 'Another perfect purchase, buy/bid with confidence, no worries here!!',
        short_msg: 'Prompt professional service and promise to deliver what you bid and win',
        userName: 'Ronald',
        location: 'Chapin, SC',
        date: 'Jan 20, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/232482/120331775_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I have made several purchases from their auctions and every time, its a perfect 10! John is an exception CSR and prompt and precise to reply to any concerns or questions, bid/buy with complete confidence, no worries here!',
        short_msg: 'These folks are honest and deliver what you win!',
        userName: 'Ronald',
        location: 'Chapin, SC',
        date: 'Jan 20, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/230765/119484021_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Carla',
        location: 'Mount Airy, NC',
        date: 'Jan 16, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/231189/119802367_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Marc',
        location: 'Austin, TX',
        date: 'Jan 16, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/232482/120331825_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I Love this Auction House they ship quick and always exactly what I expected-I have other auction houses Monsterously slow in shipping after the Auction with this house I get my Auction within days of winning that really means a lot.....',
        short_msg: 'I Love this Auction House',
        userName: 'James',
        location: 'Springfield, MO',
        date: 'Jan 15, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/230765/119483854_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Daniel',
        location: 'Cape Coral, FL',
        date: 'Jan 15, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/229571/118756008_1_x.jpg?height=94&quality=70&version=1',
        message: 'Good experience',
        short_msg: 'Good experience',
        userName: 'Joseph',
        location: 'Plantation, FL',
        date: 'Jan 10, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/229387/118595685_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Mark',
        location: 'Cherry Hill, NJ',
        date: 'Jan 06, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/229387/118595691_1_x.jpg?height=94&quality=70&version=1',
        message: 'As described. Fast shipping. Will buy again from this seller.',
        short_msg: 'Item',
        userName: 'Malasha',
        location: 'Scottsdale, AZ',
        date: 'Jan 06, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/229387/118595685_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Mark',
        location: 'Cherry Hill, NJ',
        date: 'Jan 06, 2022',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/226859/117156253_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Robin',
        location: 'Brenham, TX',
        date: 'Dec 27, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/210633/107269861_1_x.jpg?height=94&quality=70&version=1',
        message: 'You’ve gotta check it out!!',
        short_msg: 'Absolutely The Best!!',
        userName: 'Robin',
        location: 'Brenham, TX',
        date: 'Dec 27, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/228416/118096418_1_x.jpg?height=94&quality=70&version=1',
        message: 'Bang for your buck',
        short_msg: 'Wow how impressive',
        userName: 'Bud',
        location: 'Arizona City, AZ',
        date: 'Dec 27, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/228006/117857570_1_x.jpg?height=94&quality=70&version=1',
        message: 'Made sure I received my items in a timely manner. Very nice people.',
        short_msg: 'Great people',
        userName: 'Tony',
        location: 'Henderson, NV',
        date: 'Dec 23, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/222657/114671873_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great Products',
        short_msg: 'Professional',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Dec 19, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/215771/110576854_1_x.jpg?height=94&quality=70&version=1',
        message: 'Accurate information, good customer service.',
        short_msg: 'Dalshire is one of the best!!',
        userName: 'Andrew',
        location: 'Rowlett, TX',
        date: 'Dec 18, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/226740/117096250_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jim',
        location: 'Clinton, IA',
        date: 'Dec 15, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/228315/118020643_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Lynette',
        location: 'Franklinton, LA',
        date: 'Dec 15, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/228006/117857561_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Robin',
        location: 'Brenham, TX',
        date: 'Dec 14, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/219176/112676970_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Frame & glass were broken!! DALSHIRE offered to pay for repairs, but replaced them myself to save on time (item was a gift)',
        short_msg: 'Great auction house!!',
        userName: 'Richard',
        location: 'Sandwich, IL',
        date: 'Dec 13, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/226739/117096011_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very well organized and totally satisfied with the items!!',
        short_msg: 'Great auction house!!',
        userName: 'Richard',
        location: 'Sandwich, IL',
        date: 'Dec 13, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/226739/117096124_1_x.jpg?height=94&quality=70&version=1',
        message: 'It was to be a gift but, I think I will keep for myself.',
        short_msg:
            "I was'nt expecting the overall size to be so big. I must not have read the discription thhoroughly.",
        userName: 'Holly',
        location: 'Mohave Valley, AZ',
        date: 'Dec 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/222154/114404264_1_x.jpg?height=94&quality=70&version=1',
        message: "nice workmanship, ring is xmas gift for granddaughter, I'm sure she'll love it.",
        short_msg:
            'Emeralds are my favorite gemstone, these emeralds were Brazilian annd good quality.',
        userName: 'Holly',
        location: 'Mohave Valley, AZ',
        date: 'Dec 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/227240/117425797_1_x.jpg?height=94&quality=70&version=1',
        message: 'love their auctions',
        short_msg: "It's a xmas gift, hope they love as I do.",
        userName: 'Holly',
        location: 'Mohave Valley, AZ',
        date: 'Dec 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/221867/114287123_1_x.jpg?height=94&quality=70&version=1',
        message: 'I enjoy their auctions',
        short_msg: 'perfect match, love, love, love it',
        userName: 'Holly',
        location: 'Mohave Valley, AZ',
        date: 'Dec 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/220694/113511445_1_x.jpg?height=94&quality=70&version=1',
        message: 'very nice gift',
        short_msg: 'Was a gift for daughter, she loved them.',
        userName: 'Holly',
        location: 'Mohave Valley, AZ',
        date: 'Dec 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/227241/117425857_1_x.jpg?height=94&quality=70&version=1',
        message: '5 Star service',
        short_msg: 'Professional',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Dec 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/226679/117074581_1_x.jpg?height=94&quality=70&version=1',
        message: 'Fast Transaction with Exceptional packaging beyond belief',
        short_msg: 'Fast Transaction with Exceptional packaging',
        userName: 'THOMAS',
        location: 'Schaumburg, IL',
        date: 'Dec 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/227677/117733336_1_x.jpg?height=94&quality=70&version=1',
        message: 'Value for your Dollars',
        short_msg: 'Very Professional',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Dec 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/226847/117150889_1_x.jpg?height=94&quality=70&version=1',
        message:
            'It is the third Ulysee Girard watch that I have purchased. They are well made, reliable and look great. I have had numerous compliments on them. They also make great gifts. I came a print out from the watches website and include it with the watch. The recipients gasp when they see the selling price between $400.00 and $700.00. It makes me feel good.',
        short_msg: 'Watches are well made and reliable.',
        userName: 'Jack',
        location: 'Lawndale, CA',
        date: 'Dec 10, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/226109/116779526_1_x.jpg?height=94&quality=70&version=1',
        message:
            'This has been the best experience on all fronts than any other company I have bought from. They should all have this kind of integrity and kindness. A + +',
        short_msg: 'Great communication!',
        userName: 'Eugene',
        location: 'Huntsville, AL',
        date: 'Dec 09, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/226847/117150870_1_x.jpg?height=94&quality=70&version=1',
        message: 'Loved the earrings! Fast delivery. A + + +',
        short_msg: 'Lovely garnet earrings',
        userName: 'Pam',
        location: 'North Prince George, VA',
        date: 'Dec 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/224186/115622714_1_x.jpg?height=94&quality=70&version=1',
        message: 'Positive feedback and will buy more items from them in the future',
        short_msg: 'Good packaging and fast shipping',
        userName: 'Daniel',
        location: 'Hacienda Heights, CA',
        date: 'Dec 03, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/224768/115955972_1_x.jpg?height=94&quality=70&version=1',
        message: 'Recommend this auction house 100 %',
        short_msg: 'Good items and buys',
        userName: 'Daniel',
        location: 'Hacienda Heights, CA',
        date: 'Dec 03, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/226740/117096224_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great Products, fast delivery',
        short_msg: 'Very Professional',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Dec 03, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/225112/116214944_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very nice quality accurately described and shipped very quickly!!!',
        short_msg: 'Very nice and arrived fast!!!',
        userName: 'Regina',
        location: 'Levittown, PA',
        date: 'Nov 30, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/225874/116680850_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very pleasued with all my purchases',
        short_msg: 'Beautiful',
        userName: 'Cyndy',
        location: 'Inman, SC',
        date: 'Nov 29, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/221513/114077333_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Lisa',
        location: 'Clemmons, NC',
        date: 'Nov 28, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/225124/116223118_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Thank you for your kind assistance & the red box for the earrings for my D I L Christmas gift You all are THE BEST',
        short_msg: 'Organized, knows items,',
        userName: 'Marylou',
        location: 'Allison Park, PA',
        date: 'Nov 27, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/217047/111301463_1_x.jpg?height=94&quality=70&version=1',
        message: 'One of my favorite items. AAA plus',
        short_msg: 'Favorite,',
        userName: 'Rosalyn',
        location: 'Naples, FL',
        date: 'Nov 26, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/219241/112720820_1_x.jpg?height=94&quality=70&version=1',
        message: 'Easy to talk with. Fast, happy with purchase',
        short_msg: 'Easy to deal with',
        userName: 'Rosalyn',
        location: 'Naples, FL',
        date: 'Nov 26, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/225576/116495489_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tommy',
        location: 'Norristown, PA',
        date: 'Nov 24, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Scott',
        location: 'Naugatuck, CT',
        date: 'Nov 24, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223888/115407167_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I won a few items and the best part of it was the watch, for my don:) he really loved!! Fit him perfectly:)',
        short_msg: 'Exceptional!!',
        userName: 'Cynthia',
        location: 'Kenosha, WI',
        date: 'Nov 23, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/225569/116488958_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I won a few items and the best part of it was the watch, for my don:) he really loved!! Fit him perfectly:)',
        short_msg: 'Beautiful!!!!',
        userName: 'Cynthia',
        location: 'Kenosha, WI',
        date: 'Nov 23, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223888/115407167_1_x.jpg?height=94&quality=70&version=1',
        message:
            'As you open the package and you see the beautiful purple color was wow! Beautiful deep African Amethysts. Loved all my items. Great quality!',
        short_msg: 'Exceptional!!',
        userName: 'Cynthia',
        location: 'Kenosha, WI',
        date: 'Nov 23, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223016/114985503_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great transaction. Thanks.',
        short_msg: 'Great items.',
        userName: 'Isaiah',
        location: 'Omaha, NE',
        date: 'Nov 21, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223886/115406851_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very Professional',
        short_msg: 'Great products',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Nov 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223888/115407187_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very Satisfied, great products, very Professional.',
        short_msg: 'Great products',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Nov 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223892/115408713_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very Satisfied',
        short_msg: 'Great products',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Nov 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/224826/115998691_1_x.jpg?height=94&quality=70&version=1',
        message: 'Fast delivery',
        short_msg: 'Great products',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Nov 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223787/115332502_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great products, fast delivery and very Professional.',
        short_msg: 'Great products',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Nov 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223787/115332502_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great products, fast delivery and very Professional.',
        short_msg: 'Great products',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Nov 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/225576/116495563_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I don't always leave a review for John and co The product received is tops way to go DALSHIRE Much thanks, KEYRIC61 ****** 6Star CO.",
        short_msg: 'Every thing I buy',
        userName: 'Ric',
        location: 'Carthage, MO',
        date: 'Nov 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223016/114985584_1_x.jpg?height=94&quality=70&version=1',
        message: 'This company is great to work with and merchandise has been wonderful',
        short_msg: 'Love this Company',
        userName: 'Cyndy',
        location: 'Inman, SC',
        date: 'Nov 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223148/115061789_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very pleased with all my products',
        short_msg: 'Wonderful',
        userName: 'Cyndy',
        location: 'Inman, SC',
        date: 'Nov 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223280/115110987_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great company to work with',
        short_msg: 'Great',
        userName: 'Cyndy',
        location: 'Inman, SC',
        date: 'Nov 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/221513/114077333_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Lisa',
        location: 'Clemmons, NC',
        date: 'Nov 28, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/225124/116223118_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Thank you for your kind assistance & the red box for the earrings for my D I L Christmas gift You all are THE BEST',
        short_msg: 'Organized, knows items',
        userName: 'Marylou',
        location: 'Allison Park, PA',
        date: 'Nov 27, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/217047/111301463_1_x.jpg?height=94&quality=70&version=1',
        message: 'One of my favorite items. AAA plus',
        short_msg: 'Favorite,',
        userName: 'Rosalyn',
        location: 'Naples, FL',
        date: 'Nov 26, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/219241/112720820_1_x.jpg?height=94&quality=70&version=1',
        message: 'Easy to talk with. Fast, happy with purchase',
        short_msg: 'Easy to deal with',
        userName: 'Rosalyn',
        location: 'Naples, FL',
        date: 'Nov 26, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/225576/116495489_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tommy',
        location: 'Norristown, PA',
        date: 'Nov 24, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/220400/113291524_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Scott',
        location: 'Naugatuck, CT',
        date: 'Nov 24, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Scott',
        location: 'Naugatuck, CT',
        date: 'Nov 24, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223888/115407167_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I won a few items and the best part of it was the watch, for my don:) he really loved!! Fit him perfectly:)',
        short_msg: 'Exceptional!!',
        userName: 'Cynthia',
        location: 'Kenosha, WI',
        date: 'Nov 23, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/225569/116488958_1_x.jpg?height=94&quality=70&version=1',
        message:
            'As you open the package and you see the beautiful purple color was wow! Beautiful deep African Amethysts. Loved all my items. Great quality!',
        short_msg: 'Beautiful!!!!',
        userName: 'Cynthia',
        location: 'Kenosha, WI',
        date: 'Nov 23, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223016/114985503_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great transaction. Thanks.',
        short_msg: 'Great items.',
        userName: 'Isaiah',
        location: 'Omaha, NE',
        date: 'Nov 21, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223886/115406851_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very Professional',
        short_msg: 'Great products',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Nov 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223888/115407187_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very Satisfied, great products, very Professional.',
        short_msg: 'Great products',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Nov 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223892/115408713_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very Satisfied',
        short_msg: 'Great products',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Nov 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/224826/115998691_1_x.jpg?height=94&quality=70&version=1',
        message: 'Fast delivery',
        short_msg: 'Very Satisfied',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Nov 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223787/115332502_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great products, fast delivery and very Professional.',
        short_msg: 'Great products',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Nov 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/225569/116488923_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great products and fast delivery and very Professional.',
        short_msg: 'Great products',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Nov 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/225576/116495563_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great products, very Professional',
        short_msg: 'Professional',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Nov 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/225579/116497103_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great products, very Professional',
        short_msg: 'Great product',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Nov 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/222826/114821882_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I don't always leave a review for John and co The product received is tops way to go DALSHIRE Much thanks, KEYRIC61 ****** 6Star CO.",
        short_msg: 'Every thing I buy',
        userName: 'Ric',
        location: 'Carthage, MO',
        date: 'Nov 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223016/114985584_1_x.jpg?height=94&quality=70&version=1',
        message: 'This company is great to work with and merchandise has been wonderful',
        short_msg: 'Love this Company',
        userName: 'Cyndy',
        location: 'Inman, SC',
        date: 'Nov 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223016/114985584_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very pleased with all my products',
        short_msg: 'Wonderful',
        userName: 'Cyndy',
        location: 'Inman, SC',
        date: 'Nov 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223280/115110987_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great company to work with',
        short_msg: 'Great',
        userName: 'Cyndy',
        location: 'Inman, SC',
        date: 'Nov 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223886/115406764_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Robert',
        location: 'Meadowbrook, Pennsylvania',
        date: 'Nov 15, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Most everything was great! Need to change the company that certifies the authenticity of of the memorabilia. GFA does not have a good reputation. Otherwise the product and service is exceptional.',
        short_msg: 'Great service',
        userName: 'Paul',
        location: 'Buena Vista, CO',
        date: 'Nov 14, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/222657/114671916_1_x.jpg?height=94&quality=70&version=1',
        message: 'Always reliable',
        short_msg: 'Good company',
        userName: 'Sabra',
        location: 'Miami, FL',
        date: 'Nov 13, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/224765/115953444_1_x.jpg?height=94&quality=70&version=1',
        message: "Never any issue's",
        short_msg: 'Simply the best',
        userName: 'Tommy',
        location: 'Norristown, PA',
        date: 'Nov 13, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'I have dealt with Dalshire on many occasions — always good experiences.',
        short_msg: 'On time in good condition',
        userName: 'Catherine',
        location: 'Bonsall, CA',
        date: 'Nov 10, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/222146/114404143_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Darcy',
        location: 'Bonsall, CA',
        date: 'Nov 09, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/223787/115332490_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Nov 09, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/220395/113290236_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Some of the Items the color was off, but I loved all of the jewelry. At first I was very upset with the shipping costs, but when I saw all the packing material I understood. Very satisfied with my products. Thanks John.',
        short_msg: 'Always do your research before bidding on any items',
        userName: 'SHARON',
        location: 'Laurelton, NY',
        date: 'Nov 09, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/221470/114050732_1_x.jpg?height=94&quality=70&version=1',
        message:
            "Love the products I've purchased thus far, my latest order, earrings were beautiful, I love them.",
        short_msg: 'LOVELOVELOVE',
        userName: 'Holly',
        location: 'Mohave Valley, AZ',
        date: 'Nov 03, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/214549/109786672_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Once again, I cannot say enough about how satisfied I am with this Auction House and I have been more than pleased with all items I have won from Dalshire!! Also, Great Communications with John from Dalshire... he is always very helpful and very professional!!',
        short_msg: 'Dalshire Auction House',
        userName: 'Michael',
        location: 'Hamilton, CA',
        date: 'Oct 27, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/217047/111301488_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I am very happy and satisfied with all of my purchases won with this Auction House however by no blame of Dalshire Auction House, I did not receive this item. The problem was with Canada Post however Canada Post did acknowledge that it was due to problems with them and they refunded me for the item. Nevertheless, I would have loved to receive this item',
        short_msg: 'Dalshire Auction House',
        userName: 'Michael',
        location: 'Hamilton, CA',
        date: 'Oct 27, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/219241/112720729_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I have won several items from Dalshire Auction House and I am very satisfied with the entire purchase and the items won!!',
        short_msg: 'Dalshire Auction House',
        userName: 'Michael',
        location: 'Hamilton, CA',
        date: 'Oct 27, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/220123/113181497_1_x.jpg?height=94&quality=70&version=1',
        message: 'love dealing with them great bunch of people',
        short_msg: 'great bunch of people love dealing with them',
        userName: 'harold',
        location: 'Buffalo, TX',
        date: 'Oct 26, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.c		om/6667/215770/110576034_1_x.jpg?height=94&quality=70&version=1',
        message: 'Totally Professional.',
        short_msg: 'Great products!',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Sep 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/216946/111255844_1_x.jpg?height=94&quality=70&version=1',
        message: 'Totally Professional.',
        short_msg: 'Great products!',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Sep 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/215597/110505404_1_x.jpg?height=94&quality=70&version=1',
        message: 'Totally Professional.',
        short_msg: 'Great products!',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Sep 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/217047/111301558_1_x.jpg?height=94&quality=70&version=1',
        message: 'Totally Professional.',
        short_msg: 'Great products!',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Sep 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/217580/111628180_1_x.jpg?height=94&quality=70&version=1',
        message: 'Totally Professional.',
        short_msg: 'Great products!',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Sep 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/215073/110153176_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Great outfit-good product descriptions and they keep you informed when item is shipped.',
        short_msg: 'Love working with them!',
        userName: 'Richard',
        location: 'Longboat Key, Florida',
        date: 'Sep 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/215771/110576838_1_x.jpg?height=94&quality=70&version=1',
        message: '.',
        short_msg: '!',
        userName: 'JC',
        location: 'Carlsbad, CA',
        date: 'Sep 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/214541/109784508_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I�ve bought quit a bit of sports memorabilia and have never had a problem. Easy to work with, great communication..',
        short_msg: 'A +!',
        userName: 'George',
        location: 'Gresham, OR',
        date: 'Sep 14, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/202972/102836520_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great watch. Love fooling friends that I got a really expensive watch..',
        short_msg: 'Rolex Substitute!',
        userName: 'Robert',
        location: 'Bellingham, WA',
        date: 'Sep 12, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/215590/110499829_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '!',
        userName: 'Robert',
        location: 'Bellingham, WA',
        date: 'Sep 12, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/202972/102836457_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '!',
        userName: 'Daphne',
        location: 'Cabin John, MD',
        date: 'Sep 10, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '!',
        userName: 'wayne',
        location: 'Port Trevorton, PA',
        date: 'Sep 09, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/214541/109784513_1_x.jpg?height=94&quality=70&version=1',
        message: 'Totally Professional!!!',
        short_msg: 'Fantastic products!',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Sep 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/213991/109416993_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '!',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Sep 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/214549/109786683_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '!',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Sep 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/215034/110109299_1_x.jpg?height=94&quality=70&version=1',
        message: 'Totally Professional, thank you.',
        short_msg: 'Great products!',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Sep 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/215590/110499861_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Sep 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/214549/109786751_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Dory',
        location: 'Houston, TX',
        date: 'Sep 06, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/213597/109184012_1_x.jpg?height=94&quality=70&version=1',
        message: 'great auction house good to deal with',
        short_msg: 'love the pic',
        userName: 'steve',
        location: 'Mackenzie, CA',
        date: 'Sep 04, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/212827/108709000_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Anna',
        location: 'Los Angeles, California',
        date: 'Aug 28, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/212350/108471475_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Andrew',
        location: 'San Francisco, CA',
        date: 'Aug 26, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/211689/107992783_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Andrew',
        location: 'San Francisco, CA',
        date: 'Aug 25, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/212033/108278953_1_x.jpg?height=94&quality=70&version=1',
        message:
            'truly love Dalshire. Fast shipment and the send invoice immediately no begging or asking for information.',
        short_msg: 'won auction of rings',
        userName: 'Lynette',
        location: 'Franklinton, LA',
        date: 'Aug 24, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/211689/107992774_1_x.jpg?height=94&quality=70&version=1',
        message: '.',
        short_msg: '',
        userName: 'George',
        location: 'Gresham, OR',
        date: 'Aug 18, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/211100/107538569_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Very well pleased with this item and this auction house. Big PLUS that they have in-house shipping. Item was well packed and arrived on time and in excellent condition. Will shop here again. Thank you..',
        short_msg: 'Well photographed & accurately described',
        userName: 'Jeane',
        location: 'Albuquerque, NM',
        date: 'Aug 18, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/211217/107620756_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Camil',
        location: 'Chicoutimi, CA',
        date: 'Aug 14, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/211217/107620744_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Bill',
        location: 'Hatfield, PA',
        date: 'Aug 14, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Wife loved it!',
        short_msg: 'Nice',
        userName: 'Huey',
        location: 'Covington, LA',
        date: 'Aug 13, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Nicely put together',
        short_msg: 'Quality',
        userName: 'Huey',
        location: 'Covington, LA',
        date: 'Aug 13, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/210055/106996761_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'George',
        location: 'Gresham, OR',
        date: 'Aug 13, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/210343/107121826_1_x.jpg?height=94&quality=70&version=1',
        message: '` Great people to deal with. Honest, fast, easy process.',
        short_msg: 'Easy and safe to work with.',
        userName: 'David',
        location: 'Mcminnville, TN',
        date: 'Aug 06, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173849/87506031_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Shipping delay by Post Office. Took over 1 year to arrive. Not the fault of the auction house.',
        short_msg: 'Honest and accurate.',
        userName: 'Derek',
        location: 'North Bay, CA',
        date: 'Aug 05, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Aug 01, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'give Dalshire a big amount of points.... so satisfied....',
        short_msg: 'very good communication and a quick shipping... wonderful items',
        userName: 'AGUSTIN',
        location: 'Ubeda, ES',
        date: 'Jul 28, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Compared to most of these auction houses, Dalshire is great to work with. From bidding, to winning, to paying, to getting the lots in a timely manner, they are a real pleasure and easy to work with.',
        short_msg: 'Great to work with',
        userName: 'Myles',
        location: 'Land O Lakes, FL',
        date: 'Jul 28, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/208832/106316998_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tyrone',
        location: 'New Haven, CT',
        date: 'Jul 26, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/210055/106996701_1_x.jpg?height=94&quality=70&version=1',
        message: 'Items were as promised and I look forward to purchasing more from them.',
        short_msg: 'GOOD COMPANY',
        userName: 'Mia',
        location: 'Phoenix, AZ',
        date: 'Jul 23, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/210055/106996752_1_x.jpg?height=94&quality=70&version=1',
        message: 'Reputable Seller Great Product Fantastic Service and Shipping!!',
        short_msg: 'Reputable Seller Great Product',
        userName: 'Collin',
        location: 'Los Angeles, CA',
        date: 'Jul 22, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/209871/106827891_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Julie',
        location: 'New Paltz, NY',
        date: 'Jul 22, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/207890/105778523_1_x.jpg?height=94&quality=70&version=1',
        message:
            "Big Mets fan here. When I saw this up for auction I knew that I needed to have it in my possession. The Ryan Express was a big fan favorite and still is. The Mets made a mistake in trading Nolan away. Glad he had such a great career. If Dalshire International has more wonderful stuff like this, I'm all in trying to get more items like this. Once shipped it arrived very quickly. It was wrapped very well in bubble wrap and boxed well too. It's two thumbs up for Dalshire International.",
        short_msg: 'Good quality and beautifully done',
        userName: 'Larry',
        location: 'Lake City, FL',
        date: 'Jul 19, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/207889/105778199_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'James',
        location: 'Bedford, TX',
        date: 'Jul 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/208535/106208019_1_x.jpg?height=94&quality=70&version=1',
        message: 'Nice photo and item � well prices.',
        short_msg: 'Great price for a beautifully made item.',
        userName: 'Michele',
        location: 'Blairsville, GA',
        date: 'Jul 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/207291/105356569_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great Items and fast shipping',
        short_msg: 'Fast shipping',
        userName: 'Steve',
        location: 'Quinnesec, MI',
        date: 'Jul 15, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/207284/105354532_1_x.jpg?height=94&quality=70&version=1',
        message: 'They have a variety of items and very low starting bids. Will bid here again.',
        short_msg: 'Great auction house',
        userName: 'Dr Zoran',
        location: 'New York, NY',
        date: 'Jul 14, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/207890/105778583_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Shannon',
        location: 'La Plata, Maryland',
        date: 'Jul 12, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/208113/105912814_1_x.jpg?height=94&quality=70&version=1',
        message: 'Love my froggy ring Packaged carefully and shipped fast. Beautiful!',
        short_msg: 'Froggy Ring',
        userName: 'Lynette',
        location: 'Franklinton, LA',
        date: 'Jul 12, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/207021/105187979_1_x.jpg?height=94&quality=70&version=1',
        message: 'Excellent item! Fast shipping and packaged carefully.',
        short_msg: 'wonderful Ring!!',
        userName: 'Lynette',
        location: 'Franklinton, LA',
        date: 'Jul 12, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/208703/106263998_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I will buyregularly as I have done for going on two years. Nice packaging touch as well on the jewelry items you win. Presentable enough to give as a gift.',
        short_msg: 'Good Communication and nice individual products',
        userName: 'Anthony',
        location: 'Bay Point, California',
        date: 'Jul 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/206788/105017146_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Good seller, smooth transaction, fast shipping. I had one question, was answered quickly. Originally had it shipped to an old address, contacted them right away with the correct address. All went well. Would definitely buy again.',
        short_msg: 'All went smoothly, no worries at all',
        userName: 'William',
        location: 'Midland, CA',
        date: 'Jul 09, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/206253/104734614_1_x.jpg?height=94&quality=70&version=1',
        message: 'Easy to deal with! Patient and understanding to delays!',
        short_msg: 'Pros all the way!!',
        userName: 'Paul',
        location: 'Buena Park, California',
        date: 'Jul 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/207438/105426133_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Daphne',
        location: 'Cabin John, MD',
        date: 'Jul 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/204834/103927625_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tommy',
        location: 'Norristown, PA',
        date: 'Jul 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/207890/105778567_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tommy',
        location: 'Norristown, PA',
        date: 'Jul 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/208113/105912895_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tommy',
        location: 'Norristown, PA',
        date: 'Jul 07, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/207291/105356566_1_x.jpg?height=94&quality=70&version=1',
        message: "Nice purchase and nice display box. I'm very pleased.",
        short_msg: "Nice purchase and nice display box. I'm very pleased.",
        userName: 'Anthony',
        location: 'Bay Point, California',
        date: 'Jul 06, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/206350/104819639_1_x.jpg?height=94&quality=70&version=1',
        message:
            'This is my second purchase from Dalshire. The first item took close to a month to ship to me. This experience was much improved. The item came within a week which is acceptable. The quality of the piece is good. Decent luster, size and shape. I would recommend Dalshire to other buyers.',
        short_msg: 'Positive experience',
        userName: 'Gerald',
        location: 'Washington, NJ',
        date: 'Jul 06, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/206253/104734551_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The earrings were beautiful exactly as described and super fast and well-packed shipping! Thank you',
        short_msg: 'Great Seller',
        userName: 'Amanda',
        location: 'San Antonio, TX',
        date: 'Jul 05, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Dalshire is a pleasure to deal with! Very nice framed items! AAA + + +',
        short_msg: 'Perfect AAA + + +',
        userName: 'Juerg',
        location: 'Hittnau, CH',
        date: 'Jul 04, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Very nice staffing and nice goods. Always a pleasure to shop.',
        short_msg: 'OUTSTANDING BUSINESS...',
        userName: 'Lee',
        location: 'Desoto, Texas',
        date: 'Jul 04, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/207438/105426159_1_x.jpg?height=94&quality=70&version=1',
        message:
            'It has been very easy to work with Dalshire. I recommend them without reservation',
        short_msg: 'Dalshire',
        userName: 'randall',
        location: 'Oak Ridge, TN',
        date: 'Jul 03, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/206253/104734607_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Great items, no reserve however shipping can get expensive since they charge for a fee for each item won',
        short_msg: 'Great auction items/expensive to ship',
        userName: 'Ron',
        location: 'West Orange, NJ',
        date: 'Jul 03, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/206242/104732799_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I bought these Watches for my beloved & he was over the moon with them both!! Thank you Dalshire International!!',
        short_msg: 'You know your. m Watches are special!!',
        userName: 'Linda',
        location: 'Wilmslow, GB',
        date: 'Jul 02, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/205698/104415384_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Marc',
        location: 'Austin, TX',
        date: 'Jun 28, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/205501/104305213_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Marabeth',
        location: 'Omaha, NE',
        date: 'Jun 26, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/204967/104016646_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Easy to view, bid, pay, and everything else you need with the experience. I will be checking more auctions!',
        short_msg: 'Great experience!',
        userName: 'Larry',
        location: 'Vallejo, California',
        date: 'Jun 25, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/205698/104415310_1_x.jpg?height=94&quality=70&version=1',
        message: 'Described their items perfectly.',
        short_msg: 'Extremely honest and fast',
        userName: 'Donn',
        location: 'Hillsboro, MO',
        date: 'Jun 24, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/206242/104732763_1_x.jpg?height=94&quality=70&version=1',
        message: 'This is a great company that stands and delivers, I hope we can do busines again',
        short_msg: 'Honest, reliable and fast',
        userName: 'Donn',
        location: 'Hillsboro, MO',
        date: 'Jun 24, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/204835/103928947_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Westerville, OH',
        date: 'Jun 24, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/205501/104305174_1_x.jpg?height=94&quality=70&version=1',
        message: 'Beautiful as promised. Excellent packaging and shipping. Received fast. Love.',
        short_msg: 'Happy Weekend-frog ring',
        userName: 'Lynette',
        location: 'Franklinton, LA',
        date: 'Jun 21, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/205698/104415378_1_x.jpg?height=94&quality=70&version=1',
        message:
            'They ship won auctions fast and packaged well. Love my rings I received. Excellent!',
        short_msg: 'Jewelry, Watch & Memorabilia-',
        userName: 'Lynette',
        location: 'Franklinton, LA',
        date: 'Jun 21, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/204586/103771799_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Excellent response time on my questions and item was sent to me very well wrapped, timely and great quality. I would use this auction house again.',
        short_msg: 'Quality',
        userName: 'AUDREY',
        location: 'Las Vegas, NV',
        date: 'Jun 21, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/204586/103771801_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great shipping. Easy payment and came quickly. Good experience',
        short_msg: 'ring with peridot',
        userName: 'Lynette',
        location: 'Franklinton, LA',
        date: 'Jun 21, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/204497/103710518_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Dennis',
        location: 'Lomita, CA',
        date: 'Jun 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198754/100442146_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'China Grove, NC',
        date: 'Jun 19, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/204967/104016656_1_x.jpg?height=94&quality=70&version=1',
        message: 'Easy transaction, great items. Thank you!',
        short_msg: 'Love my purchase!',
        userName: 'T',
        location: 'Woodland Park, New Jersey',
        date: 'Jun 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/204967/104016603_1_x.jpg?height=94&quality=70&version=1',
        message: 'I asked to change shipping to another address and it was done immediately',
        short_msg: 'Shipping within the US was handled quickly',
        userName: 'Effraim',
        location: 'Haifa, IL',
        date: 'Jun 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/203967/103387045_1_x.jpg?height=94&quality=70&version=1',
        message: 'I would do business again, with pleasure!',
        short_msg: 'Trusted Auction House',
        userName: 'Kathy',
        location: 'Chandler, TX',
        date: 'Jun 16, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/170701/85994229_1_x.jpg?height=94&quality=70&version=1',
        message: 'VERY EFFICIENT',
        short_msg: 'YOU GET WHAT YOU SEE AND TIMELY',
        userName: 'Julius',
        location: 'Lakewood Ranch, FL',
        date: 'Jun 15, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/203480/103114638_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Was pleased with the item. It arrived in perfect condition thanks to careful packaging. Will shop with this auctioneer again.',
        short_msg: 'First Time Buyer',
        userName: 'Jeane',
        location: 'Albuquerque, NM',
        date: 'Jun 13, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/200675/101566942_1_x.jpg?height=94&quality=70&version=1',
        message: 'Shipped in good time. Lovely items',
        short_msg: 'Love my purchase',
        userName: 'Virginia',
        location: 'Chandler, TX',
        date: 'Jun 13, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/203636/103227418_1_x.jpg?height=94&quality=70&version=1',
        message:
            'They are # 1 completely honest and reliable. I would trust them no matter what i buy from them.',
        short_msg: 'They are completely honest and reliable.',
        userName: 'ron',
        location: 'Fox Point, WI',
        date: 'Jun 12, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/204834/103927677_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'james',
        location: 'Elizabeth City, NC',
        date: 'Jun 12, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/201954/102251503_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Lisa',
        location: 'Peoria, AZ',
        date: 'Jun 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/203477/103114416_1_x.jpg?height=94&quality=70&version=1',
        message: 'Designer bracelet for a reasonable pride',
        short_msg: 'Designer bracelet purchased at a great buy',
        userName: 'Carole',
        location: 'Anaheim, CA',
        date: 'Jun 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/204834/103927613_1_x.jpg?height=94&quality=70&version=1',
        message: 'I will most definitely be buying from them again!',
        short_msg:
            'Wonderfully quick service from purchase to delivery! The item I purchased is exactly like the pics',
        userName: 'Katherine',
        location: 'Lake St Louis, MO',
        date: 'Jun 08, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/202972/102836470_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The bidding is reasonable and you have a good chance to acquire the item (s) you are interested in. Reasonable lots for normal people. (No Monets)',
        short_msg: 'Good site for treasures',
        userName: 'John J',
        location: 'Grayslake, IL',
        date: 'Jun 07, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Pamela',
        location: 'Bloomington, Minnesota',
        date: 'Jun 07, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/202969/102835002_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ricky',
        location: 'Reston, VA',
        date: 'Jun 06, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/202969/102834939_1_x.jpg?height=94&quality=70&version=1',
        message: 'As always a great experience, nice piece at a great price and prompt delivery.',
        short_msg: 'Always a great experience',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Jun 05, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/203967/103387015_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Love item. The payment was easy. Shipping was fast. Quality item. Great experience!',
        short_msg: 'Flower Ring',
        userName: 'Lynette',
        location: 'Franklinton, LA',
        date: 'Jun 04, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/200675/101566905_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'c',
        location: 'Covina, California',
        date: 'Jun 04, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/202171/102409331_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The product and the picture were exactly the same. I�m still bidding online with them. Great experience.',
        short_msg: 'Great experience',
        userName: 'Glen',
        location: 'Fishers, IN',
        date: 'Jun 01, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/202166/102404607_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Glen',
        location: 'Fishers, IN',
        date: 'MAY 31, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198679/100378478_1_x.jpg?height=94&quality=70&version=1',
        message: 'Always A Perfect!!',
        short_msg: 'One of my favorite Auction Houses',
        userName: 'Angel',
        location: 'Fullerton, California',
        date: 'MAY 27, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/201954/102251422_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kisa',
        location: 'North Lauderdale, FL',
        date: 'MAY 27, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/201408/102024392_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Chadds Ford, PA',
        date: 'MAY 24, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198041/100094150_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The ring was very nice. Nicer than described definitely deserves a 5 ? ? Rating!!!!!',
        short_msg: 'Awesome ring',
        userName: 'Mark',
        location: 'Magna, UT',
        date: 'MAY 21, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171160/86217360_1_x.jpg?height=94&quality=70&version=1',
        message: 'I am happy to do business with DI.',
        short_msg: 'Reliable service',
        userName: 'Felix',
        location: 'Manteca, California',
        date: 'Jul 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171485/86389184_1_x.jpg?height=94&quality=70&version=1',
        message: 'Delivery as expected no hidden fees smooth to work with Dalshire International.',
        short_msg: 'Great service pleasure to do business with.',
        userName: 'Felix',
        location: 'Manteca, California',
        date: 'Jul 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171485/86389200_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jack',
        location: 'Traverse City, MI',
        date: 'Jul 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171160/86217355_1_x.jpg?height=94&quality=70&version=1',
        message: 'I have purchased several watches and they have all exceeded my expectations',
        short_msg: 'I would recommend this auction',
        userName: 'Tyrone',
        location: 'New Haven, CT',
        date: 'Jul 19, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171288/86278055_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Individual items were packaged carefully and sent promptly very pleased with Dalshire Auction',
        short_msg: 'As described',
        userName: 'Robert',
        location: 'Utica, NY',
        date: 'Jul 18, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171288/86278045_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joanne',
        location: 'Pecatonica, Illinois',
        date: 'Jul 18, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171157/86217110_1_x.jpg?height=94&quality=70&version=1',
        message: 'As Advertised:-Prompt Delivery Very',
        short_msg: 'Communication',
        userName: 'Dennis',
        location: 'West Palm Beach, Florida',
        date: 'Jul 16, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171151/86214940_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Julius',
        location: 'Lakewood Ranch, FL',
        date: 'Jul 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171151/86214904_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The auction process was easy and the shipping was super fast. I received my necklace much earlier than expected. I will do business with Dalshire again.',
        short_msg: 'Great Auction!',
        userName: 'Nancy',
        location: 'Houston, Texas',
        date: 'Jul 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171010/86163667_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Paul',
        location: 'Bridgwater, United Kingdom',
        date: 'Jul 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171010/86163637_1_x.jpg?height=94&quality=70&version=1',
        message: 'Will definitely be doing business with them in the future.',
        short_msg: 'Very positive',
        userName: 'scharalet',
        location: 'Brooklyn, New York',
        date: 'Jul 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167467/84446803_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Exceeded my Expectations My item is on point. Excellent quality for the extraordinary value. Will be a returning customer with family and friends accompanying me.',
        short_msg: '1st bidding experience',
        userName: 'Toni',
        location: 'Houma, Louisiana',
        date: 'Jul 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171010/86163704_1_x.jpg?height=94&quality=70&version=1',
        message:
            'My items were on point. Excellent quality for the extraordinary value. Will be a returning customer with family and friends accompanying me.',
        short_msg: 'Exceeded my Expectations',
        userName: 'Toni',
        location: 'Houma, Louisiana',
        date: 'Jul 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/170227/85783738_1_x.jpg?height=94&quality=70&version=1',
        message: 'What a beautiful bracelet!',
        short_msg: 'Pristine transaction!',
        userName: 'Stephanie',
        location: 'Ypsilanti, Michigan',
        date: 'Jul 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/170698/85994099_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Edward',
        location: 'Silver Spring, MD',
        date: 'Jul 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/170698/85994009_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Trabuco Canyon, CA',
        date: 'Jul 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/170698/85994017_1_x.jpg?height=94&quality=70&version=1',
        message: 'These earrings are gorgeous. Love them',
        short_msg: 'Beautiful',
        userName: 'Kelley',
        location: 'Gualala, California',
        date: 'Jul 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/170583/85935667_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Fantastic experience, I will run, not walk to their auctions. Everything was smooth, shipped right away, item perfect.',
        short_msg: 'Top notch seller, no bumps or delays, will purchase again.',
        userName: '',
        location: '',
        date: 'Jul 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/170583/85935688_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: 'lots of time between items check them out',
        userName: 'Robert/Don',
        location: 'Clearwater, FL',
        date: 'Jul 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/170583/85935650_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I was a new customer to Dalshire and when I had questions about how the system works to payment to shipping ALL were answered quickly',
        short_msg: 'follow up',
        userName: 'Julius',
        location: 'Lakewood Ranch, FL',
        date: 'Jul 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/170227/85783771_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Shannon',
        location: 'Durham, North Carolina',
        date: 'Jul 09, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/170227/85783783_1_x.jpg?height=94&quality=70&version=1',
        message:
            "Holy moly! This necklace is gorgeous. I bought it for the pieces but I'm not sure I want to dismantle it. And for 5 ? Oh yeah, Dalshire will see me again!",
        short_msg: 'Picture did not do item justice!',
        userName: 'Kathleen',
        location: 'Exeter, New Hampshire',
        date: 'Jul 07, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/170029/85669314_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Craig',
        location: 'Phillipsburg, New Jersey',
        date: 'Jul 05, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/170227/85783804_1_x.jpg?height=94&quality=70&version=1',
        message: 'Beautiful and quality ring',
        short_msg: 'Great',
        userName: 'Steve',
        location: 'Irving, Texas',
        date: 'Jul 04, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/170029/85669298_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Chris',
        location: 'New Kensington, Pennsylvania',
        date: 'Jul 04, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/170029/85669388_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Stephen',
        location: 'Studio City, California',
        date: 'Jul 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156672/79079746_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jill',
        location: 'Walcott, Iowa',
        date: 'Jul 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/170026/85669233_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Atlanta, Georgia',
        date: 'Jul 02, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/169838/85590531_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Irene',
        location: 'Sugar Hill, NH',
        date: 'Jun 30, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/169447/85412252_1_x.jpg?height=94&quality=70&version=1',
        message: 'They stand behind their merchandise',
        short_msg: 'John made all the difference. Honorable',
        userName: 'James',
        location: 'Naples, Florida',
        date: 'Jun 29, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/169741/85546976_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I WAS NEW TO DALSHIRE THIS YEAR AND HAD QUESTIONS WHICH WERE ADDRESSED IMMEDIATELY AND RESOLVED. THE MOST DIFFICULT PART TO ME IS THE LIMIT TO WHAT YOU BID THE SPORTS MEMORABILIA SECTION OF THEIR BUSINESS IS TOP NOTCH CJP',
        short_msg: 'FOLLOW UP SERVICE',
        userName: 'Julius',
        location: 'Lakewood Ranch, FL',
        date: 'Jun 29, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/169741/85547050_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Karyn',
        location: 'Columbia, Maryland',
        date: 'Jun 29, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/169545/85449077_1_x.jpg?height=94&quality=70&version=1',
        message:
            "It all is so easy, once you win your item. You pay, it's shipped, no headaches. I love my earrings! Thank you!",
        short_msg: "It's so easy!",
        userName: 'Patricia',
        location: 'Vineland, NJ',
        date: 'Jun 28, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159922/80707071_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tom',
        location: 'Hernando, Florida',
        date: 'Jun 28, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/169545/85449129_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very happy with the earrings, the price I got them for and the fast service.',
        short_msg: 'Great experience',
        userName: 'Steve',
        location: 'Irving, Texas',
        date: 'Jun 27, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/169543/85448261_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jody',
        location: 'Franklin, TN',
        date: 'Jun 26, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/169543/85448264_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'San Marcos, Texas',
        date: 'Jun 26, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/169447/85412251_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Rick',
        location: 'Albuquerque, New Mexico',
        date: 'Jun 26, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/169447/85412222_1_x.jpg?height=94&quality=70&version=1',
        message: 'x',
        short_msg: 'Smooth transaction',
        userName: 'IRWIN',
        location: 'Shelton, Connecticut',
        date: 'Jun 26, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/169447/85412243_1_x.jpg?height=94&quality=70&version=1',
        message: 'Smooth and seamless process',
        short_msg: 'Prompt, Professional,',
        userName: 'Robert',
        location: 'Baltimore, Maryland',
        date: 'Jun 25, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/169359/85379498_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Vilarin',
        location: 'Ladson, South Carolina',
        date: 'Jun 25, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/169359/85379497_1_x.jpg?height=94&quality=70&version=1',
        message: 'you can bid with confidence.',
        short_msg: 'good experience',
        userName: 'Don',
        location: 'Oak Island, NC',
        date: 'Jun 24, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168819/85111811_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very please with the jewelry. Will definitely purchase again.',
        short_msg: 'Quality Products',
        userName: 'Rosanne',
        location: 'Okatie, South Carolina',
        date: 'Jun 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/169359/85379429_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Donald',
        location: 'New York, NY',
        date: 'Jun 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168245/84822588_1_x.jpg?height=94&quality=70&version=1',
        message:
            "The Dalshire auctio had no reserve and bidding started at a dollar. And I finally found something I liked (a sturdy, beautiful gemmy beaded bracelet) to use as a key chain. My second compliment to Dalshire International is that I didn't have to search my crowded email inbox for the invoice. It was right there in my WON page of my account. The invoice included payment for the item, the seller's premium and shipping. With a couple of clicks I was done. Most of us attending online auctions know how dreadful and burdensome payment and arranging shipping can be. All the best to you!",
        short_msg: 'Thank you for making the payment and shipping easy!',
        userName: 'Catherine',
        location: 'Los Angeles, California',
        date: 'Jun 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167095/84287643_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Fabulously beautiful jewelry and watches of superb quality. I am very pleased with EVERY purchase!',
        short_msg:
            'The due diligence and professional conduct of the Principles and fufillment staff is superb!',
        userName: 'Jacqueline',
        location: 'Union, NJ',
        date: 'Jun 21, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168971/85186162_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Audra',
        location: 'Austin, TX',
        date: 'Jun 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168971/85186079_1_x.jpg?height=94&quality=70&version=1',
        message:
            'From beginning to end, the most efficient team of support personnel making payment and shipping a breeze.',
        short_msg: 'Efficient and Responsive Team',
        userName: 'Charles',
        location: 'Eldersburg, Maryland',
        date: 'Jun 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168971/85186141_1_x.jpg?height=94&quality=70&version=1',
        message: 'I loved my jewelry. Thank you so much.',
        short_msg: 'Rings',
        userName: 'Jennifer',
        location: 'Bayonne, NJ',
        date: 'Jun 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162942/82259956_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Robert',
        location: 'Mount Vernon, NY',
        date: 'Jun 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163279/82431002_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Robert',
        location: 'Mount Vernon, NY',
        date: 'Jun 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168490/84947149_1_x.jpg?height=94&quality=70&version=1',
        message: 'Excellent Quality and a satisfied Buyer..',
        short_msg: 'Great Service and products.',
        userName: 'Robert',
        location: 'Mount Vernon, NY',
        date: 'Jun 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168708/85063881_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I have loved every ring I have purchased and this last one is so beautiful better than expected',
        short_msg: 'Great quality rings at reasonable prices',
        userName: 'Melissa',
        location: 'Salt Lake City, UT',
        date: 'Jun 19, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168708/85063955_1_x.jpg?height=94&quality=70&version=1',
        message:
            'quality items for sale at a price that will suprise you and yourself, my kinda shopping from now on grand kids and my children, are amazed b-days taken care of even got christmas done.',
        short_msg:
            'haven, t had much experience with on-line auctions, this was awsome, a rush when you won a bid..',
        userName: 'Robert/Don',
        location: 'Clearwater, FL',
        date: 'Jun 18, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168708/85063929_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Denise',
        location: 'Social Circle, Georgia',
        date: 'Jun 18, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168708/85063853_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Edward',
        location: 'Silver Spring, MD',
        date: 'Jun 18, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168633/85027070_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Reina',
        location: 'Arlington, Washington',
        date: 'Jun 16, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168633/85026994_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kimberly',
        location: 'Pecatonica, IL',
        date: 'Jun 16, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168490/84947192_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I WOULD BID ON ANY OF THEIR AUCTIONS AGAIN, GREAT CREW WORKING THERE THANKS AGAIN GUYS AND GIRLS!!!!',
        short_msg:
            'THE FACT THEY KEPT IN TOUCH AFTER THE AUCTION, WERE VERY POLITE THROUGH ALL STEPS OF ORDEAL',
        userName: 'Robert/Don',
        location: 'Clearwater, FL',
        date: 'Jun 16, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168490/84947156_1_x.jpg?height=94&quality=70&version=1',
        message: 'Easy to navigate through total process on line.',
        short_msg: 'Shipping cost and tracking.',
        userName: 'William',
        location: 'Alexandria, VA',
        date: 'Jun 15, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168490/84947205_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Holly',
        location: 'Vine Grove, KY',
        date: 'Jun 15, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163188/82379925_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sheila',
        location: 'Rockwood, CA',
        date: 'Jun 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168306/84847873_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Patricia',
        location: 'Vineland, NJ',
        date: 'Jun 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168306/84847872_1_x.jpg?height=94&quality=70&version=1',
        message: 'took care of all my needs buffalo texas',
        short_msg: 'great people',
        userName: 'harold',
        location: 'Buffalo, TX',
        date: 'Jun 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168204/84802360_1_x.jpg?height=94&quality=70&version=1',
        message: 'Good quality at great price',
        short_msg: 'Never have a problem with any purchase',
        userName: 'Wayde',
        location: 'Many, Louisiana',
        date: 'Jun 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168204/84802344_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Charles',
        location: 'Madison, Alabama',
        date: 'Jun 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168245/84822663_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Edward',
        location: 'Silver Spring, MD',
        date: 'Jun 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168204/84802327_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Peter',
        location: 'Chittenango, NY',
        date: 'Jun 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168204/84802310_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great bunch of people',
        short_msg: 'Great people',
        userName: 'harold',
        location: 'Buffalo, TX',
        date: 'Jun 12, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168245/84822641_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'San Marcos, Texas',
        date: 'Jun 12, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168204/84802298_1_x.jpg?height=94&quality=70&version=1',
        message: 'Completely satisfied of service and fast completion.',
        short_msg: 'Excellent experience',
        userName: 'Shlomo',
        location: 'San Jose, CR',
        date: 'Jun 12, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168204/84802388_1_x.jpg?height=94&quality=70&version=1',
        message: 'Thank you',
        short_msg: 'l am very positive with my biddings',
        userName: 'alev',
        location: 'North Myrtle Beach, SC',
        date: 'Jun 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/168204/84802393_1_x.jpg?height=94&quality=70&version=1',
        message: 'My daughter loved her gift set.',
        short_msg: 'Gift',
        userName: 'Jennifer',
        location: 'Bayonne, NJ',
        date: 'Jun 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167675/84537707_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Chioe',
        location: 'Melissa, TX',
        date: 'Jun 07, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167791/84611141_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Bidding with Dalshire was easy. I could see when lots that interested me were coming up for bidding. Bidding is done quickly and fairly. Payment was made easy, and my purchases arrived quickly in excellent packaging.',
        short_msg: 'Professional Operators',
        userName: 'Barbara',
        location: 'Gilbert, Arizona',
        date: 'Jun 06, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167791/84611145_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Bryan',
        location: 'Tracy, CA',
        date: 'Jun 06, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167791/84611030_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Just as advertised. Fast shipping. Based on this experience would recommend this auction house.',
        short_msg: 'Watch purhcase',
        userName: 'Edward',
        location: 'Melbourne, Florida',
        date: 'Jun 06, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167675/84537791_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I have received items from Dalshire International about 5 or 6 times. All products are accurately depicted in their photos. Service is quick and everything is always packaged securely. Great place to deal with.',
        short_msg: 'Quality merchandise with great service!',
        userName: 'William',
        location: 'Cheektowaga, New York',
        date: 'Jun 05, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167626/84525318_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Amy',
        location: 'N Royalton, Ohio',
        date: 'Jun 05, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167626/84525301_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'marco',
        location: 'Almere, Netherlands',
        date: 'Jun 05, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167467/84446827_1_x.jpg?height=94&quality=70&version=1',
        message: 'you can bid with confidence.',
        short_msg: 'good experience; good value.',
        userName: 'Don',
        location: 'Oak Island, NC',
        date: 'Jun 05, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167626/84525440_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Overall a good experience and I�d do business with again. It�s sometimes really hard to judge everything just from pictures without being able to hold or look at but that�s the nature of online bidding. I would like to see a size range for the watches so you can determine if you need to take links out or the watch is possibly too small.',
        short_msg: 'Overall Good',
        userName: 'Patrick',
        location: 'Montgomery, TX',
        date: 'Jun 04, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167626/84525438_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Very nice and great service. A joy to deal with. Item was exactly as describe. No issues.',
        short_msg: 'Nice',
        userName: 'Joshua',
        location: 'Freeport, Illinois',
        date: 'Jun 04, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167626/84525360_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Julius',
        location: 'Lakewood Ranch, FL',
        date: 'Jun 04, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167467/84446761_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Denise',
        location: 'Social Circle, Georgia',
        date: 'Jun 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167467/84446810_1_x.jpg?height=94&quality=70&version=1',
        message: 'WILL CONTINUE BIDDING WITH THEM..',
        short_msg: 'GOOD CO. TO DEAL WITH...',
        userName: 'LEON',
        location: 'Summerfield, Florida',
        date: 'Jun 02, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167467/84446816_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Atlanta, Georgia',
        date: 'Jun 02, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167163/84297051_1_x.jpg?height=94&quality=70&version=1',
        message: 'A first class operation. Bought another item after this purchase',
        short_msg: 'In house shipping',
        userName: 'Edward',
        location: 'Melbourne, Florida',
        date: 'Jun 02, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167164/84298467_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Barbara',
        location: 'Gilbert, Arizona',
        date: 'May 31, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166304/83925202_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Paula P',
        location: 'Denver, NC',
        date: 'May 31, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167164/84298492_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Denise',
        location: 'Social Circle, Georgia',
        date: 'May 31, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167163/84297073_1_x.jpg?height=94&quality=70&version=1',
        message:
            "Very nice jewelry, no stress. I'm always happy with my items, price, & communication.",
        short_msg: 'Trusted Auctioner',
        userName: 'Sonia',
        location: 'Hialeah, Florida',
        date: 'May 30, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167163/84297078_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Edward',
        location: 'Silver Spring, MD',
        date: 'May 29, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167155/84296041_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Steven',
        location: 'Woodbury, Minnesota',
        date: 'May 28, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167155/84296026_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very satisfied',
        short_msg: 'Good product and service',
        userName: 'Jim',
        location: 'Lufkin, TX',
        date: 'May 28, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167155/84295985_1_x.jpg?height=94&quality=70&version=1',
        message: 'Interesting items for every taste and bidding is exciting and fun.',
        short_msg: 'Something for Everyone!',
        userName: 'Josh',
        location: 'Stony Point, New York',
        date: 'May 28, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167095/84287708_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I was apprehensive about how exactly a 'not in person' inline auction would work. I bid on a low priced item simply to test the water. I was truly surprised and delighted at the outcome. Just a minor technical trifle that we resolved with one e-mail message. And yes, I would consider participating in a future on-line auction.",
        short_msg: 'First Online Auction experience.',
        userName: 'C.',
        location: 'Sharon Springs, New York',
        date: 'May 26, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167095/84287689_1_x.jpg?height=94&quality=70&version=1',
        message: 'Service is excellent and all items are packaged securely. Highly recommend!',
        short_msg: 'The photos are accurate representations of the items at auction.',
        userName: 'William',
        location: 'Cheektowaga, New York',
        date: 'May 26, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167092/84287040_1_x.jpg?height=94&quality=70&version=1',
        message:
            'A repeat customer and have never been disappointed in quality of merchandise or in service provided.',
        short_msg: 'A first rate auction house.',
        userName: 'Geoffrey',
        location: 'Murray, NE',
        date: 'May 26, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167092/84287111_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Robert',
        location: 'Meadowbrook, Pennsylvania',
        date: 'May 26, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163874/82743511_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'PTY872656 Mariano',
        location: 'Miami, FL',
        date: 'Apr 16, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163874/82743510_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kim',
        location: 'Arlington Heights, IL',
        date: 'Apr 16, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159017/80305000_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I love the Dodgers. I now live in upstate NY but my heart will always be a Dodger fan.',
        short_msg:
            'I knew Duke Snider he owned the bowling alley in my home town of Fallbrook California.',
        userName: 'Shelley',
        location: 'Syracuse, NY',
        date: 'Apr 15, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163835/82727808_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jane',
        location: 'Chadds Ford, PA',
        date: 'Apr 15, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163835/82727808_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: '',
        location: '',
        date: 'Apr 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163874/82743511_1_x.jpg?height=94&quality=70&version=1',
        message: 'easy to deal with',
        short_msg: 'would bid again with confidence',
        userName: 'Don',
        location: 'Oak Island, NC',
        date: 'Apr 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163874/82743504_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Cary',
        location: 'Ripley, Oklahoma',
        date: 'Apr 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163835/82727902_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Annette',
        location: 'Pacheco, California',
        date: 'Apr 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163835/82727834_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Enjoy the auction format Accurate and Detailed Item Description Easy Bid process Easy Payment Process Fast Shipping with Excellent Packaging Very Pleased with the Entire Experience!! Thanks for a Great Job!!',
        short_msg: 'Item exactly as described and a great value',
        userName: 'Garry',
        location: 'Flowery Branch, GA',
        date: 'Apr 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163745/82689850_1_x.jpg?height=94&quality=70&version=1',
        message:
            'There�s no other auction house online that you compete with this great auction house here in Texas first of all a dollar minimum they�re fairly new so they don�t have a gigantic audience',
        short_msg: 'The best auction house online starting at one dollar',
        userName: 'James',
        location: 'Rockwall, TX',
        date: 'Apr 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163484/82520209_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Just received my items and I am so impressed, with not only my items, but with the way you packaged each item as well. You�re prompt attention to providing the best customer service experience has exceeded my expections. Thank you for being ethical in delivering to your customers what they actually see and bid on at � LiveAuctioneers. � Very impressed!',
        short_msg: 'Quality/Ethics',
        userName: 'Roberta',
        location: 'Covina, CA',
        date: 'Apr 12, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163366/82470160_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Theresa',
        location: 'Staten Island, New York',
        date: 'Apr 12, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163745/82689845_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Valree',
        location: 'Hendersonville, North Carolina',
        date: 'Apr 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163745/82689982_1_x.jpg?height=94&quality=70&version=1',
        message: 'great',
        short_msg: 'good',
        userName: 'thomas',
        location: 'Port Saint Lucie, FL',
        date: 'Apr 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163484/82520101_1_x.jpg?height=94&quality=70&version=1',
        message: 'I had a very good experience with Dalshire and would buy from again.',
        short_msg: 'Good value, good service',
        userName: 'Don',
        location: 'Oak Island, NC',
        date: 'Apr 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163484/82520157_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jennifer',
        location: 'Camp Hill, PA',
        date: 'Apr 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163484/82520201_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: '',
        location: '',
        date: 'Apr 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163484/82520184_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kim',
        location: 'Arlington Heights, IL',
        date: 'Apr 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163484/82520072_1_x.jpg?height=94&quality=70&version=1',
        message: 'Easy to deal with and efficient in sending items securely',
        short_msg: 'Dalshir',
        userName: 'Richard',
        location: 'Longboat Key, Florida',
        date: 'Apr 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163366/82470131_1_x.jpg?height=94&quality=70&version=1',
        message: 'Fantastic service and items to bid on',
        short_msg: 'Excellent',
        userName: 'David',
        location: 'Advance, NC',
        date: 'Apr 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163366/82470063_1_x.jpg?height=94&quality=70&version=1',
        message: 'Just what the review says.',
        short_msg: 'Great watches at great prices.',
        userName: 'Conrad',
        location: 'Saint Louis, MO',
        date: 'Apr 09, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163366/82470116_1_x.jpg?height=94&quality=70&version=1',
        message:
            'They are the greatest when it comes to packaging breakable items. Items were received in perfect condition. Wife and I are very impressed.',
        short_msg: 'shipping',
        userName: 'Randall',
        location: 'Stafford, VA',
        date: 'Apr 09, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163366/82470150_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kim',
        location: 'Arlington Heights, IL',
        date: 'Apr 09, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163279/82430990_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sylvia',
        location: 'Omaha, Nebraska',
        date: 'Apr 08, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163279/82431023_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'harold',
        location: 'Buffalo, TX',
        date: 'Apr 08, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163279/82431078_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kim',
        location: 'Arlington Heights, IL',
        date: 'Apr 07, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163188/82379886_1_x.jpg?height=94&quality=70&version=1',
        message: 'Always easy transactions, great stuff at great prices. Thank you',
        short_msg: 'Dalshire International',
        userName: 'Sue',
        location: 'Newmarket, NH',
        date: 'Apr 06, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162942/82259962_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kim',
        location: 'Arlington Heights, IL',
        date: 'Apr 06, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162947/82260360_1_x.jpg?height=94&quality=70&version=1',
        message: 'Could not have been easier.',
        short_msg: 'bid with confidence.',
        userName: 'Don',
        location: 'Oak Island, NC',
        date: 'Apr 06, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162947/82260356_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The best test of an organization is what happens when something goes wrong. When a watch I bought was not working, Dalshire paid to send it back and quickly replaced it.',
        short_msg: 'Dalshire backs its products',
        userName: 'Richard',
        location: 'Buckeye, AZ',
        date: 'Apr 04, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162947/82260305_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joseph',
        location: 'Cincinnati, OH',
        date: 'Apr 04, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162942/82259964_1_x.jpg?height=94&quality=70&version=1',
        message: 'Good company. Efficient and obliging. Excellent to deal with-no fus, no bother.',
        short_msg: 'Check watch pix thoroughly for gold colour if you want yellow gold',
        userName: 'Anthony',
        location: 'South Launceston, AU',
        date: 'Apr 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162942/82259655_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Shipping is fairly quick. Pictures are well packed in bubble wrap. Pics exactly as described..',
        short_msg: 'Excellent seller. Easy to deal with',
        userName: 'Craig',
        location: 'Markham, Canada',
        date: 'Apr 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162818/82203203_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'San Marcos, Texas',
        date: 'Apr 02, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/201314/101955015_1_x.jpg?height=94&quality=70&version=1',
        message: 'They are honest and fast and you get what you pay for. I trust them period..',
        short_msg: 'They are honest and fast',
        userName: 'ron',
        location: 'Fox Point, WI',
        date: 'May 21, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/199671/100941049_1_x.jpg?height=94&quality=70&version=1',
        message: 'I love my products and how quickly the package was delivered',
        short_msg: 'The quality of products',
        userName: 'Cheryl',
        location: 'Oakland, CA',
        date: 'May 20, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150808/76296897_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Karen',
        location: 'Plaquemine, LA',
        date: 'May 17, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150812/76537014_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Karen',
        location: 'Plaquemine, LA',
        date: 'May 17, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/200670/101565633_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great service',
        short_msg: 'Good people',
        userName: 'Len',
        location: 'Melrose Park, IL',
        date: 'May 15, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/200670/101565612_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I paid an enormous $40.00 for a watch that normally sells for $400.00. It is the second one I bought and I have no regrets. My Grandson won't either when he receives it.",
        short_msg: 'Ulysse Girard Watch',
        userName: 'Jack',
        location: 'Lawndale, CA',
        date: 'May 15, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/199768/101081590_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great auction house',
        short_msg: 'Fast shipping and items as described',
        userName: 'Marie',
        location: 'Celina, TX',
        date: 'May 12, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/199927/101181157_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Cosmo',
        location: 'Katy, TX',
        date: 'May 11, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/197557/99793683_1_x.jpg?height=94&quality=70&version=1',
        message: 'This pendant is more beautiful in person than in the picture and its HUGE!!',
        short_msg: 'Love this Pendant ❤ ️.',
        userName: 'Carla',
        location: 'Turlock, CA',
        date: 'May 11, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/199927/101181154_1_x.jpg?height=94&quality=70&version=1',
        message: 'Nice and first rate people.',
        short_msg: 'Solid company. A pleasure to do business with.',
        userName: 'Len',
        location: 'Melrose Park, IL',
        date: 'May 10, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/199290/100770440_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great auction site.',
        short_msg: 'Beautiful bracelet by a well-known designer in the Jewelery World',
        userName: 'Carole',
        location: 'Anaheim, CA',
        date: 'May 08, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/199670/100940913_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Alexandra',
        location: 'Dacula, GA',
        date: 'May 06, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/197250/99566326_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'China Grove, NC',
        date: 'May 05, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/197351/99632432_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'China Grove, NC',
        date: 'May 05, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198041/100094184_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'China Grove, NC',
        date: 'May 05, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198569/100336969_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'China Grove, NC',
        date: 'May 05, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198796/100476140_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'China Grove, NC',
        date: 'May 05, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198851/100505174_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'China Grove, NC',
        date: 'May 05, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/199290/100770456_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'China Grove, NC',
        date: 'May 05, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198679/100378507_1_x.jpg?height=94&quality=70&version=1',
        message: 'good transaction',
        short_msg: 'fast',
        userName: 'Frank',
        location: 'China Grove, NC',
        date: 'May 05, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/199290/100770453_1_x.jpg?height=94&quality=70&version=1',
        message:
            'It is a great experience each time I receive an item I have won. They ship immediately and are always helpful with any questions.',
        short_msg: 'Reliable and reputable.',
        userName: 'Priscilla',
        location: 'Glendale, AZ',
        date: 'May 04, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/199290/100770519_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ric',
        location: 'Carthage, MO',
        date: 'May 04, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198679/100378454_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Andrew',
        location: 'San Francisco, CA',
        date: 'May 03, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198851/100505203_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very nice sale, smooth no issues',
        short_msg: 'Wife is very happy with these, so them so am I;)',
        userName: 'KEVIN P',
        location: 'Scranton, PA',
        date: 'May 03, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/199159/100666068_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Andrew',
        location: 'Rowlett, TX',
        date: 'May 01, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/199159/100666029_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jamie',
        location: 'Birdsboro, Pennsylvania',
        date: 'Apr 30, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198796/100476110_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Charles',
        location: 'Magnolia, TX',
        date: 'Apr 29, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198679/100378434_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Prompt and no drama service + delivery. Beautiful bracelet packaged in jewelry box. Very satisfied. Tried to buy a similar bracelet i was satisfied but it was sold.',
        short_msg: 'Beautiful Silver and Turquoise bracelet packaged in jewelry box',
        userName: 'Susan',
        location: 'Austin, Texas',
        date: 'Apr 24, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198679/100378472_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Danny',
        location: 'Paradise Valley, AZ',
        date: 'Apr 24, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198569/100336996_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I have purchased many items from Dalshire end will continue to purchase. Very happy with overall experience. Shipping is expensive.',
        short_msg: 'Overall Experience',
        userName: 'Rosanne',
        location: 'Okatie, South Carolina',
        date: 'Apr 23, 2021',
        stars: '5',
    },
    {
        img_url: 'https://www.liveauctioneers.com/auctioneer/6667/dalshire-international/reviews',
        message: '',
        short_msg: '',
        userName: 'Len',
        location: 'Melrose Park, IL',
        date: 'Apr 23, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198569/100336984_1_x.jpg?height=94&quality=70&version=1',
        message: 'GREAT AUCTION HOUSE... VERY WELL RUN....',
        short_msg: 'GREAT AUCTION HOUSE',
        userName: 'Michael',
        location: 'Jacksonville, FL',
        date: 'Apr 23, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/197833/99968571_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'fred',
        location: 'Hackensack, NJ',
        date: 'Apr 22, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/197833/99968546_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jeff',
        location: 'Huntington Beach, CA',
        date: 'Apr 22, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/197833/99968584_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Dashiell pulls down some awesome stuff. I really enjoy when they release an auction, looking through all the lots. Pretty easy to work with. Keep it up!',
        short_msg: 'Great Stuff',
        userName: 'Myles',
        location: 'Land O Lakes, FL',
        date: 'Apr 21, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/197833/99968526_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'Charleston, South Carolina',
        date: 'Apr 21, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198041/100094111_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Alexandra',
        location: 'Dacula, GA',
        date: 'Apr 21, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/198023/100069917_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I trust Dalshire with their descriptions of the items and know I will be receiving the item in a timely fashion.',
        short_msg: 'Dealing with Dalshire',
        userName: 'Richard',
        location: 'Longboat Key, Florida',
        date: 'Apr 19, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            "Dalshire was quick to respond to my concerns and everything has worked quite well. I'm very happy with my experience with Dalshire.",
        short_msg: 'Good communication',
        userName: 'Ted',
        location: 'Spearfish, SD',
        date: 'Apr 18, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/197998/100044620_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'GUY',
        location: 'Smithfield, RI',
        date: 'Apr 17, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/197998/100044622_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Apr 17, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/197351/99632357_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Camil',
        location: 'Chicoutimi, CA',
        date: 'Apr 17, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/197351/99632358_1_x.jpg?height=94&quality=70&version=1',
        message: 'A nice auction house with a wide array of items.',
        short_msg: 'Great Experience',
        userName: 'John J',
        location: 'Grayslake, IL',
        date: 'Apr 16, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/197351/99632409_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Alexandra',
        location: 'Dacula, GA',
        date: 'Apr 16, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/197351/99632389_1_x.jpg?height=94&quality=70&version=1',
        message: 'My earrings were exactly as described and photographed.',
        short_msg: 'Great experience',
        userName: 'Siobhan',
        location: 'Westport, CT',
        date: 'Apr 15, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174594/87859891_1_x.jpg?height=94&quality=70&version=1',
        message: 'NOTHING',
        short_msg: 'GRANDSON LOVES HIS WATCH GOOD PRICE',
        userName: 'homer',
        location: 'Fort Worth, TX',
        date: 'Apr 11, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'China Grove, NC',
        date: 'Apr 09, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'Very Professional, Reputable and Trustworthy. Fair Final Prices and Quick Delivery. —',
        short_msg: 'What You See, Is What You Get.',
        userName: 'Robert',
        location: 'Palm Beach, FL',
        date: 'Apr 08, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: 'I have enjoyed my working with Dalshire. You can bid with confidence.',
        short_msg: 'Reliable Auction',
        userName: 'Charles',
        location: 'Bellport, NY',
        date: 'Apr 05, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Tim',
        location: 'Greenville, MI',
        date: 'Mar 30, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: 'High quality items that are exactly as described. Fast shipping. Thank you.',
        short_msg: 'Very professional Auctioneer with fast shipping.',
        userName: 'Marie',
        location: 'Celina, TX',
        date: 'Mar 30, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Quinnesec, MI',
        date: 'Mar 29, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Quinnesec, MI',
        date: 'Mar 29, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Deborah',
        location: 'Rutland, VT',
        date: 'Mar 28, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'Manteca, California',
        date: 'Mar 26, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Excellent purchase! Professional Seller!',
        short_msg: 'Fantastic',
        userName: 'Collin',
        location: 'Los Angeles, CA',
        date: 'Mar 26, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Great Product Smooth Transaction!',
        short_msg: 'Great Transaction',
        userName: 'Collin',
        location: 'Los Angeles, CA',
        date: 'Mar 25, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'I have purchased a few items at auction via Dalshire over the last two years. Consistently items of interest to my wife and I have always lived up our expectations, at a fair price. Living in New York and Palm Beach we believe we know quality and value.',
        short_msg: 'Dalshire is Reliable, Very Professional.',
        userName: 'Robert',
        location: 'Palm Beach, FL',
        date: 'Mar 23, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: 'A great auction house that provides quality items at reasonable starting prices.',
        short_msg: 'Great Auction House',
        userName: 'Michael',
        location: 'Jacksonville, FL',
        date: 'May 04, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Dallas, TX',
        date: 'Mar 22, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'I always love a mystery and this sure was one. I liked the little charms and necklace, wish charms were a bit bigger though. Good Experience!',
        short_msg: 'Nice Items Fast Bidding and Delivery',
        userName: 'Catherine',
        location: 'Rochester Hills, MI',
        date: 'Mar 21, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Debbie',
        location: 'Livermore, CA',
        date: 'Mar 21, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Items as described, fast, accurate shipping',
        short_msg: 'Great to buy from',
        userName: 'Karl',
        location: 'Ocala, Florida',
        date: 'Mar 19, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/194516/98060084_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Very nice product with exact description including authentificated certificate. Very satisfied. TY',
        short_msg: 'Wonderful piece with authentification.',
        userName: 'Chantal',
        location: 'Varennes, CA',
        date: 'Mar 18, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/194516/98060094_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Gordon',
        location: 'Pismo Beach, CA',
        date: 'Mar 17, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Sue',
        location: 'Newmarket, NH',
        date: 'Mar 16, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'The entire process was a very positive experience; professional helpful staffing, rapid processing, quick and well packed shipment. I will continue to bid without hesitation. Thank you.',
        short_msg: 'Thoroughly enjoyable experience.',
        userName: 'Edward',
        location: 'Albany, NY',
        date: 'Mar 15, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Suzzaynne',
        location: 'Vancouver, WA',
        date: 'Mar 15, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            "I have received many compliments on the items I bid on and won through Dalshire Auctions. I place my best bid, then go to bed as the auctions are often at night. If I win-great. I do win-often. Excellent customer service. Item arrives in a timely manner and packaged perfectly. I couldn't be happier!",
        short_msg: 'Variety of items and a good chance of winning bids',
        userName: 'Jamie',
        location: 'Winston Salem, NC',
        date: 'Mar 15, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'All jewelry items are beautiful and as expected. Individually packaged and presented with care. Great seller, thanks.',
        short_msg: 'Beautiful jewelry, great auction experience.',
        userName: 'Jackie',
        location: 'Auburn, WA',
        date: 'Mar 15, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'All jewelry items are beautiful and as expected. Individually packaged and presented with care. Great seller, thanks.',
        short_msg: 'Beautiful items, wonderful experience.',
        userName: 'Jackie',
        location: 'Auburn, WA',
        date: 'Mar 15, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Charles',
        location: 'Jersey City, NJ',
        date: 'Mar 12, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: 'I was completely satisfied with the signed and mounted picture I bought.',
        short_msg: 'Fast and accurate',
        userName: 'William',
        location: 'Conroe, TX',
        date: 'Mar 12, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Susan',
        location: 'Middlefield, CT',
        date: 'Mar 12, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'A extremely professional, efficient operation that offers quality products at very fair price ranges!!',
        short_msg: 'Items up for Auction are always exactly what you hope for when delivered.',
        userName: 'Robert',
        location: 'Palm Beach, FL',
        date: 'Mar 11, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'James',
        location: 'Kansas City, MO',
        date: 'Mar 11, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Elizabeth',
        location: 'Winston Salem, NC',
        date: 'Mar 10, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'John J',
        location: 'Grayslake, IL',
        date: 'Mar 09, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'thomas',
        location: 'Port Saint Lucie, FL',
        date: 'Mar 08, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'The items were exactly as described. Beautiful when received. I will continue to follow and hopefully win!',
        short_msg: 'Product',
        userName: 'Kathleen',
        location: 'Tecate, CA',
        date: 'Mar 08, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Suzzaynne',
        location: 'Vancouver, WA',
        date: 'Mar 06, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'Dalshire Internationalis the fastest shipping auction house I have had the pleasure of using thanks Folks, KEYRIC',
        short_msg: "need to work on shipping on multiple purchase's",
        userName: 'Ric',
        location: 'Carthage, MO',
        date: 'Mar 06, 2021',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159232/80385334_1_x.jpg?height=94&quality=70&version=1',
        message: 'NICE FOR THIS PRICE.',
        short_msg: 'NICE FOR THIS PRICE.',
        userName: 'Marilyn',
        location: 'Pompton Lakes, New Jersey',
        date: 'Mar 05, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Crystal Lake, IL',
        date: 'Mar 04, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Hoover, Alabama',
        date: 'Mar 01, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Hoover, Alabama',
        date: 'Mar 01, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: 'great company',
        short_msg: 'great company',
        userName: 'edward borash',
        location: 'Marblehead, MA',
        date: 'Mar 01, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'Great pictures and descriptions of the items. Fast, professional shipping and packing. Watches in come in a plain gift box with watch pillow. Jewelry comes in a velvet purse bag.',
        short_msg: 'Beautiful, Quality items at a reasonable price.',
        userName: 'Dana',
        location: 'Smyrna, GA',
        date: 'Feb 27, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Robert',
        location: 'Meadowbrook, Pennsylvania',
        date: 'Feb 23, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Dallas, TX',
        date: 'Feb 22, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: 'I WILL BE A LONG TERM CUSTOMER/',
        short_msg: 'ITEMS ARRIVED ON TIME AND AT THE CORRECT PRICE......... 100 % GOOD THANK YOU',
        userName: 'JACKIE',
        location: 'Austin, Texas',
        date: 'Feb 22, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'All went smoothly, I would deal with Dalshire International again without question!',
        short_msg: 'Easy',
        userName: 'William',
        location: 'Midland, CA',
        date: 'Feb 20, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Newport, RI',
        date: 'Feb 20, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'I feel very comfortable having Dalshire as a counter party; they are most professional.',
        short_msg: 'Dalshire',
        userName: 'Richard',
        location: 'Longboat Key, Florida',
        date: 'Feb 20, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: 'I WILL BE A LONG TIME CUSTOMER.........',
        short_msg: 'ALWAYS ARRIVES ON TIME AT THE CORRECT PRICE.... 100 % HAPPY',
        userName: 'JACKIE',
        location: 'Austin, Texas',
        date: 'Feb 20, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Joseph',
        location: 'Glendora, NJ',
        date: 'Feb 19, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            "Dalshire has never disappointed me, love each piece I've purchased, for myself or as a gift.",
        short_msg: "Dalshire doesn't disappoint.",
        userName: 'Holly',
        location: 'Mohave Valley, AZ',
        date: 'Feb 19, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: 'ALL ITEMS ARRIVED ON TIME AND AT THE CORRECT PRICE.',
        short_msg: 'EVERY THING WAS 100 % THE WAY YOU SHOULD BE ABLE TO EXPECT.',
        userName: 'JACKIE',
        location: 'Austin, Texas',
        date: 'Feb 19, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'Every Thing was 100 % Authentic, and John was the best, answered all my questions, took care of all my needs and the Owner John was THE BEST!!!!!!',
        short_msg:
            'Every Thing was 100 % Authentic, and John was the best, answered all my questions, took care of all my needs and the Owner John was THE BEST!!!!!!',
        userName: 'Joseph',
        location: 'Worcester, MA',
        date: 'Feb 19, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Great auction house. Service is the best.',
        short_msg: 'Great Experience',
        userName: 'Michael',
        location: 'Jacksonville, FL',
        date: 'Feb 19, 2021',
        stars: '5',
    },
    {
        img_url: '',
        message: 'NO PROBLEMS... THANK YOU.',
        short_msg: "ALL ITEMS ARRIVED ON TIME AND AT THE CORRECT PRICE AND THAT'S GREAT.",
        userName: 'JACKIE',
        location: 'Austin, Texas',
        date: 'Feb 18, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'I WILL CONTINUE TO BE A CUSTOMER FOREVER.',
        short_msg: "ITEMS ARRIVED ON TIME AND THE PRICE WAS CORRECT AND THAT'S GREAT.",
        userName: 'JACKIE',
        location: 'Austin, Texas',
        date: 'Feb 18, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Love this great colorful watch! Good service, shipment!',
        short_msg: 'Great watch!',
        userName: 'Tracy',
        location: 'Chapel Hill, NC',
        date: 'Feb 18, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Received item on time, happy with my purchase',
        short_msg: 'Received item on time, happy with my purchase',
        userName: 'Marshall',
        location: 'Grants Pass, OR',
        date: 'Feb 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174125/87637585_1_x.jpg?height=94&quality=70&version=1',
        message: 'Received item on time, happy with purchase',
        short_msg: 'Received item on time, happy with purchase',
        userName: 'Marshall',
        location: 'Grants Pass, OR',
        date: 'Feb 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173603/87380955_1_x.jpg?height=94&quality=70&version=1',
        message: 'Received item on time, happy with purchase',
        short_msg: 'Received item on time, happy with purchase',
        userName: 'Marshall',
        location: 'Grants Pass, OR',
        date: 'Feb 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166610/84060925_1_x.jpg?height=94&quality=70&version=1',
        message: 'Received item on time, happy with purchase',
        short_msg: 'Received item on time, happy with purchase',
        userName: 'Marshall',
        location: 'Grants Pass, OR',
        date: 'Feb 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163874/82743500_1_x.jpg?height=94&quality=70&version=1',
        message: 'Received item on time, happy with purchase',
        short_msg: 'Received item on time, happy with purchase',
        userName: 'Marshall',
        location: 'Grants Pass, OR',
        date: 'Feb 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163366/82470156_1_x.jpg?height=94&quality=70&version=1',
        message: 'Received item on time, happy with purchase',
        short_msg: 'Received item on time, happy with purchase',
        userName: 'Marshall',
        location: 'Grants Pass, OR',
        date: 'Feb 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162947/82260320_1_x.jpg?height=94&quality=70&version=1',
        message: 'Received item on time, happy with purchase',
        short_msg: 'Received item on time, happy with purchase',
        userName: 'Marshall',
        location: 'Grants Pass, OR',
        date: 'Feb 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162159/81902018_1_x.jpg?height=94&quality=70&version=1',
        message: 'Received item on time, happy with purchase',
        short_msg: 'Received item on time, happy with purchase',
        userName: 'Marshall',
        location: 'Grants Pass, OR',
        date: 'Feb 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162159/81902018_1_x.jpg?height=94&quality=70&version=1',
        message: 'Received item on time, happy with purchase',
        short_msg: 'Received item on time, happy with purchase',
        userName: 'Marshall',
        location: 'Grants Pass, OR',
        date: 'Feb 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161807/81686551_1_x.jpg?height=94&quality=70&version=1',
        message: 'Received item on time, happy with purchase',
        short_msg: 'Received item on time, happy with purchase',
        userName: 'Marshall',
        location: 'Grants Pass, OR',
        date: 'Feb 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160372/80944608_1_x.jpg?height=94&quality=70&version=1',
        message: 'Received item on time, happy with purchase',
        short_msg: 'Received item on time, happy with purchase',
        userName: 'Marshall',
        location: 'Grants Pass, OR',
        date: 'Feb 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161597/81572492_1_x.jpg?height=94&quality=70&version=1',
        message: 'Shipping was fast, loved my item',
        short_msg: 'Love my item',
        userName: 'Marshall',
        location: 'Grants Pass, OR',
        date: 'Feb 17, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'I was amazed at how easy this auction house was to work with. I�ve had a few on the west coast I will never buy from again because they are difficult. This guy is super easy yo deal with. Ain�t no place like Texas!',
        short_msg: 'Great auction house',
        userName: 'Sharon',
        location: 'Alvin, Texas',
        date: 'Feb 16, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'mark',
        location: 'Superior, CO',
        date: 'Feb 16, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Easy to work with but one item (earrings) were not what I imagined-picture for bidding did not give measurements',
        short_msg: 'Good outfit/shame on me',
        userName: 'Richard',
        location: 'Longboat Key, Florida',
        date: 'Feb 15, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'great bunch of people John is great to work with super happy with the way im treated and how fast I get my order taken care of',
        short_msg: 'people',
        userName: 'harold',
        location: 'Buffalo, TX',
        date: 'Feb 15, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'I PLAN TO STAY A CUSTOMER WITH DALSHIRE FOREVER.',
        short_msg: "EVERY THING ARRIVED ON TIME AND AT THE RIGHT PRICE AND THAT'S GOOD.",
        userName: 'JACKIE',
        location: 'Austin, Texas',
        date: 'Feb 13, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Jacqueline',
        location: 'Union, NJ',
        date: 'Feb 13, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Steven',
        location: 'Crystal Lake, IL',
        date: 'Feb 13, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'NO PROBLEMS............ THANK YOU',
        short_msg: "ITEMS ARRIVED ON TIME AT THE CORRECT PRICE AND THAT'S GOOD.",
        userName: 'JACKIE',
        location: 'Austin, Texas',
        date: 'Feb 12, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Jacqueline',
        location: 'Union, NJ',
        date: 'Feb 12, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'edward borash',
        location: 'Marblehead, MA',
        date: 'Feb 11, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'GOOD JOB,,,,,,,,,, THANK YOU',
        short_msg: 'THE ITEMS ARRIVED ON TIME AND AT THE CORRECT PRICE.',
        userName: 'JACKIE',
        location: 'Austin, Texas',
        date: 'Feb 11, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Packaged product purchased Very Well',
        short_msg: 'Excellent Packaging',
        userName: 'THOMAS',
        location: 'Schaumburg, IL',
        date: 'Feb 11, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'EVERY THING WAS GOOD..... NO PROBLEMS.',
        short_msg: 'ITEMS ARRIVED ON TIME AND AT THE CORRECT PRICE.... THANK YOU',
        userName: 'JACKIE',
        location: 'Austin, Texas',
        date: 'Feb 09, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'THANK YOU.... EVERY THING WAS GREAT.',
        short_msg: "ITEMS ARRIVED ON TIME AT THE CORRECT PRICE AND THAT'S GREAT.",
        userName: 'JACKIE',
        location: 'Austin, Texas',
        date: 'Feb 08, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Mark',
        location: 'Columbia, MD',
        date: 'Feb 08, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Marc',
        location: 'Austin, TX',
        date: 'Feb 07, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Great selections of items and the flat rate shipping is a great benefit. Everything was carefully packed and the watches even came in their own gift box with watch pillow.',
        short_msg: 'Quality items shipped reasonably',
        userName: 'Dana',
        location: 'Smyrna, GA',
        date: 'Feb 06, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            "I've participated in many Dalshire Auctions. The merchandise is high quality and the experience is great from start to finish.",
        short_msg: 'A Great Auction Experience',
        userName: 'ELLEN',
        location: 'New York, NY',
        date: 'Feb 06, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'THANK YOU FOR GREAT SERVICE.... HAPPY CAMPER.',
        short_msg: "EVERY THING ARRIVED ON TIME AT THE CORRERCT PRICE.... AND THAT'S GOOD.",
        userName: 'JACKIE',
        location: 'Austin, Texas',
        date: 'Feb 06, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Won an online auction for 2 watches. Great communication and ease of paying to expedite getting items to me with hardly any delay.',
        short_msg: 'Shop with Confidence',
        userName: 'Alan',
        location: 'Palm Harbor, Florida',
        date: 'Feb 05, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Lakeland, FL',
        date: 'Feb 05, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'mark',
        location: 'Superior, CO',
        date: 'Feb 05, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Joseph',
        location: 'Glendora, NJ',
        date: 'Feb 04, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'easy, nice quality, fast follow through with orders, payments, shipping and delivery',
        short_msg: 'excellent experience',
        userName: 'Holly',
        location: 'Mohave Valley, AZ',
        date: 'Feb 03, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/190050/95727630_1_x.jpg?height=94&quality=70&version=1',
        message: 'Dalshire always delivers safely and timely.',
        short_msg: 'Memorabilia',
        userName: 'Catherine',
        location: 'Bonsall, CA',
        date: 'Feb 03, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/190050/95727633_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'mark',
        location: 'Superior, CO',
        date: 'Feb 03, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Jacqueline',
        location: 'Union, NJ',
        date: 'Feb 03, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Kept me informed of the status of my item from bid until I received it.',
        short_msg: 'Easy to work with',
        userName: 'Richard',
        location: 'Longboat Key, Florida',
        date: 'Feb 02, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'I AM HAPPY WITH MY BUYING EXPERIENCE WITH DALSHIRE.',
        short_msg:
            'EVERY THING ARRIVED ON TIME AT THE RIGHT PRICE AND I AM A HAPPY CAMPER.... THANK YOU',
        userName: 'JACKIE',
        location: 'Austin, Texas',
        date: 'Feb 02, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'From registering all the way until my merchandise arrived, this was as easy a process. No surprises. No unnecessary waiting for answers or hidden costs. So easy a process even a novice like myself was able to be notified about auction items, billing and shipping details. Will not hesitate to bid with confidence in the future',
        short_msg: 'Wonderful auction experience',
        userName: 'Alan',
        location: 'Palm Harbor, Florida',
        date: 'Feb 02, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Annie J',
        location: 'Santa Fe, NM',
        date: 'Feb 02, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Suzzaynne',
        location: 'Vancouver, WA',
        date: 'Feb 01, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'HAPPY CAMPER',
        short_msg: 'EVERY THING WAS AS EXPECTED............. THANK YOU',
        userName: 'JACKIE',
        location: 'Austin, Texas',
        date: 'Feb 01, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Newport, RI',
        date: 'Jan 29, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'tom',
        location: 'Bordentown, NJ',
        date: 'Jan 28, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Jacqueline',
        location: 'Union, NJ',
        date: 'Jan 28, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'EVERY THING WAS GOOD....... THANK YOU',
        short_msg: 'ALL ITEMS ARRIVED ON TIME AND AT THE CORRECT PRICE...... THANK YOU.',
        userName: 'JACKIE',
        location: 'Austin, Texas',
        date: 'Jan 26, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Jacqueline',
        location: 'Union, NJ',
        date: 'Jan 26, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'mark',
        location: 'Superior, CO',
        date: 'Jan 26, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Jacqueline',
        location: 'Union, NJ',
        date: 'Jan 26, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'PTY872656 Mariano',
        location: 'Miami, FL',
        date: 'Jan 25, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'THANK YOU FOR A GOOD JOB AT YOUR END OF MY ORDER.',
        short_msg:
            'I HAVE GOTTEN MY ORDERED ITEM AT THE RIGHT PRICE AND I AM A HAPPY CAMPER.... THANK YOU',
        userName: 'JACKIE',
        location: 'Austin, Texas',
        date: 'Jan 23, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Excellent experience. No problems.',
        short_msg: 'great people to deal with',
        userName: 'DAVID',
        location: 'Schertz, TX',
        date: 'Jan 23, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Angel',
        location: 'Fullerton, California',
        date: 'Jan 21, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Angel',
        location: 'Fullerton, California',
        date: 'Jan 21, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Calvin',
        location: 'Hong Kong, HK',
        date: 'Jan 20, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'I had a question about an order. The response was quick. John was very concerned everything was done properly. I highly recommend Dalshire. They are excellent with a quick response and would not let the issue go until it was resolved.',
        short_msg: 'Question about order',
        userName: 'r',
        location: 'Oak Ridge, TN',
        date: 'Jan 19, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Florence, Kentucky',
        date: 'Jan 19, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'John is a professional and a gentleman.',
        short_msg: 'John is a professional and a gentleman.',
        userName: 'michael',
        location: 'Ronkonkoma, NY',
        date: 'Jan 18, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Mandy',
        location: 'Stoke, United Kingdom',
        date: 'Jan 18, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Ione, CA',
        date: 'Jan 16, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'I got a beautiful watch for a great price.',
        short_msg: 'Happy with my watch.',
        userName: 'Steve',
        location: 'Lakeland, FL',
        date: 'Jan 16, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Ronald',
        location: 'Port Orange, FL',
        date: 'Jan 16, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Edward',
        location: 'Silver Spring, MD',
        date: 'Jan 16, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'John is a gentleman and works hard to ensure satisfaction.',
        short_msg: 'John is a gentleman and works hard to ensure satisfaction.',
        userName: 'michael',
        location: 'Ronkonkoma, NY',
        date: 'Jan 16, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Newport, RI',
        date: 'Jan 16, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Very good experience.',
        short_msg: 'Easy Transaction.',
        userName: 'David',
        location: 'Chadds Ford, PA',
        date: 'Jan 11, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'One of the best auction houses here! Never disappoints!',
        short_msg: 'No complaints....',
        userName: 'Angel',
        location: 'Fullerton, California',
        date: 'Jan 11, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'One of the best auction houses here! Never disappoints!',
        short_msg: 'One of the best!!',
        userName: 'Angel',
        location: 'Fullerton, California',
        date: 'Jan 11, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Lydia',
        location: 'Marietta, GA',
        date: 'Jan 09, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'EVERYTHING WAS GREAT',
        short_msg: 'GREAT',
        userName: 'ROBERT',
        location: 'Hawthorne, NJ',
        date: 'Jan 09, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'The earrings were just like the picture but prettier. I love them. Susan Good Hope, GA',
        short_msg: 'accurate',
        userName: 'SUSAN',
        location: 'Good Hope, GA',
        date: 'Jan 09, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Bill',
        location: 'Endicott, New York',
        date: 'Jan 09, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Had a great experience with Dalshire International. They are one of the best with presenting the items you are bidding on.',
        short_msg: 'Great Auction House',
        userName: 'Michael',
        location: 'Jacksonville, FL',
        date: 'Jan 08, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'James',
        location: 'Lancaster, PA',
        date: 'Jan 08, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Maria',
        location: 'Rockhampton City, AU',
        date: 'Jan 08, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Charles',
        location: 'Mechanicsburg, PA',
        date: 'Jan 07, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Guy',
        location: 'San Antonio, Texas',
        date: 'Jan 07, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Arnold',
        location: 'Basel, CH',
        date: 'Jan 05, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Catherine',
        location: 'Quincy, Massachusetts',
        date: 'Jan 05, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'The product was as described, and shipped out in a timely fashion.',
        short_msg: 'Excellent company to deal with.',
        userName: 'mark',
        location: 'Superior, CO',
        date: 'Jan 05, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'I THOUGHT THE AUCTION HOUSE IS REALLY ` A FINE OILED MACHINE. THEY ARE GREAT.',
        short_msg: 'THE BID',
        userName: 'ROBERT',
        location: 'Hawthorne, NJ',
        date: 'Jan 05, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Marcia',
        location: 'Cedar Park, TX',
        date: 'Jan 05, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154943/78216578_1_x.jpg?height=94&quality=70&version=1',
        message:
            'As good as actually viewing the auction photos and descriptions are totally representative of what you purchase. V happy purchaser!',
        short_msg: 'What you see is what you get!',
        userName: 'Mandy',
        location: 'Stoke, United Kingdom',
        date: 'Jan 04, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Great communication. John is a true gentleman. Highly recommend.',
        short_msg: 'Great service.',
        userName: 'michael',
        location: 'Ronkonkoma, NY',
        date: 'Jan 02, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Pearland, TX',
        date: 'Jan 01, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Melissa',
        location: 'Salt Lake City, UT',
        date: 'Jan 01, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/186921/93995614_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Pearland, TX',
        date: 'Dec 31, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/186921/93995593_1_x.jpg?height=94&quality=70&version=1',
        message: 'Got the gift in time for Christmas. Nice work.',
        short_msg: 'Good communication.',
        userName: 'Minku',
        location: 'Chandler, Arizona',
        date: 'Dec 30, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/186921/93995600_1_x.jpg?height=94&quality=70&version=1',
        message:
            'This entire experience was easy and fun opportunity. Everyone I spoke with and dealt with handled my item with courtesy and real professionalism. I will definitely use this auction house again.',
        short_msg: 'Wonderful Experience from start to finish',
        userName: 'Joseph',
        location: 'Alexandria, VA',
        date: 'Dec 30, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'The ability to respond and act critically is the mindset and heart of this auction house. The leadership is committed to its customers and servicing their needs.',
        short_msg: 'Responsive',
        userName: 'Jacqueline',
        location: 'Union, NJ',
        date: 'Dec 30, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Very professional',
        short_msg: 'Serious',
        userName: 'Jose',
        location: 'Caguas, PR',
        date: 'Dec 28, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'PETER',
        location: 'San Antonio, TX',
        date: 'Dec 26, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'Manteca, California',
        date: 'Oct 09, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Great team of professionals who know how to please their clients. Thanks!',
        short_msg: 'Very pleased',
        userName: 'Ron',
        location: 'Auburn, NY',
        date: 'Oct 09, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Madelyn',
        location: 'San Antonio, Texas',
        date: 'Oct 08, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Great service',
        short_msg: 'Great service.. and they deliver unlike Bennifet house.',
        userName: 'Carol',
        location: 'Mesa, AZ',
        date: 'Oct 07, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Great selection, quality items, and in-house shipping makes for a great experience.',
        short_msg: 'quality and timeliness',
        userName: 'Christina',
        location: 'Pennsauken, NJ',
        date: 'Oct 02, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Holly',
        location: 'Roeland Park, KS',
        date: 'Oct 02, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Scott',
        location: 'Tuscola, IL',
        date: 'Oct 01, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Scott',
        location: 'Tuscola, IL',
        date: 'Oct 01, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Scott',
        location: 'Tuscola, IL',
        date: 'Oct 01, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'I have now bought 3 watches from Dalshire, all of which I love, and every experience has been excellent: great prices, fast, careful shipping, and seamless transactions. Highly recommend this auction house!',
        short_msg: 'Great auction house-happy customer!',
        userName: 'Kevin',
        location: 'Gilbert, AZ',
        date: 'Oct 01, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Holly',
        location: 'Mohave Valley, AZ',
        date: 'Sep 30, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Wasilla, AK',
        date: 'Sep 29, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'good items and fast shipping! Very pleased! Thank you!',
        short_msg: 'good items and fast shipping! Very pleased! Thank you!',
        userName: 'Alla',
        location: 'Reynoldsburg, OH',
        date: 'Sep 29, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Wasilla, AK',
        date: 'Sep 28, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'This auction house was great. The price for shipping was fair. One auction house charged me over one-hundred plus dollars to ship a one pound package from NYC to ATL when I knew the cost would be far less.',
        short_msg: 'Very happy with company and service.',
        userName: 'jerald',
        location: 'Dallas, GA',
        date: 'Sep 26, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Really enjoy working with the company. Great bunch of people',
        short_msg: 'Great people',
        userName: 'harold',
        location: 'Buffalo, TX',
        date: 'Sep 26, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Shipping was quite fast. Like my lots that I won.',
        short_msg: 'Quality merchandise.',
        userName: 'Steve',
        location: 'Lakeland, FL',
        date: 'Sep 26, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Very nice!!',
        short_msg: 'Beautiful necklace',
        userName: 'Carole',
        location: 'Clearwater, FL',
        date: 'Sep 26, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'St Petersburg, Florida',
        date: 'Sep 26, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Allison',
        location: 'Seattle, Washington',
        date: 'Sep 25, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Beautiful!!',
        short_msg: 'Quality',
        userName: 'Carole',
        location: 'Clearwater, FL',
        date: 'Sep 25, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'This was my second online auction with Delshire Auctions. I felt comfortable with the pictured item and the accompanying quality explanation for accuracy. The watch item was well worth the expenditure and the wait. Dalshire delivers on each posting for quality and actual item value. Great job!',
        short_msg: 'Delshire Delivers Again',
        userName: 'Gene',
        location: 'Shamong, New Jersey',
        date: 'Sep 24, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Item is just as described.',
        short_msg: 'Great packaging',
        userName: 'Donald',
        location: 'Livermore, California',
        date: 'Sep 24, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Beautiful!!!',
        short_msg: 'Quality',
        userName: 'Carole',
        location: 'Clearwater, FL',
        date: 'Sep 24, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Love doing business with DALSHIRE. Fast shipping on great merchandise.',
        short_msg: 'Great merchandise at good price.',
        userName: 'Steve',
        location: 'Lakeland, FL',
        date: 'Sep 24, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Got a beautiful piece at a great price. Very pleased and very fast shipping.',
        short_msg: 'Dalshire International',
        userName: 'Sue',
        location: 'Newmarket, NH',
        date: 'Sep 22, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Kathleen',
        location: 'Valley City, Ohio',
        date: 'Sep 22, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Very quick to receive, the item was perfect, and love that it comes with packaging to sell or gift! Thank you!',
        short_msg:
            'very quick to receive, love how it comes in packing that makes it perfect for gifting or selling.',
        userName: 'Angela',
        location: 'Halifax, Canada',
        date: 'Sep 21, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Allison',
        location: 'Seattle, Washington',
        date: 'Sep 19, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'I do a decent amount of auction purchases and Dalshire was among the fastest to get my items to me.',
        short_msg: 'Fast, easy service!',
        userName: 'Shannon',
        location: 'Olney, Maryland',
        date: 'Sep 19, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            "I submitted a bid before the auction, missed the live auction and still won my items for unbelievable price much to my surprise. Other items I've won at competing auction houses took weeks to arrive but I got my Dalshire items in less than 10 days without inquiry as to when they would ship. Thanks Dalshire!",
        short_msg: 'Pleasantly surprised and very impressed!',
        userName: 'Joel',
        location: 'Bloomfield Twp, Michigan',
        date: 'Sep 19, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'I�ve made a few bids and have been happy with my results, good company, very reliable, thank you.',
        short_msg: 'Good experience',
        userName: 'Frank',
        location: 'Sacramento, CA',
        date: 'Sep 18, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Denise',
        location: 'Social Circle, Georgia',
        date: 'Sep 16, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Anthony',
        location: 'Bensalem, PA',
        date: 'Sep 15, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Melissa',
        location: 'Jackson, Missouri',
        date: 'Sep 13, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Great condition and very well packaged for shipping.',
        short_msg: 'Quality item',
        userName: 'Donald',
        location: 'Livermore, California',
        date: 'Sep 12, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'Always have a positive experience with Dalshire International. Looking forward to my next transaction with them.',
        short_msg: 'Always a pleasure',
        userName: 'Michael',
        location: 'Wasilla, AK',
        date: 'Sep 11, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'Customer service is superbly executed!',
        short_msg: 'Customer Service is Great!',
        userName: 'Jacqueline',
        location: 'Union, NJ',
        date: 'Sep 08, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Atlanta, Georgia',
        date: 'Sep 06, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'I received a broken watch, so I emailed Dalshire. They wrote back and said they would send another-AND THEY REALLY DID! I could not be happier with their level of customer care.',
        short_msg: 'THEY MADE IT RIGHT!',
        userName: 'Dorothy',
        location: 'Sarasota, Florida',
        date: 'Sep 06, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'no hassle experience; prompt service',
        short_msg: 'excellent service',
        userName: 'Don',
        location: 'Oak Island, NC',
        date: 'Sep 05, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'from biding to receiving your package works like a chime.',
        short_msg: 'they are dependably',
        userName: 'alev',
        location: 'North Myrtle Beach, SC',
        date: 'Sep 04, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/175210/88202714_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'stephen',
        location: 'Calumet City, IL',
        date: 'Sep 04, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/175210/88202783_1_x.jpg?height=94&quality=70&version=1',
        message: 'This company is very dependable and professional in all they do.',
        short_msg: 'Great Service!!',
        userName: 'Deborah',
        location: 'Lockhart, TX',
        date: 'Sep 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/175143/88156300_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kevin',
        location: 'Annapolis, MD',
        date: 'Sep 02, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/175143/88156349_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great merchandise at great prices. Fast and quality shipping.',
        short_msg: 'A real bargain for a prized autographed photo.',
        userName: 'Steve',
        location: 'Lakeland, FL',
        date: 'Sep 01, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/175143/88156408_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kathy',
        location: 'Wilmington, NC',
        date: 'Sep 01, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173849/87506078_1_x.jpg?height=94&quality=70&version=1',
        message: 'Absolutely fabulous quality will really enjoy wearing the rings.',
        short_msg: "Quality items unique to Dalshire you won't find elsewhere",
        userName: 'Sheila',
        location: 'Rockwood, CA',
        date: 'Sep 01, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/175127/88144469_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Lydia',
        location: 'Marietta, GA',
        date: 'Aug 31, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174875/87985047_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Dalshire was very professional and prompt with delivery. I love my items and will definitely buy from them again.',
        short_msg: 'Great Experience',
        userName: 'Shannon',
        location: 'Olney, Maryland',
        date: 'Aug 28, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174875/87984982_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'James',
        location: 'Port Chester, New York',
        date: 'Aug 28, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174594/87859914_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I was more than satisfied with the entire transaction, during and after the auction. I�ve been pleased with working with & through Dalshire Int�l. to make a successful bid. Thank you very much.',
        short_msg: 'Satisfied',
        userName: 'Glennon',
        location: 'Saint Louis, MO',
        date: 'Aug 25, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174594/87859957_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Constance',
        location: 'Phoenix, Arizona',
        date: 'Aug 25, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174582/87857854_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I was so stunned when I found out I won, I had a low bid, so I was not sure if I would win. When I received this, it was bigger than I thought, it is a good size and has good coloring. Will have it appraised as soon as I can. But if it is not real, I don't care, the ring was a great price and I will enjoy wearing anyway.",
        short_msg: 'JUST BEAUTIFUL',
        userName: 'Marianne',
        location: 'Sherman Oaks, CA',
        date: 'Aug 25, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174273/87691954_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Edward',
        location: 'Piney Flats, Tennessee',
        date: 'Aug 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174273/87691955_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Dorothy',
        location: 'Sarasota, Florida',
        date: 'Aug 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174273/87692014_1_x.jpg?height=94&quality=70&version=1',
        message: 'Prompt shipping and answers to inquiries.',
        short_msg: 'Prompt shipping. But they should offer discounted shipping on multiple lots.',
        userName: 'Steve',
        location: 'Lakeland, FL',
        date: 'Aug 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174273/87691964_1_x.jpg?height=94&quality=70&version=1',
        message: 'Fast and efficient service',
        short_msg: 'Professional',
        userName: 'Natalie',
        location: 'North Scituate, RI',
        date: 'Aug 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174245/87682788_1_x.jpg?height=94&quality=70&version=1',
        message: 'Purchased several items! All were as promised, and made us very happy!',
        short_msg: 'It is as it appears to be!',
        userName: 'Dorothy',
        location: 'Sarasota, Florida',
        date: 'Aug 21, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174125/87637644_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Edward',
        location: 'Silver Spring, MD',
        date: 'Aug 21, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173711/87432975_1_x.jpg?height=94&quality=70&version=1',
        message:
            'This is a really beautiful sparkly ring the depth of green is fantastic and top quality chrome diopside.',
        short_msg: 'A great seller with quality items',
        userName: 'Sheila',
        location: 'Rockwood, CA',
        date: 'Aug 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174125/87637619_1_x.jpg?height=94&quality=70&version=1',
        message: 'The best experience ever. No hassle and purely business! I am always pleased!',
        short_msg: 'Effective servicing and personably professional staff.',
        userName: 'Jacqueline',
        location: 'Union, NJ',
        date: 'Aug 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174125/87637601_1_x.jpg?height=94&quality=70&version=1',
        message:
            'They are very fast on invoicing and shipping. The auctions are very easy to follow along online, even with my phone. All the jewelry I have bought has been better than I expected.',
        short_msg: 'Very fast invoicing and shipping',
        userName: 'Terralynn',
        location: 'Wichita Falls, Texas',
        date: 'Aug 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173711/87433034_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The only issue I found that bother me a little was the watch looked more rose gold, than a gold that it suggested in the description.',
        short_msg: 'The only thing...',
        userName: 'Danielle',
        location: 'Lancaster, Pennsylvania',
        date: 'Aug 18, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173711/87433032_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Chris',
        location: 'Amarillo, TX',
        date: 'Aug 17, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173603/87380962_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Very fast to ship them out, I was impressed by the speed, packaging, and thoroughness.',
        short_msg: 'Exactly if not better than expected',
        userName: 'ron',
        location: 'Oklahoma City, Oklahoma',
        date: 'Aug 14, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173603/87380935_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jessica',
        location: 'Richmond, CA',
        date: 'Aug 14, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173603/87380987_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Edward',
        location: 'Silver Spring, MD',
        date: 'Aug 14, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173603/87380934_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ronald',
        location: 'California City, California',
        date: 'Aug 14, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173673/87417804_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kenneth',
        location: 'Atlanta, GA',
        date: 'Aug 13, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173673/87417881_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Lynn',
        location: 'Bedford, TX',
        date: 'Aug 13, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173510/87342742_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ronald',
        location: 'California City, California',
        date: 'Aug 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173461/87324355_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ronald',
        location: 'California City, California',
        date: 'Aug 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171777/86528163_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Alfred',
        location: 'New York, NY',
        date: 'Aug 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/172105/86691112_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Alfred',
        location: 'New York, NY',
        date: 'Aug 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173461/87324291_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Atlanta, Georgia',
        date: 'Aug 10, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/173461/87324336_1_x.jpg?height=94&quality=70&version=1',
        message: 'Fast & Friendly',
        short_msg: 'Fast & Friendly',
        userName: 'Greg',
        location: 'Stouffville, CA',
        date: 'Aug 10, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171586/86444436_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Renee',
        location: 'Statesville, NC',
        date: 'Aug 10, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/172600/86901095_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Great experience. Ring was even prettier than the image. Fast delivery, great service.',
        short_msg: 'Great experience. Rings was even prettier then the image. Fast delivery',
        userName: 'Sheri',
        location: 'Solon, Kansas',
        date: 'Aug 04, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/172597/86901016_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very nice merchandise, fast shipping & excellent service.',
        short_msg: 'Great Auctions',
        userName: 'ELLEN',
        location: 'New York, NY',
        date: 'Aug 01, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/172597/86900978_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ronald',
        location: 'Port Orange, FL',
        date: 'Aug 01, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/172281/86781606_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Andrea',
        location: 'Wesley Chapel, Florida',
        date: 'Aug 01, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171010/86163634_1_x.jpg?height=94&quality=70&version=1',
        message: 'What a lovely pair of sparkly earrings at a great price.',
        short_msg: 'Great auctions with quality products',
        userName: 'Sheila',
        location: 'Rockwood, CA',
        date: 'Jul 31, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/172251/86770172_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Crystal Lake, IL',
        date: 'Jul 30, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/172251/86770166_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Dalshire notified me and provided me with a tracking number-there wasn�t any apprehension over when I would receive the item.',
        short_msg: 'Quick; efficient',
        userName: 'Richard',
        location: 'Longboat Key, Florida',
        date: 'Jul 30, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171586/86444448_1_x.jpg?height=94&quality=70&version=1',
        message:
            'No fuzz with the shipping process, easy payment and fast delivery! I am very pleased with my purchase. I highly recommend this auction house.',
        short_msg: 'Easy process, fast delivery!',
        userName: 'Edgardo',
        location: 'San Francisco, CA',
        date: 'Jul 30, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/172105/86691115_1_x.jpg?height=94&quality=70&version=1',
        message: 'Pleasant and professional.',
        short_msg: 'Quick and efficient.',
        userName: 'Barbara',
        location: 'Toronto, Canada',
        date: 'Jul 30, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/172105/86691072_1_x.jpg?height=94&quality=70&version=1',
        message: 'You can allways bid with confidence',
        short_msg: 'very prompt and professional',
        userName: 'Don',
        location: 'Oak Island, NC',
        date: 'Jul 29, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/172105/86691075_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Judi',
        location: 'Punta Gorda, Florida',
        date: 'Jul 29, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/172105/86691106_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Heather',
        location: 'Lynchburg, Virginia',
        date: 'Jul 28, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/172024/86645517_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Theodore',
        location: 'Miller Place, New York',
        date: 'Jul 28, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/172105/86691120_1_x.jpg?height=94&quality=70&version=1',
        message: 'Beautiful earrings and fabulous process from start to finish!',
        short_msg: 'Great experience all around.',
        userName: 'Nichola',
        location: 'Columbia, SC',
        date: 'Jul 28, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/172024/86645563_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Suzzaynne',
        location: 'Vancouver, WA',
        date: 'Jul 27, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171870/86567212_1_x.jpg?height=94&quality=70&version=1',
        message: 'Shipping',
        short_msg:
            'I like the fact you ship it. I hate having to have to go threw all the hassle of finding the shippI',
        userName: 'Connie',
        location: 'Jamul, California',
        date: 'Jul 26, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171685/86496800_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jacqueline',
        location: 'Union, NJ',
        date: 'Jul 24, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171685/86496799_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Suzzaynne',
        location: 'Vancouver, WA',
        date: 'Jul 24, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171685/86496761_1_x.jpg?height=94&quality=70&version=1',
        message:
            "Great price on an awesome watch. I'll be keeping an eye out for more deals just like this one.",
        short_msg: 'WoW Worth it',
        userName: 'Stephen',
        location: 'Philadelphia, PA',
        date: 'Jul 23, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171586/86444431_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jack',
        location: 'Santa Fe Springs, CA',
        date: 'Jul 23, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/171157/86217004_1_x.jpg?height=94&quality=70&version=1',
        message: 'Good to work with you!!!!',
        short_msg: 'Thumbs up!!!',
        userName: 'Felix',
        location: 'Manteca, California',
        date: 'Jul 20, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160367/80944061_1_x.jpg?height=94&quality=70&version=1',
        message: 'High quality at a great price keep up the good work.',
        short_msg: 'Awesome',
        userName: 'Carlitos',
        location: 'Providence, RI',
        date: 'Feb 29, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160367/80944021_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Palmhurst, TX',
        date: 'Feb 29, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160372/80944633_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Elaine',
        location: 'Ashton, Maryland',
        date: 'Feb 29, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159621/80553183_1_x.jpg?height=94&quality=70&version=1',
        message: 'Fabulous unique high quality items of jewelry. Really liked all 3 items.',
        short_msg: 'Great quality items.',
        userName: 'Sheila',
        location: 'Rockwood, CA',
        date: 'Feb 29, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160360/80942447_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kathleen M',
        location: 'Riverside, IL',
        date: 'Feb 27, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160100/80824050_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ricky',
        location: 'Reston, VA',
        date: 'Feb 25, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159089/80336827_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Shipping took a long time due to I gave the wrong address for the shipment. The house was so nice to deal with the mess what I bought to. Those are nice people whom you can deal business with and very straight forward also know how to do business and treat customers',
        short_msg: 'One of the best',
        userName: 'Tony',
        location: 'Woodside, New York',
        date: 'Feb 25, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160100/80824085_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'John J',
        location: 'Grayslake, IL',
        date: 'Feb 25, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160089/80820930_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great service and good product shipped carefully and on time.',
        short_msg: 'Great service',
        userName: 'Kathleen M',
        location: 'Riverside, IL',
        date: 'Feb 25, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160089/80820907_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joanna',
        location: 'Surfside, CA',
        date: 'Feb 24, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160089/80820954_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Outstanding customer service!! John is always awesome to deal with! Definitely my favorite to work with.',
        short_msg: 'Dalshire Auctions Top Notch',
        userName: 'Kelly',
        location: 'Mckinney, Texas',
        date: 'Feb 24, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159922/80707070_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'DAVID',
        location: 'Schertz, TX',
        date: 'Feb 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159784/80626964_1_x.jpg?height=94&quality=70&version=1',
        message: "I'm completely Satisfied with my purchases.",
        short_msg: 'My product is properly sized, quality and design are very nice.',
        userName: 'Holly',
        location: 'Mohave Valley, AZ',
        date: 'Feb 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159922/80707020_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Have always had a good experience with Dalshire International. Bid smart and read the details, their descriptions are accurate.',
        short_msg: 'Always a good experience',
        userName: 'Sue',
        location: 'Newmarket, NH',
        date: 'Feb 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159922/80707098_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jo-An',
        location: 'Saint Petersburg, FL',
        date: 'Feb 21, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159922/80707017_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sandy',
        location: 'San Antonio, TX',
        date: 'Feb 21, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159922/80707024_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Wayde',
        location: 'Many, Louisiana',
        date: 'Feb 21, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159784/80626908_1_x.jpg?height=94&quality=70&version=1',
        message:
            'My Stan Musial autographed picture that I won and received in the mail was much better than I expected. The photo posted in the auction did not do justice to the photo and the superb, professional mount and frame. As usual, my experience with Dalshire was excellent.',
        short_msg: 'Better than I expected',
        userName: 'Ronald',
        location: 'Chesterfield, Missouri',
        date: 'Feb 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159784/80626948_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jo-An',
        location: 'Saint Petersburg, FL',
        date: 'Feb 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159784/80626912_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'R Scott',
        location: 'Tujunga, CA',
        date: 'Feb 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157866/79677964_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Dallas, Texas',
        date: 'Feb 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159623/80553438_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Anna',
        location: 'Merrylands West, AU',
        date: 'Feb 19, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159722/80602492_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Marstons Mills, MAU',
        date: 'Feb 18, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159722/80602531_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Cecil',
        location: 'West Palm Beach, Florida',
        date: 'Feb 18, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159722/80602512_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Donald',
        location: 'Staten Island, New York',
        date: 'Feb 18, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159722/80602496_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joseph',
        location: 'Cincinnati, OH',
        date: 'Feb 18, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159722/80602437_1_x.jpg?height=94&quality=70&version=1',
        message: 'Quick respond, smooth deal',
        short_msg: 'Good auction house',
        userName: 'Tony',
        location: 'Woodside, New York',
        date: 'Feb 18, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159712/80597197_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Deborah',
        location: 'Pinson, Alabama',
        date: 'Feb 18, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159232/80385347_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Finally, an auction house that takes care of all the details and makes it as if you were there! Shipping and handling very well done!',
        short_msg: 'First rate auction house!',
        userName: 'Geoffrey',
        location: 'Murray, NE',
        date: 'Feb 18, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Lakeland, FL',
        date: 'Dec 26, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Thank you.',
        short_msg: 'As always, an A + + + + + Experience.',
        userName: 'Angel',
        location: 'Fullerton, California',
        date: 'Dec 25, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Excellent service',
        short_msg: 'Excellent service',
        userName: 'Alla',
        location: 'Reynoldsburg, OH',
        date: 'Dec 25, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'SUSAN',
        location: 'Good Hope, GA',
        date: 'Dec 25, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            "I am never underwhelmed by this auction house's performance. They DO whats best for their customer. I trust their professionalism immensely!",
        short_msg: 'The staff performance exceeds expectstions',
        userName: 'Jacqueline',
        location: 'Union, NJ',
        date: 'Dec 24, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'Hatboro, PA',
        date: 'Dec 23, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'v',
        short_msg: 'I received the item I bid on and I received it very quickly after purchase.',
        userName: 'Michael',
        location: 'Youngsville, Louisiana',
        date: 'Dec 23, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Terry',
        location: 'Clovis, CA',
        date: 'Dec 22, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Newport, RI',
        date: 'Dec 22, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Aaron',
        location: 'Peekskill, NY',
        date: 'Dec 21, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Everything about this transaction was A + + + + +.',
        short_msg: '100 % Satisfied Repeat Customer',
        userName: 'Angel',
        location: 'Fullerton, California',
        date: 'Dec 21, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'A GREAT COMPANY TO DEAL WITH. EVERYTHING WAS SMOOTH. A + + + + + + + + + + + + + + + + + + + + +',
        short_msg: 'FRAMED MICKEY',
        userName: 'ROBERT',
        location: 'Hawthorne, NJ',
        date: 'Dec 21, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'I have used them many many times and have experienced the same quality of service every time',
        short_msg: 'totally reliable',
        userName: 'Gregg',
        location: 'Port Townsend, WA',
        date: 'Dec 20, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Bracelet was just simply stunning in person & more so than in the pictures.',
        short_msg: 'Pleased',
        userName: 'James',
        location: 'Gaston, SC',
        date: 'Dec 19, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'I have received nothing but high quality items from Dalshire. I keep coming back because they are highly professional, accurate descriptions, high quality items and excellent customer service.',
        short_msg: 'A Premier Auction House',
        userName: 'Angel',
        location: 'Fullerton, California',
        date: 'Dec 15, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'Really loved everything about this auction house. Expert pictures, ease of auction platform, excellent customer service and they provide in-house shipping which is a winner in my book. My item arrived well packaged and exactly as described. Have since bid again with them!',
        short_msg: 'Great Auction House Experience',
        userName: 'Angel',
        location: 'Fullerton, California',
        date: 'Dec 15, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'I bid with Dalshire quite often. Love the products I get a wonderful price. Very reliable to deal with.',
        short_msg: 'Very reliable',
        userName: 'Sue',
        location: 'Newmarket, NH',
        date: 'Dec 14, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Awesome!',
        short_msg: 'Incredibly professional. Highly recommended!',
        userName: 'Richard',
        location: 'Bloomsbury, NJ',
        date: 'Dec 14, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Janean',
        location: 'Reedsport, Oregon',
        date: 'Dec 14, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Great in all respects',
        short_msg: 'Good place to do business',
        userName: 'Salim',
        location: 'Cambridge, Massachusetts',
        date: 'Dec 12, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'Hatboro, PA',
        date: 'Dec 12, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'Hatboro, PA',
        date: 'Dec 12, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Picture was wrapped with the utmost of care. Lots of bubblewrap and tape.',
        short_msg: 'Care of product',
        userName: 'Mike',
        location: 'Springfield, Illinois',
        date: 'Dec 12, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'SUSAN',
        location: 'Good Hope, GA',
        date: 'Dec 11, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/183776/92422490_1_x.jpg?height=94&quality=70&version=1',
        message: 'A very fun and exciting experience!',
        short_msg: 'Extremely Professional',
        userName: 'David',
        location: 'Gresham, Oregon',
        date: 'Dec 09, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Bryan',
        location: 'Tracy, CA',
        date: 'Dec 08, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/181660/91410601_1_x.jpg?height=94&quality=70&version=1',
        message: 'Beautiful items. Seamless transaction & as described. Thank you!',
        short_msg: 'Thank you',
        userName: 'Maria',
        location: 'Moorpark, CA',
        date: 'Dec 07, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/174125/87637643_1_x.jpg?height=94&quality=70&version=1',
        message: 'Items sell for a great price. Will make lovely gifts at Christmas.',
        short_msg: 'Great watch bargains and superb topaz ring.',
        userName: 'Sheila',
        location: 'Rockwood, CA',
        date: 'Dec 07, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Janean',
        location: 'Reedsport, Oregon',
        date: 'Dec 05, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Erin',
        location: 'Papillion, NE',
        date: 'Dec 04, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'A + just as described',
        short_msg: 'Smooth transaction',
        userName: 'Robert',
        location: 'Stormville, NY',
        date: 'Dec 04, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Good service, good merchandise, good experience. What else can you ask for?',
        short_msg: 'Great as always',
        userName: 'Christina',
        location: 'Davenport, Iowa',
        date: 'Dec 03, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/183776/92422525_1_x.jpg?height=94&quality=70&version=1',
        message: 'John did a great job, and I am very happy with my investment',
        short_msg: 'PLEASED',
        userName: 'TIMOTHY',
        location: 'League City, Texas',
        date: 'Dec 02, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Celia',
        location: 'West Vancouver, Canada',
        date: 'Dec 02, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/183776/92422510_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Registration approved timely and item shipped in a reasonable time and properly packaged.',
        short_msg:
            'Registration approved timely and item shipped in a reasonable time and properly packaged.',
        userName: 'Luis',
        location: 'Kingsville, MD',
        date: 'Dec 02, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'I am always excited with the quality of offerings by Dalshire International.',
        short_msg: 'Great selection of curated merchandise',
        userName: 'Jacqueline',
        location: 'Union, NJ',
        date: 'Dec 01, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Luis',
        location: 'Kingsville, MD',
        date: 'Nov 30, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'The personnel of thisvauction house works diligently to remain highly competitive and customer service o',
        short_msg: 'Responsive toward purchaseriented.',
        userName: 'Jacqueline',
        location: 'Union, NJ',
        date: 'Nov 27, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Looks just as described, no issues with purchase',
        short_msg: 'Ring',
        userName: 'Wayde',
        location: 'Many, Louisiana',
        date: 'Nov 27, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Robert',
        location: 'Boulder, CO',
        date: 'Nov 27, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Gail',
        location: 'Atlanta, GA',
        date: 'Nov 26, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165268/83425080_1_x.jpg?height=94&quality=70&version=1',
        message:
            'What else can I say? Great quality items at a fair upfront price you can always count on!',
        short_msg: 'Perfect as always!',
        userName: 'Byron',
        location: 'Atlanta, GA',
        date: 'Nov 22, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167889/84657885_1_x.jpg?height=94&quality=70&version=1',
        message: 'Count on it!',
        short_msg: 'Reliable as always',
        userName: 'Byron',
        location: 'Atlanta, GA',
        date: 'Nov 22, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'There’s a lot to be said for up front shipping prices & knowing exactly what something is going to cost you before you bid on it! Bid with confidence as always',
        short_msg: 'As always, they deliver quality products',
        userName: 'Byron',
        location: 'Atlanta, GA',
        date: 'Nov 22, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'You can always count on getting exactly what you are supposed to be getting....',
        short_msg: 'I’m a repeat customer who buys lots of watches from Dalshire',
        userName: 'Byron',
        location: 'Atlanta, GA',
        date: 'Nov 22, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'The earring are gorgeous',
        short_msg: 'Loved them',
        userName: 'Denise',
        location: 'Social Circle, Georgia',
        date: 'Nov 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156672/79079728_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Nov 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154404/77933465_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Nov 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148835/75698203_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Nov 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148837/75767874_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Nov 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146117/74024930_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Nov 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154635/78049384_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Nov 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154769/78142963_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Nov 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150804/76193461_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Nov 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/181660/91410590_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Renee',
        location: 'Statesville, NC',
        date: 'Nov 20, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Gary',
        location: 'Montgomery, Alabama',
        date: 'Nov 20, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: "good value 'prompt and dependable service",
        short_msg: 'very dependable as always.',
        userName: 'Don',
        location: 'Oak Island, NC',
        date: 'Nov 20, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: "Great items, such a wonderful auction staff, I can't stay away!",
        short_msg: 'Winning is such fun!',
        userName: 'Angela',
        location: 'Mount Laurel, NJ',
        date: 'Nov 17, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'happy with my purchase and its delivery',
        short_msg: 'i received what i bought. it was presented very well to me.',
        userName: 'Michael',
        location: 'St Petersburg, Florida',
        date: 'Nov 17, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'The bracelets were fabulous!!!? A little expensive but original and very well made. I had no issues with the auctioneer.',
        short_msg: 'Quality',
        userName: 'Sabra',
        location: 'Miami, FL',
        date: 'Nov 16, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            "The items are exactly as described. The customer service of the auction house is exceptional. I will continue to bid with them with the confidence that they'll continue providing high quality items with great care.",
        short_msg: 'Excellent items, personal service',
        userName: 'Wayne',
        location: 'Orange, California',
        date: 'Nov 16, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Maria',
        location: 'Moorpark, CA',
        date: 'Nov 14, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: '',
        location: '',
        date: 'Nov 13, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Jeff',
        location: 'Dunnville, CA',
        date: 'Nov 13, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'I HAD to win this big, crazy ring! I wear it everyday and am so in love with it!!!',
        short_msg: 'OMG I LOVE THIS RING!!!',
        userName: 'Angela',
        location: 'Mount Laurel, NJ',
        date: 'Nov 09, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Maria',
        location: 'Moorpark, CA',
        date: 'Nov 07, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Arthur',
        location: 'Vienna, VA',
        date: 'Nov 07, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'I love this auction house!',
        short_msg: 'Great as ALWAYS!',
        userName: 'Angela',
        location: 'Mount Laurel, NJ',
        date: 'Nov 07, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Denise',
        location: 'Social Circle, Georgia',
        date: 'Nov 06, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Maria',
        location: 'Rockhampton City, AU',
        date: 'Nov 06, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Marianne',
        location: 'Sherman Oaks, CA',
        date: 'Nov 06, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            "Prior to covid19 my wife and I loved to travel. We especially liked the West Coast, Taos, Sante Fe, and Albuquerque New Mexico. Of course a million other places! I loved buying jewelry for my wife, especially earrings, which she wears all the time. I had pretty much given up on that until I started checking our Dalshire International. I found that they had an amazing selection and equally amazing prices, shipping and overall service. For example: I found a beautiful of old pawn silver, micro-inlaid, Navajo made earrings for an incredible price! Having been through 100's of Native American jewelry shops from Vancouver Island throughout the Southwest, Texas, and the Northwest including Oregon, Washington and Idaho as well as our home state of California I've seen all kinds of jewelry and all kinds of prices. I would have no difficulty in recommending Dalshire International to anyone! Be careful! It is addicting! John G.",
        short_msg: 'Beautiful Jewelry!',
        userName: 'John',
        location: 'Manteca, California',
        date: 'Nov 06, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Hilke',
        location: 'Longwood, FL',
        date: 'Nov 06, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'After bidding on and receiving a watch that looked like a wall clock on my wrist, I messaged a request to Dashire that they include the face diameter of the watches listed on auction. Within a few weeks, they entertained my request. Five stars!',
        short_msg: 'Dashire listens!',
        userName: 'Steven',
        location: 'Redondo Beach, CA',
        date: 'Nov 05, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'Needed item quickly as a present and they shipped quickly to help me out. Great to deal with.',
        short_msg: 'Fantastic people!',
        userName: 'Roger',
        location: 'Tallahassee, FL',
        date: 'Nov 05, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Maria',
        location: 'Moorpark, CA',
        date: 'Nov 03, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'I am forever pleased with the command performance exhibited by this auction experience as executed by Dalshire International.',
        short_msg: 'Efficiency and diligence in the area of customer service.',
        userName: 'Jacqueline',
        location: 'Union, NJ',
        date: 'Nov 02, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Gail D',
        location: 'Vero Beach, FL',
        date: 'Nov 02, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Wasilla, AK',
        date: 'Oct 31, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Theresa',
        location: 'Gill, MA',
        date: 'Oct 31, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Jerry',
        location: 'Saint Louis, MO',
        date: 'Oct 31, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Great to deal with. Customer service was terrific',
        short_msg: 'customer service',
        userName: 'Richard J',
        location: 'North Port, FL',
        date: 'Oct 29, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'What you bid on is what you get',
        short_msg: 'Great deal',
        userName: 'Wayde',
        location: 'Many, Louisiana',
        date: 'Oct 29, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'On receiving my pendant with the mixed stones of sapphire, topaz & aquamarine, it is simply beautiful & a joy to wear. Again, another success. Thank you. Cleo',
        short_msg: 'Beautiful Blues',
        userName: 'Cleo',
        location: '',
        date: 'Oct 25, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Earl',
        location: 'Lake Forest, California',
        date: 'Oct 24, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Gerald',
        location: 'Hampton, Virginia',
        date: 'Oct 24, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Jerry',
        location: 'Saint Louis, MO',
        date: 'Oct 24, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Very pretty delicate bracelet. Love it.',
        short_msg: 'Bracelet',
        userName: 'Sue',
        location: 'Newmarket, NH',
        date: 'Oct 22, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'Highly professional Company. Enjoyed the transaction.',
        short_msg: 'Highly professional Company. Enjoyed the transaction.',
        userName: 'Michael',
        location: 'Jacksonville, FL',
        date: 'Oct 22, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: 'I have purchased several products from them and have been satisfied every time.',
        short_msg: 'Nice object, easy transaction',
        userName: 'Christina',
        location: 'Davenport, Iowa',
        date: 'Oct 22, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'All items were shipped quickly and were well packaged and well protected so as not to get damaged in route.',
        short_msg: 'All items were exactly as pictured and described which was excellent.',
        userName: 'Pamela',
        location: 'Porterville, CA',
        date: 'Oct 22, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'Multiple auction purchases in jewelry and watches Has been highly satisfactory in quality of the item-especially the watches and timeliness of the delivery. They make all purchases easy with high quality customer satisfaction. Thank you Dalshire for your high level of item quality Items and easy made purchases. Not all online auction companies are this reliable.',
        short_msg: 'Delshire-What You See is What You Get!',
        userName: 'Gene',
        location: 'Shamong, New Jersey',
        date: 'Oct 18, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Alan',
        location: 'Chicago, IL',
        date: 'Oct 17, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'Manteca, California',
        date: 'Oct 16, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Alan',
        location: 'Chicago, IL',
        date: 'Oct 16, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message:
            'I look forward to more auctions from Dalshire. I thought they had a great selection and that the hammer prices were very good. The shipping was very fast and the items were in their own boxes inside a padded envelope.',
        short_msg: 'Beautiful products, great prices, easy transaction, fast shipping!',
        userName: 'John',
        location: 'Manteca, California',
        date: 'Oct 16, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Aaron',
        location: 'Peekskill, NY',
        date: 'Oct 12, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Dean',
        location: 'Millville, New Jersey',
        date: 'Oct 12, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Pamela',
        location: 'San Francisco, CA',
        date: 'Oct 10, 2020',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Mark',
        location: 'Niles, Michigan',
        date: 'Oct 10, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/220106/113171487_1_x.jpg?height=94&quality=70&version=1',
        message: 'Fantastic',
        short_msg: 'Pro',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Oct 24, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/220707/113518012_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Windsor, CT',
        date: 'Oct 23, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/219267/112737236_1_x.jpg?height=94&quality=70&version=1',
        message: 'Awesome',
        short_msg: 'In one word',
        userName: 'Tommy',
        location: 'Norristown, PA',
        date: 'Oct 22, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/220115/113176527_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tommy',
        location: 'Norristown, PA',
        date: 'Oct 22, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/221096/113879222_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tommy',
        location: 'Norristown, PA',
        date: 'Oct 22, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/219267/112737243_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I have purchased a few items through this auction house and I have always been happy with them. Payments are easy and they ship the items quickly and carefully.',
        short_msg: 'Great Auction House',
        userName: 'Patricia',
        location: 'Richland, WA',
        date: 'Oct 22, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/214549/109786681_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I received my item very promptly. I was delighted thank you Dalshire Int a very happy customer',
        short_msg: 'Blue stone & diamond cross.',
        userName: 'Linda',
        location: 'Wilmslow, GB',
        date: 'Oct 21, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/219017/112483708_1_x.jpg?height=94&quality=70&version=1',
        message: 'The team@ Dalshire is always helpful and timely! Thank you',
        short_msg: 'Excellent service as usual',
        userName: 'Ron',
        location: 'Auburn, NY',
        date: 'Oct 21, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/218515/112213748_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Hugh',
        location: 'Niagara Falls, NY',
        date: 'Oct 13, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/218513/112213619_1_x.jpg?height=94&quality=70&version=1',
        message: 'Smooth transaction as always!',
        short_msg: 'Cute, fun ring',
        userName: 'Angela',
        location: 'Mount Laurel, NJ',
        date: 'Oct 12, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/218515/112213750_1_x.jpg?height=94&quality=70&version=1',
        message: 'Easy transactions each time. Thanks for that!',
        short_msg: 'Always a pleasure!',
        userName: 'Angela',
        location: 'Mount Laurel, NJ',
        date: 'Oct 12, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/219168/112667578_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Rosalyn',
        location: 'Naples, FL',
        date: 'Oct 11, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/219168/112667574_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Oct 06, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/218328/112072514_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tommy',
        location: 'Norristown, PA',
        date: 'Oct 06, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/217590/111632422_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tommy',
        location: 'Norristown, PA',
        date: 'Oct 06, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message:
            'They write an accurate description, and are easy to work with. I look forward to their monthly memorabilia auction for great historical pieces.',
        short_msg: 'Great Auction House',
        userName: 'Tommy',
        location: 'Land O Lakes, FL',
        date: 'Oct 06, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/219017/112483667_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very Professional',
        short_msg: 'Great products',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Oct 04, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/218447/112170760_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very Professional',
        short_msg: 'Great job',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Oct 04, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/217584/111630223_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jay',
        location: 'Brooklyn, NY',
        date: 'Oct 04, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/217047/111301483_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Great experience for me, fast and simple process registration to bidding, to winning and great delivery time.',
        short_msg: 'Fast and easy process from bidding to delivery',
        userName: 'William',
        location: 'Deltona, FL',
        date: 'Oct 04, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/217580/111628114_1_x.jpg?height=94&quality=70&version=1',
        message: 'Terrific to bid and deal with',
        short_msg: 'Recommend',
        userName: 'Rosalyn',
        location: 'Naples, FL',
        date: 'Oct 03, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/217590/111632488_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Rosalyn',
        location: 'Naples, FL',
        date: 'Oct 03, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/217047/111301505_1_x.jpg?height=94&quality=70&version=1',
        message: 'You will not regret bidding with Dalshire International fairness is the word.',
        short_msg: 'Fairness with the auction bids',
        userName: 'Robert',
        location: 'Phoenix, AZ',
        date: 'Oct 01, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/217042/111299407_1_x.jpg?height=94&quality=70&version=1',
        message: 'enjoying watch keeps time well and seams well made',
        short_msg: 'watch was as described',
        userName: 'Jim',
        location: 'Clinton, IA',
        date: 'Sep 27, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/216241/110815195_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very Professional, made everything easy from start to finish.',
        short_msg: 'Great products',
        userName: 'Stephen',
        location: 'Garland, TX',
        date: 'Sep 25, 2021',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Marietta, GA',
        date: 'Sep 23, 2021',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157865/79677698_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kirk',
        location: 'Newbury Park, CA',
        date: 'Jan 24, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157865/79677590_1_x.jpg?height=94&quality=70&version=1',
        message: `I have bid with Dalshire International several times and love the fact that many of their auctions are "No Reserve" or "$1 Start." I received my orders in record time-they were perfectly packaged and were exactly as represented on the site. Highly recommend!`,
        short_msg: 'Fantastic and very responsive company',
        userName: 'Katherine',
        location: 'Alexandria, Virginia',
        date: 'Jan 24, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157865/79677649_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Dianna',
        location: 'Plainfield, Illinois',
        date: 'Jan 24, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157792/79631335_1_x.jpg?height=94&quality=70&version=1',
        message: 'Always offering excellent items of superior quality.',
        short_msg: 'Quality merchandise.',
        userName: 'Galileo',
        location: 'Albuquerque, NM',
        date: 'Jan 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157792/79631296_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'John J',
        location: 'Grayslake, IL',
        date: 'Jan 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157792/79631319_1_x.jpg?height=94&quality=70&version=1',
        message: 'The good people you can deal with',
        short_msg: 'Very good auction house',
        userName: 'Tony',
        location: 'Woodside, New York',
        date: 'Jan 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157792/79631284_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Woodbridge, CT',
        date: 'Jan 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157104/79303841_1_x.jpg?height=94&quality=70&version=1',
        message: 'arrived in time for Christmas',
        short_msg: 'fast and easy',
        userName: 'Ranae',
        location: 'Los Gatos, California',
        date: 'Jan 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157715/79589861_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'San Marcos, Texas',
        date: 'Jan 21, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157715/79589929_1_x.jpg?height=94&quality=70&version=1',
        message: 'I use them again and again.',
        short_msg: 'Quality',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Jan 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157715/79589867_1_x.jpg?height=94&quality=70&version=1',
        message: 'As described, quick delivery and well packaged.',
        short_msg: 'Well Done',
        userName: 'Dean',
        location: 'Moline, Illinois',
        date: 'Jan 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157715/79589863_1_x.jpg?height=94&quality=70&version=1',
        message: 'Beautiful awesome watch',
        short_msg: 'Awesome watch',
        userName: 'Carlitos',
        location: 'Providence, RI',
        date: 'Jan 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157707/79588935_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Diana',
        location: 'Taylor, MI',
        date: 'Jan 19, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157702/79587539_1_x.jpg?height=94&quality=70&version=1',
        message: 'good experience',
        short_msg: 'collectable item',
        userName: 'Daniel',
        location: 'Union City, California',
        date: 'Jan 18, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157707/79588948_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Pam',
        location: 'Northridge, CA',
        date: 'Jan 18, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157702/79587542_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great communication with the auctioneer fast and rapid response fast delivery',
        short_msg: 'Awesome',
        userName: 'Carlitos',
        location: 'Providence, RI',
        date: 'Jan 17, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157705/79588450_1_x.jpg?height=94&quality=70&version=1',
        message: 'Fast rapid response great communication fast deliveries',
        short_msg: 'Awesome',
        userName: 'Carlitos',
        location: 'Providence, RI',
        date: 'Jan 17, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157705/79588519_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jesse',
        location: 'Austin, Texas',
        date: 'Jan 17, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157702/79587555_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great experience',
        short_msg: 'Simple love it!',
        userName: 'Patty',
        location: 'Kapaau, Hawaii',
        date: 'Jan 17, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157702/79587603_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Arthur',
        location: 'Beach Lake, PA',
        date: 'Jan 16, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157107/79305244_1_x.jpg?height=94&quality=70&version=1',
        message: 'Nice lot',
        short_msg: 'Good auction house',
        userName: 'Tony',
        location: 'Woodside, New York',
        date: 'Jan 16, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157322/79394218_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Ivins, UT',
        date: 'Jan 15, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157321/79394016_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I love it! Bought the item for myself and it’s very pretty, very large ring face, but for perfect.',
        short_msg: 'Love it!',
        userName: 'Lori',
        location: 'Rome, NY',
        date: 'Jan 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157322/79394257_1_x.jpg?height=94&quality=70&version=1',
        message: 'I bought as a gift and she loved it! It was very pretty.',
        short_msg: 'She loved it',
        userName: 'Lori',
        location: 'Rome, NY',
        date: 'Jan 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157322/79394301_1_x.jpg?height=94&quality=70&version=1',
        message: 'Doing an awesome job keep it up thank you',
        short_msg: 'Awesome',
        userName: 'Carlitos',
        location: 'Providence, RI',
        date: 'Jan 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157322/79394200_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I’m well satisfied. They’re honest and item was as described. They did ship quickly. I’m using them again.',
        short_msg: 'excellent service and shipping',
        userName: 'Pamela',
        location: 'Bolton, Mississippi',
        date: 'Jan 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157321/79393994_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very good compliance.',
        short_msg: "NO, it's an excellent auction house",
        userName: 'Shlomo',
        location: 'San Jose, CR',
        date: 'Jan 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157105/79304861_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ranae',
        location: 'Los Gatos, California',
        date: 'Jan 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157321/79394009_1_x.jpg?height=94&quality=70&version=1',
        message: 'They were lovely to work with and made the entire process so easy.',
        short_msg: 'Fast, easy, painless.',
        userName: 'Jennifer',
        location: 'Fort Worth, Texas',
        date: 'Jan 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157321/79394028_1_x.jpg?height=94&quality=70&version=1',
        message: 'Nice items and good price!',
        short_msg: 'God people whom you can deal with',
        userName: 'Tony',
        location: 'Woodside, New York',
        date: 'Jan 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157321/79394039_1_x.jpg?height=94&quality=70&version=1',
        message: 'Good prices for bidding',
        short_msg: 'What can I say but Quality',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Jan 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157107/79305260_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Bryan',
        location: 'Tracy, CA',
        date: 'Jan 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157321/79393979_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great seller',
        short_msg: 'Great prices, always good quality and fast efficient shipping',
        userName: 'Robert',
        location: 'Lake Worth, FL',
        date: 'Jan 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157321/79394012_1_x.jpg?height=94&quality=70&version=1',
        message: 'the items are accurately described and they deliver in a timely manner.',
        short_msg: 'they deliver in a timely manner',
        userName: 'Sylvia',
        location: 'Omaha, Nebraska',
        date: 'Jan 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157321/79394056_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Marlyce',
        location: 'Louisburg, KS',
        date: 'Jan 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157321/79394023_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Linda',
        location: 'Chicago, Illinois',
        date: 'Jan 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157106/79305090_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Randy',
        location: 'Blackfalds, CA',
        date: 'Jan 09, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157106/79305131_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Anna',
        location: 'Merrylands West, AU',
        date: 'Jan 09, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157106/79305055_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Product send out quicky.... shipping order and tracking number provided quicky..... great job Dalshire!',
        short_msg: 'Great job Dalshire...',
        userName: 'Jimmi',
        location: 'La Paz, Mexico',
        date: 'Jan 09, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157106/79305098_1_x.jpg?height=94&quality=70&version=1',
        message: 'Keep up the good work awesome job guys',
        short_msg: 'Awesome job',
        userName: 'Carlitos',
        location: 'Providence, RI',
        date: 'Jan 09, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157105/79304948_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Vicky',
        location: 'Chatsworth, California',
        date: 'Jan 07, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157105/79304925_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Love the items I find on the auctions with Dalshire International. They have beautiful jewelry and bidding is easy!',
        short_msg: 'Dalshire great company to deal with',
        userName: 'Carmela',
        location: 'Dunnellon, FL',
        date: 'Jan 07, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157104/79303751_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Julie',
        location: 'Park Ridge, IL',
        date: 'Jan 06, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157104/79303844_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Walter',
        location: 'Saylorsburg, Pennsylvania',
        date: 'Jan 06, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157104/79303745_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Thomas',
        location: 'Charlotte, NC',
        date: 'Jan 06, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156254/78856246_1_x.jpg?height=94&quality=70&version=1',
        message: 'great deal timely arrival thanks',
        short_msg: 'great deal',
        userName: 'NZ2880004 Mark',
        location: 'Portland, Oregon',
        date: 'Jan 06, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155662/78569997_1_x.jpg?height=94&quality=70&version=1',
        message: 'Received my items right on scheduled just as described.',
        short_msg: 'PERFECT... Received my items right on scheduled just as described.',
        userName: 'Ellie',
        location: 'Inverness, FL',
        date: 'Jan 05, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156672/79079588_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'James',
        location: 'Bellevue, Washington',
        date: 'Jan 05, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156615/79055853_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Thomas',
        location: 'Charlotte, NC',
        date: 'Jan 04, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156615/79055929_1_x.jpg?height=94&quality=70&version=1',
        message:
            'To receive personal email and answers to questions is very rare you can depend on thia auction house to do right by you, the bidder.',
        short_msg: 'Very responsive and trustworthy',
        userName: 'Walter',
        location: 'Saylorsburg, Pennsylvania',
        date: 'Jan 04, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156615/79055886_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joe',
        location: 'Bennett, CO',
        date: 'Jan 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156615/79055834_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kelly',
        location: 'Mckinney, Texas',
        date: 'Jan 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156615/79055835_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Carole',
        location: 'Anaheim, CA',
        date: 'Jan 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156615/79055885_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Great online experience: purchase item and next thing I know I received item just like it looked on line. No complaints here.',
        short_msg: 'Great Experience',
        userName: 'Tammy',
        location: 'Georgetown, KY',
        date: 'Jan 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156612/79055164_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Lori',
        location: 'Rome, NY',
        date: 'Jan 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156612/79055152_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Matthew',
        location: 'Madison, Wisconsin',
        date: 'Jan 02, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156253/78856163_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Matthew',
        location: 'Madison, Wisconsin',
        date: 'Jan 02, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156612/79055083_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Thomas',
        location: 'Charlotte, NC',
        date: 'Jan 02, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156612/79055100_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Rodney',
        location: 'Brandywine, Maryland',
        date: 'Jan 02, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156253/78856155_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Very satisfied with each of my items. Shipping was immediate! and definitely quality items',
        short_msg: 'Simplicity and Valyes',
        userName: 'Kathryn L',
        location: 'St. Francisville, Louisiana',
        date: 'Jan 01, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156253/78856181_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very happy with my purchases. Great prices and great items.',
        short_msg: '',
        userName: 'Sue',
        location: 'Newmarket, NH',
        date: 'Jan 01, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156254/78856240_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great jewels for fair prices',
        short_msg: 'Great Auction House',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Dec 31, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156254/78856299_1_x.jpg?height=94&quality=70&version=1',
        message: 'Excellent, Always 5 STARS on everything every time!!!',
        short_msg: 'Very Satisfied every time, 5 STARS for Everything!!',
        userName: 'JOHN',
        location: 'Medford, Massachusetts',
        date: 'Dec 31, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156249/78855674_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great earrings at a great price!',
        short_msg: 'Awesome Earrings!',
        userName: 'Sharon',
        location: 'Olympia, WA',
        date: 'Dec 31, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156254/78856308_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Bryan',
        location: 'Tracy, CA',
        date: 'Dec 31, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153882/77684251_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ellie',
        location: 'Inverness, FL',
        date: 'Dec 30, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156253/78856177_1_x.jpg?height=94&quality=70&version=1',
        message: 'Item exactly as described, easy payment process, quick shipping.',
        short_msg: 'Trustworthy auction house',
        userName: 'Collean',
        location: 'Philadelphia, Pennsylvania',
        date: 'Dec 30, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156253/78856159_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Stewart',
        location: 'Park Ridge, NJ',
        date: 'Dec 30, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156251/78855909_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Carl',
        location: 'San Dimas, California',
        date: 'Dec 28, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156250/78855863_1_x.jpg?height=94&quality=70&version=1',
        message: 'PayPal and shipping',
        short_msg: 'Smooth process',
        userName: 'Pamela',
        location: 'Bolton, Mississippi',
        date: 'Dec 27, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156250/78855822_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I have purchased at least four watches from Dashire over the past several years. Each purchase was hassle-free, efficient, and safe.',
        short_msg: 'Best watch deals out there',
        userName: 'Steven',
        location: 'Redondo Beach, CA',
        date: 'Dec 27, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156250/78855879_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Wayde',
        location: 'Many, Louisiana',
        date: 'Dec 27, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156249/78855733_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Be on top of your game buy right sell right this auction gives you the opportunity to do that.',
        short_msg: 'Awesome',
        userName: 'Carlitos',
        location: 'Providence, RI',
        date: 'Dec 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/156249/78855689_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I will continue bidding and buying from Dalshire. Very good products at excellent prices.',
        short_msg: "I get very good values on quality items... watches and women's jewelry.",
        userName: 'Tom',
        location: 'Vero Beach, FL',
        date: 'Dec 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155997/78726718_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Savannah, Georgia',
        date: 'Dec 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155998/78726966_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Julie',
        location: 'Park Ridge, IL',
        date: 'Dec 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155998/78726926_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joe',
        location: 'Monticello, Georgia',
        date: 'Dec 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155997/78726771_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very convenient and easy to order items. Customer service is top notch!',
        short_msg: 'Very convenient and easy',
        userName: 'Lori',
        location: 'Rome, NY',
        date: 'Dec 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155997/78726717_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Fred E.',
        location: 'Winner, South Dakota',
        date: 'Dec 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155997/78726729_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'John J',
        location: 'Grayslake, IL',
        date: 'Dec 23, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155997/78726688_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Watches arrived and are of good quality. Company communicated well processed transaction without complication.',
        short_msg: 'The item was described accurately and shipped promptly',
        userName: 'Thomas',
        location: 'Charlotte, NC',
        date: 'Dec 23, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155997/78726719_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Carole',
        location: 'Anaheim, CA',
        date: 'Dec 23, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155662/78569998_1_x.jpg?height=94&quality=70&version=1',
        message:
            "My Eric Davis autographed picture arrived in a timely manner and was exactly as presented at auction. It makes a fine addition to my Reds 'collection!",
        short_msg: 'Eric the Red!',
        userName: 'Randall',
        location: 'Bluff City, Tennessee',
        date: 'Dec 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155662/78569967_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Charles',
        location: 'Columbus, Indiana',
        date: 'Dec 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155662/78570030_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I love the ring. The craftsmanship is good. It is everything I expected it to be from the photo.',
        short_msg: 'Quality Items to Bid On',
        userName: 'Carol',
        location: 'Iron Mountain, Michigan',
        date: 'Dec 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155615/78535771_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Bryan',
        location: 'Tracy, CA',
        date: 'Dec 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154941/78458966_1_x.jpg?height=94&quality=70&version=1',
        message: 'Bought a lot of 4 watches Pleased with all Great deals Thankyou',
        short_msg: 'Second Positive Transaction',
        userName: 'NZ2880004 Mark',
        location: 'Portland, Oregon',
        date: 'Dec 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155614/78535732_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Wayde',
        location: 'Many, Louisiana',
        date: 'Dec 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155614/78535705_1_x.jpg?height=94&quality=70&version=1',
        message: 'good stuff, good prices',
        short_msg: 'good stuff, good prices',
        userName: 'Steven',
        location: 'Dallas, Texas',
        date: 'Dec 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/155614/78535713_1_x.jpg?height=94&quality=70&version=1',
        message: 'great',
        short_msg: 'great',
        userName: 'thomas',
        location: 'Port Saint Lucie, FL',
        date: 'Dec 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154634/78049191_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I just love the mix of natural colours in these earrings they have a delicacy with the silver filigree. I will really enjoy wearing them.',
        short_msg: 'Funky Earrings',
        userName: 'Sheila',
        location: 'Rockwood, CA',
        date: 'Dec 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154636/78049293_1_x.jpg?height=94&quality=70&version=1',
        message:
            "These pieces of jewelry are simply fabulous. I really love all the earrings, l will keep some as haven't seen anything like them in the market. The opal and peridot necklace is destined for a young lady who has been most helpful to me in keeping up with domestic chores and just a pleasure to chat with.",
        short_msg: 'Unique jewelry',
        userName: 'Sheila',
        location: 'Rockwood, CA',
        date: 'Dec 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154940/78456903_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very happy with winning bid.',
        short_msg: 'Bracelet was nicer than pictured.',
        userName: 'Rita',
        location: 'Apple Valley, California',
        date: 'Dec 18, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154941/78458929_1_x.jpg?height=94&quality=70&version=1',
        message: 'An auction house that is reliable and efficient.',
        short_msg: 'Always dependable!',
        userName: 'Pamela',
        location: 'San Francisco, CA',
        date: 'Dec 17, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154940/78456871_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Said',
        location: 'London, GB',
        date: 'Dec 17, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154940/78456897_1_x.jpg?height=94&quality=70&version=1',
        message:
            'First of several I have bought. Great deals Great service. Will buy again. Cheers Mark',
        short_msg: 'Thoroughly Professional',
        userName: 'NZ2880004 Mark',
        location: 'Portland, Oregon',
        date: 'Dec 16, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154940/78456864_1_x.jpg?height=94&quality=70&version=1',
        message: 'Products are always as advertised, good process, never a problem with deliveries',
        short_msg: 'never had a problem with Dalshire purchases',
        userName: 'Robert',
        location: 'Lake Worth, FL',
        date: 'Dec 16, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154940/78456926_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Galileo',
        location: 'Albuquerque, NM',
        date: 'Dec 16, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146114/74024542_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Claude',
        location: 'Burien, WA',
        date: 'Aug 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145631/73822955_1_x.jpg?height=94&quality=70&version=1',
        message: 'Excellent to do business with.',
        short_msg: 'Dalshire International is great to do business with',
        userName: 'JOHN',
        location: 'Medford, Massachusetts',
        date: 'Aug 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146117/74024929_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ted',
        location: 'Mobile, Alabama',
        date: 'Aug 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146117/74024935_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Linda',
        location: 'Waterloo, CA',
        date: 'Aug 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146117/74024921_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ann',
        location: 'Gainesville, FL',
        date: 'Aug 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146117/74024949_1_x.jpg?height=94&quality=70&version=1',
        message: 'Wonderful people to do business with!',
        short_msg: 'Honest',
        userName: 'Phillip E',
        location: 'Aguascalientes, MX',
        date: 'Aug 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146117/74024957_1_x.jpg?height=94&quality=70&version=1',
        message: 'The quality of the items they sell is excellent!!',
        short_msg: 'Love this auction house!!',
        userName: 'Pamela',
        location: 'San Francisco, CA',
        date: 'Aug 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146117/74024936_1_x.jpg?height=94&quality=70&version=1',
        message: 'Will use Auction house again',
        short_msg: 'Quality of items Auctioned for',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Aug 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146117/74024896_1_x.jpg?height=94&quality=70&version=1',
        message:
            'A good solid company to do business with. Excellent with descriptions, starting prices, billing and shipping. Keep you well informed on what is going on with your item. Excellent all around!!!!',
        short_msg: 'Highly Recommend to do business with!!!!',
        userName: 'JOHN',
        location: 'Medford, Massachusetts',
        date: 'Aug 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146481/74136777_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ruth',
        location: 'Brownsville, Texas',
        date: 'Aug 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146114/74024496_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jackie',
        location: 'Lebanon, IN',
        date: 'Aug 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146114/74024522_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very beautiful Blue MOP Ring. The silver filigree is also beautiful.',
        short_msg: 'Beautiful Ring',
        userName: 'Sharon',
        location: 'Olympia, WA',
        date: 'Aug 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146114/74024508_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Barbara',
        location: 'Saint Charles, Missouri',
        date: 'Aug 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146114/74024540_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great Auction house.',
        short_msg: 'Quality of gems',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Aug 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145632/73931790_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Anker',
        location: 'Colorado Springs, Colorado',
        date: 'Aug 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146114/74024455_1_x.jpg?height=94&quality=70&version=1',
        message: 'A + transaction. Thank you',
        short_msg: 'A +',
        userName: 'Angela',
        location: 'Folly Beach, South Carolina',
        date: 'Aug 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146481/74136823_1_x.jpg?height=94&quality=70&version=1',
        message: 'How could I not like the beautiful earrings at this price.',
        short_msg: 'This was easy',
        userName: 'Carolyn',
        location: 'Fresno, CA',
        date: 'Aug 18, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145632/73931793_1_x.jpg?height=94&quality=70&version=1',
        message: 'Beautiful ring and cross necklace. Well made. Shipped quickly. Fun bidding!',
        short_msg: 'Love the jewelry!',
        userName: 'Sharon',
        location: 'Olympia, WA',
        date: 'Aug 18, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145632/73931714_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sheba',
        location: 'Rockville, Maryland',
        date: 'Aug 17, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145632/73931755_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Dalshire International is very easy to work with, mostly because they ship in-house.',
        short_msg: 'Simple process after winning',
        userName: 'Jen',
        location: 'Cape Elizabeth, Maine',
        date: 'Aug 17, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145632/73931723_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Val',
        location: 'Clifton, New Jersey',
        date: 'Aug 17, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145632/73931770_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Cynthia',
        location: 'Perth Amboy, NJ',
        date: 'Aug 17, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145632/73931722_1_x.jpg?height=94&quality=70&version=1',
        message: 'Had no problem. Great to work with fast shipping. Will shop again......',
        short_msg: 'No problem',
        userName: 'Mark Jeffrey',
        location: 'Middleburg, Florida',
        date: 'Aug 17, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145632/73931743_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'William',
        location: 'Brick, New Jersey',
        date: 'Aug 17, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145632/73931766_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very responsive and nice people.',
        short_msg: 'Great Values',
        userName: 'Michael',
        location: 'Miami, Florida',
        date: 'Aug 17, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145632/73931720_1_x.jpg?height=94&quality=70&version=1',
        message:
            'While I am not a Dodgers fan, these iconic players are beautifully depicted, the framing is superb, and the autographs clear. It is a wonderful display piece that, as a baseball fan, is a wonderful addition to my collection',
        short_msg: 'Snyder/Koufax',
        userName: 'Randall',
        location: 'Bluff City, Tennessee',
        date: 'Aug 17, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146482/74136902_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Aug 15, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145631/73822939_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Henry',
        location: 'Shaker Heights, OH',
        date: 'Aug 15, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145631/73822985_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I have had good experiences with the items that I won. Easy, smooth transactions. Recommend to all.',
        short_msg: 'Payment options and fast shipping.',
        userName: 'William',
        location: 'Tarpon Springs, Florida',
        date: 'Aug 15, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145631/73823009_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sheba',
        location: 'Rockville, Maryland',
        date: 'Aug 15, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145672/73777916_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Randall',
        location: 'Bluff City, Tennessee',
        date: 'Aug 14, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145672/73777914_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Excellent professional service. Beautiful items. All around great experience. Thank you.',
        short_msg: 'Excellent customer service, beautiful items',
        userName: 'Christopher',
        location: 'Fort Lauderdale, FL',
        date: 'Aug 13, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146480/74099684_1_x.jpg?height=94&quality=70&version=1',
        message: 'A +!! I will be back! thank you for a pleasant experience',
        short_msg: 'Thanks for a very pleasant experience!',
        userName: 'A',
        location: 'Folly Beach, South Carolina',
        date: 'Aug 13, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145672/73777961_1_x.jpg?height=94&quality=70&version=1',
        message: 'One of the best auction houses out there tremendous deals.',
        short_msg: 'Outstanding customer service',
        userName: 'James',
        location: 'Rockwall, TX',
        date: 'Aug 13, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146481/74136730_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Aug 13, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146116/74097075_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Aug 13, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146114/74024460_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Aug 13, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145672/73777948_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Irene',
        location: 'Sugar Hill, NH',
        date: 'Aug 13, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145672/73777935_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'John J',
        location: 'Grayslake, IL',
        date: 'Aug 13, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145672/73777953_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I found Dalshire easy to communicate with, easy to work with, and that they are dependable. I am most pleased with the item I won at auction, and will definitely keep them in my favorite sellers list, and bid at their auctions.',
        short_msg: 'Dependable, and Easy to Work With',
        userName: 'Janet',
        location: 'Stone Mountain, Georgia',
        date: 'Aug 13, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145629/73708281_1_x.jpg?height=94&quality=70&version=1',
        message:
            'You have a chance to bid on good quality items and hopefully get some for less than wholesale prices! Be aware that it is addicting! But FUN!!',
        short_msg: 'Great opportunity to purchase awesome items!',
        userName: 'Sharon',
        location: 'Olympia, WA',
        date: 'Aug 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145630/73775493_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very nice earrings',
        short_msg: 'Satisfied',
        userName: 'Jaimee',
        location: 'Apple Valley, CA',
        date: 'Aug 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145630/73775466_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Andrew',
        location: 'Sumter, South Carolina',
        date: 'Aug 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145630/73775514_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very rapid delivery',
        short_msg: 'Very rapid delivery',
        userName: 'Mario',
        location: 'Quebec, Canada',
        date: 'Aug 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145630/73775473_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very happy!',
        short_msg: 'As Described',
        userName: 'Ronald',
        location: 'Chico, CA',
        date: 'Aug 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145630/73775488_1_x.jpg?height=94&quality=70&version=1',
        message: 'everything runs smoothly',
        short_msg: 'Excellent Auction house',
        userName: 'Harold',
        location: 'West Palm Beach, FL',
        date: 'Aug 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145630/73775535_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Good, Solid People and Company to do business with. I highly recommend them. Great to go live on line with their auctions.',
        short_msg: 'EExcellent to do business with on-line!!',
        userName: 'JOHN',
        location: 'Medford, Massachusetts',
        date: 'Aug 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145630/73775546_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Miami, Florida',
        date: 'Aug 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145630/73775496_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Marion',
        location: 'Doylestown, PA',
        date: 'Aug 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145630/73775521_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Raymond',
        location: 'Mishawaka, IN',
        date: 'Aug 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145630/73775469_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: '',
        location: '',
        date: 'Aug 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145629/73708317_1_x.jpg?height=94&quality=70&version=1',
        message: 'yes',
        short_msg: 'good prices',
        userName: 'Ronald',
        location: 'Murray, KY',
        date: 'Aug 11, 2019',
        stars: '5',
    },

    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'Linda',
        location: 'Waterloo, CA',
        date: 'Aug 11, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/143203/72477241_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Isabel',
        location: 'Eagle, CO',
        date: 'Aug 11, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145629/73708277_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I have Over 80 purchases through 9 to 12 different auction houses in over 30 different auctioneers right now Dalshire has the best deals of all of them because they are fairly new I have purchased numerous times from them already and always had a pleasant transaction as well as an awesome deal I feel like I’m stealing from them Sincerely James',
        short_msg: 'DaleShire international has the best deals going.',
        userName: 'James',
        location: 'Rockwall, TX',
        date: 'Aug 11, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145629/73708245_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Camil',
        location: 'Chicoutimi, CA',
        date: 'Aug 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145629/73708319_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The framed/signed Ali picture was actually more spectacular in person than the way it looked online. The colors and frame combine to make the collectable quite a pleasing display.',
        short_msg: 'The Greatest!',
        userName: 'Randall',
        location: 'Bluff City, Tennessee',
        date: 'Aug 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145629/73708227_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'Lubbock, Texas',
        date: 'Aug 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145628/73622262_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'Grayslake, IL',
        date: 'Aug 09, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145632/73931738_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Aug 09, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145628/73622247_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sheba',
        location: 'Clearwater, FL',
        date: 'Aug 08, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145628/73622226_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Susan',
        location: 'New Port Richey,fl 34652, Florida',
        date: 'Aug 08, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145620/73622141_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Very convenient for buyers, shipping is easy and reasonable. Price ok, well done.',
        short_msg: 'Convenience',
        userName: 'Ken',
        location: 'Markham, Canada',
        date: 'Aug 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145632/73931798_1_x.jpg?height=94&quality=70&version=1',
        message:
            'This is my 2nd order. I am very satisfied after my 2nd order even though 1st order was missing boxes.',
        short_msg: 'Excellent',
        userName: 'Emdad',
        location: 'Bronx, NY',
        date: 'Aug 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145296/73499918_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Smooth, is the atmosphere at these auctions, where everything runs smoothly from Auction biding to delivery of items won during the auctions.',
        short_msg: 'Thank You',
        userName: 'David',
        location: 'Advance, NC',
        date: 'Aug 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145620/73622194_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sheba',
        location: 'Rockville, Maryland',
        date: 'Aug 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145620/73622198_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Mary',
        location: 'Spring, Texas',
        date: 'Aug 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145226/73452365_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Mitchell',
        location: 'Supply, North Carolina',
        date: 'Aug 04, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145672/73777970_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Aug 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145302/73593233_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ann',
        location: 'Gainesville, FL',
        date: 'Aug 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145302/73593156_1_x.jpg?height=94&quality=70&version=1',
        message: 'Responsive and accurate. No problems purchasing multiple items.',
        short_msg: 'Great selection and values',
        userName: 'Bradley',
        location: 'Sandy Springs, Georgia',
        date: 'Aug 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145302/73593144_1_x.jpg?height=94&quality=70&version=1',
        message:
            'It was a pleasure dealing with Dashire Int’l. There was no need to call them. Fully complies.',
        short_msg: 'Excellent experience',
        userName: 'Shlomo',
        location: 'San Jose, CR',
        date: 'Aug 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145302/73593204_1_x.jpg?height=94&quality=70&version=1',
        message: 'Like the Jewelery.',
        short_msg: 'Great Auction House',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Aug 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145296/73499908_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very satisfied with purchase.',
        short_msg: 'Item as described.',
        userName: 'Marqueta',
        location: 'Sanger, CA',
        date: 'Aug 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145302/73593188_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Charles',
        location: 'Antelope, CA',
        date: 'Aug 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145302/73593148_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Roger',
        location: 'Tallahassee, FL',
        date: 'Aug 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145629/73708320_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Aug 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145631/73823019_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Aug 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145630/73775559_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Aug 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145296/73499846_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Boom',
        location: 'Chardon, Ohio',
        date: 'Aug 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145296/73499856_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'West Columbia, SC',
        date: 'Aug 01, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145296/73499838_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Lio',
        location: 'Columbus, New Jersey',
        date: 'Aug 01, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/143479/72642442_1_x.jpg?height=94&quality=70&version=1',
        message:
            "Very nice rings. They were much prettier in person than in the photos. I wouldn't hesitate to bid on this auction house's items again.",
        short_msg: 'Very nice things.',
        userName: 'Marie',
        location: 'Minneapolis, Minnesota',
        date: 'Aug 01, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145226/73452334_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very satisfied from the service, compliance & efficiently. Many thanks',
        short_msg:
            'To have the corrected on time and efficiently, this were my impression of Dalshire.',
        userName: 'Shlomo',
        location: 'San Jose, CR',
        date: 'Jul 30, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145226/73452412_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Lio',
        location: 'Columbus, New Jersey',
        date: 'Jul 29, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145226/73452377_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Edward',
        location: 'Ooltewah, TN',
        date: 'Jul 29, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145226/73452319_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The items I have purchased have been high-quality and the process thus far has been seamless. I highly recommend!',
        short_msg: 'Great organizational structure',
        userName: 'Randall',
        location: 'Bluff City, Tennessee',
        date: 'Jul 29, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145620/73622200_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Jul 27, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145296/73499925_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Jul 23, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144624/73241386_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Zena',
        location: 'Santa Cruz, CA',
        date: 'Jul 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144624/73241410_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Irene',
        location: 'Sugar Hill, NH',
        date: 'Jul 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144624/73241388_1_x.jpg?height=94&quality=70&version=1',
        message: 'Item bid on better than expected and shipped on time.',
        short_msg: 'Item better than expected and shipped on time.',
        userName: 'Charles',
        location: 'Columbus, Indiana',
        date: 'Jul 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144624/73241431_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I was thrilled to see the care and handling on my items was TOP RATE!! The items were just as pictured on the internet.',
        short_msg: 'Dalshire International is the best',
        userName: 'Pamela',
        location: 'San Francisco, CA',
        date: 'Jul 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144624/73241382_1_x.jpg?height=94&quality=70&version=1',
        message: 'all was very good',
        short_msg: 'all was very good',
        userName: 'Mario',
        location: 'Quebec, Canada',
        date: 'Jul 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144748/73208271_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jim',
        location: 'Clinton, IA',
        date: 'Jul 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/139727/71103390_1_x.jpg?height=94&quality=70&version=1',
        message: 'Excellent service.',
        short_msg: 'Service',
        userName: 'Steve',
        location: 'Cincinnati, OH',
        date: 'May 16, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/139727/71103419_1_x.jpg?height=94&quality=70&version=1',
        message: 'Beautiful piece! Great price.... love it.',
        short_msg: 'Love it!',
        userName: 'Jennifer',
        location: 'Denver, Colorado',
        date: 'May 18, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/141166/71599463_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very very well package A1, will be back. Thanks',
        short_msg: 'Perfectly in a reasonable time',
        userName: 'Normand',
        location: 'Montreal, Canada',
        date: 'May 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/141166/71599353_1_x.jpg?height=94&quality=70&version=1',
        message: 'Thank You',
        short_msg: '',
        userName: 'Steve',
        location: 'Cincinnati, OH',
        date: 'May 30, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/141815/71814976_1_x.jpg?height=94&quality=70&version=1',
        message: 'Thank You',
        short_msg: '',
        userName: 'Steve',
        location: 'Ivry-sur-le-lac, Canada',
        date: 'Jun 06, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/141815/71814976_1_x.jpg?height=94&quality=70&version=1',
        message: 'Thank You',
        short_msg: '',
        userName: 'Steve',
        location: 'Ivry-sur-le-lac, Canada',
        date: 'Jun 06, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/141815/71815090_1_x.jpg?height=94&quality=70&version=1',
        message: 'My experience with Dalshire International was positive and memorable.',
        short_msg: 'Professional Group',
        userName: 'Steven',
        location: 'Redondo Beach, CA',
        date: 'Jun 06, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/141815/71815037_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Nanette',
        location: 'Palm Springs, CA',
        date: 'Jun 09, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/142369/72050346_1_x.jpg?height=94&quality=70&version=1',
        message:
            'My item was exactly as described, and it was shipped to me quickly. WIll use Delshire International again and would recommend to any buyer!',
        short_msg: 'Cared about quality of item and buyer.',
        userName: 'Ronda',
        location: 'Scottsdale, Arizona',
        date: 'Jun 13, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/142774/72245145_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great Auctioneer! Great communication and delivery time. Very Happy!!',
        short_msg: 'Attention to Detail!',
        userName: 'Candace',
        location: 'Bellefonte, PA',
        date: 'Jun 13, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/141815/71815060_1_x.jpg?height=94&quality=70&version=1',
        message: 'Thank You',
        short_msg: 'Thank You',
        userName: 'Sylvia',
        location: 'Omaha, Nebraska',
        date: 'Jun 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/142774/72245181_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Items were perfectly described and items were shipped and received promptly. Thank you very much',
        short_msg: 'Good all around',
        userName: 'Shera',
        location: 'Lake Balboa, California',
        date: 'Jun 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/142774/72245222_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Irene',
        location: 'Sugar Hill, NH',
        date: 'Jun 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/143075/72389792_1_x.jpg?height=94&quality=70&version=1',
        message: 'good price',
        short_msg: 'ring',
        userName: 'Ronald',
        location: 'Murray, KY',
        date: 'Jun 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/143075/72389851_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Cincinnati, OH',
        date: 'Jun 25, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144498/73070279_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Collingswood, NJ',
        date: 'Jul 08, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144498/73070224_1_x.jpg?height=94&quality=70&version=1',
        message:
            'My item arrived today perhaps even better than advertised! The color and presentation was spectacular and the packaging enabled it to arrive in pristine condition. I was most pleased with the entire process, from purchasing to shipping to the final arrival at my home.',
        short_msg: '1984 Passing Leaders-Joe Montana and Dan Marino',
        userName: 'Randall',
        location: 'Bluff City, Tennessee',
        date: 'Jul 09, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162718/82168015_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Pamela',
        location: 'San Francisco, CA',
        date: 'Apr 01, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162718/82167949_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Howard',
        location: 'Alexandria, Virginia',
        date: 'Mar 31, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162718/82167958_1_x.jpg?height=94&quality=70&version=1',
        message: 'I always have good experiences buying with Dalshire International',
        short_msg: 'Good experience',
        userName: 'Ricardo',
        location: 'Los Angeles, CA',
        date: 'Mar 31, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162718/82167918_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joseph',
        location: 'Cincinnati, OH',
        date: 'Mar 31, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162539/82092541_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Milan',
        location: 'Vancouver, CA',
        date: 'Mar 31, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162539/82092555_1_x.jpg?height=94&quality=70&version=1',
        message: 'Yes, a reliable service.',
        short_msg: 'Are they for real?',
        userName: 'Brenda',
        location: 'Toronto, Canada',
        date: 'Mar 30, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162539/82092616_1_x.jpg?height=94&quality=70&version=1',
        message: 'quick auction action',
        short_msg: 'good items',
        userName: 'Jerry',
        location: 'Venice, Florida',
        date: 'Mar 30, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162539/82092615_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jo-An',
        location: 'Saint Petersburg, FL',
        date: 'Mar 30, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162539/82092562_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great experience',
        short_msg: 'Excellent team',
        userName: 'Ron',
        location: 'Auburn, NY',
        date: 'Mar 30, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162539/82092577_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'San Marcos, Texas',
        date: 'Mar 30, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162490/82069512_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sandie',
        location: 'Rockaway, NJ',
        date: 'Mar 29, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162537/82091303_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Conrad',
        location: 'Saint Louis, MO',
        date: 'Mar 29, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162511/82074692_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very useful for everyone.....',
        short_msg: 'My new experience it was went great',
        userName: 'Sathees',
        location: 'Muscat, Oman',
        date: 'Mar 28, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162511/82074905_1_x.jpg?height=94&quality=70&version=1',
        message: 'Good deal',
        short_msg: 'Just as pictured',
        userName: 'Wayde',
        location: 'Many, Louisiana',
        date: 'Mar 28, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162511/82074667_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Like anything, a picture can be worth a thousand words but still cannot compare to touch. Beautiful piece.',
        short_msg: 'Stunning',
        userName: 'Kathleen',
        location: 'Exeter, New Hampshire',
        date: 'Mar 27, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162511/82074869_1_x.jpg?height=94&quality=70&version=1',
        message: 'Received faster than expected, well packed and just as described.',
        short_msg: 'Perfect service',
        userName: 'Jay',
        location: 'Brooklyn, NY',
        date: 'Mar 27, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162490/82069581_1_x.jpg?height=94&quality=70&version=1',
        message: 'Love the auctions and the quality of the products!!!',
        short_msg: 'Quality Products',
        userName: 'Rosanne',
        location: 'Okatie, South Carolina',
        date: 'Mar 26, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162490/82069588_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I'm hooked on Dalshire International Auctions. From browsing to bidding to purchasing to shipping-great experience!",
        short_msg: "Bidding is easy to follow and am very pleased with the items I've purchased.",
        userName: 'Holly',
        location: 'Mohave Valley, AZ',
        date: 'Mar 26, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162159/81902017_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'San Marcos, Texas',
        date: 'Mar 24, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158109/79812461_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jose',
        location: 'Fort Worth, Texas',
        date: 'Mar 24, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162035/81805899_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Clark, NJ',
        date: 'Mar 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162035/81805872_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Julie',
        location: 'Park Ridge, IL',
        date: 'Mar 23, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161939/81735054_1_x.jpg?height=94&quality=70&version=1',
        message: 'Got my item quick and easy. Invoice process was effective and easy.',
        short_msg: 'Faaaaaaast',
        userName: 'Mark',
        location: 'Philadelphia, Pennsylvania',
        date: 'Mar 22, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159922/80707004_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Federica',
        location: 'Katy, TX',
        date: 'Mar 21, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161939/81735077_1_x.jpg?height=94&quality=70&version=1',
        message:
            'This is a great seller and one l encourage everyone to check out. Unique high quality items.',
        short_msg: 'Fabulous Unique Jewelry',
        userName: 'Sheila',
        location: 'Rockwood, CA',
        date: 'Mar 21, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161939/81735084_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Scott',
        location: 'Las Vegas, NV',
        date: 'Mar 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161816/81688472_1_x.jpg?height=94&quality=70&version=1',
        message: 'Accurate, Timely and Shipping was reasonable',
        short_msg: 'Accurate',
        userName: 'Mary',
        location: 'Vancouver, Washington',
        date: 'Mar 20, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161816/81688508_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Everything I have purchased from Dalshire has been exactly as shown/described, packaging has been great and very fast shipping. I would nothesitate to deal with them again. A + + +',
        short_msg: 'Always efficient and prompt',
        userName: 'Randy',
        location: 'Blackfalds, CA',
        date: 'Mar 19, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161816/81688510_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ronald',
        location: 'Chesterfield, Missouri',
        date: 'Mar 19, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161807/81686573_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joseph',
        location: 'Cincinnati, OH',
        date: 'Mar 17, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161597/81572521_1_x.jpg?height=94&quality=70&version=1',
        message: 'really ok',
        short_msg: 'new exp é rience ok',
        userName: 'Jean Paul',
        location: 'Charleroi, Belgium',
        date: 'Mar 17, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161597/81572680_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Every shipment I have received from Dalshire has had products exactly as shown/described, has had exceptional packaging and has arrived within a reasonable/good time frame unlike certain other auctioneers.',
        short_msg: 'Great to deal with',
        userName: 'Randy',
        location: 'Blackfalds, CA',
        date: 'Mar 16, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161597/81572513_1_x.jpg?height=94&quality=70&version=1',
        message: 'Reasonable prices for jewelry items, prompt delivery, correct descriptions.',
        short_msg: 'Items are described accurately.',
        userName: 'Carole',
        location: 'Anaheim, CA',
        date: 'Mar 16, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161597/81572479_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sue',
        location: 'Newmarket, NH',
        date: 'Mar 16, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161256/81399390_1_x.jpg?height=94&quality=70&version=1',
        message:
            'adequately tell you that they do not handle shipping which to me is a hassle I do not need, I will try to never do business with them again',
        short_msg:
            'Thisi is a side comment I would never buy another product from Greenwich Aictions. They do not adeq',
        userName: 'Morris',
        location: 'Chicago, IL',
        date: 'Mar 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159712/80597163_1_x.jpg?height=94&quality=70&version=1',
        message: 'Lovely earrings and a very pretty watch for unbelievably low prices.',
        short_msg: 'Beautiful jewelry',
        userName: 'Sheila',
        location: 'Rockwood, CA',
        date: 'Mar 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160723/81138731_1_x.jpg?height=94&quality=70&version=1',
        message:
            'What lovely rings, my young cleaning lady will love the peridot ring for her birthday in August. The topaz ring is one l will keep for myself. Simply great quality for fantastic very high quality items.',
        short_msg: 'More Great Buys',
        userName: 'Sheila',
        location: 'Rockwood, CA',
        date: 'Mar 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160864/81190643_1_x.jpg?height=94&quality=70&version=1',
        message:
            'This is a seller with high quality items, some very unique and usually even better than expected. Great auctioneer with really low prices.',
        short_msg: 'Fabulous jewelry on this Auction site.',
        userName: 'Sheila',
        location: 'Rockwood, CA',
        date: 'Mar 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161160/81356469_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joseph',
        location: 'Cincinnati, OH',
        date: 'Mar 12, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161160/81356516_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very nice',
        short_msg: 'Nice',
        userName: 'Carlitos',
        location: 'Providence, RI',
        date: 'Mar 12, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161047/81291841_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Deborah',
        location: 'Pinson, Alabama',
        date: 'Mar 12, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161158/81356199_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Anna',
        location: 'Merrylands West, AU',
        date: 'Mar 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161158/81356131_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Albert',
        location: 'Toledo, OH',
        date: 'Mar 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161158/81356116_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great descriptive pictures of the item, very accurate.....',
        short_msg: 'Very accurate description....',
        userName: 'Kenneth',
        location: 'Hollis Hills, NY',
        date: 'Mar 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161158/81356135_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joseph',
        location: 'Cincinnati, OH',
        date: 'Mar 10, 2020',
        stars: '5',
    },

    {
        img_url: '',
        message: 'First class operation best deals bar none on all the auction houses.',
        short_msg: 'Best deals on any auction house',
        userName: 'James',
        location: 'Rockwall, TX',
        date: 'Mar 09, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161047/81291851_1_x.jpg?height=94&quality=70&version=1',
        message: "Beautiful, as seen in photo's. Love it",
        short_msg: 'Beautiful exactly as seen in photo. Love it..',
        userName: 'donna',
        location: 'Ocala, FL',
        date: 'Mar 09, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161047/81291830_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'Renfrew, CA',
        date: 'Mar 09, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161047/81291836_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Johns Creek, Georgia',
        date: 'Mar 09, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/161047/81291844_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Brian',
        location: 'Visalia, CA',
        date: 'Mar 09, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160868/81191038_1_x.jpg?height=94&quality=70&version=1',
        message: 'Top notch service',
        short_msg: 'Great items',
        userName: 'Johnny',
        location: 'Zephyrhills, Florida',
        date: 'Mar 08, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160919/81234413_1_x.jpg?height=94&quality=70&version=1',
        message: 'great',
        short_msg: 'great',
        userName: 'thomas',
        location: 'Port Saint Lucie, FL',
        date: 'Mar 07, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160868/81190939_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Excellent experience. Excellent items. You will be happy with the items you purchase',
        short_msg: 'Excellent auction items. Was very happy when items were received.',
        userName: 'James',
        location: 'Satellite Beach, FL',
        date: 'Mar 07, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160864/81190593_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Longboat Key, Florida',
        date: 'Mar 05, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160864/81190621_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Patrick',
        location: 'Fort Wayne, Indiana',
        date: 'Mar 05, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160864/81190646_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Gerry',
        location: 'Fairhope, Alabama',
        date: 'Mar 05, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160723/81138752_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Have dealt with them many times over the past 3 years. Always have a positive experience.',
        short_msg: 'Love their auctions',
        userName: 'Sue',
        location: 'Newmarket, NH',
        date: 'Mar 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160723/81138666_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Katrina',
        location: 'North Scituate, Rhode Island',
        date: 'Mar 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160567/81044652_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Donald',
        location: 'Staten Island, New York',
        date: 'Mar 02, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160567/81044571_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Vincent',
        location: 'Naples, FL',
        date: 'Mar 02, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160567/81044572_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Debbie',
        location: 'Olive Branch, MS',
        date: 'Mar 02, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160372/80944618_1_x.jpg?height=94&quality=70&version=1',
        message: 'no',
        short_msg: 'Beautiful jewelry with excellent quality.',
        userName: 'Ricky',
        location: 'Reston, VA',
        date: 'Feb 29, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159922/80707000_1_x.jpg?height=94&quality=70&version=1',
        message: 'item as described. tightly wrapped in bubble wrap.',
        short_msg: 'excellent seller',
        userName: 'Craig',
        location: 'Markham, Canada',
        date: 'Feb 29, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160367/80944068_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Marstons Mills, MA',
        date: 'Feb 29, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160372/80944595_1_x.jpg?height=94&quality=70&version=1',
        message: 'Beautiful watches good price',
        short_msg: 'Awesome watch',
        userName: 'Carlitos',
        location: 'Providence, RI',
        date: 'Feb 29, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150813/76538934_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Littleton, Colorado',
        date: 'Nov 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150813/76538970_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Stephanie',
        location: 'Deerfield Beach, Florida',
        date: 'Nov 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150813/76538911_1_x.jpg?height=94&quality=70&version=1',
        message: 'Excellent',
        short_msg: 'Quality of Product',
        userName: 'Brad',
        location: 'Miami, FL',
        date: 'Nov 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150813/76538822_1_x.jpg?height=94&quality=70&version=1',
        message:
            'by leaps and bounds over any other auction company I have ever dealt with period!',
        short_msg: 'Great items for yourself or for you to sell.',
        userName: 'Kevin',
        location: 'La Crosse, Wisconsin',
        date: 'Nov 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150813/76538972_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Santa Monica, California',
        date: 'Nov 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150813/76538981_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Greg',
        location: 'Jefferson, MD',
        date: 'Nov 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150813/76538837_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Thomas',
        location: 'Saltspring, Canada',
        date: 'Nov 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150813/76538901_1_x.jpg?height=94&quality=70&version=1',
        message: 'Auctions are great & shipping time is fast & everything is packaged well!',
        short_msg: 'Outstanding Auctions',
        userName: 'Marlyce',
        location: 'Louisburg, KS',
        date: 'Nov 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150812/76536935_1_x.jpg?height=94&quality=70&version=1',
        message:
            'If you like to buy and resell items, this is the best auction online PERIOD! If your like me and hate to pay full price for anything this is the place to find incredible down deals. I highly recommend! MgySgt USMC',
        short_msg: 'The Best merchandise Auction I’ve ever see.',
        userName: 'Kevin',
        location: 'La Crosse, Wisconsin',
        date: 'Nov 01, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150812/76536929_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Hakop',
        location: 'Los Angeles, California',
        date: 'Nov 01, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150811/76450254_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'William',
        location: 'Tarpon Springs, Florida',
        date: 'Oct 31, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150811/76450304_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Linda',
        location: 'PSL, Florida',
        date: 'Oct 31, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/151639/76541561_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I have bought a number of items at different auctions held by Dalshire International and have found them to be consistently reliable. In each case the photo was a fair representation of the item acquired, the item was promptly mailed and it arrived in good condition, having been carefully packed. I have no hesitation in recommending them.',
        short_msg: 'Reliable',
        userName: 'Christopher',
        location: 'Goode Beach, AU',
        date: 'Oct 30, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/151640/76541549_1_x.jpg?height=94&quality=70&version=1',
        message: 'I have won Several Auctions from them. There Great.!!!!!!',
        short_msg: 'Great Auction House',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Oct 30, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/151640/76541502_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Really appreciate Dalshires immediate response to any questions that arise. They really take care of their clients and I value customer service highly.',
        short_msg: 'Very Responsive!',
        userName: 'Linda',
        location: 'PSL, Florida',
        date: 'Oct 30, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150810/76439850_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tyrone',
        location: 'New Haven, CT',
        date: 'Oct 30, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150810/76439805_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Gwen',
        location: 'Paradise Valley, AZ',
        date: 'Oct 29, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150808/76296930_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Anna',
        location: 'Merrylands West, AU',
        date: 'Oct 28, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150808/76296913_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Scott',
        location: 'Santa Fe, New Mexico',
        date: 'Oct 28, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150808/76296965_1_x.jpg?height=94&quality=70&version=1',
        message:
            'by all means ASK! Dalshire is very responsive to issues and will bend over backwards to make your purchase satisfactory.',
        short_msg: 'if you have any questions about an order',
        userName: 'Linda',
        location: 'PSL, Florida',
        date: 'Oct 28, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150807/76296781_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The bracelet was just as pictured and described. The bid process was easy and I will probably look again for a quality piece.',
        short_msg: 'Quality Item',
        userName: 'Carol',
        location: 'Iron Mountain, Michigan',
        date: 'Oct 27, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150807/76296836_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Albert',
        location: 'North Chesterfield, VA',
        date: 'Oct 27, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150807/76296848_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Eva',
        location: 'Hasbrouck Heights, New Jersey',
        date: 'Oct 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150806/76194425_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kimberly',
        location: 'Pflugerville, Texas',
        date: 'Oct 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150807/76296844_1_x.jpg?height=94&quality=70&version=1',
        message: 'Bidding ar Dalshire is Addicting. Great.',
        short_msg: 'Quality and good gems',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Oct 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150806/76194360_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Beautiful watch, great price, very smooth transaction. Have made numerous purchases from Dalshire and have always been completely satisfied.',
        short_msg: 'Beautiful watch, great price, very smooth transaction',
        userName: 'Robert',
        location: 'Lake Worth, FL',
        date: 'Oct 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150807/76296861_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great seller, great prices, easy bidding process.',
        short_msg: 'Great seller, great prices, easy bidding process.',
        userName: 'Robert',
        location: 'Lake Worth, FL',
        date: 'Oct 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150807/76296841_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Hakop',
        location: 'Los Angeles, California',
        date: 'Oct 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150807/76296834_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Quality Ring Purchased Exceeded Expectations 100 % Satisfied with the purchase & shipment process Easy, quick and a great deal',
        short_msg: 'Trusted Live Auction',
        userName: 'Pam',
        location: 'San Jose, California',
        date: 'Oct 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150805/76193616_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Great source for watches and jewelry at super prices. Easy bidding and payment process and always deliver as promised.',
        short_msg:
            'very complete descriptions and accurate pictures. never a problem with delivery',
        userName: 'Robert',
        location: 'Lake Worth, FL',
        date: 'Oct 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150806/76194343_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'William',
        location: 'Tarpon Springs, Florida',
        date: 'Oct 25, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150805/76193651_1_x.jpg?height=94&quality=70&version=1',
        message: 'Ditto',
        short_msg: 'Quality and affordability',
        userName: 'Mark',
        location: 'Grand Blanc, MI',
        date: 'Oct 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150805/76193597_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Don',
        location: 'Phoenix, Arizona',
        date: 'Oct 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148840/75992344_1_x.jpg?height=94&quality=70&version=1',
        message:
            "Very awesome auctioning house. Beautiful stuff. Exactly as it was in description and pics. Winner winner chicken dinner! Try it, you'll like it, fo sho",
        short_msg: 'OMG Winning!',
        userName: 'Jean',
        location: 'Yakima, WA',
        date: 'Oct 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148375/75328979_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great response to any questions-I am wearing this watch-Looks great.',
        short_msg: 'Great response',
        userName: 'Carl',
        location: 'Bury, GB',
        date: 'Oct 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/151639/76541577_1_x.jpg?height=94&quality=70&version=1',
        message: 'Good folks to deal with!!!',
        short_msg: 'great job',
        userName: 'Michael',
        location: 'Bryan, TX',
        date: 'Oct 23, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150804/76193518_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Eva',
        location: 'Hasbrouck Heights, New Jersey',
        date: 'Oct 23, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/151647/76699539_1_x.jpg?height=94&quality=70&version=1',
        message: 'Everything went great as expected. Highly recommend this Auction Co.',
        short_msg: 'Great Auction experience',
        userName: 'Richard',
        location: 'Sterrett, Alabama',
        date: 'Oct 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150804/76193447_1_x.jpg?height=94&quality=70&version=1',
        message: 'Best Auction Experience',
        short_msg: 'Best Auction Experience',
        userName: 'Pam',
        location: 'San Jose, California',
        date: 'Oct 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150804/76193471_1_x.jpg?height=94&quality=70&version=1',
        message: 'Good experience and service shipping prices ought to be checked before bidding!',
        short_msg:
            'Pictures are way smaller than objects. There was no mention of size so my earrings are minuscule.',
        userName: 'Chitra',
        location: 'Greenwich, CT',
        date: 'Oct 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150804/76193543_1_x.jpg?height=94&quality=70&version=1',
        message: 'I was very HAPPY with the watches i got i will be back and bid on more',
        short_msg: 'Happy',
        userName: 'Edward',
        location: 'Clarksville, IN',
        date: 'Oct 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150810/76439841_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Janet',
        location: 'Abita Springs, LA',
        date: 'Oct 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148844/76147776_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Eva',
        location: 'Hasbrouck Heights, New Jersey',
        date: 'Oct 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148844/76147863_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'West Columbia, SC',
        date: 'Oct 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148844/76147854_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Janet',
        location: 'Abita Springs, LA',
        date: 'Oct 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148844/76147841_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Morris',
        location: 'Chicago, IL',
        date: 'Oct 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148843/76147741_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very satisfied with all levels of service, payment, shipping and responsivness.',
        short_msg: 'Have a high level of integrity',
        userName: 'Morris',
        location: 'Chicago, IL',
        date: 'Oct 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148842/76088630_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I am always happy with my bidding and purchasing experience with Dalshire International.',
        short_msg: 'Great variety. Easy payment and fast shipping.',
        userName: 'Kenneth',
        location: 'Fairfax, VA',
        date: 'Oct 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/151646/76698279_1_x.jpg?height=94&quality=70&version=1',
        message: 'One of the best, auction sites',
        short_msg: 'Great service',
        userName: 'Greg',
        location: 'Farmersburg, Indiana',
        date: 'Oct 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148842/76088627_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Make sure you request ONE invoice if bidding on 2 or 3 consecutive auction days.....',
        short_msg: 'How to save on Shipping',
        userName: 'Anna',
        location: 'Merrylands West, AU',
        date: 'Oct 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148842/76088602_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Where else can you bid on something’s of good quality and get for a steal? The auction is fun and entertaining, but addicting!',
        short_msg: 'What an opportunity!!',
        userName: 'Sharon',
        location: 'Olympia, WA',
        date: 'Oct 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148373/75222086_1_x.jpg?height=94&quality=70&version=1',
        message: 'Beautiful bracelet. Better than expected',
        short_msg: 'Great Quality',
        userName: 'Linda',
        location: 'Blairsville, GA',
        date: 'Oct 18, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148842/76088622_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Julie',
        location: 'Jacksonville, FL',
        date: 'Oct 18, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148842/76088617_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Chris',
        location: 'Stacy, Minnesota',
        date: 'Oct 18, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148844/76147847_1_x.jpg?height=94&quality=70&version=1',
        message: 'As usual everything goes perfect with Dalshire International!',
        short_msg: 'Excellent!!! All is Excellent!!',
        userName: 'JOHN',
        location: 'Medford, Massachusetts',
        date: 'Oct 18, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148839/75960430_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Josephine',
        location: 'Simi Valley, CA',
        date: 'Oct 16, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148840/75992380_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great',
        short_msg: 'Quality',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Oct 15, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148840/75992367_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Great quality, description, price, selection and service. A + all the way! Thank you Dalshire International.......',
        short_msg: 'My Go To Auction!',
        userName: 'Michael',
        location: 'Morgantown, West Virginia',
        date: 'Oct 15, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148837/75767826_1_x.jpg?height=94&quality=70&version=1',
        message: 'The piece of jewelry was exactly as expected',
        short_msg: 'Easy purchase from start to finish',
        userName: 'Jeff',
        location: 'Aliquippa, PA',
        date: 'Oct 15, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148839/75960456_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Arkady',
        location: 'Winnipeg, CA',
        date: 'Oct 14, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148838/75797739_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Renata',
        location: 'Saint Louis, MO',
        date: 'Oct 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148838/75797745_1_x.jpg?height=94&quality=70&version=1',
        message: 'The whole experience was pleasant.',
        short_msg: 'Quality experience',
        userName: 'Donald',
        location: 'Staten Island, New York',
        date: 'Oct 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148838/75797728_1_x.jpg?height=94&quality=70&version=1',
        message: 'Wonderful Auction House',
        short_msg: 'Wonderful Auction House',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Oct 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148838/75797663_1_x.jpg?height=94&quality=70&version=1',
        message: "the best auction house i've dealt with in 5 years",
        short_msg: 'honest they are the best',
        userName: 'Daniel',
        location: 'Omaha, NE',
        date: 'Oct 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/139727/71103397_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kathleen',
        location: 'LA, Louisiana',
        date: 'Oct 11, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148837/75767864_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Shelley',
        location: 'Monterey, California',
        date: 'Oct 11, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148375/75329031_1_x.jpg?height=94&quality=70&version=1',
        message: 'looks great on my hand. Enjoying this find...',
        short_msg: 'Stylish',
        userName: 'Mark Jeffrey',
        location: 'Middleburg, Florida',
        date: 'Oct 11, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148833/75601792_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ralph',
        location: 'Mashpee, MA',
        date: 'Oct 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148836/75721572_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Bryan',
        location: 'Tracy, CA',
        date: 'Oct 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148836/75721562_1_x.jpg?height=94&quality=70&version=1',
        message: 'Excellent items. Excellent customer service. Great items for prices',
        short_msg: "Excellent beautiful item's",
        userName: 'Christopher',
        location: 'Fort Lauderdale, FL',
        date: 'Oct 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148836/75721644_1_x.jpg?height=94&quality=70&version=1',
        message: 'Items received exactly as per the ad. Reasonable shipment time. Thank you.',
        short_msg: 'Good Vendor',
        userName: 'Cecilia',
        location: 'Leesburg, Virginia',
        date: 'Oct 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148836/75721634_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'John J',
        location: 'Grayslake, IL',
        date: 'Oct 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148835/75698150_1_x.jpg?height=94&quality=70&version=1',
        message: 'You get what you see; pictures and descriptions are accurate.',
        short_msg: 'Quality items',
        userName: 'Stephen Thomas Md',
        location: 'Lancaster, Pennsylvania',
        date: 'Oct 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148832/75601656_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ralph',
        location: 'Mashpee, MA',
        date: 'Oct 08, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148835/75698211_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Staten Island, NY',
        date: 'Oct 08, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148844/76147788_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Priscilla',
        location: 'Los Angeles, California',
        date: 'Oct 08, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148833/75601738_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Buckeye, AZ',
        date: 'Oct 07, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148833/75601817_1_x.jpg?height=94&quality=70&version=1',
        message: 'Beautiful jewelery at Fair Prices. Keep em Coming guys!!!!!!!!!',
        short_msg: 'What can I say but Great Auction House',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Oct 07, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148832/75601641_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: '',
        location: '',
        date: 'Oct 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148832/75601616_1_x.jpg?height=94&quality=70&version=1',
        message: 'Thank you for all smooth transactions???? best experience on this site.',
        short_msg: 'Top notch professionals',
        userName: 'Janet',
        location: 'Abita Springs, LA',
        date: 'Oct 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148832/75601643_1_x.jpg?height=94&quality=70&version=1',
        message: 'What else can I say but GREAT!!!!!!!!',
        short_msg: 'The Auction House',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Oct 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148832/75601624_1_x.jpg?height=94&quality=70&version=1',
        message: 'I’ve always had great experiences purchasing from Dalshire International.',
        short_msg: 'Really prompt delivery',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Oct 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148830/75497203_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ralph',
        location: 'Mashpee, MA',
        date: 'Oct 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148831/75549129_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jeffrey',
        location: 'Red Lion, Pennsylvania',
        date: 'Oct 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148831/75549147_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Cindy',
        location: 'San Antonio, TX',
        date: 'Oct 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148831/75549178_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Astoria, NY',
        location: 'Claudiu ASTORIA ART FRAMING',
        date: 'Oct 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148830/75497188_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Marylou',
        location: 'Allison Park, PA',
        date: 'Oct 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148830/75497235_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Loved the cut of stone and a ring that could be worn by either a man or women.....',
        short_msg: 'Just the whole design of ring.',
        userName: 'Mark Jeffrey',
        location: 'Middleburg, Florida',
        date: 'Oct 01, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148830/75497268_1_x.jpg?height=94&quality=70&version=1',
        message: 'I have bid many times for many items, and will bid again',
        short_msg: 'Great gems at a good price',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Oct 01, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148837/75767813_1_x.jpg?height=94&quality=70&version=1',
        message:
            'As is usual, Dalshire International is a very good auction house to do business with. All Aspects of bidding, payment, shipping are perfect. Item is always exactly as described and very accurate. Bid with confidence!!!!!',
        short_msg: 'Excellent!!',
        userName: 'JOHN',
        location: 'Medford, Massachusetts',
        date: 'Oct 01, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148827/75476562_1_x.jpg?height=94&quality=70&version=1',
        message: 'I keep on coming Back. Great Auction House!!!!!!!!! Thanks Everyone!!!!!!',
        short_msg: 'Great stuff for reasonable prices',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Sep 30, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146483/74338565_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ralph',
        location: 'Mashpee, MA',
        date: 'Sep 29, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148839/75960426_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Description of item always on the mark, any communications is rapidly answered and shipping is prompt and secure',
        short_msg: 'Accurate in description, and prompt communication',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Sep 29, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148376/75330722_1_x.jpg?height=94&quality=70&version=1',
        message: 'Extremely pleasant auction experience with excellent people to work with.',
        short_msg: 'A really great auction experience',
        userName: 'John',
        location: 'San Antonio, Texas',
        date: 'Sep 29, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148376/75330734_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Susan',
        location: 'Elmira, New York',
        date: 'Sep 28, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148376/75330651_1_x.jpg?height=94&quality=70&version=1',
        message: 'wonderful to do business with',
        short_msg: "they are pro's",
        userName: 'Daniel',
        location: 'Omaha, NE',
        date: 'Sep 28, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148376/75330687_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Charles',
        location: 'Columbus, Indiana',
        date: 'Sep 28, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148376/75330661_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Mark',
        location: 'Katy, Texas',
        date: 'Sep 28, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148376/75330727_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Renata',
        location: 'Saint Louis, MO',
        date: 'Sep 28, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148374/75260959_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Shirley',
        location: 'Olive Branch, Mississippi',
        date: 'Sep 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144748/73208263_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I guess I say everything when I say that I will do business with Dalshire International again. I am very impressed with the easy and speed that took me to complete the payment and to receive my package. They took care of everything, and the items as good as they described them',
        short_msg: 'Great Company!',
        userName: 'Paul',
        location: 'Edmonton, Canada',
        date: 'Jul 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144748/73208292_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Barry',
        location: 'Albuquerque, New Mexico',
        date: 'Jul 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144748/73208310_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Cindy',
        location: 'Sedona, AZ',
        date: 'Jul 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144498/73070389_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Friendly staff and understanding group. Great products. John is great person to buy from.',
        short_msg: 'Great people',
        userName: 'Douglas',
        location: 'Harker Heights, Texas',
        date: 'Jul 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144498/73070240_1_x.jpg?height=94&quality=70&version=1',
        message:
            'There are great deals to be made on this auction house. So far I am very satisfied with my purchases. Great deals..',
        short_msg: 'Items win',
        userName: 'James',
        location: 'Rockwall, TX',
        date: 'Jul 18, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144498/73070268_1_x.jpg?height=94&quality=70&version=1',
        message: 'Thank You Ronald',
        short_msg: 'Dalshire International',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Jul 18, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144498/73070266_1_x.jpg?height=94&quality=70&version=1',
        message: '.',
        short_msg: 'Good to work with',
        userName: 'Jeff',
        location: 'Johnson City, TN',
        date: 'Jul 18, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145226/73452371_1_x.jpg?height=94&quality=70&version=1',
        message: 'Better quality and looks than I expected.',
        short_msg: 'What a beautiful ring',
        userName: 'Marqueta',
        location: 'Sanger, CA',
        date: 'Jul 18, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144375/73024385_1_x.jpg?height=94&quality=70&version=1',
        message: 'Dalshire International',
        short_msg: 'Thank You Steve',
        userName: 'Steve',
        location: 'Marstons Mills, MA',
        date: 'Jul 15, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144375/73024407_1_x.jpg?height=94&quality=70&version=1',
        message: 'Eficaces complying the results without question.',
        short_msg: 'Very good auction house',
        userName: 'Shlomo',
        location: 'San Jose, CR',
        date: 'Jul 15, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144375/73024468_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I bid in a number of online auctions. One of the biggest annoyances is submitting a winning bid and then finding out that it doesn't meet the reserve price. Dalshire has no reserve auctions so that doesn't happen. They also give you the shipping price at upfront so you can take that into account.",
        short_msg: 'No Reserve',
        userName: 'John J',
        location: 'Grayslake, IL',
        date: 'Jul 15, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144375/73024402_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I did not receive a tracking number after my purchase and confirmation of payment. I contacted via email and received call and delivery was received in a professional manner. Will bid again. Enjoyed our conversation.',
        short_msg: 'Responded well regarding delivery status',
        userName: 'Michael',
        location: 'Lawrenceville, Georgia',
        date: 'Jul 15, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144375/73024441_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I CAN'T SAY ENOUGH GREAT THING ABOUT THIS AUCTION!! Everything thing was wrapped carefully and delivered quickly! Amazing prices and they a great to deal with.",
        short_msg: 'Dalshire International Is THE BEST!!!',
        userName: 'Pamela',
        location: 'San Francisco, CA',
        date: 'Jul 15, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144375/73024458_1_x.jpg?height=94&quality=70&version=1',
        message: 'Originally thought different company. Very satisfied with ur service',
        short_msg: 'Gr8 service',
        userName: 'Jeffrey',
        location: 'Mastic Beach, New York',
        date: 'Jul 14, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144498/73070281_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Great experience with Dalshire... nice watches purchased and received fast. Thank you',
        short_msg: 'Recommended seller',
        userName: 'Mark',
        location: 'Lake City, Florida',
        date: 'Jul 11, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/144498/73070278_1_x.jpg?height=94&quality=70&version=1',
        message: 'Professional staff answer your questions promptly and shipping is very fast.',
        short_msg: 'Great A1 Auction House',
        userName: 'Marie',
        location: 'Celina, TX',
        date: 'Jul 09, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/167092/84287079_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Dalshire International was very easy to deal with in the process of purchasing item of interest.',
        short_msg: 'Responsive and communicative with client inquiry.',
        userName: 'Dana',
        location: 'Santa Barbara, CA',
        date: 'May 25, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165949/83749732_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Cori',
        location: 'New York, New York',
        date: 'May 24, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166621/84065363_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I purchased on another selling platform a gorgeous Eisenberg Ice bracelet. With it came a business card from the seller which directed me to this auction. Here is where I won this silver, garnet ring. My packages both arrived very fast and the jewelry was clean and packaged neatly. I had so much fun bidding on items from the auction. I have since gone back to the site for more. Great prices. I am completely satisfied with my order.',
        short_msg: 'Dalshire International Review',
        userName: 'Kris',
        location: 'Tacoma, Washington',
        date: 'May 24, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166621/84065352_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'howard',
        location: 'Osprey, Florida',
        date: 'May 23, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166621/84065357_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Happy with all my winnings. The shipping was easy & very well done, my questions were answered promptly. ThankU',
        short_msg: 'Bid with confidence ~ *',
        userName: 'Sonia',
        location: 'Hialeah, Florida',
        date: 'May 23, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166610/84060899_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Matthew',
        location: 'La Habra, California',
        date: 'May 23, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165949/83749727_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I am always pleased with my purchases from Dalshire. It is always an easy transaction. Highly recommend.',
        short_msg: 'Always pleased',
        userName: 'Sue',
        location: 'Newmarket, NH',
        date: 'May 22, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165949/83749703_1_x.jpg?height=94&quality=70&version=1',
        message: 'Really good company that got my winning bid on my auction item to me right away.',
        short_msg: 'Great Auction!',
        userName: 'Larry',
        location: 'Fort Worth, TX',
        date: 'May 22, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165949/83749785_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Holly',
        location: 'Mohave Valley, AZ',
        date: 'May 22, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166598/84057667_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Denise',
        location: 'Social Circle, Georgia',
        date: 'May 22, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166598/84057605_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Edward',
        location: 'Silver Spring, MD',
        date: 'May 22, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166598/84057599_1_x.jpg?height=94&quality=70&version=1',
        message: 'No problems with dealing with them',
        short_msg: 'easy to work with',
        userName: 'DAVID',
        location: 'Schertz, TX',
        date: 'May 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166598/84057608_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Patrick',
        location: 'Battle Creek, Michigan',
        date: 'May 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166598/84057497_1_x.jpg?height=94&quality=70&version=1',
        message: 'Outstanding Smooth Transaction',
        short_msg: 'Communication',
        userName: 'Dennis',
        location: 'West Palm Beach, Florida',
        date: 'May 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165265/83424538_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Canyon Country, CA',
        date: 'May 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165438/83516398_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Canyon Country, CA',
        date: 'May 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165520/83545848_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Canyon Country, CA',
        date: 'May 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166304/83925149_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Canyon Country, CA',
        date: 'May 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165949/83749787_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Canyon Country, CA',
        date: 'May 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166496/84019877_1_x.jpg?height=94&quality=70&version=1',
        message:
            "No issues. Should have done my research. Earrings consistently worth about what I paid. Estimates are highly inflated but so are many on here. I'd buy from them again.",
        short_msg: 'Earrings are fine.',
        userName: 'Joshua',
        location: 'Freeport, Illinois',
        date: 'May 19, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166496/84019849_1_x.jpg?height=94&quality=70&version=1',
        message: 'I was very satisfied with everything.',
        short_msg: 'Everything was so easy',
        userName: 'Kenneth',
        location: 'Ocean Isle Beach, North Carolina',
        date: 'May 19, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166304/83925234_1_x.jpg?height=94&quality=70&version=1',
        message:
            "My daughter loved the cross ring I sent her for Mother's Day, the grey mother of pearl color matched perfect with other items I've purchased in past from Dalshire's Auctions and I have been very pleasantly pleased with all my previous correspondence.",
        short_msg: 'Styles, quality and satisfaction',
        userName: 'Holly',
        location: 'Mohave Valley, AZ',
        date: 'May 19, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165633/83584313_1_x.jpg?height=94&quality=70&version=1',
        message: 'Outstanding/Smooth Transition',
        short_msg: 'Communication',
        userName: 'Dennis',
        location: 'West Palm Beach, Florida',
        date: 'May 19, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166304/83925138_1_x.jpg?height=94&quality=70&version=1',
        message: 'Exceptional service very good communication',
        short_msg: 'Very Professional',
        userName: 'Gordon',
        location: 'RYE, United Kingdom',
        date: 'May 19, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166304/83925209_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: '',
        location: '',
        date: 'May 18, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166304/83925214_1_x.jpg?height=94&quality=70&version=1',
        message: 'Will be doing business with in future!!!!!!',
        short_msg: 'Very responsive in payment process and shipment!!!',
        userName: 'William',
        location: 'Alexandria, VA',
        date: 'May 18, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166304/83925136_1_x.jpg?height=94&quality=70&version=1',
        message: 'Outstanding Smooth Transaction',
        short_msg: 'Communication nication',
        userName: 'Dennis',
        location: 'West Palm Beach, Florida',
        date: 'May 18, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166304/83925179_1_x.jpg?height=94&quality=70&version=1',
        message: 'GOOD CO. TO DEAL WITH..',
        short_msg: 'excellent auction house',
        userName: 'LEON',
        location: 'Summerfield, Florida',
        date: 'May 18, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166304/83925157_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I bid on and won a very nice watch in one of Dalshire's auction. Was very impressed with the quality of the item and the speed of shipping. Will definitely participate in their auctions again.",
        short_msg: 'Great auction house',
        userName: 'Kevin',
        location: 'Gilbert, AZ',
        date: 'May 18, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/160919/81234437_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Henry',
        location: 'Newark, Delaware',
        date: 'May 18, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165520/83545829_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I've spoken to on several 6and were very professional. Quick to respond. And can't say enough about the buisness as a whole. You won't be disappointed.",
        short_msg: 'Great service Excellent customer service',
        userName: 'Kevin',
        location: 'Brechin, Canada',
        date: 'May 17, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166134/83831992_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Angela',
        location: 'Montgomery, New York',
        date: 'May 16, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166134/83831928_1_x.jpg?height=94&quality=70&version=1',
        message: 'Smooth transaction',
        short_msg: 'Outstanding!',
        userName: 'Dennis',
        location: 'West Palm Beach, Florida',
        date: 'May 15, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166134/83831956_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Steve',
        location: 'Marstons Mills, MA',
        date: 'May 15, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166134/83832002_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great service! Fast shipping! Totally recommend.',
        short_msg: 'Great service!',
        userName: 'Brian',
        location: 'Pottstown, Pennsylvania',
        date: 'May 15, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/166134/83832004_1_x.jpg?height=94&quality=70&version=1',
        message: 'GREAT TO DO BUSINESS WITH..',
        short_msg: 'very pleasant people...',
        userName: 'LEON',
        location: 'Summerfield, Florida',
        date: 'May 15, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165870/83713759_1_x.jpg?height=94&quality=70&version=1',
        message: 'Watches were cheap in price but attractive',
        short_msg: 'Wataches were cheap in price but attractive',
        userName: 'Jim',
        location: 'Hillsboro, MO',
        date: 'May 13, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162490/82069522_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sathees',
        location: 'Muscat, Oman',
        date: 'May 13, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162537/82091312_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sathees',
        location: 'Muscat, Oman',
        date: 'May 13, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162035/81805850_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sathees',
        location: 'Muscat, Oman',
        date: 'May 13, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/162947/82260301_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The picture of this topaz opal ring does not do it justice. This ring really sparkles and the opal is of excellent quality. Great seller with superior quality items.',
        short_msg: 'Sparkle duzzy',
        userName: 'Sheila',
        location: 'Rockwood, CA',
        date: 'May 13, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165870/83713868_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Suzzaynne',
        location: 'Vancouver, WA',
        date: 'May 12, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165520/83545865_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Melinda',
        location: 'Crystal Lake, IL',
        date: 'May 12, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165785/83678774_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Melinda',
        location: 'Crystal Lake, IL',
        date: 'May 12, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165870/83713795_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jeff',
        location: 'Dunnville, CA',
        date: 'May 12, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165870/83713780_1_x.jpg?height=94&quality=70&version=1',
        message: 'I’ve done a lot of business with Dalshire and trust them.',
        short_msg: 'Dalshire service is very fast and efficient. Products always as described.',
        userName: 'Richard',
        location: 'Atlanta, Georgia',
        date: 'May 12, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165870/83713841_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: '',
        location: '',
        date: 'May 12, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165870/83713729_1_x.jpg?height=94&quality=70&version=1',
        message: 'Outstanding service-quick response!',
        short_msg: 'Communication',
        userName: 'Dennis',
        location: 'West Palm Beach, Florida',
        date: 'May 12, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165785/83678768_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Atlanta, Georgia',
        date: 'May 12, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165633/83584293_1_x.jpg?height=94&quality=70&version=1',
        message: 'First anniversary gift was well recieved',
        short_msg: 'Nice ring',
        userName: 'Robert',
        location: 'North Miami, Florida',
        date: 'May 11, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165785/83678732_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: '',
        location: '',
        date: 'May 11, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165785/83678792_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Barbara',
        location: 'Metairie, Louisiana',
        date: 'May 11, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165785/83678758_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Canyon Country, CA',
        date: 'May 11, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165785/83678807_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jo-An',
        location: 'Saint Petersburg, FL',
        date: 'May 11, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165785/83678809_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The items arrived several days ahead of schedule and were well packaged to prevent damage. I am well pleased with both necklaces,',
        short_msg: 'The two items were even nicer than describe and shown in photos.',
        userName: 'Douglas',
        location: 'Columbus, Georgia',
        date: 'May 11, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165633/83584255_1_x.jpg?height=94&quality=70&version=1',
        message: 'excellent auction house',
        short_msg: 'excellent auction house',
        userName: 'Miriam',
        location: 'San Antonio, TX',
        date: 'May 11, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165633/83584297_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I bought several watches for myself. So much for just one Timex! And the quality and pricing was so good, I thought: who do I owe gifts to? What family, what friends, who works for me? All have been pleased with the surprising gifts that just came to them, no BDay, not Christmas, just Thank You. So glad I found Dalshire. Tom',
        short_msg: "I'm hooked! And Glad!",
        userName: 'Tom',
        location: 'Vero Beach, FL',
        date: 'May 09, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165633/83584294_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very quick and satisfying experience.',
        short_msg: 'Efficient',
        userName: 'Mike',
        location: 'Springfield, Illinois',
        date: 'May 09, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165633/83584267_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Paula',
        location: 'Fall River, Massachusetts',
        date: 'May 09, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165630/83583420_1_x.jpg?height=94&quality=70&version=1',
        message: 'GIVES ANSWERS EXCEPTIONALLY WELL/FAST SERVICE',
        short_msg: 'EXCEPTIONAL AUCTIONEER',
        userName: 'LEON',
        location: 'Summerfield, Florida',
        date: 'May 08, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165592/83571686_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Canyon Country, CA',
        date: 'May 08, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165592/83571713_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Donald',
        location: 'Staten Island, New York',
        date: 'May 07, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165520/83545784_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Darian',
        location: 'Mclean, Virginia',
        date: 'May 06, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165520/83545844_1_x.jpg?height=94&quality=70&version=1',
        message: 'as aways fast efficient professional',
        short_msg: 'a joy to work with',
        userName: 'Gregg',
        location: 'Port Townsend, WA',
        date: 'May 05, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165520/83545803_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: '',
        location: 'Michael',
        date: 'New York, NY',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165520/83545807_1_x.jpg?height=94&quality=70&version=1',
        message:
            "The earrings that I won at auction from Dalshire are super cute. They were exactly as described and I received them quicker than expected. I have won quite a few other auctions since, so we'll see if those transactions go as smoothly as this one did. Very pleased so far though.",
        short_msg: 'So far so good',
        userName: 'Mitzi',
        location: 'Everett, Washington',
        date: 'May 05, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164764/83190156_1_x.jpg?height=94&quality=70&version=1',
        message: 'Love how fast I received my items and they are good quality',
        short_msg: 'Great quality items',
        userName: 'Melissa',
        location: 'Salt Lake City, UT',
        date: 'May 04, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165271/83426275_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Weslee',
        location: 'Cincinnati, OH',
        date: 'May 02, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165271/83426218_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Longboat Key, Florida',
        date: 'May 01, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165271/83426274_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Aaron',
        location: 'Beachwood, OH',
        date: 'May 01, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165271/83426236_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Madison Heights, MI',
        date: 'May 01, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165271/83426194_1_x.jpg?height=94&quality=70&version=1',
        message: 'A very professional business',
        short_msg: 'A very professional business',
        userName: '',
        location: '',
        date: 'May 01, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165271/83426243_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Canyon Country, CA',
        date: 'May 01, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165268/83425121_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Angela',
        location: 'Montgomery, New York',
        date: 'Apr 30, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165265/83424557_1_x.jpg?height=94&quality=70&version=1',
        message: 'great job guys',
        short_msg: 'very fast returning bids, on time and pain less thanks',
        userName: 'Robert/Don',
        location: 'Clearwater, FL',
        date: 'Apr 28, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165031/83313054_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kim',
        location: 'Arlington Heights, IL',
        date: 'Apr 27, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/165031/83313051_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'donna',
        location: 'Ocala, FL',
        date: 'Apr 26, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164764/83190154_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'M',
        location: 'New Orleans, LA',
        date: 'Apr 25, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164764/83190143_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Hatboro, Pennsylvania',
        date: 'Apr 24, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164764/83190134_1_x.jpg?height=94&quality=70&version=1',
        message: 'RIGHT ON THE MONEY FOR DISCRIPTIONS... …..',
        short_msg: "HONEST ITEM'S",
        userName: 'LEON',
        location: 'Summerfield, Florida',
        date: 'Apr 24, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164764/83190115_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Thomas D',
        location: 'Modesto, California',
        date: 'Apr 24, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164764/83190138_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tom',
        location: 'Irvine, California',
        date: 'Apr 24, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164764/83190099_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jeffrey',
        location: 'Harrisonburg, Virginia',
        date: 'Apr 24, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164764/83190165_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Canyon Country, CA',
        date: 'Apr 24, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164295/82944414_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Thomas',
        location: 'Sebastian, FL',
        date: 'Apr 24, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164757/83189035_1_x.jpg?height=94&quality=70&version=1',
        message: 'This was by far the easiest online auction I have ever used.',
        short_msg: 'Easiest online auction I have used',
        userName: 'Mark',
        location: 'Franklin, Indiana',
        date: 'Apr 23, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164392/83013121_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'William',
        location: 'Phila, Pennsylvania',
        date: 'Apr 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164392/83013142_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Rebecca',
        location: 'Tualatin, Oregon',
        date: 'Apr 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164392/83013169_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kim',
        location: 'Arlington Heights, IL',
        date: 'Apr 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164361/82982667_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kim',
        location: 'Arlington Heights, IL',
        date: 'Apr 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164361/82982673_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: '',
        location: '',
        date: 'Apr 21, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164361/82982602_1_x.jpg?height=94&quality=70&version=1',
        message: 'Excellent and efficient. Love the site.',
        short_msg: 'I love how easy it is',
        userName: 'Mike',
        location: 'Phoenix, Arizona',
        date: 'Apr 20, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164361/82982598_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Ione, CA',
        date: 'Apr 20, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164361/82982658_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Dalshire always produces as advertised and in a timely manner. You can buy with complete confidence from this auctioneer.',
        short_msg: 'Dalshire always produces as advertised and in a timely manner.',
        userName: 'Robert',
        location: 'Lake Worth, FL',
        date: 'Apr 20, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164361/82982659_1_x.jpg?height=94&quality=70&version=1',
        message: 'Watch runs great',
        short_msg: 'Watch',
        userName: 'donna',
        location: 'Ocala, FL',
        date: 'Apr 20, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164361/82982582_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Bronia',
        location: 'Buffalo, New York',
        date: 'Apr 20, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/163835/82727839_1_x.jpg?height=94&quality=70&version=1',
        message: 'I would use this auction cite with confidence.',
        short_msg: 'Prompt and flawless.',
        userName: 'Don',
        location: 'Oak Island, NC',
        date: 'Apr 19, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164295/82944394_1_x.jpg?height=94&quality=70&version=1',
        message: 'perfect place to shop',
        short_msg: 'great all over',
        userName: 'donna',
        location: 'Ocala, FL',
        date: 'Apr 18, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164295/82944358_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Angela',
        location: 'Montgomery, New York',
        date: 'Apr 18, 2020',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/164101/82838803_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kim',
        location: 'Arlington Heights, IL',
        date: 'Apr 18, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154940/78456876_1_x.jpg?height=94&quality=70&version=1',
        message: 'great stuff',
        short_msg: 'my wife loves thre ring',
        userName: 'Steven',
        location: 'Dallas, Texas',
        date: 'Dec 16, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154943/78216616_1_x.jpg?height=94&quality=70&version=1',
        message: 'Always a great experience',
        short_msg: 'Great stuff',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Dec 15, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154943/78216571_1_x.jpg?height=94&quality=70&version=1',
        message: 'Everything was as advertised and was received on time.',
        short_msg: 'Satisfied',
        userName: 'Charles',
        location: 'Columbus, Indiana',
        date: 'Dec 14, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154943/78216587_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Beautiful framing, great quality. Ship packaging superb. Immediate response to inquiries. Highly recommend',
        short_msg: 'Quality products and company',
        userName: 'Sid',
        location: 'Oneonta, Alabama',
        date: 'Dec 14, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154844/78181469_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Elizabeth',
        location: 'New Orleans, LA',
        date: 'Dec 13, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154844/78181553_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Carlitos',
        location: 'Providence, RI',
        date: 'Dec 12, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154844/78181505_1_x.jpg?height=94&quality=70&version=1',
        message: 'Excellent to do business with!! Everything is great as Usual!!!',
        short_msg: 'Excellent as is usual!!!',
        userName: 'JOHN',
        location: 'Medford, Massachusetts',
        date: 'Dec 12, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154844/78181486_1_x.jpg?height=94&quality=70&version=1',
        message: 'Best customer service I’ve ever dealt with at any auction!',
        short_msg: 'Ease of bidding and great customer service',
        userName: 'Kevin',
        location: 'La Crosse, Wisconsin',
        date: 'Dec 12, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154844/78181488_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Philip',
        location: 'Clackamas, Oregon',
        date: 'Dec 12, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154769/78142953_1_x.jpg?height=94&quality=70&version=1',
        message: "Awesome auction great response best delivery John's the best",
        short_msg: 'Great job',
        userName: 'Carlitos',
        location: 'Providence, RI',
        date: 'Dec 10, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154769/78142925_1_x.jpg?height=94&quality=70&version=1',
        message: 'Awesome to do business with!! Everything is always excellent!!',
        short_msg: 'Excellent to do business with!!!!! Bid with confidence!!',
        userName: 'JOHN',
        location: 'Medford, Massachusetts',
        date: 'Dec 10, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154768/78142870_1_x.jpg?height=94&quality=70&version=1',
        message:
            'LiveAuctioneers.com is the best and incredibly professional auction. Absolutely everything always has been smooth.',
        short_msg: 'Great Professional auction one the best online auctions in the world!',
        userName: 'Kevin',
        location: 'La Crosse, Wisconsin',
        date: 'Dec 10, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154634/78049147_1_x.jpg?height=94&quality=70&version=1',
        message: 'X',
        short_msg: 'Good transaction',
        userName: 'Mark',
        location: 'Grand Blanc, MI',
        date: 'Dec 10, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154768/78142902_1_x.jpg?height=94&quality=70&version=1',
        message: 'N/A',
        short_msg: 'Good transaction',
        userName: 'Mark',
        location: 'Grand Blanc, MI',
        date: 'Dec 10, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154768/78142819_1_x.jpg?height=94&quality=70&version=1',
        message:
            'This watch had an unfamiliar, to me, way of setting the time/date. Dalshire responded to my question promptly with an e mail and a phone call!',
        short_msg: 'Excellent post sale support',
        userName: 'Ike',
        location: 'Sugar Land, TX',
        date: 'Dec 10, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152647/77296081_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Product was exactly as described and shipped really fast. Payment was a breeze! Will purchase from again.',
        short_msg: 'Great product and quick shipping',
        userName: 'Matt',
        location: 'Elkhorn, Nebraska',
        date: 'Dec 09, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154404/77933454_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Bryan',
        location: 'Tracy, CA',
        date: 'Dec 03, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154307/77879717_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Talbot',
        location: 'Yorktown, Virginia',
        date: 'Dec 02, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154297/77869361_1_x.jpg?height=94&quality=70&version=1',
        message: 'she loves it',
        short_msg: 'neat house to deal with',
        userName: 'John',
        location: 'Prospect Heights, Illinois',
        date: 'Dec 02, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/154297/77869369_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Nancy',
        location: 'Houston, Texas',
        date: 'Dec 02, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153992/77728884_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very nice quality jewelry.',
        short_msg: 'Excellent Prices',
        userName: 'Sheila',
        location: 'Rockwood, CA',
        date: 'Dec 01, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153991/77728792_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kelly',
        location: 'Mckinney, Texas',
        date: 'Dec 01, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153992/77728870_1_x.jpg?height=94&quality=70&version=1',
        message:
            'John H. Is awesome and always available to help with any questions. The items I have won are by far the best I have seen in quality and accurate. I would highly recommend doing business with Dalshire International. FIVE STARS all around',
        short_msg: 'Great staff',
        userName: 'John',
        location: 'New Port Richey, Florida',
        date: 'Nov 30, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153991/77728813_1_x.jpg?height=94&quality=70&version=1',
        message: 'reliable company',
        short_msg: 'easy to bid and pay',
        userName: 'Allen',
        location: 'Melbourne, Florida',
        date: 'Nov 30, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153991/77728831_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great Auction House',
        short_msg: 'Quality',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Nov 30, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153992/77728867_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kevin',
        location: 'La Crosse, Wisconsin',
        date: 'Nov 30, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153987/77728449_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'William',
        location: 'Tarpon Springs, Florida',
        date: 'Nov 29, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153989/77728746_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jane',
        location: 'Plymouth, United Kingdom',
        date: 'Nov 29, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153882/77684255_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Wayde',
        location: 'Many, Louisiana',
        date: 'Nov 29, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153718/77613652_1_x.jpg?height=94&quality=70&version=1',
        message: 'Quick delivery and item I won was right on.',
        short_msg: 'Item I bid on is exactly as described.',
        userName: 'Ellie',
        location: 'Inverness, FL',
        date: 'Nov 28, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153719/77613698_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Karen',
        location: 'Poultney, Vermont',
        date: 'Nov 27, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153718/77613662_1_x.jpg?height=94&quality=70&version=1',
        message: 'Easy to buy and they take care of the rest! Items are always top notch.',
        short_msg: 'Dalshire International is the BEST!',
        userName: 'Pamela',
        location: 'San Francisco, CA',
        date: 'Nov 26, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153280/77537087_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Customer service is 5 star, easy to reach someone if needed, the best auction site I’ve every used in a decade. Highly Recommend',
        short_msg: 'Incredible auction great selection of items',
        userName: 'Kevin',
        location: 'La Crosse, Wisconsin',
        date: 'Nov 25, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153279/77536880_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Knud',
        location: 'Sandy Hook, Connecticut',
        date: 'Nov 25, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153147/77332646_1_x.jpg?height=94&quality=70&version=1',
        message:
            'If you want to deal with a true professional auction then Dalshire International is the best by leaps and bounds.',
        short_msg:
            'I have been buying at auctions for over a decade, Dalshire International Auctions is the best!',
        userName: 'Kevin',
        location: 'La Crosse, Wisconsin',
        date: 'Nov 25, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150805/76193660_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'David',
        location: 'Denham Springs, LA',
        date: 'Nov 24, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153275/77399129_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I have dealt with them several times..... a favorite... they are efficient fast and professional...',
        short_msg: 'good to deal with',
        userName: 'Gregg',
        location: 'Port Townsend, WA',
        date: 'Nov 23, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153274/77399096_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Have purchased from Dalshire many times. Always satisfied. Good quality, good service. very reliable and responsive seller.',
        short_msg:
            'Have purchased from Dalshire many times. Always satisfied. Good quality, good service.',
        userName: 'Robert',
        location: 'Lake Worth, FL',
        date: 'Nov 23, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153249/77396292_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I'm happy with their service and like what I bought very much. They put the jewelry into separate bags with anti-tarnish pieces. Excellent.",
        short_msg: 'Prompt and exactly as described',
        userName: 'Joyce',
        location: 'Wayne, PA',
        date: 'Nov 23, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153249/77396263_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Ordered a framed autographed picture. The shipping was pricey but when I received it I understood why. The picture and frame where in mint condition. I got my monies worth of bubble wrap. This is a gift that I can’t wait to give. I have confidence to order from Dalshire again.',
        short_msg: 'Better than Expected',
        userName: 'Elizabeth',
        location: 'Austin, Texas',
        date: 'Nov 22, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153147/77332619_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Carmela',
        location: 'Dunnellon, FL',
        date: 'Nov 21, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153147/77332617_1_x.jpg?height=94&quality=70&version=1',
        message: 'Price and quality were both good!',
        short_msg: 'Quality',
        userName: 'Mark',
        location: 'Grand Blanc, MI',
        date: 'Nov 21, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152648/77333252_1_x.jpg?height=94&quality=70&version=1',
        message:
            'really enjoy doing business with them-have even recommended to my local jeweler:)',
        short_msg: 'always so responsive',
        userName: 'Linda',
        location: 'PSL, Florida',
        date: 'Nov 21, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152648/77333215_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tom',
        location: 'Elm Grove, Wisconsin',
        date: 'Nov 21, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153147/77332616_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Alec',
        location: 'Monroe, GA',
        date: 'Nov 21, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153084/77295924_1_x.jpg?height=94&quality=70&version=1',
        message:
            'An item I had won was damaged while being prepared for shipping. They contacted me and arranged for a mutually accepted substitute. I have never had any problems with their shipment or items.',
        short_msg: 'Good Customer Service',
        userName: 'John J',
        location: 'Grayslake, IL',
        date: 'Nov 20, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/153084/77295873_1_x.jpg?height=94&quality=70&version=1',
        message:
            'This is the best Auction house online. They have great reminders plus there is absolutely no hassle bidding, paying, the whole process is great. A very professional web site.',
        short_msg: 'A very good selection',
        userName: 'Kevin',
        location: 'La Crosse, Wisconsin',
        date: 'Nov 20, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152646/77195092_1_x.jpg?height=94&quality=70&version=1',
        message:
            'All of the jewelry I have bought on the auction are so very well made. You can tell they are good quality. Can’t beat the prices!',
        short_msg: 'Love the ring!!',
        userName: 'Sharon',
        location: 'Olympia, WA',
        date: 'Nov 19, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152646/77195171_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Dalshire always has great items, never any problems from start to finish, receive items in a few days, they never disappoint! Thank you John and staff at Dalshire!',
        short_msg: 'Great Turquoise Pinky Ring',
        userName: 'Remney',
        location: 'Fresno, California',
        date: 'Nov 19, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152646/77195172_1_x.jpg?height=94&quality=70&version=1',
        message: 'Wonderful Auction House',
        short_msg: 'Great Jewels',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Nov 19, 2019',
        stars: '5',
    },
    {
        img_url: '',
        message: '',
        short_msg: '',
        userName: 'John J',
        location: 'Grayslake, IL',
        date: 'Nov 19, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152646/77195116_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great items, Great price, Fast shipping',
        short_msg: 'the best auction site!',
        userName: 'Mark',
        location: 'Niles, Michigan',
        date: 'Nov 19, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152188/77083689_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'christopher',
        location: 'Hampstead, NC',
        date: 'Nov 18, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152644/77171745_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Johnny',
        location: 'Zephyrhills, Florida',
        date: 'Nov 18, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152644/77171812_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Michelle',
        location: 'Los Angeles, California',
        date: 'Nov 18, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152644/77171829_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Cindy',
        location: 'San Antonio, TX',
        date: 'Nov 18, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152643/77168339_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Virginia',
        location: 'Sydney, Australia',
        date: 'Nov 17, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152643/77168377_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very pleased with buying experience-',
        short_msg: 'Very attentive and engaging-',
        userName: 'Ned',
        location: 'Fort Worth, Texas',
        date: 'Nov 17, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152158/76996579_1_x.jpg?height=94&quality=70&version=1',
        message:
            'DASHLANE IS THE DREAM AUCTION HOUSE... EVERY BIDDER LIKES TO DEAL WITH ZERO PROBLEMS AND THIS IS THE PLACE TO BID.',
        short_msg:
            'I WAS EXTREMELY SATISFIED WITH EVERY ASPECT OF THE AUCTION PROCESS. I WOULD BUY FROM THIS BAUCTION H',
        userName: 'Candi',
        location: 'Lakewood, Colorado',
        date: 'Nov 17, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152643/77168399_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kathy',
        location: 'Highlands, Texas',
        date: 'Nov 16, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152639/77162807_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great service, the item arrived on time',
        short_msg: 'Great service, the item arrived on time',
        userName: 'Richard',
        location: 'Savannah, Georgia',
        date: 'Nov 16, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152638/77139220_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Carole',
        location: 'Derry, New Hampshire',
        date: 'Nov 16, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152643/77168422_1_x.jpg?height=94&quality=70&version=1',
        message: 'Will use them again',
        short_msg: 'Great gems and other stuff',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Nov 16, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152639/77162904_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Arthur',
        location: 'Pembroke Pines, FL',
        date: 'Nov 16, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152638/77139207_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Nilu',
        location: 'Sherman Oaks, California',
        date: 'Nov 14, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145302/73593172_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Very easy transaction with this auction house. Pleased with the process and the product.',
        short_msg: 'Easy transaction',
        userName: 'Leticia',
        location: 'Long Beach, California',
        date: 'Nov 14, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152601/77098727_1_x.jpg?height=94&quality=70&version=1',
        message: 'I have made many purchases from Dalshire and have never been disappointed.',
        short_msg: 'Good variety of desirable products. Fast service and always reliable',
        userName: 'Robert',
        location: 'Lake Worth, FL',
        date: 'Nov 14, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150811/76450334_1_x.jpg?height=94&quality=70&version=1',
        message: 'Exellent',
        short_msg: 'Exellent',
        userName: 'David',
        location: 'Advance, NC',
        date: 'Nov 13, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152601/77098829_1_x.jpg?height=94&quality=70&version=1',
        message: 'GreatJewels.',
        short_msg: 'Quality',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Nov 13, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152601/77098825_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Great and easy company to buy from! Have become a regular visitor and frequent buyer since.',
        short_msg: 'Simply awesome!',
        userName: 'Yolande',
        location: 'Magnolia, DE',
        date: 'Nov 13, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152601/77098747_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Linda',
        location: 'PSL, Florida',
        date: 'Nov 13, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152188/77083664_1_x.jpg?height=94&quality=70&version=1',
        message: 'The items I won in auction were wrapped and packaged with a lot of care.',
        short_msg: 'Much care given.',
        userName: 'Greg',
        location: 'Jefferson, MD',
        date: 'Nov 13, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152188/77083650_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ulrike',
        location: 'Birmingham, AL',
        date: 'Nov 12, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152158/76996570_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Irene',
        location: 'Sugar Hill, NH',
        date: 'Nov 12, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152138/76825322_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Carlo',
        location: 'Capraia E Limite, IT',
        date: 'Capraia E Limite, IT',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152188/77083589_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Linda',
        location: 'PSL, Florida',
        date: 'Nov 12, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152158/76996575_1_x.jpg?height=94&quality=70&version=1',
        message: 'HIGHLY RECOMMEND',
        short_msg: 'FAST SHIPPING/GREAT AUCTION',
        userName: 'Mark',
        location: 'Niles, Michigan',
        date: 'Nov 11, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152158/76996577_1_x.jpg?height=94&quality=70&version=1',
        message: 'Everything went as expected.',
        short_msg: 'Everything went as expected.',
        userName: 'Charles',
        location: 'Columbus, Indiana',
        date: 'Nov 11, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152158/76996523_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Bryan',
        location: 'Tracy, CA',
        date: 'Nov 11, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152158/76996547_1_x.jpg?height=94&quality=70&version=1',
        message: 'Delivery is fast, payment is easy and quality is good.',
        short_msg: 'Always a great experience',
        userName: 'Peter',
        location: 'Tucson, AZ',
        date: 'Nov 11, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152157/76992871_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Nick',
        location: 'Hebron, KY',
        date: 'Nov 11, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152138/76825269_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Won an item I bid on. Shipping was quick and received items within one week after buying in perfect condition very good auction site to deal with',
        short_msg: 'Knowing you can trust too get your items shipped ASAP',
        userName: 'Steve',
        location: 'Marstons Mills, MA',
        date: 'Nov 08, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152137/76816489_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Alice',
        location: 'Schlieren, Switzerland',
        date: 'Nov 07, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152137/76816508_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Wayde',
        location: 'Many, Louisiana',
        date: 'Nov 07, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152137/76816472_1_x.jpg?height=94&quality=70&version=1',
        message:
            'From registration, to bidding, to payment, to delivery-all was very fast and easy to understand and navigate. Product was exactly as described and better than I imagined.',
        short_msg: 'Fast everything!',
        userName: 'Darrin',
        location: 'Phoenix, Arizona',
        date: 'Nov 07, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/152137/76816525_1_x.jpg?height=94&quality=70&version=1',
        message: "They're responsive to any concerns that one might have",
        short_msg: "They're responsive to any concerns that one might have",
        userName: 'Linda',
        location: 'PSL, Florida',
        date: 'Nov 07, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/151647/76699534_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Wayde',
        location: 'Many, Louisiana',
        date: 'Nov 06, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/151647/76699535_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Stephanie',
        location: 'Deerfield Beach, Florida',
        date: 'Nov 05, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150808/76296896_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ralph',
        location: 'Mashpee, MA',
        date: 'Nov 05, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/151647/76699522_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: '',
        location: '',
        date: 'Nov 05, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/151647/76699509_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Morris',
        location: 'Chicago, IL',
        date: 'Nov 05, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/151646/76698306_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Eva',
        location: 'Hasbrouck Heights, New Jersey',
        date: 'Nov 04, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/151646/76698323_1_x.jpg?height=94&quality=70&version=1',
        message:
            'always responds quickly to a question; items are quality; arrives in a timely manner',
        short_msg: 'Great Seller!',
        userName: 'Kathy',
        location: 'Highlands, Texas',
        date: 'Nov 04, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/151646/76698223_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joseph',
        location: 'Elizabeth, New Jersey',
        date: 'Nov 04, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150813/76538897_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Gerard',
        location: 'El Paso, TX',
        date: 'Nov 03, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150810/76439883_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Wayde',
        location: 'Many, Louisiana',
        date: 'Nov 03, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150813/76538829_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Josephine',
        location: 'Simi Valley, CA',
        date: 'Nov 03, 2019',
        stars: '5',
    },
    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150813/76538829_1_x.jpg?height=94&quality=70&version=1',
        message: 'Everything was Excellent!! Excellent Auction House!!! Bid with confidence!!!!!',
        short_msg: 'Excellent as usual!!',
        userName: 'JOHN',
        location: 'Medford, Massachusetts',
        date: 'Nov 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159712/80597177_1_x.jpg?height=94&quality=70&version=1',
        message: 'First rate auction house that takes care of the customer!',
        short_msg: 'Quality item!',
        userName: 'Geoffrey',
        location: 'Murray, NE',
        date: 'Feb 17, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159712/80597170_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'Glen Ellyn, IL',
        date: 'Feb 17, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159712/80597130_1_x.jpg?height=94&quality=70&version=1',
        message: 'Beautiful awesome watch great deal',
        short_msg: 'Beautiful awesome watch',
        userName: 'Carlitos',
        location: 'Providence, RI',
        date: 'Feb 17, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159623/80553408_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Atlanta, Georgia',
        date: 'Feb 16, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159623/80553454_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Wayde',
        location: 'Many, Louisiana',
        date: 'Feb 16, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159623/80553397_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ml',
        location: 'Roswell, Georgia',
        date: 'Feb 15, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159623/80553371_1_x.jpg?height=94&quality=70&version=1',
        message: 'It has been a pleasure shopping this way!',
        short_msg: 'Ideal shopping conditions.',
        userName: 'Cristina',
        location: 'New York, New York',
        date: 'Feb 15, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159621/80553206_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Dalshire offers many super bargains at auction for jewelry, watches, memorabilia etc.',
        short_msg: 'GREAT AUCTION House',
        userName: 'Barbara',
        location: 'New Port Richey, Florida',
        date: 'Feb 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159621/80553208_1_x.jpg?height=94&quality=70&version=1',
        message: 'Awesome watches great quality good price',
        short_msg: 'Awesome watches',
        userName: 'Carlitos',
        location: 'Providence, RI',
        date: 'Feb 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159621/80553218_1_x.jpg?height=94&quality=70&version=1',
        message:
            'This was my second purchase and the quality is very good. I appreciated the fair shipping rate too.',
        short_msg: 'Nice Items from Dalshire',
        userName: 'Carol',
        location: 'Iron Mountain, Michigan',
        date: 'Feb 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159621/80553170_1_x.jpg?height=94&quality=70&version=1',
        message: 'Check Dalshire International for reasonable items for auction.',
        short_msg: 'Good buys-description is correct on each iteml',
        userName: 'Carole',
        location: 'Anaheim, CA',
        date: 'Feb 14, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159232/80385318_1_x.jpg?height=94&quality=70&version=1',
        message: 'High quality at a great price why ask for anything else 800',
        short_msg: 'Simply awesome',
        userName: 'Carlitos',
        location: 'Providence, RI',
        date: 'Feb 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159232/80385320_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Darlene',
        location: 'Saskatoon, Canada',
        date: 'Feb 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159232/80385351_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'San Marcos, Texas',
        date: 'Feb 13, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159231/80385235_1_x.jpg?height=94&quality=70&version=1',
        message: 'Prompt delivery. Excellent packaging and pretty wrapping of the item. Thank you.',
        short_msg: 'Everything in good working order',
        userName: 'Jacqueline A.',
        location: 'Sidney, CA',
        date: 'Feb 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159089/80336811_1_x.jpg?height=94&quality=70&version=1',
        message: 'great auction house',
        short_msg: 'always use them',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Feb 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159231/80385176_1_x.jpg?height=94&quality=70&version=1',
        message: 'They were responsive, all was good and received a day sooner than expected.',
        short_msg: 'Met all expectations',
        userName: 'Michael',
        location: 'San Marcos, CA',
        date: 'Feb 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159089/80336788_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Martin',
        location: 'Hopkinsville, Kentucky',
        date: 'Feb 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159021/80306244_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Martin',
        location: 'Hopkinsville, Kentucky',
        date: 'Feb 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158108/79812359_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Martin',
        location: 'Hopkinsville, Kentucky',
        date: 'Feb 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157792/79631264_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Martin',
        location: 'Hopkinsville, Kentucky',
        date: 'Feb 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159231/80385200_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Martin',
        location: 'Hopkinsville, Kentucky',
        date: 'Feb 11, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159017/80305028_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I have bought several jewelry items so far and very happy with the purchase and speed of delivery. Some great bargains to be had!',
        short_msg: 'Wonderful items to be found here.',
        userName: 'Wendy',
        location: 'New York, NY',
        date: 'Feb 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159089/80336753_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Kelly',
        location: 'Mckinney, Texas',
        date: 'Feb 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159017/80305109_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Frank',
        location: 'San Marcos, Texas',
        date: 'Feb 10, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159021/80306269_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great people to do business with.',
        short_msg: 'Great people to do business with.',
        userName: 'Marqueta',
        location: 'Sanger, CA',
        date: 'Feb 08, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159021/80306218_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Good and reasonable, prompt at answering any questions you might have I will purchase from again.',
        short_msg: 'Good Products',
        userName: 'Judith',
        location: 'Pocahontas, Illinois',
        date: 'Feb 08, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159021/80306208_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Darlene',
        location: 'Saskatoon, Canada',
        date: 'Feb 08, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159021/80306272_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Patrick',
        location: 'Fort Wayne, Indiana',
        date: 'Feb 08, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159017/80304992_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great people to do business with.',
        short_msg: 'Great people to do business with.',
        userName: 'Marqueta',
        location: 'Sanger, CA',
        date: 'Feb 07, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159017/80305075_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Bryan',
        location: 'Tracy, CA',
        date: 'Feb 07, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159017/80304941_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'R Scott',
        location: 'Tujunga, CA',
        date: 'Feb 07, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157871/79678032_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Everything was delivered in a short time period and it was all wrapped real good and it was as seen and described. Thank you I will use again.',
        short_msg: 'Good experience',
        userName: 'Judith',
        location: 'Pocahontas, Illinois',
        date: 'Feb 07, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159015/80303076_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Patrick',
        location: 'Fort Wayne, Indiana',
        date: 'Feb 06, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159015/80302924_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great Auction House',
        short_msg: 'Great',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Feb 06, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159015/80303025_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I am a repeat buyer with Dalshire International. The variety and quality of items is exceptional at a good price. Fast shipment.',
        short_msg: 'Excellent quality and value',
        userName: 'Kenneth',
        location: 'Fairfax, VA',
        date: 'Feb 06, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159008/80293313_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Darlene',
        location: 'Saskatoon, Canada',
        date: 'Feb 04, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/159008/80293320_1_x.jpg?height=94&quality=70&version=1',
        message: 'Piece arrived on time and was as expected.',
        short_msg: 'Everything was good,',
        userName: 'Charles',
        location: 'Columbus, Indiana',
        date: 'Feb 04, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158403/79972299_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Lynn',
        location: 'Roosevelt, UT',
        date: 'Feb 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158403/79972380_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Marjorie',
        location: 'Colts Neck, New Jersey',
        date: 'Feb 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158403/79972281_1_x.jpg?height=94&quality=70&version=1',
        message:
            "As a 1st time bidder/buyer, my experience was surprisingly exhilarating and very good. I'm very pleased with my purchase and it's quality. I was so satisfied that I've already made my 2nd bid/purchase. Thank you John.",
        short_msg: 'Most Satisfied with service, quality and shipping',
        userName: 'Holly',
        location: 'Mohave Valley, AZ',
        date: 'Feb 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158403/79972297_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great experienced doign business wit this aution.',
        short_msg: 'good',
        userName: 'George',
        location: 'Cedar Grove, New Jersey',
        date: 'Feb 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158403/79972294_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Paul',
        location: 'Bridgwater, United Kingdom',
        date: 'Feb 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158403/79972375_1_x.jpg?height=94&quality=70&version=1',
        message: 'John always gets back to any inquiries-and promptly!',
        short_msg: 'very responsive',
        userName: 'Linda',
        location: 'PSL, Florida',
        date: 'Feb 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158108/79812378_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great product! Shipped fast! First class auction house!',
        short_msg: 'Fast invoicing, fast shipping, quality items!',
        userName: 'Geoffrey',
        location: 'Murray, NE',
        date: 'Feb 03, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/150805/76193613_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Scott',
        location: 'Deerfield Beach, Florida',
        date: 'Feb 02, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158111/79813220_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joseph',
        location: 'Cincinnati, OH',
        date: 'Feb 01, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158111/79813291_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Love this piece! Vibrant color. Love the design of the cross and am looking forward to wearing.',
        short_msg: 'Beautiful piece',
        userName: 'Jeannine',
        location: 'Long Beach, California',
        date: 'Feb 01, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158111/79813239_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'christopher',
        location: 'Hampstead, NC',
        date: 'Feb 01, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158109/79812456_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Bob',
        location: 'Pleasant Hill, CA',
        date: 'Jan 31, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158108/79812312_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great doing business with you.',
        short_msg: 'great purchase',
        userName: 'Marqueta',
        location: 'Sanger, CA',
        date: 'Jan 31, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158109/79812523_1_x.jpg?height=94&quality=70&version=1',
        message: 'nice',
        short_msg: 'nice',
        userName: 'donna',
        location: 'Ocala, FL',
        date: 'Jan 31, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158109/79812443_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Stewart',
        location: 'Park Ridge, NJ',
        date: 'Jan 31, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157871/79678041_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Higinio',
        location: 'Homestead, Florida',
        date: 'Jan 31, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158108/79812379_1_x.jpg?height=94&quality=70&version=11',
        message:
            "I've bought things in auctions from them a couple of times. Their service is excellent, and if you write with a question, you'll get a quick response. The items arrive promptly, too.",
        short_msg: 'Great service.',
        userName: 'Joyce',
        location: 'Wayne, PA',
        date: 'Jan 31, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158108/79812383_1_x.jpg?height=94&quality=70&version=1',
        message: 'great experience good shipper.',
        short_msg: 'great experiecie, good value ver fast shipper.',
        userName: 'George',
        location: 'Cedar Grove, New Jersey',
        date: 'Jan 30, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/158108/79812328_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jon',
        location: 'Circleville, OH',
        date: 'Jan 30, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157871/79678076_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Dustin',
        location: 'Houston, Texas',
        date: 'Jan 29, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157883/79679394_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great styles at affordable prices-all new!',
        short_msg: 'Merchandise',
        userName: 'Anna',
        location: 'Merrylands West, AU',
        date: 'Jan 28, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157883/79679378_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Brad',
        location: 'Salt Lake City, Utah',
        date: 'Jan 28, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157715/79589931_1_x.jpg?height=94&quality=70&version=1',
        message:
            'not quite what I expected but adequate for the price. will work for a nice fishing watch',
        short_msg: 'received in great condition.',
        userName: 'Jim',
        location: 'Clinton, IA',
        date: 'Jan 28, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157871/79678066_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great company. Easy to communicate and work with. Genuine and HONEST.',
        short_msg: 'Personal service that made me smile. Fast and accurate!',
        userName: 'David',
        location: 'Mcminnville, TN',
        date: 'Jan 27, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157871/79678110_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Lori',
        location: 'Rome, NY',
        date: 'Jan 27, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157871/79678071_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tyrone',
        location: 'New Haven, CT',
        date: 'Jan 27, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157866/79678015_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Nice doing business with Dalshire. Honest, and punctual. All items were accurately described, and matched what I received.',
        short_msg: 'John delivers on time',
        userName: 'Babak',
        location: 'San Francisco, California',
        date: 'Jan 25, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157866/79677971_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Marjorie',
        location: 'Colts Neck, New Jersey',
        date: 'Jan 25, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157866/79677917_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joseph',
        location: 'Palmdale, California',
        date: 'Jan 25, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157792/79631257_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Gary',
        location: 'Janesville, Wisconsin',
        date: 'Jan 25, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157866/79677941_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joseph',
        location: 'Cincinnati, OH',
        date: 'Jan 25, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/157865/79677486_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Buckeye, AZ',
        date: 'Jan 24, 2020',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148375/75329017_1_x.jpg?height=94&quality=70&version=11',
        message: 'Bid on items. You won’t be sorry you did.',
        short_msg: 'Great Auction house',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Sep 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148375/75328991_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Bryan',
        location: 'Tracy, CA',
        date: 'Sep 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148835/75698201_1_x.jpg?height=94&quality=70&version=1',
        message: 'Thanks for making the auction experience awesome!',
        short_msg: 'Simplicity and Ease',
        userName: 'Timothy',
        location: 'Freeport, FL',
        date: 'Sep 25, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148374/75261182_1_x.jpg?height=94&quality=70&version=1',
        message: 'Always an interesting auction.',
        short_msg: 'A smooth transition between the purchase and shipping',
        userName: 'Francis',
        location: 'Glen Mills, Pennsylvania',
        date: 'Sep 25, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148374/75261244_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Mayank',
        location: 'Mumbai, India',
        date: 'Sep 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148374/75261232_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Great Auction House with great bidding for great Stuff. Fun Bidding with them. I Recommend this Auction House. Thanks I am very Satisfied.',
        short_msg: 'Quality Jewelry and Honest Dealers',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Sep 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148060/75056194_1_x.jpg?height=94&quality=70&version=1',
        message: 'The item was exactly as advertised and arrived within a couple days.',
        short_msg: 'Excellent experience with Dalshire Intl',
        userName: 'Peg',
        location: 'Deltona, Florida',
        date: 'Sep 23, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148835/75698190_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'donna',
        location: 'Ocala, FL',
        date: 'Sep 23, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148373/75222157_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Dalshire provides Quality products through a user friendly auction bidding platform.',
        short_msg: 'Mom loves her Beautiful bracelet gift',
        userName: 'Bryan',
        location: 'Tracy, CA',
        date: 'Sep 23, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148373/75222159_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Everything goes perfect!! From winning Bid to shipping and handling and accuracy of describing product!!! They are Honest, Have Integrity and appreciate the customer!!',
        short_msg: 'Excellent!! Bid with Confidence!!',
        userName: 'JOHN',
        location: 'Medford, Massachusetts',
        date: 'Sep 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148830/75497234_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Excellent every time I win a bid. Entire process from Bidding to Winning and everything else!! Dalshire International is one of the best auction houses I have done business with!!!!',
        short_msg: 'Excellent! Great to do business with!! Bid with Confidence!',
        userName: 'JOHN',
        location: 'Medford, Massachusetts',
        date: 'Sep 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148060/75056124_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Donald',
        location: 'Staten Island, New York',
        date: 'Sep 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148060/75056146_1_x.jpg?height=94&quality=70&version=1',
        message:
            'This company delivers-the items are always just as advertised, well packaged and delivered safely and intact.',
        short_msg: 'Reliable!',
        userName: 'Randall',
        location: 'Bluff City, Tennessee',
        date: 'Sep 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148833/75601814_1_x.jpg?height=94&quality=70&version=1',
        message: `Everything is excellent!! Dalshire International is "Good People" to do business with. Where I come from that means professional, trusted, dependable and they take care of business and their customers!!! Highly recommend!!!!`,
        short_msg: 'Excellent! Highly Recommend!!',
        userName: 'JOHN',
        location: 'Medford, Massachusetts',
        date: 'Sep 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148060/75056130_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sherri',
        location: 'Grand Rapids, Michigan',
        date: 'Sep 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148060/75056129_1_x.jpg?height=94&quality=70&version=1',
        message: 'Lovely selection of accurately described items.',
        short_msg: 'Great prices',
        userName: 'Joseph',
        location: 'New Bern, NC',
        date: 'Sep 21, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148059/75056003_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Gilbert',
        location: 'Santa Fe, New Mexico',
        date: 'Sep 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148059/75056017_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The autographed Griffey/Bonds piece was a great value for two iconic players. While they may have taken different paths to stardom, the picture nevertheless captures them at their athletic peak!',
        short_msg: 'Quality and value',
        userName: 'Randall',
        location: 'Bluff City, Tennessee',
        date: 'Sep 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148059/75056043_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Daniel',
        location: 'Omaha, NE',
        date: 'Sep 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148059/75056083_1_x.jpg?height=94&quality=70&version=1',
        message: 'Never disappointed with this company.',
        short_msg: 'High quality items!',
        userName: 'Galileo',
        location: 'Albuquerque, NM',
        date: 'Sep 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148059/75056045_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'West Columbia, SC',
        date: 'Sep 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148059/75055985_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great Auction House. Fair prices for great Jewels and other items.',
        short_msg: 'Great Earrings',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Sep 19, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148058/74986314_1_x.jpg?height=94&quality=70&version=1',
        message:
            'You can find such interesting item, they come carefully wrapped, and are delivered quickly.',
        short_msg: 'Love this auction house!!',
        userName: 'Pamela',
        location: 'San Francisco, CA',
        date: 'Sep 17, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148057/74981804_1_x.jpg?height=94&quality=70&version=1',
        message: 'Stands behind their products and sales. Quick to correct any issues...',
        short_msg: 'Great Auctioneer',
        userName: 'Tom',
        location: 'Bordentown, New Jersey',
        date: 'Sep 16, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/147533/74672310_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Charlotte',
        location: 'Saint Helena, CA',
        date: 'Sep 16, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148057/74981763_1_x.jpg?height=94&quality=70&version=1',
        message: 'N/A',
        short_msg: 'Quality',
        userName: 'Mark',
        location: 'Grand Blanc, MI',
        date: 'Sep 16, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148057/74981723_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ellis',
        location: 'Broken Arrow, OK',
        date: 'Sep 16, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148057/74981778_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Will use them over and over again. Great Auction House with great prices and Gems and jewels.',
        short_msg: 'Great Quality and Prices',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Sep 16, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148376/75330702_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Sep 16, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148375/75329006_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Sep 16, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148056/74813758_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The merchandise is very well made and as described. The packaging is excellent and shipping is quick. Nothing to complain about.',
        short_msg: 'Brand new, great quality',
        userName: 'ELLEN',
        location: 'New York, NY',
        date: 'Sep 15, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148056/74813726_1_x.jpg?height=94&quality=70&version=1',
        message: 'Beautiful jewelery.',
        short_msg: 'Great Rings',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Sep 14, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148056/74813718_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Wayde',
        location: 'Many, Louisiana',
        date: 'Sep 14, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148056/74813704_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Patrick',
        location: 'Hillsboro, Oregon',
        date: 'Sep 14, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148056/74813713_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Richard',
        location: 'Puyallup, Washington',
        date: 'Sep 14, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148056/74813723_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Rosemary',
        location: 'Long Beach, California',
        date: 'Sep 14, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/147535/74812945_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The experience overall was excellent. As it turns out the shipping charges were worth it. The items came in undamaged an in excellent condition. Thank you.',
        short_msg: 'The experience was great',
        userName: 'Mack',
        location: 'Dewey, Arizona',
        date: 'Sep 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/147535/74812923_1_x.jpg?height=94&quality=70&version=1',
        message: 'Ring was beautiful',
        short_msg: 'Geat business people',
        userName: 'Marqueta',
        location: 'Sanger, CA',
        date: 'Sep 12, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/147534/74672515_1_x.jpg?height=94&quality=70&version=1',
        message: 'Items as described. Smooth transaction and have purchased from again.',
        short_msg: 'Excellent seller',
        userName: 'Melissa',
        location: 'Elkridge, Maryland',
        date: 'Sep 11, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/147534/74672520_1_x.jpg?height=94&quality=70&version=1',
        message:
            "don't be afraid-these guys are the real thing-have to wait a short while for them toasemble frames-but they are so professional compare to many other auction houses",
        short_msg: 'honest andprofessional',
        userName: 'Daniel',
        location: 'Omaha, NE',
        date: 'Sep 11, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146753/74465013_1_x.jpg?height=94&quality=70&version=1',
        message: 'Lovely bracelet. Quick delivery.',
        short_msg: 'Item Described Accurately',
        userName: 'Cathleen',
        location: 'Upper Marlboro, Maryland',
        date: 'Sep 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/147534/74672454_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very easy to work with, quick response on questions.',
        short_msg: 'great products and service',
        userName: 'James',
        location: 'Winnsboro, Texas',
        date: 'Sep 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/147534/74672511_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Bryan',
        location: 'Tracy, CA',
        date: 'Sep 10, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/147533/74672381_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sue',
        location: 'Newmarket, NH',
        date: 'Sep 09, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/147533/74672410_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Janet',
        location: 'Abita Springs, LA',
        date: 'Sep 09, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/147533/74672405_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Jim',
        location: 'Clinton, IA',
        date: 'Sep 09, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/147533/74672400_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great Auction Hose. Great Jewels.',
        short_msg: 'Quality',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Sep 09, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146754/74577314_1_x.jpg?height=94&quality=70&version=1',
        message: 'Fast shipping and all items exactly as described. Very Happy. Thanks.',
        short_msg: 'Great Auction House',
        userName: 'Marie',
        location: 'Celina, TX',
        date: 'Sep 09, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148373/75222115_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Sep 08, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148059/75056019_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Sep 08, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146756/74588820_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Carlo',
        location: 'Capraia E Limite, IT',
        date: 'Sep 07, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146756/74588773_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Claude',
        location: 'Burien, WA',
        date: 'Sep 07, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146756/74588779_1_x.jpg?height=94&quality=70&version=1',
        message: 'They have a lot of high end products up for auction',
        short_msg: 'Very reputable auction house.',
        userName: 'Raymond',
        location: 'Buffalo, New York',
        date: 'Sep 07, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146754/74577274_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Edward',
        location: 'Amsterdam, New York',
        date: 'Sep 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146753/74465005_1_x.jpg?height=94&quality=70&version=1',
        message: 'Love dealing with them.',
        short_msg: 'Great experience',
        userName: 'Marqueta',
        location: 'Sanger, CA',
        date: 'Sep 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146754/74577358_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Susan',
        location: 'New Port Richey,fl 34652, Florida',
        date: 'Sep 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146754/74577276_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very nice ring matches bracelet I purchased earlier. Well made.',
        short_msg: 'Very nice ring. Great price. Shipped quickly.',
        userName: 'Sharon',
        location: 'Olympia, WA',
        date: 'Sep 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146754/74577303_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Great Quality for a Great Customer! Thank you for coming back to us again and again.',
        short_msg: 'Quality',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Sep 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146751/74402094_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I had a couple of concerns because I am in Canada, between an email and a phone call all concerns dissipated. John is so personable. Great service. Thank you.',
        short_msg: 'Communicate',
        userName: 'Ada',
        location: 'Waterloo, Ontario Canada, CA',
        date: 'Sep 05, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/148058/74986257_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Sep 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146753/74465009_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Joseph K.',
        location: 'Sebastian, FL',
        date: 'Sep 03, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146752/74464269_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Well worth what I bid on this watch!! Super good deal. Unique looking watch. Very happy with it. Shipped quickly.',
        short_msg: 'Love this watch!',
        userName: 'Sharon',
        location: 'Olympia, WA',
        date: 'Sep 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146752/74464332_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Awesome to do business with! Bidding process, payment, Item pictures and description are all perfect!!',
        short_msg: 'Excellent as is usual!!! Bid with confidence!!',
        userName: 'JOHN',
        location: 'Medford, Massachusetts',
        date: 'Sep 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146752/74464366_1_x.jpg?height=94&quality=70&version=1',
        message: 'Will use them again and again. Am very Satisfied with the Auction house.',
        short_msg: 'Quality of gems and other items',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Sep 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146751/74402021_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Bryan',
        location: 'Tracy, CA',
        date: 'Sep 02, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146751/74402103_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I love the item I won, but I recommend that you read the details about auction house fees and shipping costs, as they will add more than you expect to the final cost of your item if you don't. Overall I AM satisfied.",
        short_msg: 'Auction fees & shipping costs',
        userName: 'Kristy',
        location: 'Ft. Myers, Florida',
        date: 'Sep 01, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146116/74097100_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Carlo',
        location: 'Capraia E Limite, IT',
        date: 'Aug 31, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146751/74402027_1_x.jpg?height=94&quality=70&version=1',
        message: 'Excellent experience! Fully compliant in a very short time from payment day.',
        short_msg: 'Very good experience',
        userName: 'Shlomo',
        location: 'San Jose, CR',
        date: 'Aug 31, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146751/74402069_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Claude',
        location: 'Burien, WA',
        date: 'Aug 31, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146751/74402071_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Deborah',
        location: 'Berwyn, Illinois',
        date: 'Aug 31, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146751/74402104_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I love this ring and it fits!! A keeper! I didn't know my ring size when I started bidding. I learned by buying rings too small and too big. Be sure you know your ring size (or the size of the recipient) before bidding. It is fun, but addicting!",
        short_msg: 'Beautiful ring for a great price!!',
        userName: 'Sharon',
        location: 'Olympia, WA',
        date: 'Aug 31, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146483/74338615_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I am pleased to tell everyone that Dalshire International has proven itself to me as a source of excellent auction items. Their service is timely and all business. The one time I needed a question answered, they responded briskly, succinctly, and to my complete satisfaction. I would definitely bid on their items again.',
        short_msg: 'Accurate, Timely, and All Business',
        userName: 'Ronald',
        location: 'Chesterfield, Missouri',
        date: 'Aug 30, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/147535/74812961_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Belinda',
        location: 'Stockton, California',
        date: 'Aug 30, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146483/74338605_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Rick',
        location: 'Kettering, Ohio',
        date: 'Aug 29, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146483/74338635_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great Auction House. Bought many items and am Happy with the Auction House.',
        short_msg: 'Quality of jewelery',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Aug 29, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146483/74338568_1_x.jpg?height=94&quality=70&version=1',
        message: 'Nice Online Auction house. Fast shipping..',
        short_msg: 'Nice online Auction house.',
        userName: 'Keith',
        location: 'Live Oak, FL',
        date: 'Aug 29, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146483/74338604_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very nice bangle type bracelet. Jade. Received quickly.',
        short_msg: 'Beautiful bracelet! Love it!',
        userName: 'Sharon',
        location: 'Olympia, WA',
        date: 'Aug 29, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146482/74136851_1_x.jpg?height=94&quality=70&version=1',
        message: 'A keeper! I love it. Great price. Received quickly.',
        short_msg: 'Love the bracelet!',
        userName: 'Sharon',
        location: 'Olympia, WA',
        date: 'Aug 29, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146482/74136857_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Lake Havasu City, Arizona',
        date: 'Aug 28, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146482/74136895_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Irene',
        location: 'Sugar Hill, NH',
        date: 'Aug 28, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146482/74136844_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Allen',
        location: 'San Carlos, CA',
        date: 'Aug 27, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146482/74136897_1_x.jpg?height=94&quality=70&version=1',
        message: 'Love dealing with them, very organized.',
        short_msg: 'Still buying from them.',
        userName: 'Marqueta',
        location: 'Sanger, CA',
        date: 'Aug 27, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146482/74136842_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The items I purchased are more than just watches, They are Gentlemen Timepieces. Dalshire representatives are friendly and responsive.',
        short_msg: 'Excellent Quality',
        userName: 'Bryan',
        location: 'Tracy, CA',
        date: 'Aug 27, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146751/74402072_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Item as described, Accurate, Payment, Shipping and Everything Else is just Perfect. Confident when I purchase from Dalshire International That all will get done with no issues or concerns. Bid and buy with confidence!!!!!',
        short_msg: 'Highly Recommend!! A +',
        userName: 'JOHN',
        location: 'Medford, Massachusetts',
        date: 'Aug 27, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146482/74136838_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ralph',
        location: 'Mashpee, MA',
        date: 'Aug 27, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146483/74338627_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Mark',
        location: 'Grand Blanc, MI',
        date: 'Aug 27, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146481/74136803_1_x.jpg?height=94&quality=70&version=1',
        message: 'Great Auction House. Have bought items from them and Love the stuff',
        short_msg: 'Quality of items bid for and Honesty',
        userName: 'Ronald',
        location: 'New York, NY',
        date: 'Aug 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146481/74136821_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tom',
        location: 'Bordentown, New Jersey',
        date: 'Aug 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146481/74136812_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Barbara',
        location: 'Saint Charles, Missouri',
        date: 'Aug 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146481/74136825_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The items were very nice and all were exactly as described and pictured. The quality is very good and it is easy to do business with this company.',
        short_msg: 'Very good quality',
        userName: 'ELLEN',
        location: 'New York, NY',
        date: 'Aug 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146481/74136815_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I am most pleased with the quality of merchandise I received as well as the excellent customer service and timely responses.',
        short_msg: 'Quality merchandise',
        userName: 'Bryan',
        location: 'Tracy, CA',
        date: 'Aug 26, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146482/74136891_1_x.jpg?height=94&quality=70&version=1',
        message:
            'Item exactly as described. Invoice and payment went smoothly, shipping was excellent with receipt of item as expected and easy to follow with tracking number. Good to do business with. They have my trust and confidence!',
        short_msg: 'Excellent to do business with',
        userName: 'JOHN',
        location: 'Medford, Massachusetts',
        date: 'Aug 25, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146480/74099606_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Christopher',
        location: 'Fairlawn, Ohio',
        date: 'Aug 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146480/74099621_1_x.jpg?height=94&quality=70&version=1',
        message: 'Except the mail fees (too expensive) all is perfect',
        short_msg: 'The transaction was absolutely smooth',
        userName: 'Mario',
        location: 'Quebec, Canada',
        date: 'Aug 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146480/74099626_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'Seatac, WA',
        date: 'Aug 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146753/74464939_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Norbert',
        location: 'Clearwater, FL',
        date: 'Aug 23, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/145620/73622114_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Sara',
        location: 'Beirut, Lebanon',
        date: 'Aug 23, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146116/74097043_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Daniel',
        location: 'Anderson, IN',
        date: 'Aug 23, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/146116/74097123_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Claude',
        location: 'Burien, WA',
        date: 'Aug 22, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/139727/71103412_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Irene',
        location: 'Bayside, NY',
        date: 'May 16, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/139727/71103400_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Irene',
        location: 'Bayside, NY',
        date: 'May 16, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/139727/71103412_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'John',
        location: 'NY, New York',
        date: 'May 16, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/141166/71599340_1_x.jpg?height=94&quality=70&version=1',
        message: 'Got a great deal on a autograph picture of NOLAN Ryan. Crazy price',
        short_msg: 'Autograph picture',
        userName: 'James',
        location: 'Rockwall, TX',
        date: 'May 30, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/141166/71599479_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Miami, FL',
        date: 'May 31, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/141815/71814983_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Michael',
        location: 'Miami, FL',
        date: 'Jun 06, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/141815/71815099_1_x.jpg?height=94&quality=70&version=1',
        message:
            'This is my third auction with this company they are very quick and very thorough I will do business with them again in a heartbeat.',
        short_msg: 'Turquoise necklace',
        userName: 'James',
        location: 'Rockwall, TX',
        date: 'Jun 06, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/142369/72050491_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Wayde',
        location: 'Many, Louisiana',
        date: 'Jun 13, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/142369/72050320_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Irene',
        location: 'Bayside, NY',
        date: 'Jun 13, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/141166/71599450_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I bought three watches. They were more stunning in person than on the website. I will definitely be buying more from this auction house. I really enjoy their inventory. The one thing I didn't really like is paying the shipping amount on each individual item and then they all came in one box. I felt the price of shipping was on the higher side considering it came in a small box. It did ship quickly and they were wrapped nicely.",
        short_msg: 'Dalshire International Auction House',
        userName: 'Linette',
        location: 'Billings, Montana',
        date: 'Jun 17, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/142774/72245192_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Karen',
        location: 'Sherman Oaks, CA',
        date: 'Jun 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/142774/72245087_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Tabby',
        location: 'Brookhaven, MS',
        date: 'Jun 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/142774/72245096_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Robert',
        location: 'Brigham City, Utah',
        date: 'Jun 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/142774/72245114_1_x.jpg?height=94&quality=70&version=1',
        message: '',
        short_msg: '',
        userName: 'Ronald',
        location: 'Murray, KY',
        date: 'Jun 20, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/142774/72245207_1_x.jpg?height=94&quality=70&version=1',
        message:
            'The product arrived promptly, as described. The packaging was very nice, better than I expected. Would definitely bid again.',
        short_msg: 'Great transaction',
        userName: 'Michael',
        location: 'Perrysburg, Ohio',
        date: 'Jun 23, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/143075/72389798_1_x.jpg?height=94&quality=70&version=1',
        message: 'Very nice watch. Great price. Seamless interaction. Very satisfied.',
        short_msg: 'A seamless transaction',
        userName: 'Rebecca',
        location: 'Charleston, West Virginia',
        date: 'Jun 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/143075/72389776_1_x.jpg?height=94&quality=70&version=1',
        message:
            "I did love the item I bid on. I just can't find any markings on the silver and hope it isn't a mystery metal. I dont wear ot all the time so that won't matter much I hope...",
        short_msg: 'Check the weight of the silver',
        userName: 'Anne',
        location: 'Camp Hill, Australia',
        date: 'Jun 24, 2019',
        stars: '5',
    },

    {
        img_url:
            'https://p1.liveauctioneers.com/6667/143075/72389858_1_x.jpg?height=94&quality=70&version=1',
        message:
            'I really liked working with Dalshire a International. They answered my questions quickly and their payment and shipping was so easy. The jewelry I purchased is beautiful and as described',
        short_msg: 'Very Pleased with Purchases',
        userName: 'Rosemary',
        location: 'Long Beach, California',
        date: 'Jun 28, 2019',
        stars: '5',
    },
]
