import React, { useState, useEffect } from 'react'
import CheckoutCard from '../../components/molecules/CheckoutCard'
import CheckoutPayment from '../../components/molecules/CheckoutPayment'
import { connect } from 'react-redux'
import { Userdetails } from '../../actions/useraction'
import './Checkout.css'
import _, { set } from 'lodash'
import axios from 'axios'
import Loader from '../../components/molecules/Loader'
function Checkout(props) {
    // console.log('state data ===========', props.location.state)
    const [viewCartItems, setViewCartItems] = useState([])
    const [ship_local, setShipLocal] = useState([])
    const [loading, setLoading] = useState(false)
    const id = new URLSearchParams(window.location.search.substring(1)).getAll('id')
    const getCheckoutAuction = async (body) => {
        const token = localStorage.getItem('userToken')
        const res = await axios.post(
            `${process.env.REACT_APP_DOMAIN}mobileapi/getCheckoutAuction`,
            body,
            {
                headers: {
                    Authorization: 'Bearer ' + token,
                    domain: process.env.REACT_CONTROL_HUB_DOMAIN,
                },
            },
        )
        if (res.data.success) {
            setLoading(false)
            res.data.details.map((item) => {
                if (parseInt(item.hasshipping) == 0) {
                    item.total_payable_amount =
                        parseFloat(item.total_payable_amount) - parseFloat(item.shipping_fee)
                    item.shipping_fee = 0
                }
                ship_local.push({ id: item.buynow_id, hasshipping: item.hasshipping })
            })
            setViewCartItems(res.data.details)
            setShipLocal(ship_local)
        }
    }

    useEffect(() => {
        console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn')
        getCheckoutAuction({ invoice_id: id })
        setLoading(true)
    }, [])
    return (
        <div className="checkoutContainer d-flex justify-content-between customContainer">
            {loading && <Loader />}
            {viewCartItems.length !== 0 ? (
                <>
                    <div className="checkoutPdt">
                        <p className="checkoutTitle">Checkout</p>
                        {viewCartItems.map((data, index) => (
                            <CheckoutCard data={data} />
                        ))}
                    </div>
                    <div className="paymentContainer ml-3">
                        <p className="paymentTitle">Payment Details</p>

                        <CheckoutPayment
                            // totalPrice={data.pdtPrice}
                            // name={data.pdtName}
                            // inv={data.inv}
                            // additional_amount_1={data.additional_amount_1}
                            // additional_amount_2={data.additional_amount_2}
                            // additional_amount_3={data.additional_amount_3}
                            // profile={props.userDetails.userdetails}
                            // conFee={data.Fee}
                            // taxPrice={data.Tax}
                            // grandTotal={data.Total}
                            amount={_.sumBy(viewCartItems, (x) => parseFloat(x.amount))}
                            invoice_id={id}
                            buyers_premium={_.sumBy(viewCartItems, (x) =>
                                parseFloat(x.buyers_premium + x.charge_fee),
                            )}
                            transaction_fee={_.sumBy(viewCartItems, (x) =>
                                parseFloat(x.transaction_fee),
                            )}
                            ship_local={ship_local}
                            shipping_fee={_.sumBy(viewCartItems, (x) => parseFloat(x.shipping_fee))}
                            sales_tax={_.sumBy(viewCartItems, (x) => parseFloat(x.sales_tax))}
                            total_payable_amount={_.sumBy(viewCartItems, (x) =>
                                parseFloat(x.total_payable_amount),
                            )}
                        />
                    </div>
                </>
            ) : (
                <p className="text-center w-100">No Data Found</p>
            )}
        </div>
    )
}
const mapDispatchToProps = (dispatch) => ({
    User: () => dispatch(Userdetails()),
})

const mapStateToProps = (state) => {
    return {
        userDetails: state.User,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
