// prettier-ignore
import React, { useContext, useEffect } from 'react'
import CustomInput from '../../atoms/Inputs/CustomInput'
import PrimaryButton from '../../atoms/PrimaryButton'
import SecondaryButton from '../../atoms/SecondaryButton'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import { Button } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { handleRedirectInternal, useCustomMediaQuery } from '../../../common/components'
import isAuthenticate from '../../../Utils/Utility/auth'
import './Header.css'
import { Link, NavLink } from 'react-router-dom'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { makeStyles } from '@material-ui/core/styles'
import Swal from 'sweetalert2'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    list: {
        width: 300,
        // padding: '60px 70px 10px 60px',
    },
    fullList: {
        width: 'auto',
    },
}))

const Header = (props) => {
    const history = useHistory()
    const location = useLocation()
    const [text, setText] = React.useState('')
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)

    let deviceWidth = useCustomMediaQuery('(max-width:991px)')

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }
    const hamMenu = useStyles()
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })
    // top
    const toggleDrawers = (toppanel, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [toppanel]: open })
    }
    // top
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    var pathname = window.location.pathname

    const onChange = (e) => setText(e.target.value)

    const onSubmit = (e) => {
        e.preventDefault()
        let query = new URLSearchParams(location.search)
        query.set('search', text)
        history.push({ pathname: '/search', search: '?' + query.toString() })
    }
    // top
    const toplists = (toppanel) => (
        <div
            className={clsx(hamMenu.list, {
                [classes.fullList]: toppanel === 'top' || toppanel === 'bottom',
            })}
            role="presentation"
            // onClick={toggleDrawer(anchor, true)}
            // onKeyDown={toggleDrawer(anchor, false)}
        >
            <header className="beforHead searchTop">
                {/* <div className="navLogo">
                    <Link to="">
                        <img alt="LOGO" src="/images/logo.jpg" />
                    </Link>
                    <Button className="headDrawerClose" onClick={toggleDrawers('top', false)}>
                        <span className="material-icons">close</span>
                    </Button>
                </div> */}
                <form className="Search d-flex justify-content-between" onSubmit={onSubmit}>
                    <CustomInput placeholder="Search" onChange={onChange} />
                    <Button type="submit">
                        <span className="material-icons">search</span>
                    </Button>
                </form>
            </header>
        </div>
    )
    // top
    const list = (anchor) => (
        <div
            className={clsx(hamMenu.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            // onClick={toggleDrawer(anchor, true)}
            // onKeyDown={toggleDrawer(anchor, false)}
        >
            <header className="beforHead">
                <div className="navLogo">
                    <Link to="">
                        <img alt="LOGO" src="/images/logo.jpg" />
                    </Link>
                    <Button className="headDrawerClose" onClick={toggleDrawer('right', false)}>
                        <span className="material-icons">close</span>
                    </Button>
                </div>
                {/* <form className="Search d-flex justify-content-between" onSubmit={onSubmit}>
                    <CustomInput placeholder="Search" onChange={onChange} />
                    <Button type="submit">
                        <span className="material-icons">search</span>
                    </Button>
                </form> */}
                <>
                    {isAuthenticate() != null ? (
                        <>
                            <List onClick={toggleDrawer('right', false)}>
                                <ListItem
                                    className={pathname === '/search' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'search')}
                                >
                                    <i className="fas fa-gavel"></i>
                                    <ListItemIcon>Auctions</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    className={pathname === '/past_auctions' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'past_auctions')}
                                >
                                    <i className="fas fa-gavel"></i>
                                    <ListItemIcon>Past Auctions</ListItemIcon>
                                </ListItem>
                                {/* <ListItem
                                    className={pathname === '/buynow' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'buynow')}
                                >
                                    <span className="material-icons">local_mall</span>
                                    <ListItemIcon>Buynow</ListItemIcon>
                                </ListItem> */}
                                <ListItem
                                    className={pathname === '/dashboard' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'dashboard')}
                                >
                                    <span className="material-icons">dashboard</span>
                                    <ListItemIcon>Dashboard</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    className={pathname === '/contactus' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'contactus')}
                                >
                                    <span className="material-icons">perm_phone_msg</span>
                                    <ListItemIcon>Contact Us</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    className={pathname === '/privacypolicy' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'privacypolicy')}
                                >
                                    <span className="material-icons">privacy_tip</span>
                                    <ListItemIcon>Privacy policy</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    className={pathname === '/terms_of_service' ? 'active' : ''}
                                    button
                                    onClick={() =>
                                        handleRedirectInternal(history, 'terms_of_service')
                                    }
                                >
                                    <span className="material-icons">fact_check</span>
                                    <ListItemIcon>Terms and Conditions</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => {
                                        localStorage.removeItem('userToken')
                                        Swal.fire({
                                            icon: 'success',
                                            title: 'Logged out successfully',
                                            timer: 1500,
                                            showConfirmButton: false,
                                            position: 'center',
                                        })
                                        history.push('/login')
                                    }}
                                >
                                    <span className="material-icons">power</span>
                                    <ListItemIcon>Logout</ListItemIcon>
                                </ListItem>
                            </List>
                        </>
                    ) : (
                        <>
                            <List onClick={toggleDrawer('right', false)}>
                                <ListItem
                                    button
                                    onClick={() => handleRedirectInternal(history, 'search')}
                                >
                                    {/* <span className="material-icons">shopping_bag</span>
                                     */}
                                    <i className="fas fa-gavel"></i>
                                    <ListItemIcon>Auctions</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    className={pathname === '/past_auctions' ? 'active' : ''}
                                    button
                                    onClick={() => handleRedirectInternal(history, 'past_auctions')}
                                >
                                    <i className="fas fa-gavel"></i>
                                    <ListItemIcon>Past Auctions</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => handleRedirectInternal(history, 'contactus')}
                                >
                                    <span className="material-icons">perm_phone_msg</span>
                                    <ListItemIcon>Contact Us</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => handleRedirectInternal(history, 'privacypolicy')}
                                >
                                    <span className="material-icons">privacy_tip</span>
                                    <ListItemIcon>Privacy policy</ListItemIcon>
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() =>
                                        handleRedirectInternal(history, 'terms_of_service')
                                    }
                                >
                                    <span className="material-icons">fact_check</span>
                                    <ListItemIcon>Terms and Conditions</ListItemIcon>
                                </ListItem>
                                <ListItem>
                                    <SecondaryButton
                                        label="Login"
                                        onClick={() => handleRedirectInternal(history, 'login')}
                                    />
                                </ListItem>
                                <ListItem>
                                    <PrimaryButton
                                        className="dasda"
                                        label="Register for Auction"
                                        onClick={() => handleRedirectInternal(history, 'signup')}
                                    />
                                </ListItem>
                            </List>
                        </>
                    )}
                </>
                {/* {useCustomMediaQuery('(max-width: 991px)') ? (
                ) : null} */}
            </header>
        </div>
    )

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open)
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    // console.log('deviceWidth====>' asdasd, deviceWidth)

    return (
        <>
            <header>
                <div className="resHeader customContainer d-flex justify-content-between align-items-center">
                    <div className="quickNots">
                        <div className="headerNav">
                            {['top'].map((toppanel) => (
                                <React.Fragment key={toppanel}>
                                    <div className="imgBtn">
                                        <Button onClick={toggleDrawers(toppanel, true)}>
                                            <span className="material-icons">search</span>
                                        </Button>
                                    </div>
                                    <Drawer
                                        anchor={toppanel}
                                        open={state[toppanel]}
                                        onClose={toggleDrawers(toppanel, false)}
                                    >
                                        {toplists(toppanel)}
                                    </Drawer>
                                </React.Fragment>
                            ))}
                        </div>
                        <div>
                            <Link to="/">
                                <img className="logo siteLogo" src="/images/logo.jpg" alt="logo" />
                            </Link>
                        </div>
                        {isAuthenticate() != null ? (
                            <>
                                <div className="headerNav">
                                    {['right'].map((anchor) => (
                                        <React.Fragment key={anchor}>
                                            <div className="imgBtn">
                                                <Button onClick={toggleDrawer(anchor, true)}>
                                                    <span className="material-icons">segment</span>
                                                </Button>
                                            </div>
                                            <Drawer
                                                anchor={anchor}
                                                open={state[anchor]}
                                                onClose={toggleDrawer(anchor, false)}
                                            >
                                                {list(anchor)}
                                            </Drawer>
                                        </React.Fragment>
                                    ))}
                                </div>
                                {/* // ) */}
                            </>
                        ) : (
                            <div className="headerNav">
                                {['right'].map((anchor) => (
                                    <React.Fragment key={anchor}>
                                        <div className="imgBtn">
                                            <Button onClick={toggleDrawer(anchor, true)}>
                                                <span className="material-icons">segment</span>
                                            </Button>
                                        </div>
                                        <Drawer
                                            anchor={anchor}
                                            open={state[anchor]}
                                            onClose={toggleDrawer(anchor, false)}
                                        >
                                            {list(anchor)}
                                        </Drawer>
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </header>
        </>
    )
}

const mapStateToProps = (state) => ({
    Profile: state.User,
})

export default connect(mapStateToProps, null)(Header)
