import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import Dashboard from '../Dashboard/Dashboard'
import CustomSelect from '../../components/atoms/Inputs/CustomSelect'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import SecondaryButton from '../../components/atoms/SecondaryButton/index'
import Switch from '@material-ui/core/Switch'
import { Formik } from 'formik'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import axios from '../../Utils/Utility/axiosconfig'
import { connect } from 'react-redux'
import { Userdetails } from '../../actions/useraction'
import isAuthenticate from '../../Utils/Utility/auth'
import Axios from 'axios'
import './profile.css'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import SimpleSelect from '../../components/atoms/DropDown/index'
import SimpleSelect1 from '../../components/atoms/DropDown/Dropdown1'
import SimpleSelect2 from '../../components/atoms/DropDown/Dropdown2'
import Country from '../Signup/Country.json'
import State from '../Signup/State.json'
const Profile = (props) => {
    useEffect(() => {
        props.User()
        console.log(localStorage.getItem('userToken'))
    }, [])

    const [state, setState] = React.useState({
        checkedA: true,
    })

    const handleChanges = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked })
    }

    const [telephoneValid, setTelephoneValid] = useState(true)
    const setValidity = (valid) => {
        console.log('validity changessssssssssss', valid)
        setTelephoneValid(valid)
    }

    const [avatar, setavatar] = useState('')
    const [File, setFile] = useState({ File: '' })
    const [Id, setID] = useState('')
    const [sId, setStateId] = useState('')
    const [Countrycode, setCountrycode] = useState('')
    const uploadedImage = useRef(null)
    const imageUploader = useRef(null)
    const [userdetails, setUserDetails] = useState({})

    const [userPhone, setUserphone] = useState('')

    const handleimageLoader = (e) => {
        const [file] = e.target.files
        setFile({ ...File, File: file })
        if (file) {
            const reader = new FileReader()
            const { current } = uploadedImage
            current.file = file
            reader.onload = (e) => {
                current.src = e.target.result
            }
            reader.readAsDataURL(file)
        }
    }

    const profileSchema = yup.object({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string(),
        phoneno: yup.string().required(),
        city: yup.string(),
        state: yup.string().required(),
        country: yup.string().required(),
        zipcode: yup.string().required(),
        addressline1: yup.string().required(),
        addressline2: yup.string(),
        currentpassword: yup.string(),
        newpassword: yup.string().min(8, 'Minimum 8 characters'),
        confirmpassword: yup
            .string()
            .min(8, 'Minimum 8 characters')
            .oneOf([yup.ref('newpassword'), null], 'Passwords must match'),
    })

    const formRef = useRef()

    const updatePassword = async () => {
        try {
            const cred = {
                confirm_new_password: formRef.current.values.confirmpassword,
                current_password: formRef.current.values.currentpassword,
                new_password: formRef.current.values.newpassword,
            }
            if (
                cred.current_password === '' ||
                cred.new_password === '' ||
                cred.confirm_new_password === ''
            ) {
                Swal.fire({
                    title: 'Please Enter Password',
                    icon: 'warning',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            } else {
                const res = await axios.post('mobileapi/updatepassword_in_profile', cred)
                if (res.data.status === 'no') {
                    Swal.fire({
                        title: res.data.message,
                        icon: 'error',
                        timer: 3000,
                        showConfirmButton: false,
                        position: 'center',
                    })
                } else {
                    Swal.fire({
                        title: 'Password Updated Successfully',
                        icon: 'success',
                        timer: 3000,
                        showConfirmButton: false,
                        position: 'center',
                    })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const formdata = new FormData()

    const updateProfileAvatar = async () => {
        try {
            if (
                formRef.current.values.firstname !== '' &&
                formRef.current.values.lastname !== '' &&
                formRef.current.values.country !== '' &&
                formRef.current.values.state !== '' &&
                formRef.current.values.phoneno !== '' &&
                formRef.current.values.zipcode !== '' &&
                formRef.current.values.address1 !== '' &&
                formRef.current.values.firstname !== null &&
                formRef.current.values.lastname !== null &&
                formRef.current.values.country !== null &&
                formRef.current.values.state !== null &&
                formRef.current.values.phoneno !== null &&
                formRef.current.values.zipcode !== null &&
                formRef.current.values.address1 !== null
            ) {
                if (File) {
                    let userData = JSON.parse(localStorage.getItem('userData'))
                    const cust_id = userData.card_paymentid
                    formdata.append('first_name', formRef.current.values.firstname)
                    formdata.append('last_name', formRef.current.values.lastname)
                    formdata.append('email', formRef.current.values.email)
                    formdata.append('country', formRef.current.values.country)
                    formdata.append('state', formRef.current.values.state)
                    formdata.append('city', formRef.current.values.city)
                    formdata.append('phone', formRef.current.values.phoneno)
                    formdata.append('card_paymentid', cust_id)
                    formdata.append(
                        'user_profile_image',
                        File.File
                            ? File.File
                            : props.Profile.userdetails.avatar
                            ? props.Profile.userdetails.avatar
                            : '',
                    )
                    formdata.append('zip', formRef.current.values.zipcode)
                    formdata.append('address1', formRef.current.values.addressline1)
                    formdata.append('address2', formRef.current.values.addressline2)
                    const res = await axios.post(`mobileapi/updateUserProfile`, formdata)
                    if (res.data.status === 'yes') {
                        props.User()
                        Swal.fire({
                            title: 'Profile Updated Successfully',
                            icon: 'success',
                            timer: 1500,
                            showConfirmButton: false,
                            position: 'center',
                        })
                    }
                }
            } else {
                Swal.fire({
                    title: 'Please Enter Profile Details',
                    icon: 'warning',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const processNumber = (isValid, phone, country) => {
        console.log('isvalidd state', isValid)
        return `+${country.dialCode} ${phone}`
    }

    useEffect(() => {
        setUserphone(props.Profile.userdetails.phone?.split(' ')[1])
    }, [props.Profile.userdetails.phone])

    useEffect(() => {
        if (props.Profile.userdetails.country) {
            var user_coutry_code = Country.filter(
                (val) => val.name === props.Profile.userdetails.country,
            )
            if (user_coutry_code.length > 0) {
                setID(user_coutry_code[0].id)
            }
        }
    }, [props.Profile.userdetails])

    useEffect(() => {
        if (props.Profile.userdetails.email) {
            setTimeout(() => {
                setUserDetails(props.Profile.userdetails)
            }, 1000)
        }
    }, [props.Profile.userdetails.email])

    return (
        <div>
            <Dashboard>
                <div>
                    <Formik
                        initialValues={{
                            firstname: userdetails.first_name ? userdetails.first_name : '',
                            lastname: userdetails.last_name ? userdetails.last_name : '',
                            email: userdetails.email ? userdetails.email : '',
                            phoneno: userdetails.phone ? userdetails.phone : '',
                            city: userdetails.city ? userdetails.city : '',
                            state: userdetails.state ? userdetails.state : '',
                            country: userdetails.country ? userdetails.country : '',
                            zipcode: userdetails.zip ? userdetails.zip : '',
                            addressline1: userdetails.address1 ? userdetails.address1 : '',
                            addressline2: userdetails.address2 ? userdetails.address2 : '',
                            currentpassword: '',
                            newpassword: '',
                            confirmpassword: '',
                        }}
                        innerRef={formRef}
                        enableReinitialize={true}
                        validationSchema={profileSchema}
                        onSubmit={(values) => {}}
                    >
                        {({
                            errors,
                            touched,
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            setFieldTouched,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="Full-Container">
                                    <div className="Profilepic-Profile">
                                        <img
                                            ref={uploadedImage}
                                            src={
                                                props.Profile.userdetails.avatar
                                                    ? `${process.env.REACT_APP_DOMAIN}uploads/${props.Profile.userdetails.avatar}`
                                                    : '/images/nouserimg.png'
                                            }
                                        />
                                        <label
                                            for="profileImage"
                                            className="pp-edit material-icons"
                                        >
                                            edit
                                        </label>
                                        <input
                                            id="profileImage"
                                            type="file"
                                            name="profile_image"
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            multiple="true"
                                            ref={imageUploader}
                                            onChange={handleimageLoader}
                                        />
                                    </div>
                                    <h5>Personal Details</h5>
                                    <div className="Profile-Container-personalDetails">
                                        <CustomInput
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="First Name"
                                            variant="standard"
                                            name="firstname"
                                            value={values.firstname}
                                            helperText={
                                                errors.firstname && touched.firstname
                                                    ? 'Please Enter First Name'
                                                    : ''
                                            }
                                        />
                                        <CustomInput
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Last Name"
                                            variant="standard"
                                            name="lastname"
                                            value={values.lastname}
                                            helperText={
                                                errors.lastname && touched.lastname
                                                    ? 'Please Enter Last Name'
                                                    : ''
                                            }
                                        />
                                        <CustomInput
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="Email"
                                            variant="standard"
                                            name="email"
                                            value={values.email}
                                            disabled
                                        />
                                        {console.log('userPhone------>', userPhone)}
                                        {userPhone != undefined ? (
                                            <div className="intlIptWrpr ">
                                                <label>Phone No.</label>
                                                <IntlTelInput
                                                    {...props}
                                                    disabled
                                                    containerClassName="intl-tel-input"
                                                    label="Phone No."
                                                    value={userPhone}
                                                    fieldName={'phoneno'}
                                                    onPhoneNumberBlur={(isValid) => {
                                                        setFieldTouched('phoneno', true)
                                                        setValidity(isValid)
                                                    }}
                                                    onPhoneNumberChange={(
                                                        isValid,
                                                        phone,
                                                        country,
                                                    ) => {
                                                        setFieldValue(
                                                            'phoneno',
                                                            processNumber(isValid, phone, country),
                                                        )
                                                    }}
                                                    separateDialCode={true}
                                                />
                                                {!telephoneValid && (
                                                    <p className="text-danger mt-2 mb-0">
                                                        Phonenumber invalid
                                                    </p>
                                                )}
                                                {telephoneValid && errors.phone && touched.phone ? (
                                                    <div className="errorMessage-2 errorTxt">
                                                        {errors.phone}
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : (
                                            <CustomInput
                                                onChange={handleChange}
                                                disabled={true}
                                                onBlur={handleBlur}
                                                label="Phone No."
                                                variant="standard"
                                                name="phoneno"
                                                value={values.phoneno}
                                                helperText={
                                                    errors.phoneno && touched.phoneno
                                                        ? 'Please Enter Phone Number'
                                                        : ''
                                                }
                                            />
                                        )}
                                    </div>
                                    <h5>Location Details</h5>
                                    <div className="Profile-Container-LocationDetails">
                                        <div className="customInput">
                                            <div className="MuiFormControl-root MuiTextField-root">
                                                <SimpleSelect
                                                    value={values.country ? values.country : ''}
                                                    dropCountr={Country}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    countryID={(data) => setID(data)}
                                                    ctrcode={(data) => setCountrycode(data)}
                                                    name="country"
                                                    label="Country"
                                                    helperText={
                                                        errors.cardcountry && touched.cardcountry
                                                            ? 'Please Enter Country'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="customInput">
                                            <div className="MuiFormControl-root MuiTextField-root">
                                                <SimpleSelect1
                                                    selected={values.state}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    countryID={(data) => setID(data)}
                                                    ctrcode={(data) => setCountrycode(data)}
                                                    name="state"
                                                    label="State"
                                                    dropState={State.filter(
                                                        (data) => data.country_id === Id.toString(),
                                                    )}
                                                    stateID={(data) => setStateId(data)}
                                                    helperText={
                                                        errors.cardstate && touched.cardstate
                                                            ? 'Please Enter State'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <CustomInput
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            label="City"
                                            variant="standard"
                                            name="city"
                                            value={values.city}
                                        />
                                        <CustomInput
                                            label="Zip Code"
                                            variant="standard"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="zipcode"
                                            value={values.zipcode}
                                            helperText={
                                                errors.zipcode && touched.zipcode
                                                    ? 'Please Enter Zip Code'
                                                    : ''
                                            }
                                        />
                                        <CustomInput
                                            label="Address Line 1"
                                            variant="standard"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="addressline1"
                                            value={values.addressline1}
                                            helperText={
                                                errors.addressline1 && touched.addressline1
                                                    ? 'Please Enter Address'
                                                    : ''
                                            }
                                        />
                                        <CustomInput
                                            label="Address Line 2"
                                            variant="standard"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="addressline2"
                                            value={values.addressline2}
                                        />
                                    </div>
                                    <div className="profileButtons">
                                        <PrimaryButton
                                            onClick={updateProfileAvatar}
                                            type="button"
                                            label="Save Changes"
                                        />
                                        <SecondaryButton
                                            label="Reset"
                                            type="reset"
                                            onClick={() => {
                                                setFieldValue('firstname', '')
                                                setFieldValue('lastname', '')
                                                setFieldValue('phoneno', '')
                                                setFieldValue('city', '')
                                                setFieldValue('state', '')
                                                setFieldValue('country', '')
                                                setFieldValue('zipcode', '')
                                                setFieldValue('addressline1', '')
                                                setFieldValue('addressline2', '')
                                            }}
                                        />
                                    </div>
                                    <div className="changePassword-Header">
                                        <h5>Change Password</h5>
                                        <Switch
                                            checked={state.checkedA}
                                            onChange={handleChanges}
                                            name="checkedA"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    </div>
                                    {state.checkedA ? (
                                        <div>
                                            <div className="ProfilePasswordInput">
                                                <CustomInput
                                                    label="Current Password"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="currentpassword"
                                                    value={values.currentpassword}
                                                    type="password"
                                                />
                                                <CustomInput
                                                    label="New Password"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="newpassword"
                                                    value={values.newpassword}
                                                    type="password"
                                                />
                                                <CustomInput
                                                    label="Confirm Password"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="confirmpassword"
                                                    value={values.confirmpassword}
                                                    type="password"
                                                />
                                            </div>
                                            <div className="profileButtons">
                                                <PrimaryButton
                                                    label="Save Changes"
                                                    type="button"
                                                    onClick={updatePassword}
                                                />
                                                <SecondaryButton
                                                    label="Reset"
                                                    type="reset"
                                                    onClick={() => {
                                                        setFieldValue('currentpassword', '')
                                                        setFieldValue('newpassword', '')
                                                        setFieldValue('confirmpassword', '')
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </Dashboard>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    User: () => dispatch(Userdetails()),
})

const mapStateToProps = (state) => {
    return {
        Profile: state.User,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
