import axios from 'axios'
import isAuthenticate from './auth'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_PAY,
    responseType: 'json',
    headers: {
        domain: process.env.REACT_CONTROL_HUB_DOMAIN,
    },
})

export default axiosInstance
