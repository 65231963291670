import React from 'react'
import PrimaryButton from '../../atoms/PrimaryButton'
import Timer from '../Timer'
import './ProductCard.css'
import { onImageError } from '../../../Utils/Utility'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { removeHTMLTags } from '../../../common/components'

function ListView(props) {
    const history = useHistory()
    return (
        <div className="listViewCnt" key={props.id}>
            <div className="listCard d-flex">
                <LazyLoadImage
                    className="cardImage"
                    src={props.imgSrc}
                    onError={onImageError}
                    effect="blur"
                    placeholderSrc="/images/imageLoading.svg"
                    height="100%"
                    width="100%"
                    alt="Next Lot"
                />
                <div className="listPdtCnt">
                    <p className="pdtCategory">{props.category}</p>
                    <p className="pdtListName">{props.Name}</p>
                    <p className="pdtStart">{moment(props.Start).format('MM/DD/YYYY hh:mm A')}</p>
                    {/* <p className="pdtEnd">{moment(props.End).format('MM/DD/YYYY HH:mm A')}</p> */}
                    <p className="listPdtDescrp">{removeHTMLTags(props.descrp)}</p>
                </div>
                <div className="timeBtn d-flex flex-column justify-content-end">
                    {props.startTime && <p className="timeTitle">Starts In</p>}
                    {props.endTime && <p className="timeTitle">Ends In</p>}
                    <Timer
                        className="Listtime"
                        productTime={{ start: props.Start, end: props.End }}
                        liveToast={true}
                    />
                    <PrimaryButton
                        className="viewAuctionBtn"
                        onClick={() => history.push(`/auction/${props.id}?page=1`)}
                    >
                        {props.label}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    )
}

export default ListView
