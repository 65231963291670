import React from 'react'
import { testimonial } from '../../Utils/UI/reviews'

const AllReviews = () => {
    return (
        <div className="allrwWrp customContainer">
            <h4 className="text-center pt-5 pb-5">
                <b>All Reviews</b>
            </h4>
            {testimonial.map((data, index) => (
                <>
                    {data.stars > 3 ? (
                        <div key={index}>
                            <div className="carCardWrpr d-flex align-items-start">
                                <div className="imageContainer">
                                    <img
                                        className={`${data.img_url === '' && 'plcHldImg'}`}
                                        src={data.img_url ? data.img_url : '/images/img.png'}
                                    />
                                </div>
                                <div className="rvwCnt w-100">
                                    <h5>{data.short_msg}</h5>
                                    <p>{data.message}</p>
                                    <div className="rvwrDtl mt-3 d-flex align-items-center justify-content-between w-100">
                                        <div>
                                            <h6 className="d-flex align-items-center">
                                                {data.userName}{' '}
                                                <span className="material-icons verifiedIcon ml-2">
                                                    verified
                                                </span>
                                            </h6>
                                            <small>{data.location}</small>
                                        </div>
                                        <div className="text-right">
                                            <div className="rtngCntnr d-flex align-items-center">
                                                {[1, 2, 3, 4, 5].map((values, index) => (
                                                    <span
                                                        className={`material-icons ${
                                                            index + 1 <= data.stars ? 'active' : ''
                                                        }`}
                                                    >
                                                        star
                                                    </span>
                                                ))}
                                            </div>
                                            <small>{data.date}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </>
            ))}
        </div>
    )
}

export default AllReviews
