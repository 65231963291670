import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import './index.css'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import axios from '../../../Utils/Utility/axiosconfig'
import { dateFormatFunction } from '../../../common/components'
import { setDecimalPoint } from '../../../Utils/Utility'
import moment from 'moment'

const useStyles = makeStyles((theme, maxWidth) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        maxWidth: '55rem',
    },
}))

const BidHistory = (props) => {
    const classes = useStyles()
    let [productHistory, setProductHistory] = useState([])

    useEffect(() => {
        props.productId !== undefined && getHistory()
    }, [props.productId])

    const getHistory = async () => {
        setProductHistory([])

        let { data } = await axios.post('mobileapi/getbiddetails', { product_id: props.productId })
        if (data.success === 'yes' && data.results.length !== 0) {
            setProductHistory(data.results)
        }
    }

    return (
        <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={props.open}
            className={`${classes.modal} customModal`}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.open}>
                <div className="bidHistoryModal">
                    <div className={classes.paper}>
                        <div className="modal fade show custom-modal">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Bid History</h4>
                                        <button
                                            type="button"
                                            className="close"
                                            onClick={(e) => props.onClose(undefined)}
                                        >
                                            <i className="fa fa-times"></i>
                                        </button>
                                    </div>
                                    {productHistory.length !== 0 ? (
                                        <div className="modal-body cm-body table-responsive">
                                            {console.log('productHistory', productHistory)}
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            {props.auctionType === 'liveAuction'
                                                                ? 'Paddle Id'
                                                                : 'Bidder Id'}
                                                        </th>
                                                        {/* <th scope="col">Name</th> */}
                                                        <th className="text-center" scope="col">
                                                            Date
                                                        </th>
                                                        <th scope="col">Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {productHistory.map((data, index) => (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    {data.bidder_paddle_no
                                                                        ? data.bidder_paddle_no
                                                                        : data.user_id}
                                                                </td>
                                                                {/* <td>{data.namesec}</td> */}
                                                                <td>
                                                                    {moment(data.created_at).format(
                                                                        'MMM Do YYYY,hh:mm:ss  A',
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    $
                                                                    {setDecimalPoint(
                                                                        data.proposed_amount
                                                                            .toString()
                                                                            .replace(/,/g, ''),
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div>No Bids Found</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default BidHistory
