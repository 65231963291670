import React, { useState, useEffect, useContext, useRef, useCallback } from 'react'
import './LiveLots.css'
import axios from 'axios'
import Timer from '../../components/molecules/Timer'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Chip, LinearProgress, Button } from '@material-ui/core'
import Swal from 'sweetalert2'
import { capitalize, currencyFormat, handleRedirectInternal } from '../../common/components'
// import BiddingItem from '../../components/molecules/Bidding/BiddingItem'
import { useMediaQuery } from 'react-responsive'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import 'react-photoswipe/lib/photoswipe.css'
import AuctionContext from '../../context/auction/auctionContext'
import ProductContext from '../../context/product/productContext'
import AuthContext from '../../context/auth/authContext'
import AlertContext from '../../context/alert/alertContext'
import UserContext from '../../context/user/userContext'
import Tooltip from '@material-ui/core/Tooltip'
// import AddCreditCard from '../AddCreditCard'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import Popup from '../../components/organisms/Popup'

import ReactPlayer from 'react-player/lazy'
import { socket, reInitializeSocket } from '../../common/socket'
import Slider from 'react-slick'
import Loaders from '../../components/molecules/Loader'
import { useDispatch } from 'react-redux'
import liveLotsReducer from '../../reducers/liveLotsReducer'
import auctionlotDetails from '../../actions/auctionLotsAction'
import auctionMsgDetails from '../../actions/auctionMessageAction'
import { connect } from 'react-redux'
import livelotDetails from '../../actions/liveLotsAction'
import BiddingLive from '../../components/molecules/BiddingLiveLot/BiddingLive'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import auctionPercentage from '../../actions/auctionPercentage'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useCookies } from 'react-cookie'
import PreBidComponent from '../../components/molecules/PreBidComponent'
function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div className="tabBody">{children}</div>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}))

function LiveLots(props) {
    const dispatch = useDispatch()
    const history = useHistory()
    // const search = useLocation().search
    const [auction, setAuction] = useState({})
    const [user, setUser] = useState()
    const [lotDetails, setLotDetails] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [messageBoxContent, setMessageBoxContent] = useState([])
    const [currentLotIndex, setCurrentLotIndex] = useState(0)
    const [currentLotImages, setCurrentLotImages] = useState([])
    const [completionPercentage, setCompletionPercentage] = useState(0)
    const [messageBoxElement, setMessageBoxElement] = useState()
    const [savedCards, setSavedCards] = useState([])
    const [viewAddCredit, setViewAddCredit] = useState(false)
    const noImageAvailable = '/assets/img.png'
    const [closedLot, setClosedLot] = useState(null)
    const [cancelBid, setCancelBid] = useState('')
    const [showTimer, setTimer] = useState(true)
    const [liveCountValue, setLiveCountValue] = useState(0)
    const [messageTemp, setMessageTemp] = useState('')
    const [bidUpdate, setbidUpdate] = useState('')
    const [bidSocketData, setBidSocketData] = useState({})
    const [cookies, setCookie] = useCookies(['user_session'])

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        // prevArrow: <SamplePrevArrow />,
        // nextArrow: <SampleNextArrow />,
    }
    // const { user } = useContext(AuthContext)

    const createMessageBoxRef = useCallback((node) => {
        if (node !== null) {
            setMessageBoxElement(node)
        }
    })

    // const { addSavedSearch, getStripeCard } = useContext(UserContext)
    // const { setAlert } = useContext(AlertContext)

    // const getAllSavedCards = async () => {
    //     const result = await getStripeCard({ userid: user.id })
    //     if (result && result.result_stripe && result.result_stripe.status === 'success') {
    //         setSavedCards(result.result_stripe.data.responseData.data)
    //     }
    // }

    // useEffect(() => {
    //     if (user && Object.keys(user).length) getAllSavedCards()
    // }, [user])

    useEffect(() => {
        console.log('props.match.params.auctionId', props.match.params.auctionId)
        let auctionId = props.match.params.auctionId
        if (auctionId) {
            dispatch(
                auctionlotDetails({
                    title: '',
                    auctionId: auctionId,
                    userid: props.match.params.user_id,
                    page: '',
                    perpage: '2000',
                    auctionDate: '',
                    orderby: 1,
                }),
            )
            dispatch(auctionMsgDetails({ auctionid: auctionId }))
        } else {
            console.log('error occured')
            // handleRedirectInternal(history, 'searchAuction')
        }
    }, [props.match.params.auctionId])

    useEffect(() => {
        console.log('innnnnnnnnnnnnnnnnnnnnn', props.auctionLots)
        if (Object.keys(props.auctionLots.auctiondetails).length > 0) {
            var auction_details = props.auctionLots.auctiondetails
            if (props.auctionLots.auctionRegisteredStatus.length > 0) {
                if (props.auctionLots.auctionRegisteredStatus[0].status == 'active') {
                    auction_details.is_register_active = 0
                } else {
                    auction_details.is_register_active = 1
                }
            } else {
                auction_details.is_register_active = 1
            }

            setAuction(auction_details)
        }
        // else {
        //     // history.goBack()
        //     toast.warning('Auction Id does not exist')
        // }
    }, [props.auctionLots.auctiondetails])

    useEffect(() => {
        if (closedLot && closedLot.lotid && closedLot.next_lot) {
            console.log(
                'allauctionlotsnew ===',
                props.auctionLots.allauctionlots[1],
                props.auctionLots.allauctionlots.length > 1,
            )
            if (
                props.auctionLots.allauctionlots[1] !== undefined ||
                props.auctionLots.allauctionlots.length > 1
            ) {
                if (
                    props.auctionLots.allauctionlots[0].id == closedLot.lotid ||
                    props.auctionLots.allauctionlots[1].id == closedLot.next_lot
                ) {
                    // continue if id is matching
                } else if (props.auctionLots.allauctionlots[0].id == closedLot.next_lot) {
                    // skip if id is passed
                    return setClosedLot(null)
                } else {
                    if (closedLot.site_id && closedLot.site_id != process.env.REACT_APP_SITE_ID) {
                        // skip if site_id not matching
                        return setClosedLot(null)
                    } else {
                        // reload if id not matching
                        setClosedLot(null)
                        return window.location.reload()
                    }
                }
                dispatch(
                    livelotDetails({
                        lotId: props.auctionLots.allauctionlots[1].id,
                        user_id: Number(props.match.params.user_id),
                    }),
                )
                // setIsLoading(true)
                setClosedLot(null)
                props.auctionLots.allauctionlots.splice(0, 1)
                dispatch({
                    type: 'AUCTIONLOTS',
                    ...{
                        list: {
                            results: props.auctionLots.allauctionlots,
                            auctionList: props.auctionLots.auctiondetails,
                            total_results: props.auctionLots.totalAuctionLots,
                            isRegistered: props.auctionLots.auctionRegistered,
                            isRegistered_list: props.auctionLots.auctionRegisteredStatus,
                        },
                    },
                })

                let auctionId = props.match.params.auctionId
                if (auctionId) {
                    dispatch(auctionPercentage({ auctionid: parseInt(auctionId) }))
                } else {
                    console.log('error occured')
                }
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'This Auction Has Been Closed.',
                    showConfirmButton: true,
                    position: 'center',
                }).then(function (data) {
                    if (data.isConfirmed) {
                        return history.push('/search')
                    }
                })
            }
        }
    }, [closedLot])

    useEffect(() => {
        socket.on('liveAuctionMsg', (data) => {
            console.log(
                'liveAuctionMsg live uaction message check',
                data.auctionid,
                props.match.params.auctionId,
            )
            if (data.auctionid == props.match.params.auctionId) {
                setMessageTemp(data)
            }
        })
    }, [])

    useEffect(() => {
        if (messageTemp) {
            let auctionMessage = {}
            auctionMessage.message = messageTemp.message
            auctionMessage.type = messageTemp.type
            messageBoxContent.splice(0, 0, auctionMessage)
        }
    }, [messageTemp])

    useEffect(() => {
        if (props.auctionLots.allauctionlots.length > 0) {
            dispatch(
                livelotDetails({
                    lotId: props.auctionLots.allauctionlots[currentLotIndex].id,
                    user_id: Number(props.match.params.user_id),
                }),
            )
            // setIsLoading(true)
        }
        // getAllBidHistory({
        //   lotid: allauctionlots[currentLotIndex].id,
        // });
        // } else {
        //     // history.goBack()
        //     toast.warning('No Auction Lot Present')
        // }

        // let completedLots =
        //     props.auctionLots.allauctionlots &&
        //     props.auctionLots.filter((auctionlot) => auctionlot.market_status === 'closed').length
        // if (completedLots !== 0) {
        //     setCompletionPercentage((completedLots / props.auctionLots.allauctionlots.length) * 100)
        // }
    }, [props.auctionLots.allauctionlots, currentLotIndex])

    useEffect(() => {
        if (props.liveLots.liveLots.list !== undefined) {
            if (Object.keys(props.liveLots.liveLots.list).length > 0) {
                setIsLoading(false)
                setLotDetails(props.liveLots.liveLots.list)
                let lotimages = []
                if (props.liveLots.liveLots.list.images.length > 0) {
                    props.liveLots.liveLots.list.images.map((imgObj) =>
                        lotimages.push({
                            original: process.env.REACT_APP_PRODUCT_IMG + imgObj.file_name,
                            thumbnail: process.env.REACT_APP_PRODUCT_IMG + imgObj.file_name,
                        }),
                    )
                }
                setCurrentLotImages(lotimages.reverse())
            }
        }
    }, [props.liveLots.liveLots.list])

    const compareByDate = (objectA, objectB) => {
        const dateObjectA = Date.parse(objectA.date_sent)
        const dateObjectB = Date.parse(objectB.date_sent)

        let comparison = 0
        if (dateObjectA < dateObjectB) {
            comparison = 1
        } else if (dateObjectA > dateObjectB) {
            comparison = -1
        }
        return comparison
    }

    useEffect(() => {
        let dataToDisplay = []
        if (props.auctionMsg.auctionMessage && props.auctionMsg.auctionMessage.length > 0) {
            dataToDisplay = props.auctionMsg.auctionMessage
            dataToDisplay.sort(compareByDate)
        }
        // if (search_allbidhistory.totalRecords > 0) {
        //   search_allbidhistory.records.map((bidhistory) =>
        //     bidhistory.declined === 0
        //       ? dataToDisplay.push({
        //           type: "bidder",
        //           message: `$${bidhistory.proposed_amount} Completing Bid`,
        //         })
        //       : dataToDisplay.push({
        //           type: "bidder",
        //           message: `Completing Bidder was was outbid by bidder ${bidhistory.first_name} proxy bid`,
        //         })
        //   );
        // }
        console.log(dataToDisplay, 'this is data to display')
        setMessageBoxContent(dataToDisplay)
        //scrollToBottom(messageBoxElement);
    }, [props.auctionMsg.auctionMessage])

    // useEffect(() => {
    //   scrollToBottom(messageBoxElement);
    // }, [messageBoxElement]);
    const lotDetailsRef = useRef(lotDetails)

    useEffect(() => {
        lotDetailsRef.current = lotDetails
    })

    useEffect(() => {
        // socket.on('bidoffers', (data) => {
        //     console.log('')
        //     if (
        //         data &&
        //         Object.keys(data).length > 0 &&
        //         lotDetails &&
        //         Object.keys(lotDetails).length > 0
        //     ) {
        //         let newLot = lotDetails

        //         if (data.lotid === newLot.lotDetails.id) {
        //             newLot.lotDetails.bidcnt = data.bids_offer[0].bidcnt
        //             newLot.lotDetails.wprice = data.bids_offer[0].current_amount
        //             newLot.current_bid = data.bids_offer[0].current_amount
        //         }

        //         setLotDetails(newLot)
        //         dispatch(auctionMsgDetails({ auctionid: auction.id }))
        //     }
        // })

        socket.on('bidAddtime', (data) => {
            console.log('bid placed emit socket check after bid', data)
            setBidSocketData(data)
        })
    }, [])
    useEffect(() => {
        if (
            bidSocketData &&
            Object.keys(bidSocketData).length > 0 &&
            lotDetails &&
            Object.keys(lotDetails).length > 0
        ) {
            let newLot = lotDetails
            if (bidSocketData.status !== 'failed') {
                if (parseInt(bidSocketData.id) === parseInt(newLot.lotDetails.id)) {
                    newLot.lotDetails.bidcnt = bidSocketData.bpop_bidcount
                    newLot.lotDetails.wprice = bidSocketData.bpop_wprice
                    newLot.current_bid = bidSocketData.bpop_wprice
                    newLot.next_bid = bidSocketData.bpop_nextbid
                    newLot.highbid =
                        bidSocketData.bpop_higher &&
                        bidSocketData.bpop_higher == props.match.params.user_id
                            ? true
                            : false
                    newLot.cancel_bid = 0
                }
                setLotDetails(newLot)
                console.log('lotdetails after socket emit', lotDetailsRef.current)
                // dispatch(auctionMsgDetails({ auctionid: auction.id }))
            }
        }
    }, [bidSocketData])

    useEffect(() => {
        socket.on('bidderapproval', (data) => {
            console.log('bidders approval socket data', data)
            setbidUpdate(data)
        })
    }, [])

    useEffect(() => {
        updateRegisterStatus(bidUpdate)
    }, [bidUpdate])

    const updateRegisterStatus = (data) => {
        if (auction && auction.id && props.userDetails?.userdetails?.id && data.auction_obj) {
            let match_user = data.auction_obj.filter(
                (ele) => ele.user_id == props.userDetails?.userdetails?.id,
            )
            console.log('match_user', match_user)
            let match_auction = []
            if (match_user.length > 0) {
                match_auction = match_user.filter((ele) => ele.auction_id == auction.id)
            }
            console.log('match_auction', match_auction)
            if (match_auction.length > 0) {
                if (data.status === 'active') {
                    auction.is_register_active = 0
                    setAuction(auction)
                } else {
                    auction.is_register_active = 1
                    setAuction(auction)
                }
            }
        }
    }

    useEffect(() => {
        socket.on('liveauctionclose', (data) => {
            console.log(
                'Inside Auction Close in livelotpage',
                data,
                data.auctionid,
                props.match.params.auctionId,
            )
            if (parseInt(data.auctionid) === parseInt(props.match.params.auctionId)) {
                console.log('Inside This Auction Close')
                setTimer(false)
                Swal.fire({
                    icon: 'success',
                    title: 'This Auction Has Been Ended By Seller Now.',
                    showConfirmButton: true,
                    position: 'center',
                }).then(function (data) {
                    if (data.isConfirmed) {
                        return history.push('/search')
                    }
                })
            }
        })
    }, [])

    useEffect(() => {
        socket.on('liveauctionclose', (data) => {
            console.log(
                'Inside Auction Close in livelotpage socket forward',
                data,
                data.auctionid,
                props.match.params.auctionId,
            )
            if (parseInt(data.auctionid) === parseInt(props.match.params.auctionId)) {
                console.log('Inside This Auction Close')
                setTimer(false)
                Swal.fire({
                    icon: 'success',
                    title: 'This Auction Has Been Ended By Seller Now.',
                    showConfirmButton: true,
                    position: 'center',
                }).then(function (data) {
                    if (data.isConfirmed) {
                        return history.push('/search')
                    }
                })
            }
        })
    }, [])

    const scrollToBottom = (element) => {
        if (element) {
            element.scrollTop = element.scrollHeight
        }
    }

    const handleLotChangeClick = (e, type) => {
        if (type === 'next') {
            if (currentLotIndex < props.auctionLots.allauctionlots.length - 1) {
                setCurrentLotIndex(currentLotIndex + 1)
                // let currentLotInd = currentLotIndex + 1;
                // let nextSetLot = allauctionlots.slice(currentLotInd);
            }
        }
        if (type === 'prev') {
            if (currentLotIndex > 0) {
                setCurrentLotIndex(currentLotIndex - 1)
            }
        }
    }

    const handleLotImageClick = (e, index) => {
        if (currentLotIndex !== index) {
            setCurrentLotIndex(index)
        }
    }

    const isMobile = useMediaQuery({
        query: '(max-width: 600px)',
    })
    const [isOpen, setOpen] = useState(false)
    const classes = useStyles()

    const productDetails = [
        {
            title: 'Description',
            description: lotDetails.lotDetails && lotDetails.lotDetails.description,
        },
        {
            title: 'Shipping',
            description: auction.store_comment_two,
        },
        {
            title: 'Item Condition',
            description: auction.store_comment_four,
        },
        {
            title: 'Auction notes',
            description: auction.store_comment_one + auction.store_comment_five,
        },
    ]

    const [value, setValue] = useState(0)
    const [expanded, setExpanded] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    useEffect(() => {
        if (auction.id) {
            init()
        }
    }, [auction])

    const config = {
        iceServers: [
            {
                urls: 'turn:100.20.224.224:3478',
                credential: 'Aucsoft@!',
                username: 'AsUser',
            },
        ],
    }

    let [uri, setUri] = useState([])

    let peerConnection
    const videoRef = useRef()

    useEffect(() => {
        socket.on('offer', (id, description) => {
            console.log('Offer', id)
            peerConnection = new RTCPeerConnection(config)
            peerConnection
                .setRemoteDescription(description)
                .then(() => peerConnection.createAnswer())
                .then((sdp) => peerConnection.setLocalDescription(sdp))
                .then(() => {
                    socket.emit('answer', id, peerConnection.localDescription)
                })
            peerConnection.ontrack = (event) => {
                console.log(event.streams[0])
                setUri(event.streams[0])
            }
            peerConnection.onicecandidate = (event) => {
                if (event.candidate) {
                    socket.emit('candidate', id, event.candidate)
                }
            }
        })
    }, [])

    useEffect(() => {
        socket.on('liveauctionAwarding', (data) => {
            if (data.site_id && data.site_id != process.env.REACT_APP_SITE_ID) {
                // skip if site_id not matching
                return false
            }

            console.log('closed auction emit', data)
            console.log('allauctionlots new', props.auctionLots.allauctionlots)
            setClosedLot(data)
            // let auctionId = props.match.params.auctionId
            // if (auctionId) {
            //     dispatch(auctionPercentage({ auctionid: parseInt(auctionId) }))
            // } else {
            //     console.log('error occured')
            // }
            // props.auctionLots.allauctionlots.splice(0, 1)
            // dispatch({ type: 'AUCTIONLOTS', ...props.auctionLots })
            // console.log('allauctionlots new after check', props.auctionLots.allauctionlots)
            // let auctionId = props.match.params.auctionId
            // if (auctionId) {
            //     dispatch(
            //         auctionlotDetails({
            //             title: '',
            //             auctionId: auctionId,
            //             userid: props.match.params.user_id,
            //             page: '',
            //             perpage: '2000',
            //             auctionDate: '',
            //             orderby: 1,
            //         }),
            //     )
            //     dispatch(auctionPercentage({ auctionid: parseInt(auctionId) }))
            // } else {
            //     console.log('error occured')
            // }
        })

        socket.on('cancelbidemitted', (data) => {
            console.log('cancel bid emitted data', data)
            setCancelBid(data)
        })
    }, [])

    useEffect(() => {
        socket.on('liveViewDalshire', (data) => {
            console.log('Im in socket date socket view value', data)
            let min = 0
            if (!isNaN(data)) {
                min += data
            }
            console.log('Im in socket date live count emitted values', data, min)
            setLiveCountValue(min)
        })
    }, [])

    useEffect(() => {
        if (cancelBid) {
            console.log('cancel bid update values', lotDetails, cancelBid, cancelBid.prodata)
            if (
                cancelBid &&
                // cancelBid.prodata &&
                // Object.keys(cancelBid.prodata).length > 0 &&
                lotDetails &&
                Object.keys(lotDetails).length > 0
            ) {
                let newLot = lotDetails

                console.log('current id vs new lot id', cancelBid.id, newLot.lotDetails.id)

                if (parseInt(cancelBid.project_id) === parseInt(newLot.lotDetails.id)) {
                    newLot.lotDetails.bidcnt = cancelBid.bpop_bidcount || newLot.lotDetails.bidcnt
                    newLot.lotDetails.wprice = cancelBid.bpop_wprice || newLot.lotDetails.wprice
                    newLot.current_bid = cancelBid.prodata
                        ? cancelBid.prodata.proposed_amount
                        : newLot.lotDetails.wprice
                    newLot.next_bid = cancelBid.bpop_nextbid || newLot.next_bid
                    newLot.highbid =
                        cancelBid.prodata && cancelBid.prodata.user_id == props.match.params.user_id
                            ? true
                            : false
                    if (cancelBid.cancelbid) {
                        newLot.cancel_bid = cancelBid.cancelbid.price
                    }
                }
                setLotDetails(newLot)
                // dispatch(auctionMsgDetails({ auctionid: auction.id }))
            }
        }
    }, [cancelBid])

    // const cancelBidUpdate = (data) => {}

    useEffect(() => {
        socket.on('candidate', (id, candidate) => {
            console.log('peerConnection', peerConnection)
            peerConnection
                .addIceCandidate(new RTCIceCandidate(candidate))
                .catch((e) => console.error(e))
        })
    }, [])

    useEffect(() => {
        return () => {
            socket.emit('watcher-disconnect', localStorage.getItem('broadcasterID'))
            if (peerConnection) {
                peerConnection.close()
            }
            // reInitializeSocket()
        }
    }, [])

    useEffect(() => {
        socket.on('disconnectPeer', () => {
            peerConnection.close()
        })
    }, [])

    useEffect(() => {
        window.onunload = window.onbeforeunload = () => {
            // update live view when close window
            let socket_data = {
                action: 'userDisconnected',
                session: cookies.user_session || '',
                site_id: process.env.REACT_APP_SITE_ID,
            }
            socket.emit('userWatch', socket_data)

            socket.emit('watcher-disconnect', localStorage.getItem('broadcasterID'))
            if (peerConnection) {
                peerConnection.close()
            }
            // reInitializeSocket()
        }
    }, [window])

    useEffect(() => {
        setUser(props.userDetails.userdetails)
    }, [props.userDetails])

    const tooltipLotdtls = (lot) => {
        return (
            <div className="toolTopInfo">
                <LazyLoadImage
                    src={process.env.REACT_APP_PRODUCT_IMG + lot.avatar}
                    effect="blur"
                    placeholderSrc="/images/imageLoading.svg"
                    height="100%"
                    width="100%"
                    onClick={(e) => handleLotImageClick(e)}
                    onError={onImageError}
                    alt="Next Lot"
                />

                <p>
                    Lot Name :{' '}
                    <span
                        dangerouslySetInnerHTML={{
                            __html: lot.title,
                        }}
                    ></span>
                </p>
                <p>Current Price : US ${lot.wprice}</p>
            </div>
        )
    }

    async function DirectAPICAll(method, url, data) {
        return new Promise(async (resolve, reject) => {
            try {
                const config = {
                    headers: {
                        authorization:
                            'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTE2LCJpYXQiOjE2MjcwNDAxNTUsImV4cCI6MTYyNzY0NDk1NX0.bE9l99te-OBpA93qyxHpxGRhs_8_oBeeJt3oLBlklBk',
                    },
                }
                if (method === 'get') {
                    let res = await axios.get(`${url}`)
                    console.log('responsode from api', res)
                    resolve(res)
                } else if (method === 'post') {
                    if (data) {
                        let res = await axios.post(`${url}`, data, config)
                        resolve(res)
                    } else {
                        let res = await axios.post(`${url}`)
                        resolve(res)
                    }
                }
            } catch (err) {
                console.log('responsode error from api', err)
                resolve(err)
            }
        })
    }

    async function init() {
        const payload = {
            auctionid: auction.id,
        }

        const data = await DirectAPICAll(
            'post',
            `https://dpworldapi.auctionsoftware.com/api/video/newVideoStream`,
            payload,
        )
        if (data?.data?.data?.responseData?.length) {
            localStorage.setItem('broadcasterID', data.data.data.responseData[0].sender_stream)
            socket.emit('watcher', data.data.data.responseData[0].sender_stream)
        }
    }

    const onImageError = (e) => (e.target.src = '/images/logo.jpg')

    useEffect(() => {
        dispatch(auctionPercentage({ auctionid: parseInt(props.match.params.auctionId) }))
    }, [])

    useEffect(() => {
        console.log('auctionPercent', props.auctionPercent)
    }, [props.auctionPercent])

    return (
        <>
            {isLoading ? (
                <div className="mnHtcontnr">
                    <div className="liveLotsLoader">
                        <Loaders name="live_auction" isLoading={isLoading} />
                    </div>
                </div>
            ) : (
                <div className="liveLots">
                    <ToastContainer
                        autoClose={5000}
                        hideProgressBar={true}
                        pauseOnFocusLoss={false}
                    />
                    <div className="lotHeader">
                        <div className="d-flex justify-content-between align-items-center">
                            <h4>{auction.title}</h4>
                            {showTimer ? (
                                new Date() < new Date(auction.date_added) ? (
                                    <div className="lotTimer">
                                        <Timer
                                            productTime={{
                                                start: auction.date_added,
                                                end: auction.date_closed,
                                            }}
                                            timerBasic={true}
                                            // date_added={auction.date_added}
                                            // date_closed={auction.date_closed}
                                            // withText={1}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )
                            ) : (
                                <div className="lotTimer">
                                    <span>Auction Closed</span>
                                </div>
                            )}
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            {/* {console.log('lot count values', lotDetails, auction)} */}
                            <Chip size="medium" label={`Lot ${lotDetails.lotDetails.sku}`} />
                            <h3>
                                {`${parseFloat(
                                    (props.auctionPercent.auctionPercent.close_count /
                                        props.auctionPercent.auctionPercent.total_count) *
                                        100,
                                ).toFixed()}% Completed`}{' '}
                                <small>{`(${props.auctionLots.allauctionlots.length} of ${auction.total_lot} Lots Remaining)`}</small>
                            </h3>
                        </div>
                        <div className="d-flex justify-content-between align-items-center prodctTit">
                            <h1
                                dangerouslySetInnerHTML={{
                                    __html: lotDetails.lotDetails.title,
                                }}
                            ></h1>
                            <div className="d-flex align-items-center">
                                <Chip
                                    className="mr-2 estChip"
                                    size="medium"
                                    label={`EST: ${currencyFormat(
                                        lotDetails.lotDetails.lowest,
                                    )} - ${currencyFormat(lotDetails.lotDetails.highest)}`}
                                />
                                <Chip
                                    size="medium"
                                    label={`Buyers Premium ${auction.buyer_premium}%`}
                                />
                            </div>
                            {/* <h5>{`${
                                props.auctionLots.allauctionlots &&
                                props.auctionLots.filter(
                                    (auctionlot) => auctionlot.market_status === 'open',
                                ).length
                            } of ${props.auctionLots.allauctionlots.length} Lots Remaining`}</h5> */}
                        </div>
                    </div>
                    <LinearProgress variant="determinate" value={completionPercentage} />
                    <div className="row lotMain">
                        <div className="col-md-6 col-12 lotSection ">
                            <div className="d-flex">
                                <div className="currentLot">
                                    <div className="lotStats">
                                        <span className="liveAuctionTag">
                                            LIVE <span className="material-icons">visibility</span>
                                            {liveCountValue}
                                        </span>
                                    </div>
                                    <div className="imageSliderLive">
                                        <ImageGallery
                                            items={currentLotImages}
                                            thumbnailPosition="bottom"
                                            showNav={true}
                                            lazyLoad={true}
                                            showBullets={false}
                                            showFullscreenButton={true}
                                            showPlayButton={false}
                                            onErrorImageURL="/images/logo.jpg"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                            <div className="bidCnt">
                                <h2>
                                    <b>{currencyFormat(lotDetails.current_bid)}</b>
                                </h2>
                                {new Date() < new Date(auction.date_added) ? (
                                    <div className="lvNtStBtn">
                                        <PrimaryButton
                                            label={
                                                <span className="d-flex align-items-center">
                                                    <span>Live Auction Not Yet Started</span>
                                                </span>
                                            }
                                            disabled={true}
                                        />
                                    </div>
                                ) : (
                                    props.auctionLots.allauctionlots &&
                                    props.auctionLots.allauctionlots.length && (
                                        <>
                                            {props.auctionLots.allauctionlots[0].id ===
                                            lotDetails.lotDetails.id ? (
                                                <BiddingLive
                                                    lotdetails={lotDetails}
                                                    type="hard"
                                                    size="medium"
                                                    auctionId={auction.id}
                                                    user={user}
                                                    is_not_approved={auction.is_register_active}
                                                />
                                            ) : (
                                                <PreBidComponent
                                                    lotdetails={lotDetails}
                                                    type="proxy"
                                                    size="medium"
                                                    auctionId={auction.id}
                                                    user={user}
                                                    is_not_approved={
                                                        auction.is_register_active == 0 ? 1 : 0
                                                    }
                                                />
                                            )}
                                        </>
                                    )
                                )}
                            </div>
                        </div>
                        <div className="col-md-3 col-12">
                            {new Date() >= new Date(auction.date_added) ? (
                                <div className="bidsSection" ref={createMessageBoxRef}>
                                    {messageBoxContent.length > 0
                                        ? messageBoxContent.map((messageObject, index) =>
                                              messageObject.type === 'seller' ? (
                                                  <div
                                                      className={`bidComment ${
                                                          messageObject.message.includes('sold')
                                                              ? 'blue'
                                                              : messageObject.message.includes(
                                                                    'Opened',
                                                                )
                                                              ? 'green'
                                                              : 'yellow'
                                                      }`}
                                                      key={index}
                                                  >
                                                      {messageObject.message}
                                                  </div>
                                              ) : (
                                                  <div className="bidComment" key={index}>
                                                      {messageObject.message}
                                                  </div>
                                              ),
                                          )
                                        : ''}
                                    {/* <div className="bidComment">$3501: Completing Bid</div>
                  <div className="bidComment">
                    Competing Bid was outbid by bidder 942's proxy
                  </div>
                  <div className="bidComment yellow">Fair Warning!</div>
                  <div className="bidComment green">
                    Lot 122 was sold for $233 to Bidder 322
                  </div>
                  <div className="bidComment">
                    Competing Bid was outbid by bidder 942's proxy
                  </div>
                  <div className="bidComment yellow">Fair Warning!</div>
                  <div className="bidComment green">
                    Lot 121 was sold for $245 to Bidder 124
                  </div>
                  <div className="bidComment">$3501: Completing Bid</div>
                  <div className="bidComment">
                    Competing Bid was outbid by bidder 942's proxy
                  </div>
                  <div className="bidComment yellow">Fair Warning!</div> */}
                                </div>
                            ) : (
                                <div className="noMsgFound">
                                    <span className="material-icons">mail</span>
                                    <p className="">No Message Found</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 lotSection upcomming-sliebar">
                            <div className="upcomingLots">
                                <h5 className="upcmgLtsTtle">UPCOMING LOTS</h5>
                                <div className="d-flex align-items-center lotChangeButton">
                                    <Button
                                        className="prevBtn"
                                        onClick={(e) => handleLotChangeClick(e, 'prev')}
                                        disabled={currentLotIndex === 0 && true}
                                    >
                                        <span className="material-icons">chevron_left</span> Prev
                                        Lots
                                    </Button>
                                    <Button
                                        className="nextBtn"
                                        onClick={(e) => handleLotChangeClick(e, 'next')}
                                        disabled={
                                            props.auctionLots.allauctionlots &&
                                            currentLotIndex ===
                                                props.auctionLots.allauctionlots.length - 1 &&
                                            true
                                        }
                                    >
                                        Next Lots{' '}
                                        <span className="material-icons">chevron_right</span>
                                    </Button>
                                    {/* {currentLotIndex === allauctionlots.length - 1 ? null : (
                                            )}
                                            {allauctionlots && allauctionlots.length > 9 ? (
                                            
                                    ) : null} */}
                                </div>
                                {/* <div className='ltImgGrdWrpr'>
                                    {console.log('auctionlots', allauctionlots)}
                                    {allauctionlots &&
                                    allauctionlots.length > 0 &&
                                    allauctionlots.map((lot, index) => (
                                        <Button className='ucImg' key={index}>
                                        <img
                                            src={global.images_url + lot.avatar}
                                            alt='Next Lot'
                                            onClick={(e) => handleLotImageClick(e, index)}
                                            onError={noImageAvailable}
                                        />
                                        </Button>
                                    ))}
                                </div> */}
                                <Slider {...settings}>
                                    {props.auctionLots.allauctionlots &&
                                        props.auctionLots.allauctionlots.length > 0 &&
                                        props.auctionLots.allauctionlots.map((lot, index) => (
                                            <Button
                                                className={`ucImg  ${
                                                    currentLotIndex === index ? 'active-slide' : ''
                                                }`}
                                                key={index}
                                            >
                                                <Tooltip
                                                    placement="top"
                                                    title={tooltipLotdtls(lot)}
                                                    arrow
                                                >
                                                    <LazyLoadImage
                                                        src={
                                                            process.env.REACT_APP_PRODUCT_IMG +
                                                            lot.avatar
                                                        }
                                                        effect="blur"
                                                        placeholderSrc="/images/imageLoading.svg"
                                                        height="100%"
                                                        width="100%"
                                                        onClick={(e) =>
                                                            handleLotImageClick(e, index)
                                                        }
                                                        onError={onImageError}
                                                        alt="Next Lot"
                                                    />
                                                </Tooltip>
                                            </Button>
                                        ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 col-md-12">
                            <div className={`${isMobile && 'mobileView'} productDetails`}>
                                {isMobile ? (
                                    <div className="pvAccordian mt-2 mb-2 w-100">
                                        {productDetails.map((data, index) => (
                                            <Accordion
                                                expanded={expanded === `panel${index}`}
                                                onChange={handleExpand(`panel${index}`)}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={`panel${index}a-content`}
                                                    id={`panel${index}a-header`}
                                                >
                                                    <Typography className={classes.heading}>
                                                        {data.title}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    data.description &&
                                                                    data.description.trim(),
                                                            }}
                                                        />
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        <AppBar position="static">
                                            <Tabs
                                                value={value}
                                                onChange={handleChange}
                                                aria-label="simple tabs example"
                                            >
                                                {productDetails.map((data, index) => (
                                                    <Tab
                                                        label={data.title}
                                                        {...a11yProps({ index })}
                                                    />
                                                ))}
                                            </Tabs>
                                        </AppBar>
                                        {productDetails.map((data, index) => (
                                            <TabPanel value={value} index={index}>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: data.description,
                                                    }}
                                                />
                                            </TabPanel>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                        {/* <div className="col-12 col-md-5">
                <div className="mapPlugin">Map plugin to be added here</div>
              </div> */}
                    </div>
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.User ? state.User : {},
        bidding: state.bidding ? state.bidding : {},
        serverTime: state.serverTime ? state.serverTime.time : '',
        liveLots: state.liveLots ? state.liveLots : '',
        auctionLots: state.auctionLots ? state.auctionLots : '',
        auctionMsg: state.auctionMsg ? state.auctionMsg : '',
        auctionPercent: state.auctionPercent ? state.auctionPercent : '',
    }
}

export default connect(mapStateToProps, null)(LiveLots)
