import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            <div className="footerCnt">
                <div className="hmcustomContainer d-flex align-items-start justify-content-between flex-wrap">
                    <div className="lftFtrLgnCntn">
                        <a href={'/'} className="footlogo">
                            <img className="Logo" src="/images/logo-white.svg" alt="logo" />
                        </a>
                        {/* <div className="scmlMdaLnks">
                            <div className="mdaWrpr fbLnk">
                                <a
                                    href="https://www.facebook.com/Dalshire-International-102665361571874/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src="/images/fb_footer.svg" />
                                </a>
                            </div>
                            <div className="mdaWrpr twLnk">
                                <a href="#">
                                    <img src="/images/twitter_footer.svg" />
                                </a>
                            </div>
                        </div> */}
                    </div>
                    <div className="rtCntIntoWrpr">
                        <div className="termWrp">
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/contactus">Contact Us</Link>
                                </li>
                                <li>
                                    <Link to="/aboutus">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/terms_of_service">Terms & Conditions</Link>
                                </li>
                            </ul>
                            {/* <div className="mlInfoTxt text-left">
                                <a href="mailto:eugenep@dalshire.com">eugenep@dalshire.com</a>
                                <br />
                                <a href="mailto:johnh@dalshire.com">johnh@dalshire.com</a>
                            </div> */}
                        </div>
                        <div className="adrsInfoWrpr text-left">
                            <p className="stnTtle">FIND US HERE</p>

                            <div className="flxGrdVwRp">
                                <img src="/images/footer_company.svg" />
                                <div className="rtCrsTxt">
                                    <p>Dalshire International</p>
                                </div>
                            </div>
                            <div className="flxGrdVwRp">
                                <img src="/images/footer_location.svg" />
                                <div className="rtCrsTxt">
                                    <p>
                                        2452 Lacy Ln Ste 116 <br /> Carrollton, TX
                                        <br /> 75006
                                    </p>
                                </div>
                            </div>
                            <div className="flxGrdVwRp">
                                <img src="/images/footer_phone.svg" />
                                <div className="rtCrsTxt">
                                    <p>
                                        Phone: <a href="tel:9725288847">(972) 837-0404</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cpyRtFtr">
                <p>
                    ©{new Date().getFullYear()} Dalshire International |{' '}
                    <Link to="/terms_of_service">Terms & Conditions</Link>
                </p>
            </div>
        </>
    )
}

export default Footer
