import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import userDetails from '../reducers/userReducer'
import serverTime from '../reducers/serverTimeReducer'
import biddingReducer from '../reducers/biddingReducer'
import category from '../reducers/categoryReducer'
import chatreducer from '../reducers/chatmessagereducer'
import liveLotsReducer from '../reducers/liveLotsReducer'
import auctionLotReducer from '../reducers/auctionLotsReducer'
import auctionMsgReducer from '../reducers/auctionMsgReducer'
import auctionPercentageReducer from '../reducers/auctionPercentageReducer'
import auctionSearchReducer from '../reducers/auctionSearchReducer'
import livePriceReducer from '../reducers/livePriceReducer'
import lotSearchReducer from '../reducers/lotSearchReducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
    const store = createStore(
        combineReducers({
            User: userDetails,
            serverTime: serverTime,
            bidding: biddingReducer,
            category: category,
            chatsocket: chatreducer,
            liveLots: liveLotsReducer,
            auctionLots: auctionLotReducer,
            auctionMsg: auctionMsgReducer,
            auctionPercent: auctionPercentageReducer,
            auctionSearch: auctionSearchReducer,
            livePrice: livePriceReducer,
            allLotDetails: lotSearchReducer,
        }),
        composeEnhancers(applyMiddleware(thunk)),
    )

    return store
}
