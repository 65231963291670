import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import MyAuction from './MyAuctions'
import Notification from '../Notifications/Notification'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import List from '@material-ui/core/List'
import Drawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import clsx from 'clsx'

import './Dashboard.css'
import { SignalCellularNull } from '@material-ui/icons'

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
})

const Dashboard = (props) => {
    const [type, settype] = useState({ type: '1', class: 'Active' })

    // dashboard Responsive Sidenav
    // const current = new Date()
    const date = `${new Date().getFullYear()}`
    const classes = useStyles()
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            // onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className="mobFilter">
                <div className="userProfileimg">
                    <img
                        src={
                            props.Profile.userdetails.avatar
                                ? `${process.env.REACT_APP_DOMAIN}uploads/${props.Profile.userdetails.avatar}`
                                : '/images/nouserimg.png'
                        }
                        alt="user Img"
                    />
                    <div className="profileInf">
                        <h4>{props.Profile.userdetails.first_name}</h4>
                        <p>Lorem ippsum</p>
                    </div>
                </div>
                <Divider />
                <ListItem
                    button
                    className={window.location.pathname === '/dashboard' ? 'Active' : ''}
                >
                    <ListItemIcon>
                        {/* <span className="material-icons">price_check</span> */}
                        <i className="fas fa-gavel"></i>
                    </ListItemIcon>
                    <NavLink activeClassName="active" to="/dashboard">
                        My Auctions
                    </NavLink>
                </ListItem>
                <ListItem
                    button
                    className={
                        window.location.pathname === '/Dashboard/FavouritesB' ? 'Active' : ''
                    }
                >
                    <ListItemIcon>
                        <span className="material-icons">favorite</span>
                    </ListItemIcon>
                    <NavLink activeClassName="active" to="/Dashboard/FavouritesB">
                        Favorites
                    </NavLink>
                </ListItem>
                {/* <ListItem
                    button
                    className={window.location.pathname === '/Dashboard/Message' ? 'Active' : ''}
                >
                    <ListItemIcon>
                        <span className="material-icons">sms</span>
                    </ListItemIcon>
                    <NavLink activeClassName="active" to="/Dashboard/Message">
                        Message
                    </NavLink>
                </ListItem> */}
                <ListItem
                    button
                    className={window.location.pathname === '/Dashboard/Profile' ? 'Active' : ''}
                >
                    <ListItemIcon>
                        <span className="material-icons">person_pin</span>
                    </ListItemIcon>
                    <NavLink activeClassName="active" to="/Dashboard/Profile">
                        {' '}
                        Profile
                    </NavLink>
                </ListItem>
                {/* <ListItem
                    button
                    className={
                        window.location.pathname === '/Dashboard/Paymenttabs' ? 'Active' : ''
                    }
                >
                    <ListItemIcon>
                        <span className="material-icons">monetization_on</span>
                    </ListItemIcon>
                    <NavLink activeClassName="active" to="/Dashboard/Paymenttabs">
                        Payments
                    </NavLink>
                </ListItem> */}
                <ListItem
                    button
                    className={
                        window.location.pathname === '/Dashboard/Notification' ? 'Active' : ''
                    }
                >
                    <ListItemIcon>
                        <span className="material-icons">circle_notifications</span>
                    </ListItemIcon>
                    <NavLink activeClassName="active" to="/Dashboard/Notification">
                        Notification
                    </NavLink>
                </ListItem>
                <div className="socialLinks">
                    {/* <Link href="#">
                        <i className="lab la-facebook"></i>
                    </Link>
                    <Link href="#">
                        <i className="las la-twitter"></i>
                    </Link>
                    <Link href="#">
                        <i className="las la-instagram"></i>
                    </Link> */}
                    <p>Copyrights @{date}</p>
                </div>
            </List>
        </div>
    )

    return (
        <div>
            <div className="dashNav">
                {['left'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Button onClick={toggleDrawer(anchor, true)}>
                            <span className="material-icons">grid_view</span> Dashboard
                        </Button>
                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                        >
                            {list(anchor)}
                        </Drawer>
                    </React.Fragment>
                ))}
            </div>
            <div className="customContainer Dashboard custom-container-Dashboard">
                <div className="Tabs">
                    <img
                        src={
                            props.Profile.userdetails.avatar
                                ? `${process.env.REACT_APP_DOMAIN}uploads/${props.Profile.userdetails.avatar}`
                                : '/images/nouserimg.png'
                        }
                    />
                    <h4>{props.Profile.userdetails.first_name}</h4>
                    <div className="Tabs-Items">
                        <ul>
                            <li
                                className={
                                    window.location.pathname === '/dashboard' ? 'Active' : ''
                                }
                            >
                                <NavLink activeClassName="active" to="/dashboard">
                                    My Auctions
                                </NavLink>
                            </li>
                            <li
                                className={
                                    window.location.pathname === '/Dashboard/FavouritesB'
                                        ? 'Active'
                                        : ''
                                }
                            >
                                <NavLink activeClassName="active" to="/Dashboard/FavouritesB">
                                    Favorites
                                </NavLink>
                            </li>
                            {/* <li
                                className={
                                    window.location.pathname === '/Dashboard/Message'
                                        ? 'Active'
                                        : ''
                                }
                            >
                                <NavLink activeClassName="active" to="/Dashboard/Message">
                                    Message
                                </NavLink>
                            </li> */}
                            <li
                                className={
                                    window.location.pathname === '/Dashboard/Profile'
                                        ? 'Active'
                                        : ''
                                }
                            >
                                <NavLink activeClassName="active" to="/Dashboard/Profile">
                                    {' '}
                                    Profile
                                </NavLink>
                            </li>
                            <li
                                className={
                                    window.location.pathname === '/Dashboard/Paymenttabs'
                                        ? 'Active'
                                        : ''
                                }
                            >
                                <NavLink activeClassName="active" to="/Dashboard/Paymenttabs">
                                    Saved Cards
                                </NavLink>
                            </li>
                            <li
                                className={
                                    window.location.pathname === '/Dashboard/Notification'
                                        ? 'Active'
                                        : ''
                                }
                            >
                                <NavLink activeClassName="active" to="/Dashboard/Notification">
                                    Notification
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="custom-Components">{props.children}</div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        Profile: state.User,
    }
}

export default connect(mapStateToProps, null)(Dashboard)
