import React from 'react'
import PrimaryButton from '../../atoms/PrimaryButton'
import Timer from '../Timer'
import './ProductCard.css'
import { onImageError, setDecimalPoint } from '../../../Utils/Utility'
import { useHistory } from 'react-router-dom'
import { currencyFormat } from '../../../common/components'
import { Chip } from '@material-ui/core'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

function Gridview(props) {
    const history = useHistory()

    return (
        <div className="gridViewCnt" key={props.id}>
            <div className="cards lotCardWrpr">
                {props.card_type === 'lotView' && (
                    <div className="favBox">
                        <span
                            name={props.prodid}
                            onClick={props.onFavClick}
                            className="material-icons Favicon"
                        >
                            {props.isFav === 1 ? 'favorite' : 'favorite_border'}
                        </span>
                    </div>
                )}

                <LazyLoadImage
                    className="cardImage"
                    src={props.imgSrc}
                    onError={onImageError}
                    onClick={props.onClick ? props.onClick : null}
                    effect="blur"
                    placeholderSrc="/images/imageLoading.svg"
                    height="100%"
                    width="100%"
                    alt="Next Lot"
                />
                <div className="pdtContent">
                    <p
                        className="pdtName mb-2"
                        onClick={props.onClick ? props.onClick : null}
                        dangerouslySetInnerHTML={{
                            __html: props.Name,
                        }}
                    >
                        {/* {props.Name} */}
                    </p>
                    {props.lotId ? (
                        <Chip label={<p className="m-0">Lot Id: {props.lotId}</p>} />
                    ) : (
                        ''
                    )}
                    {props.closedAuction ? (
                        <>
                            {/* <p className="currPrice pb-3">
                                Sold Price: <span> $ {setDecimalPoint(props.currentPrice, 2)}</span>
                            </p> */}
                            <PrimaryButton
                                cstmClass="w-100 mt-0"
                                className="viewAuctionBtn"
                                onClick={() =>
                                    history.push(encodeURI(`/auction/${props.id}?page=1`))
                                }
                            >
                                {props.label}
                            </PrimaryButton>
                        </>
                    ) : (
                        <>
                            {(props.lowest || props.highest) && (
                                <p className="mt-3">
                                    Est: {currencyFormat(props.lowest) || 100} -{' '}
                                    {currencyFormat(props.highest) || 1000}
                                </p>
                            )}
                            <p
                                className="pdtDescrp mt-4"
                                dangerouslySetInnerHTML={{ __html: props.descrp }}
                            ></p>
                            <Timer
                                productTime={{ start: props.startTime, end: props.endTime }}
                                timerBasic={props.timerBasic}
                                liveToast={props.lotBidding ? false : true}
                            />
                            {props.lotBidding ? (
                                <>
                                    {props.market_status === 'sold' ||
                                    props.market_status === 'unsold' ||
                                    props.market_status === 'closed' ? (
                                        <p className="currPrice pb-3"></p>
                                    ) : (
                                        <p className="currPrice pb-3">
                                            Current Price
                                            <span> $ {setDecimalPoint(props.currentPrice, 2)}</span>
                                        </p>
                                    )}
                                    {/* {props.lotBidding} */}
                                </>
                            ) : (
                                <PrimaryButton
                                    cstmClass="w-100 mt-0"
                                    className="viewAuctionBtn"
                                    onClick={() =>
                                        history.push(encodeURI(`/auction/${props.id}?page=1`))
                                    }
                                >
                                    {props.label}
                                </PrimaryButton>
                            )}
                            {props.highbid_user_id &&
                                props.userDetails &&
                                props.highbid_user_id == props.userDetails.userdetails.id && (
                                    <h5 className="winningLabel">
                                        <b>You're Winning!</b>
                                    </h5>
                                )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Gridview
