import React from 'react'
import './static.css'

const TermsandConditions = () => {
    return (
        <div className="staticContainer container">
            <div className="mb-4">
                <h1 className="staticTitle">TERMS AND CONDITIONS OF AUCTION SALE</h1>
                <h4>
                    By Registering on Dalshire Auction Site You agree to the following Terms and
                    Conditions
                </h4>
            </div>
            <h3 className="staticSubtitle">Shipping & Handling:</h3>
            <p>
                Shipping & Handling Fees will be included on your invoice. All shipping arrangements
                will be handled by Dalshire. Once paid, Dalshire will usually ship within 2-3
                business days. Please understand that each item is individually picked, quality
                controlled, cleaned, packed and insured. There is cost to this activity along with
                the actual shipping charges incurred from whichever freight company is used. With
                most companies this overhead is usually covered in the price of the product. Because
                we run mostly no reserve auctions, we cannot cover this cost in the price of the
                product.{' '}
            </p>
            <h6>
                <strong>Domestic Fee Schedule:</strong>
            </h6>
            <ul>
                <li>
                    Small items, Cards, Coins, Jewelry and Baseballs:
                    <br />
                    $9.95 for each of the first two items, $4.95 for each item after that.
                </li>
                <li>
                    Watches:
                    <br />
                    $12.95 for each of the first two items, $4.95 for each item after that.{' '}
                </li>
                <li>
                    Unframed Cells and Prints: <br />
                    $18.95 for the first item, $4.95 for each item after that.{' '}
                </li>
                <li>
                    Helmets, Footballs, Basketballs, Baseball Bats, Shoes, Boxing Gloves and Loose
                    items: <br />
                    $24.95 for the first item, $4.95 for each item after that.{' '}
                </li>
                <li>
                    Custom Framed Pieces: <br />
                    $46.95 for each item.
                </li>
                <li>Items over $1,000.00 will ship 2-day air. </li>
            </ul>
            <h5 className="important mb-4">
                IMPORTANT- SOME ITEMS MAY BE SHIPPED TO YOU IN THE SAME MAILING PACKAGE. INSURANCE
                AGAINST DAMAGE INCLUDED IN ABOVE PRICES. ALL SHIPPING AND HANDLING PRICES, MAY BE
                AMENDED FROM TIME TO TIME. ALL ITEMS MUST BE SHIPPED BY DALSHIRE. WILL CALL IS NOT
                AVAILABLE. WE ARE NOT RESPONSIBLE FOR ADDITIONAL FEES INCLUDING BUT NOT LIMITED TO
                VAT DUTIES, BROKER FEES, AND OTHER IMPORT FEES.
            </h5>
            <h3 className="staticSubtitle">Additional Terms</h3>
            <ol>
                <li>
                    All items are sold AS IS, WHERE IS WITH ALL FAULTS. ALL SALES ARE FINAL - NO
                    REFUNDS AND NO EXCHANGES. WE AND THE CONSIGNOR, AS APPLICABLE, DISCLAIM ANY AND
                    ALL WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED
                    WARRANTY OF MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE. NO WARRANTIES
                    ARE MADE THAT ANY OF THE MERCHANDISE COMPLIES WITH ANY APPLICABLE GOVERNMENTAL
                    RULES, REGULATIONS OR ORDINANCES OF ANY KIND OR NATURE WHATSOEVER. All items are
                    available for inquiries prior to bidding. Written and oral descriptions are the
                    opinions of Dalshire and should in no way be construed as a guarantee of any
                    kind as to authenticity, age, condition, materials or any other feature of items
                    being sold. Dalshire believes to the best of its knowledge that the information
                    presented in any auction has come from reliable sources. However, such
                    information is presented solely for the convenience of the prospective
                    purchasers. Dalshire attempts to catalog every lot correctly and will attempt to
                    point out any defects but will not be responsible or liable for the correctness
                    of the catalog or other descriptions of the lot. It is the responsibility of
                    prospective bidders to examine all items in which they have an interest. By
                    placing a bid, in any capacity, you signify that you have examined the items as
                    fully as you desire and have had the opportunity to ask questions and receive
                    answers from Dalshire that you deem adequate. If you require absolute certainty
                    in all areas of authenticity, and the results of your evaluation leave
                    uncertainty in your mind, do not bid on the lot in question.
                </li>
                <li>
                    Neither Dalshire nor the consignor, as applicable, make any warranties or
                    representations of any kind or nature with respect to property or its value, and
                    in no event shall be responsible for the correctness of description,
                    genuineness, attribution, provenance, authenticity, authorship, and
                    completeness, condition of property or estimate of value. Any appraisals,
                    Estimates (as defined herein) or evaluations provided are solely for the
                    convenience of the bidder and Dalshire makes no warranties of any kind relating
                    to them. Appraisals may include valuations which reflect the full, highest
                    retail price of the item in a specific geographic region. The terms Estimate or
                    Estimated Value (collectively "Estimate") as used herein may be an arbitrary
                    value and may fail to represent an actual resale value, insurance replacement
                    value, or cost of a similar good. Similarly, no appraisal value should be
                    considered to represent the actual resale value, insurance replacement value, or
                    cost of a similar good. No statement (oral or written) shall be deemed such a
                    warranty or representation, or any assumption of responsibility. In no
                    circumstance will a vastly different appraisal or estimate of any third-party
                    given on behalf of a purchaser in a Dalshire auction be grounds for a return,
                    cancellation or refund. All measurements given are approximate and within
                    industry standards and customs.
                </li>
                <li>
                    In no event shall Dalshire's liability for any breach, or act or omission exceed
                    the Purchase Price (as defined herein) actually paid by the buyer, and in no
                    event shall Dalshire have any liability under any circumstances for special,
                    indirect, incidental or consequential damages (including for loss of profits or
                    revenue, costs of obtaining alternative property, claims of customers of buyer
                    or otherwise), whether in contract, tort, negligence, strict liability, or
                    otherwise, arising out of, resulting from or in any way relating to the lot or
                    its purchase, sale, delivery or non-delivery, or the acts or omissions of
                    Dalshire.{' '}
                </li>
                <li>
                    The successful bidder is the highest bidder acknowledged by Dalshire. In the
                    event of any dispute between bidders, or in the event of doubt on Dalshire's
                    part as to the validity of any bid, Dalshire will have the final discretion
                    either to determine the successful bidder or to re-offer and resell the article
                    in dispute. If any dispute arises after the sale, Dalshire's sale record is
                    conclusive. While Dalshire makes every effort to guarantee accuracy, in the
                    event of a mistake on the part of auctioneer, and a lot is accidentally declared
                    sold by mistake of the auctioneer, Dalshire reserves the right to cancel the
                    sale and relist the lot in its sole discretion. Bids may be received by Dalshire
                    through the internet, telephone, facsimile, or e-mail. Bidding through a
                    telephone, facsimile, or e-mail are only available to pre-qualified dealers or
                    resellers.
                </li>
                <li>
                    The bidder assumes full responsibility for items at the fall of the hammer (or
                    expiration of time in an online auction). A bid entered by mistake on the part
                    of a bidder is NOT grounds for a cancellation of the bid. Each bidder is
                    responsible for his or her own account and bids. By allowing access to another
                    who enters a bid without your knowledge will NOT be grounds for a rescission of
                    the bid(s). Therefore, any bid made on your account will bind you to the bid in
                    accordance with these terms. If an entity places a bid on a lot, then the person
                    executing such bid on behalf of the entity hereby agrees to personally guarantee
                    payment for any successful bid.
                </li>
                <li>
                    All bids are subject to (i) a non-refundable 20% buyer's premium which shall be
                    added to a successfully placed bid upon sale of each lot, (ii) any applicable
                    local sales tax, and (iii) shipping and handling costs that are more fully
                    described in the section entitled "Shipping and Handling" (collectively, the
                    "Purchase Price"). For all residents of Texas or shipments to Texas, applicable
                    sales tax will be added to your invoice. Any bidder that represents a purchaser
                    is personally and individually responsible for any obligations of the purchaser
                    set forth in these terms and conditions. All purchasers purchasing pursuant to a
                    valid resale license will need to provide appropriate documentation for removal
                    of state sales tax.{' '}
                </li>
                <li>
                    Bidder's bid at all times represents an irrevocable offer by bidder to enter
                    into a binding contract to purchase the lot in accordance with the specific
                    provisions of the listing and these general terms and conditions, as applicable,
                    even if a higher bid was received by Dalshire. In the placing of any bid the
                    bidder understands and agrees that the bid, once accepted by Dalshire, will be
                    the winning bid if there is no higher bid by another bidder and if any reserve
                    or reduced reserve on the lot is exceeded by the bid. All bids are binding,
                    irrevocable and non-cancelable by bidder. Dalshire has final discretion on sale
                    of a lot. The decisions of Dalshire are final. In the unlikely event that we
                    lose our internet connection, Dalshire reserves the right in its sole and
                    absolute discretion to cancel the remainder of the auction.{' '}
                </li>
                <li>
                    PAYMENT: IF IN ITS SOLE DISCRETION DALSHIRE AGREES TO REFUND A PURCHASE YOU WILL
                    BE CHARGED A 15% RESTOCKING FEE OF THE HAMMER PRICE AND YOU WILL STILL BE
                    RESPONSIBLE FOR SHIPPING AND HANDLING FEES AS WELL AS RETURN SHIPPING. THE
                    PREVIOUSLY PAID BUYER'S PREMIUM IS NOT REFUNDABLE. PLEASE BID RESPONSIBLY AND
                    DIRECT ANY QUESTIONS YOU MAY HAVE PRIOR TO PLACING A BID.{' '}
                </li>
                <li>
                    LIQUIDATED DAMAGES: IN THE EVENT THAT YOU BID AND ARE DEEMED THE WINNER OF AN
                    ITEM BY DALSHIRE, BUT FAIL TO MAKE PAYMENT IN ACCORDANCE WITH THE INVOICE SENT
                    TO YOU OR CLAIM A DISPUTE WITH ANY THIRD-PARTY PAYMENT INTERMEDIARY, SUCH AS A
                    CREDIT CARD COMPANY, YOU WILL BE ASSESSED A CHARGE OF 15% OF THE HAMMER PRICE AS
                    LIQUIDATED DAMAGES. BY BIDDING ON ANY SUCH LOT YOU HEREBY AGREE TO THE PAYMENT
                    OF SUCH LIQUIDATED DAMAGES AS A RESULT OF THE IRREPARABLE HARM THAT WILL BE
                    SUSTAINED BY DALSHIRE. FURTHERMORE, IN THE EVENT THAT A WINNING BIDDER FAILS TO
                    MAKE ANY PAYMENTS UNDER THESE TERMS AND CONDITIONS, INCLUDING THE LIQUIDATED
                    DAMAGES DESCRIBED HEREIN, WE RESERVE THE RIGHT TO REPORT SUCH NONPAYMENT TO
                    CREDIT AND COLLECTION AGENCIES IN OUR SOLE DISCRETION.{' '}
                </li>
                <li>
                    Dalshire reserves the right to reject any bid at any time and at his sole
                    discretion. Dalshire additionally reserves the right in its sole and unlimited
                    discretion at any time prior to, during, or even after the close of the auction
                    to exclude any person or individual bid and to award any lot to the highest
                    accepted bid. Dalshire reserves the right to withdraw any lot at any time
                    before, during, or after the auction without liability.
                </li>
                <li>
                    On the fall of the auctioneer's hammer (or expiration of time in an online
                    auction), ownership of the offered lot will pass to the highest bidder
                    acknowledged by the auctioneer, subject to fulfillment by such bidder, of all
                    the conditions set forth herein, and such bidder thereupon, a) assumes full risk
                    and responsibility thereof, but not limited to, insurance, fire, theft, removal
                    and storage or damage from any and all causes, and b) will pay the full Purchase
                    Price thereof or such part as we may require. In addition to other remedies
                    available to us by law, we reserve the right to impose a late charge of 1.5% per
                    month of the total Purchase Price if payment is not made in accordance with the
                    conditions set forth herein. If any applicable conditions herein are not
                    complied with by the purchaser, in addition to other remedies available to us
                    and the consignor by law, including, without limitation, the right to hold the
                    purchaser liable for the total Purchase Price, we at our option may, a) cancel
                    the sale, retaining all payments made by the purchaser, b) resell the property
                    at public auction with or without reserve, and the purchaser will be liable for
                    any deficiency costs including handling charges, the expenses of both sales, our
                    commissions on both sales at our regular rates, reasonable attorney's fees,
                    incidental damages, and all other charges due hereunder, c) reject future bids
                    from the buyer or render such bids subject to payment of a deposit, d) charge
                    interest at 18% per annum from the date payment became due until the date the
                    Purchase Price is received in cleared funds, e) subject to notification of the
                    buyer, exercise a lien over any of the buyer's property which is in the
                    possession of Dalshire and no earlier than 30 days from the date of such notice,
                    arrange the sale of such property and apply the proceeds to the amount owed to
                    Dalshire or any of our affiliated companies after the deduction from sale
                    proceeds of our standard commission rates and sale-related expenses, f) commence
                    legal proceedings to recover the Purchase Price, together with interest and the
                    costs of such proceedings, g) set off the outstanding amount remaining unpaid by
                    the buyer against any amounts which Dalshire, or our affiliated companies, may
                    owe the buyer in any other transactions, or h) take such other action as we deem
                    necessary or appropriate. In the event that such a buyer pays a portion of the
                    Purchase Price for any or all lots purchased, Dalshire shall apply the payment
                    received to such lot or lots that Dalshire, in its sole discretion deems
                    appropriate. In the case of default, purchaser shall be liable for legal fees,
                    costs and expenses associated with an attempted collection of payment from
                    purchaser. In addition, a defaulting purchaser will be deemed to have granted us
                    a security interest in, and we may retain as collateral security for such
                    purchaser's obligations to us, any property in our possession owned by such
                    purchaser. Furthermore, in the event that a purchaser fails to make any payments
                    under these terms and conditions, a 15% charge as liquidated damages will be
                    administered as described in Section 9. We hereby reserve the right to report
                    such non-payment to credit and collection agencies in our sole discretion.{' '}
                </li>
                <li>
                    Dalshire, and any consignors, reserve the right to bid on any lot(s). Unless
                    explicitly stated otherwise, all lots are subject to a reserve price that shall
                    be hidden from all bidders. Dalshire shall act to protect the reserve by bidding
                    in the auction process if needed. Dalshire may open bidding on any lot below the
                    reserve by placing its own bid. Dalshire may continue to bid on behalf of itself
                    up to the amount of the reserve, either by placing consecutive bids or by
                    placing bids in response to other bidders.
                </li>
                <li>
                    If any dispute occurs between a bidder and Dalshire that cannot be resolved,
                    then the parties agree to the rules, regulations and procedures of the dispute
                    resolution described herein and agree to the following procedures for resolution
                    of the dispute: If either party alleges that the other party is in default under
                    this agreement, then the dispute or allegation shall be submitted to binding
                    arbitration in the State of Texas. Dalshire shall select a neutral arbitrator.
                    The arbitration process shall be initiated by either party requesting
                    arbitration which must be served upon the other party by certified mail. Failure
                    to participate in the arbitration is a default. In the event that an arbitration
                    procedure is initiated by request of a bidder, the bidder shall bear the
                    financial burden of fees to be paid to an arbitrator to that maximum amount
                    available by law. Each party shall simply present their case (limited to a
                    maximum of one hour for each party) to the arbitrator. By bidding on any lot,
                    the bidder hereby agrees and acknowledges that it is completely waiving its
                    rights to have the dispute heard in a court of law. The bidder agrees that the
                    arbitrator shall have complete jurisdiction over the dispute and can issue an
                    award to the prevailing party. Arbitration fees shall be paid by the party
                    prevailing in the dispute or as seen fit by the arbitrator. Any award determined
                    by the arbitrator shall be binding and the prevailing party shall be awarded
                    full reimbursement of its actual paid fees in connection with the dispute. By
                    participating in an Dalshire auction, bidder specifically agrees to the dispute
                    resolution specified in the foregoing paragraph as bidder's SOLE means to
                    resolve any and all disputes which may arise with Dalshire. By doing so bidder
                    specifically agrees that bidder will not file a dispute of any kind with an
                    online venue, a credit card, or any other third-parties. Bidder specifically
                    waives the right to initiating a credit card chargeback dispute on any basis of
                    claim and agrees that bidder will pay Dalshire a minimum of $300 plus hourly
                    charges and costs for any such chargeback dispute opened, due to the severe
                    administrative burden of such disputes and their direct violation of the dispute
                    provisions above. In the event that Bidder violates this dispute resolution
                    provisions, in consideration of Dalshire's approval of the application of the
                    bidder to bid in Dalshire's auction, bidder confesses judgment to fraud having
                    been committed by bidder against Dalshire and further agrees that bidder is
                    liable to Dalshire for all damages, losses and costs, including time charges,
                    resulting.{' '}
                </li>
                <li>
                    The bidder hereby waives, releases, remises, acquits, and forever discharges
                    Dalshire and any consignor, and shall indemnify and hold them harmless against
                    any claims, actions, cause of action, demands, rights, damages, costs, expenses,
                    or compensation whatsoever, direct or indirect, known or unknown, foreseen or
                    unforeseen, which the bidder now has or which may arise in the future on the
                    account of or in any way growing out of or connected with any defects, latent or
                    otherwise, or the physical condition of any items or lots purchased or any law
                    or regulation applicable thereto. Dalshire or consignor shall provide notice to
                    bidder promptly of any such claim, suit, or proceeding.{' '}
                </li>
                <li>
                    If any provision of these terms and conditions or of any listing be void,
                    unlawful or unenforceable under applicable law, that provision shall be modified
                    to the minimum extent possible to comply with applicable law, or if modification
                    is not possible, deleted and severed from the remaining provisions. Any such
                    modification, deletion or severing shall not affect the enforceability or
                    validity of any of the remaining provisions.{' '}
                </li>
                <li>
                    These terms and conditions, the contents of any listing and the contractual
                    relationship between Dalshire and any bidder shall be interpreted under and
                    governed by the laws of the State of Texas. **IMPORTANT - PLEASE READ** AS YOU
                    HAVE ACKNOWLEDGED BY AGREEING TO THE TERMS AND CONDITIONS OF THIS AUCTION, THERE
                    ARE NO REFUNDS OR EXCHANGES. IN THE EVENT THAT YOU REQUEST AND ARE GRANTED BY
                    DALSHIRE, A RETURN OR REFUND, YOU AGREE THAT YOU ARE STILL RESPONSIBLE FOR
                    PAYING A 15% RESTOCKING FEE BASED ON THE HAMMER PRICE OF EACH ITEM THAT IS
                    RETURNED, LESS APPLICABLE TAXES, PLUS THE ENTIRE BUYER'S PREMIUM FOR EACH ITEM
                    AS WELL AS ALL OF THE SHIPPING/HANDLING CHARGES. ACCORDINGLY, THE RESTOCKING
                    FEE, BUYER'S PREMIUM, AND ANY SHIPPING AND HANDLING COSTS ARE NEVER ELIGIBLE FOR
                    A RETURN OR REFUND. IN THE EVENT THAT YOU REFUSE PAYMENT FOR A WINNING BID YOU
                    WILL BE SUBJECT TO A 15% LIQUIDATED DAMAGES CHARGE OF THE HAMMER PRICE AS MORE
                    FULLY DESCRIBED IN SECTION 9 HEREIN.
                </li>
            </ol>
            <p>
                Regarding Appraised Jewelry, Watches and Loose Stones. Dalshire may send jewelry,
                watches, accessories and loose stones out to a third-party for appraisals. All such
                lots are sold AS IS and may have been worn, been previously repaired, altered,
                customized (including having been modified with after-market stones or had the bezel
                changed in its entirety on certain jewelry or watches), or been embellished. For
                purposes of our auctions, the terms custom, modified, or after-market may be used
                interchangeably. Such wear, repair or changes may display varying levels of
                evidence. The bidder hereby acknowledges that the absence of any reference to the
                condition of a lot does not imply the absence of wear, repairs, customization, or
                defects. While Dalshire makes every effort to call to the attention of bidders the
                use of after-market parts in jewelry and watches sold, the use of such parts or
                stones shall not constitute a warranty or representation that the items are original
                in their entirety. These modifications, customizations, and alterations may impair
                the ability of the original manufacturer to repair such item or certify the item as
                authentic All descriptions and statements related to such lots, including
                measurement, authorship, source or origin, or other aspects are qualified opines and
                do not constitute a warranty or representation, and are provided for identification
                purposes only. Not all Jewelry, watches or loose stones are appraised, and the
                appraisals are provided for bidders only in certain instances at the discretion of
                Dalshire. Our appraisals are conducted by independent third-party appraisal firms.
                Such firms may be certified GIA Gemologists or have education in the industry or
                alternative certifications. Appraisals should be used for verification of gemstone
                and/or metal weight, size, and grade only, not actual value for resale or insurance
                purposes. Appraisal value should be not considered for actual value, as price varies
                greatly from retail locations and other merchants in different geographic locations.
                All Items auctioned by Dalshire should not be purchased with a view or expectation
                toward resale for appraisal price given, or to make a profit. The ultimate value for
                which an item can be resold may vary drastically from the appraisals listed by
                Dalshire. All dimensions and weights of such lots are approximate and are consistent
                with standards and practices in the industry. Condition reports are provided as a
                service to prospective bidders. Bidders should note that such descriptions are not
                warranties and that watches may need general service, change of battery or further
                repair work for which the buyer is solely responsible. Certain watch bands from
                protected species of animals (i.e. alligator, crocodile) and items made of ivory and
                tortoise may be subject to restrictions in certain countries.
            </p>
            <p>
                Regarding Memorabilia Items - All autographed memorabilia comes with a Certificate
                of Authenticity. Dalshire is not responsible for the opinions of any of the
                third-party authenticators. You should in no way rely exclusively on such
                third-party opinions without conducting your own due diligence prior to bidding.{' '}
            </p>
            <p>
                Regarding Coins & Currency - No material certified by third parties may be returned
                to Dalshire because of a possible difference of opinion with respect to the grade
                offered by such third-party organization, dealer, or service. No guarantee of grade
                is offered for uncertified lots sold and then subsequently submitted to a
                third-party grading service. There are absolutely no exceptions to this policy. Any
                coins sold referencing a third-party grading service as sold AS IS without any
                express or implied warranty. Notes sold referencing a third-party grading service
                are sold AS IS without any express or implied warranty. Grading, condition or other
                attributes of any lot may have a material effect on its value, and the opinion of
                others, including third-party grading services may differ with that of Dalshire.
                Dalshire shall not be bound by any prior or subsequent opinion, determination, or
                certification by any grading service. Bidder specifically waives any claim to right
                of return of any item because of the opinion, determination, or certification, or
                lack thereof, by any grading services. Third-party graded notes are not returnable
                for any reason whatsoever. Due to changing grading standards over time, differing
                interpretations and to possible mishandling of items by subsequent owners, Dalshire
                reserves the right to grade items differently than shown on certificates from any
                grading service that accompany the items. Dalshire also reserves the right to grade
                items differently than the grades shown in the prior catalog should such items be
                reconsigned or reoffered in any future auction. Although consensus grading is
                employed by most grading services, it should be noted as aforesaid that grading is
                not an exact science and various third-party grading services, or even different
                employees from the same grading service may grade the same lot differently. Bidder
                fully understands and is aware of such potential inconsistencies and is bidding with
                such knowledge. Certification does not guarantee protection against the normal risks
                associated with potentially volatile markets. The degree of liquidity for certified
                coins and collectibles will vary according to general market conditions and the
                particular lot involved. For some lots there may be no active market at all at
                certain points in time. Bidders are advised that certain types of plastic may react
                with a coin's metal or transfer plasticizer to notes and may cause damage. Caution
                should be used to avoid storage in materials that are not inert. Regarding Stamps
                and Philatelic Items No stamps or philatelic items may be returned because of a
                possible differences of opinion with respect to the grade offered by any third-party
                organization, dealer or service. No guarantee of grade is offered for ungraded items
                sold and subsequently submitted to a third-party grading service. There are
                absolutely no exceptions to this policy. Multiple stamp or philatelic lots,
                including sets, collections, large lots, and small group lots, whether certified or
                not, are sold AS IS without warranty of any kind. There are absolutely no exceptions
                to this policy. Any stamps and philatelic items referencing a third-party
                certification or grading service are sold AS IS. Bidder shall solely rely upon
                warranties of the authentication provider issuing the certificate or opinion.
                Grading, condition or other attributes of any lot may have a material effect on its
                value, and the opinion of others, including third-party grading services may differ
                with that of Dalshire. Dalshire shall not be bound by any prior or subsequent
                opinion, determination or certification by any grading service. Bidder specifically
                waives any claim to right of return of any item because of the opinion,
                determination, or certification, or lack thereof, by any grading service. In the
                event Dalshire cannot deliver the lot or subsequently it is established that the lot
                lacks title, or other transfer or condition issues is claimed, Dalshire's liability
                shall be limited to the rescission of sale and refund of the Purchase Price; in no
                case shall Dalshire's maximum liability exceed the high bid on the lot, which shall
                be deemed for all purposes the value of the lot. After one year has elapsed from the
                close of the sale, Dalshire's maximum liability shall be limited to any commissions
                and fees Dalshire earned on the lot. The grading of stamps and philatelic items is a
                matter of opinion, an art and not a science, and therefore the opinion rendered by
                Dalshire or any third-party grading service may not agree with the opinion of others
                (including trained experts)
            </p>
            <p>
                Please email us at <a href="mailto:johnh@dalshire.com">johnh@dalshire.com</a> with
                any specific questions.{' '}
            </p>
            <p>Payment All payments for lots won are due Immediately upon receiving your invoice</p>
        </div>
    )
}

export default TermsandConditions
