import React, { useState, useEffect } from 'react'
import Dashboard from '../Dashboard/Dashboard'
import CustomSelect from '../../components/atoms/Inputs/CustomSelect'
import Favouritecard from '../Favourites/Favouritecard'
import axios from '../../Utils/Utility/axiosconfig'
import Swal from 'sweetalert2'
import JwtDecode from 'jwt-decode'
import isAuthenticate from '../../Utils/Utility/auth'
import Pagination from '@material-ui/lab/Pagination'
import { getPaginationCount } from '../../Utils/Utility/index'
import { _, isEmpty } from 'lodash'
import { getnearteImages } from '../../Utils/Utility'

import './Favourites.css'

const req = {
    sh_limit: 10,
    page: 1,
    orderby: 1,
    category: '',
}

const FavouritesB = (props) => {
    const [favlist, setfavlist] = useState({})
    const [Data, setData] = useState([])

    const [favparams, setfavparams] = useState({
        sh_limit: 6,
        page: 1,
        status: 'watchlist',
        ftpImage: true,
    })

    const userFavourite = async () => {
        const res = await axios.post(`mobileapi/buyerDashboard`, favparams)
        try {
            if (res.data.success === 'yes') {
                let favList = getnearteImages(res.data.results)
                setfavlist({ ...favlist, fav: favList, pgcount: res.data.total_pagecnt })
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        userFavourite()
    }, [favparams])

    const favorite = async (productId) => {
        try {
            await axios.post('mobileapi/removeFwatchlist', {
                id: productId,
                userid: JwtDecode(isAuthenticate()).id,
            })
            Swal.fire({
                title: 'Product Removed from Favorites Successfully',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
            userFavourite()
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <Dashboard>
                <div>
                    <div className="Favourite-Header">
                        {/* <CustomSelect variant="standard" label="Ending Soon" /> */}
                    </div>
                    <div className="Favourites-cards">
                        {favlist.fav &&
                            favlist.fav.map((data, index) => (
                                <Favouritecard
                                    data={data}
                                    index={index}
                                    dummy="dummy"
                                    favour={favorite}
                                />
                            ))}
                        {isEmpty(favlist.fav) ? (
                            <div className="card card-body text-center mt-4 mx-3">
                                <b>No Favorites found</b>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="fav-pagination">
                        <Pagination
                            onChange={(e, value) => {
                                setfavparams({ ...favparams, page: value })
                            }}
                            count={favlist.pgcount ? getPaginationCount(favlist.pgcount, 6) : '1'}
                        />
                    </div>
                </div>
            </Dashboard>
        </div>
    )
}

export default FavouritesB
