import React from 'react'
import SecondaryButton from '../../components/atoms/SecondaryButton/index'
import { isEmpty, findIndex } from 'lodash'
import Timer from '../../components/molecules/Timer/index'
import { useHistory } from 'react-router-dom'
import Pagination from '@material-ui/lab/Pagination'
import { getPaginationCount } from '../../Utils/Utility'

import './LostAuction.css'

const LostAuction = (props) => {
    const history = useHistory()

    return (
        <div>
            <div className="LostAuction-Table">
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Auction Name</th>
                            <th scope="col">Title</th>
                            <th scope="col">Sold For</th>
                            {/* <th scope="col">Time</th> */}
                            <th scope="col">Date Ended</th>
                            {/* <th scope="col">Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(props.data.results) ? (
                            props.data.results.map((data, index) => (
                                <tr>
                                    <th scope="row" className="frstHdrRow">
                                        {data.sku}
                                    </th>
                                    <td>{data.auctionlot_title}</td>
                                    <td>
                                        {' '}
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: data.title,
                                            }}
                                        ></p>
                                    </td>
                                    <td>
                                        {data.market_status == 'sold' ? '$ ' + data.wprice : '-'}
                                    </td>
                                    <td>{data.dateclosing}</td>
                                    {/* <td>
                                        <SecondaryButton
                                            label="View"
                                            onClick={() => {
                                                history.push(
                                                    `/auction/${data.lotof}/${data.auctionlot_title}`,
                                                )
                                            }}
                                        />
                                    </td> */}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colspan="12" className="border-none">
                                    <div className="card card-body text-center mt-2">
                                        <b>No Auction Found</b>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="lost-pgn">
                <Pagination
                    onChange={(e, value) => {
                        props.paramsupd(value)
                    }}
                    count={
                        props.data.total_pagecnt
                            ? getPaginationCount(props.data.total_pagecnt, 10)
                            : '1'
                    }
                />
            </div>
        </div>
    )
}

export default LostAuction
