import React from 'react'
import FavoriteCheckbox from '../../components/atoms/FavoriteCheckbox/index'

import Gridview from '../../components/molecules/ProductCard/GridView'
import './Favouritecard.css'

const Favouritecard = (props) => {
    return (
        <div className="Favflex">
            <div className="fawWrap">
                <div className="Favbox-Favflex">
                    <FavoriteCheckbox
                        watchlisted={props.data.watchlisted}
                        project_id={props.data.id}
                        dummy="dummy"
                        onchangedata={props.favour}
                        index={props.index}
                        favIdChild={props.data.id}
                    />
                </div>
                <div className="grdViewFav">
                    <Gridview
                        imgSrc={
                            props.data.avatar_vc
                                ? `${process.env.REACT_APP_PRODUCT_IMG + props.data.avatar_vc}`
                                : '/images/pdtimg.png'
                        }
                        Name={props.data.title}
                        // descrp={props.data.category_id}
                        lotId={props.data.id}
                        label={'View Auction'}
                        id={props.data.lotof}
                        startTime={props.data.date_added}
                        endTime={props.data.date_closed}
                    />
                </div>
            </div>
        </div>
    )
}

export default Favouritecard
