import React, { useEffect, useRef, useState } from 'react'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import CheckBox from '../../components/atoms/CheckBox/index'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { Formik } from 'formik'
import * as yup from 'yup'
import axiosInstance from '../../Utils/Utility/axiosconfig'
import { Link, useHistory, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import JwtDecode from 'jwt-decode'
import isAuthenticate from '../../Utils/Utility/auth'
import './Login.css'
import { IconButton, InputAdornment } from '@material-ui/core'

const Login = (props) => {
    const history = useHistory()
    const { login_email } = useParams()

    useEffect(() => {
        if (isAuthenticate()) {
            history.push('/')
        }
        console.log('login props', props)
    }, [])

    useEffect(() => {
        console.log(passwords)
    })

    useEffect(() => {
        console.log('login_email', login_email)
        if (
            window.location.pathname.includes('/autologin') ||
            window.location.pathname.includes('/auto-login')
        ) {
            // setLoad(true)
            let username = login_email
            let password = ''
            let autologin = 1
            let encrypt = window.location.pathname.includes('/auto-login') ? 1 : 0
            let loginValues = {
                username: username,
                password: password,
                autologin: autologin,
                encrypt: encrypt,
                is_auctionio: 1,
                community: 'auction_io',
            }
            console.log('loginvalues', loginValues)
            autoLogin(loginValues)
        }
    }, [])

    const autoLogin = async (data) => {
        const res = await axiosInstance.post('mobileapi/login', data)

        if (res.data.status === 'no') {
            return Swal.fire({ icon: 'error', title: res.data.message })
        } else {
            localStorage.setItem('userToken', res.data.data)
            Swal.fire({
                icon: 'success',
                title: 'Signed in successfully',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })

            const userData = JwtDecode(res.data.data)

            localStorage.setItem('userData', JSON.stringify(userData))
            history.push(props.location.state ? props.location.state : '/')
        }
    }

    const [username, setUsername] = useState('')
    const [passwords, setPassword] = useState('')
    const [remember, setremember] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const formRef = useRef()

    const rememberchange = (e) => {
        if (e.target.checked === true) {
            const details = {
                id: formRef.current.values.email,
                pass: formRef.current.values.password,
            }
            localStorage.setItem('details', JSON.stringify(details))
            setremember(true)
        } else {
            localStorage.removeItem('details')
            setremember(false)
        }
    }

    useEffect(() => {
        const usercred = JSON.parse(localStorage.getItem('details'))
        console.log(usercred)
        if (usercred !== null) {
            setUsername(usercred.id)
            setPassword(usercred.pass)
            setremember(true)
        }
    }, [])

    const signinSchema = yup.object({
        email: yup.string().email('Invalid email format').required('Required!'),
        password: yup.string().required('Required!'),
    })

    const userlogin = async (data) => {
        try {
            const { email, password } = data

            const formData = {
                username: email,
                password: password,
                is_auctionio: 1,
                community: 'auction_io',
            }

            const res = await axiosInstance.post('mobileapi/login', formData)

            if (res.data.status === 'no') {
                return Swal.fire({ icon: 'error', title: res.data.message })
            } else {
                localStorage.setItem('userToken', res.data.data)
                Swal.fire({
                    icon: 'success',
                    title: 'Signed in successfully',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })

                const userData = JwtDecode(res.data.data)

                localStorage.setItem('userData', JSON.stringify(userData))
                history.push(props.location.state ? props.location.state : '/')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    return (
        <div>
            <Formik
                initialValues={{
                    email: remember ? username : username,
                    password: remember ? passwords : passwords,
                }}
                validationSchema={signinSchema}
                onSubmit={(values) => userlogin(values)}
                innerRef={formRef}
                enableReinitialize
            >
                {({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="Login">
                            <div className="coinImg">
                                <img src="/images/loginback.jpg" alt="coin" />
                            </div>
                            <div className="loginField custom-container">
                                <div className="logo">
                                    <Link to="/">
                                        <img
                                            src="/images/logo.jpg"
                                            className="cmlftogo sd"
                                            alt="logo"
                                        />
                                    </Link>
                                </div>
                                <div className="loginText">
                                    <h1>Login</h1>
                                    <h3 className="welcomeText">Welcome Back</h3>
                                </div>
                                <div className="loginInput">
                                    <CustomInput
                                        label="Email"
                                        variant="standard"
                                        value={values.email}
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.email && touched.email ? (
                                        <div className="invalidInp-1 errorTxt">{errors.email}</div>
                                    ) : null}
                                    <CustomInput
                                        label="Password"
                                        name="password"
                                        variant="standard"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {values.showPassword ? (
                                                        <span className="material-icons">
                                                            visibility_off
                                                        </span>
                                                    ) : (
                                                        <span className="material-icons">
                                                            visibility
                                                        </span>
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {errors.password && touched.password ? (
                                        <div className="invalidInp-2 errorTxt">
                                            {errors.password}
                                        </div>
                                    ) : null}
                                </div>

                                <div className="endInput">
                                    <CheckBox
                                        label="Remember me"
                                        checked={remember}
                                        onchange={rememberchange}
                                    />
                                    <div className="forgotPassword">
                                        <Link to="/resetpassword">Forgot Password?</Link>
                                    </div>
                                </div>
                                <div className="loginButton">
                                    <PrimaryButton label="Login" type="submit" />
                                </div>
                                <div className="bottomText">
                                    <Link to="/signup">Create An Account</Link>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default Login
