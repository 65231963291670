import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import CustomInput from '../../atoms/Inputs/CustomInput'
import PrimaryButton from '../../atoms/PrimaryButton'
import RadioBox from '../../atoms/RadioBox'
import axiosmethod from 'axios'
import payAmountStripe from '../../../Utils/API/payamountstripe'
import axiosInstance from '../../../Utils/Utility/axiosconfig'
import './CheckoutPayment.css'
import Swal from 'sweetalert2'
import listpayment from '../../../Utils/API/selectcardpayment'
import getShippingAmount from '../../../Utils/API/shipstation'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { currencyFormat } from '../../../common/components'
import CustomSelect from '../../atoms/Inputs/CustomSelect'
import Popup from '../../../components/organisms/Popup/index'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Country from '../../../views/Signup/Country.json'
import State from '../../../views/Signup/State.json'
import cities from '../../../views/Signup/cities.json'
import SimpleSelect from '../../../components/atoms/DropDown/index'
import SimpleSelect1 from '../../../components/atoms/DropDown/Dropdown1'
import SimpleSelect2 from '../../../components/atoms/DropDown/Dropdown2'

const CheckoutPayment = (props) => {
    const history = useHistory()
    console.log('checkout propes --------', props)
    const [radvalue, setValue] = useState('nocard')
    const [selectedCard, setSelectedCard] = useState([])
    const [cardNumber, setCardNumber] = useState('')
    const [expMonth, setExpMonth] = useState('')
    const [expYear, setExpYear] = useState('')
    const [cardCvv, setCardCvv] = useState('')
    const [cardName, setCardName] = useState('')
    const [oldCard, setOldCard] = useState(0)
    const [cartTotal, setCartTotal] = useState(0)
    const [shipAmt, setShipAmt] = useState(0)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({})
    const [selectAddress, setSelectAddress] = useState(0)
    const [get_all_address, setAlladress] = useState([])
    const [addAddressOpen, setAddAddressOpen] = useState(false)
    const [Country_code, setCountry] = useState(231)
    const [addressLoading, setAddressLoading] = useState(false)
    const [Id, setID] = useState('')
    const [sId, setStateId] = useState('')
    const [Countrycode, setCountrycode] = useState('')
    const [cardAddress1, setCardAddress1] = useState('')
    const [cardAddress2, setCardAddress2] = useState('')
    const [cardCountry, setCardCountry] = useState('')
    const [cardState, setCardState] = useState('')
    const [cardCity, setCardCity] = useState('')
    const [cardZip, setCardZip] = useState('')

    const validationArray = Yup.object({
        // first_name: Yup.string()
        //     .trim()
        //     .matches(/^[0-9a-zA-Z]*$/g, 'Special characters not allowed')
        //     .max(250, 'Maximum 250 characters')
        //     .required('Required'),
        // last_name: Yup.string()
        //     .trim()
        //     .matches(/^[0-9a-zA-Z]*$/g, 'Special characters not allowed')
        //     .max(250, 'Maximum 250 characters')
        //     .required('Required'),
        // email: Yup.string()
        //     .email('Invalid email format')
        //     .max(250, 'Maximum 250 characters')
        //     .required('Required'),
        // phone: Yup.string()
        //     .trim()
        //     .matches(/^[0-9]*$/g, 'Only numbers are allowed')
        //     .min(10, 'Minimum 10 characters')
        //     .required('Required'),
        country: Yup.string().trim().required('Required'),
        state: Yup.string().trim().required('Required'),
        address: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Special characters not allowed')
            .max(250, 'Maximum 250 characters')
            .required('Required'),
        city: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z ]*$/g, 'Only characters allowed')
            .required('Required'),
        zipcode: Yup.string()
            .required('Required')
            .min(5, 'Minimum 5 digits required')
            .max(6, 'Maximum 6 digits only allowed'),
    })

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            address: '',
            address1: '',
            country: 231,
            city: '',
            zipcode: '',
            state: 3919,
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            setAddressLoading(true)
            const userData = JSON.parse(localStorage.getItem('userData'))
            values.first_name = userData.first_name
            values.last_name = userData.last_name
            values.email = userData.email
            values.phone = userData.phone
            values.country = Country.filter((x) => parseInt(x.id) == parseInt(Country_code))[0].name
            values.state = State.filter(
                (x) => parseInt(x.id) == parseInt(formik.values.state),
            )[0].name
            const { data } = await axiosInstance.post('mobileapi/add_shipping_address', values)

            try {
                if (data.success) {
                    allSvaedAdress()
                    setAddAddressOpen(false)
                    Swal.fire({
                        title: 'Address Added Successfully!!!',
                        icon: 'success',
                        timer: 1500,
                        showConfirmButton: false,
                        position: 'center',
                    })
                    setAddressLoading(false)
                    formik.resetForm()
                } else {
                    setAddressLoading(false)
                }
            } catch (err) {
                console.log(err)
            }
        },
    })

    const listedcardPayment = async (data) => {
        const det = await listpayment(data, props.inv)
        console.log(det, 'resres')
        if (det === true) {
            Swal.fire({
                title: 'Successfully paid',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
            history.push({
                pathname: '/dashboard',
                state: {
                    value: 1,
                },
            })
        } else {
            Swal.fire({
                title: 'Try Again later.',
                icon: 'error',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
        }
    }

    useEffect(() => {
        console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn')
        allsavedCards()
        allSvaedAdress()
    }, [])

    const shippingAmount = async () => {
        let params = {
            mode: process.env.REACT_APP_PAYMENTMODE, //process.env.REACT_APP_PAYMENT_MODE,
            fromzipcode: '98684',
            zipcode: props.profile.zip,
            weight: 10,
            height: 10,
        }
        const res = await getShippingAmount(params)
        console.log('getShippingAmount', params, res)
        setShipAmt(res.data.ship_rate)
        setLoading(false)
    }

    const allsavedCards = async () => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const cardID = userData.card_paymentid
        let body = {
            account_id: process.env.REACT_APP_AUCTIONPAYKEY,
            customer_id: cardID,
        }
        const { data } = await axiosmethod.post(
            `${process.env.REACT_APP_PAYMENT_API_URL}/api/customer/source/list`,
            body,
        )

        try {
            if (data.status === 'success') {
                console.log('data.responseData.data ', data.data.responseData.data)
                data.data.responseData.data && setSelectedCard(data.data.responseData.data)
                // data.selectedCard && setSelected(data.selectedCard)
            } else {
                setSelectedCard([])
            }
        } catch (err) {
            console.log(err)
        }
    }
    const allSvaedAdress = async () => {
        const { data } = await axiosInstance.get('mobileapi/get_all_shipping_address', {})

        try {
            // console
            if (data.success) {
                setAlladress(data.result.length ? data.result : [])
            } else {
                setAlladress([])
            }
        } catch (err) {
            console.log(err)
        }
    }
    // const savedCards = [
    //     { id: 1, cardNumber: '4132 1231 3212 2135', dateExp: '12/20', cardName: 'MasterCard' },
    //     { id: 2, cardNumber: '4132 1231 3212 2135', dateExp: '12/20', cardName: 'MasterCard' },
    // ]

    const handleChangeRadio = (data) => {
        console.log('event ==============', data)
        setValue(data.source)
    }
    const paynow = async () => {
        setLoading(true)
        const userData = JSON.parse(localStorage.getItem('userData'))
        var cardID = userData.card_paymentid ? userData.card_paymentid : ''
        const token = localStorage.getItem('userToken')
        if (parseInt(selectAddress) == 0) {
            setLoading(false)
            Swal.fire({
                title: 'Please Select Shipping Address',
                icon: 'error',
                timer: 1500,
                showConfirmButton: false,
                position: 'center',
            })
            return false
        }
        if (cardID == '') {
            var body_data = {
                account_id: process.env.REACT_APP_AUCTIONPAYKEY,
                name: userData.first_name,
                email: userData.email,
                description: 'Test Description',
            }
            const { data } = await axios.post(
                `${process.env.REACT_APP_PAYMENT_API_URL}/api/customer/create`,
                body_data,
            )
            if (data.status == 'success') {
                cardID = data.data.responseData.id

                let userData = JSON.parse(localStorage.getItem('userData'))
                userData.card_paymentid = data.data.responseData.id
                localStorage.setItem('userData', JSON.stringify(userData))
                const url = `${process.env.REACT_APP_DOMAIN}auctionpay/save_customer_id`
                const { data_res } = await axios.post(
                    url,
                    { customer_id: cardID },
                    {
                        headers: {
                            Authorization: 'Bearer ' + token,
                            domain: process.env.REACT_CONTROL_HUB_DOMAIN,
                        },
                    },
                )
            }
        }
        if (radvalue != 'nocard') {
            let body = {
                payment_details: {
                    source: radvalue,
                    customer: cardID,
                    currency: 'usd',
                    description: 'Card checkout',
                },
                invoice_id: props.invoice_id,
                shipping_addr_id: selectAddress,
                user_country: 'US',
                ship_local: props.ship_local,
                shipping_inside: 'yes',
                mode: process.env.REACT_APP_PAYMENTMODE,
                auction_io: 1,
            }

            const resData = await axios.post(
                `${process.env.REACT_APP_DOMAIN}mobileapi/successAfterPayAuction`,
                body,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        domain: process.env.REACT_CONTROL_HUB_DOMAIN,
                    },
                },
            )

            if (resData.data.status === 'yes') {
                Swal.fire({
                    title: 'Successfully paid',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
                history.push({
                    pathname: '/dashboard',
                    state: {
                        value: 1,
                    },
                })
                // props.upd()
                // setmodalOpen(false)
            } else {
                setLoading(false)
                Swal.fire({
                    title: 'Try Again later.',
                    icon: 'error',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
        } else {
            let error = {}
            if (cardNumber == '' || cardNumber.length < 14 || cardNumber.length > 16) {
                error['cardNumber'] = 'Enter valid card number'
            }
            if (cardName == '') {
                error['cardName'] = 'Enter valid card name'
            }
            if (expMonth == '' || parseInt(expMonth) <= 0 || parseInt(expMonth) > 12) {
                error['expMonth'] = 'Enter valid expiry month'
            }
            if (cardCvv == '' || cardCvv.length > 4 || cardCvv.length < 3) {
                error['cvv'] = 'Enter valid CVV'
            }
            if (expYear == '') {
                error['expYear'] = 'Enter valid expiry year'
            }
            if (!error['expMonth'] && !error['expYear']) {
                let month = expMonth.length === 1 ? `${0}${parseInt(expMonth)}` : parseInt(expMonth)
                let year = expYear.length === 2 ? `YY` : 'YYYY'
                if (moment(`${month}/${parseInt(expYear)}`, `MM/${year}`).isBefore(moment())) {
                    error['expYear'] = 'Invalid year'
                }
            }
            if (cardAddress1 == '') {
                error['cardAddress1'] = 'Enter card address line 1'
            }
            if (cardCountry == '') {
                error['cardCountry'] = 'Enter valid card country'
            }
            if (cardState == '') {
                error['cardState'] = 'Enter valid card state'
            }
            if (cardCity == '') {
                error['cardCity'] = 'Enter valid card city'
            }
            if (cardZip == '') {
                error['cardZip'] = 'Enter valid card zip code'
            }
            setError(error)
            if (_.isEmpty(error)) {
                if (userData.state == null) {
                    const formdata = new FormData()
                    formdata.append('first_name', userData.first_name)
                    formdata.append('last_name', userData.last_name)
                    formdata.append('email', userData.email)
                    formdata.append('country', cardCountry)
                    formdata.append('state', cardState)
                    formdata.append('city', cardCity)
                    formdata.append('phone', userData.phone)
                    //formdata.append('user_profile_image', File.File)
                    formdata.append('card_paymentid', cardID)
                    formdata.append('zip', cardZip)
                    formdata.append('address1', cardAddress1)
                    formdata.append('address2', cardAddress2)
                    const user_update = await axiosInstance.post(
                        `mobileapi/updateUserProfile`,
                        formdata,
                    )
                    userData.state = cardState
                    userData.city = cardCity
                    userData.country = cardCountry
                    userData.address1 = cardAddress1
                    userData.address2 = cardAddress2
                    userData.zip = cardZip
                    userData.card_paymentid = cardID
                    localStorage.setItem('userData', JSON.stringify(userData))
                }
                let body = {
                    payment_details: {
                        customer: cardID,
                        currency: 'usd',
                        description: 'Amount paid from checkout page!',
                        card_name: cardName,
                        card_number: cardNumber,
                        card_cvc: cardCvv,
                        card_exp_month: expMonth,
                        card_exp_year: expYear,
                        card_token: true,
                        message: 'Manual Payment!',
                        card_address_line1: cardAddress1,
                        card_address_line2: cardAddress2,
                        card_address_city: cardCity,
                        card_address_state: cardState,
                        card_address_country: cardCountry,
                        card_address_zip: cardZip,
                        billing_details: {
                            email: userData.email,
                            name: userData.first_name,
                            address: {
                                line1: userData.address1,
                                line2: userData.address2,
                                city: userData.city,
                                state: userData.state,
                                country: 'US',
                                postal_code: userData.zip,
                            },
                        },
                    },
                    invoice_id: props.invoice_id,
                    shipping_addr_id: selectAddress,
                    user_country: 'US',
                    ship_local: props.ship_local,
                    shipping_inside: 'yes',
                    mode: process.env.REACT_APP_PAYMENTMODE,
                    auction_io: 1,
                }
                const resData = await axios.post(
                    `${process.env.REACT_APP_DOMAIN}mobileapi/successAfterPayAuction`,
                    body,
                    {
                        headers: {
                            Authorization: 'Bearer ' + token,
                            domain: process.env.REACT_CONTROL_HUB_DOMAIN,
                        },
                    },
                )
                console.log('payment response', resData)
                if (resData.data.status === 'yes') {
                    Swal.fire({
                        title: 'Successfully paid',
                        icon: 'success',
                        timer: 1500,
                        showConfirmButton: false,
                        position: 'center',
                    })
                    history.push({
                        pathname: '/dashboard',
                        state: {
                            value: 1,
                        },
                    })
                    // props.upd()
                    // setmodalOpen(false)
                } else {
                    Swal.fire({
                        title: 'Try Again later.',
                        icon: 'error',
                        timer: 1500,
                        showConfirmButton: false,
                        position: 'center',
                    })
                    setLoading(false)
                }
            } else {
                setLoading(false)
                Swal.fire({
                    title: 'Please Select Or Add New Card Details',
                    icon: 'error',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
        }
    }
    return (
        <div className="paymentDetails">
            <div className="paymentTotal">
                <div className="won_amount d-flex justify-content-between">
                    <p className="totalTitle">won amount: </p>
                    <p className="won_amount">{currencyFormat(props.amount)}</p>
                </div>
                <div className="admin_amount_1 d-flex justify-content-between">
                    <p className="totalTitle">Buyer's Premium:</p>
                    <p className="additional_amount_1">{currencyFormat(props.buyers_premium)}</p>
                </div>
                <div className="admin_amount_2 d-flex justify-content-between">
                    <p className="totalTitle">Sales Tax:</p>
                    <p className="additional_amount_2">{currencyFormat(props.sales_tax)}</p>
                </div>
                <div className="admin_amount_3 d-flex justify-content-between">
                    <p className="totalTitle">Transaction Fee: </p>
                    <p className="additional_amount_3">{currencyFormat(props.transaction_fee)}</p>
                </div>
                <div className="admin_amount_3 d-flex justify-content-between">
                    <p className="totalTitle">Shipping Amount:</p>
                    <p className="additional_amount_3">{currencyFormat(props.shipping_fee)}</p>
                </div>
                <div className="totalAmount d-flex justify-content-between">
                    <p className="totalTitle">TotalAmount:</p>
                    <p className="totalPrice">{currencyFormat(props.total_payable_amount)}</p>
                </div>
                {/* <div className="convenienceAmount d-flex justify-content-between">
                        <p className="convenienceTitle">Convenience Fee 3%</p>
                        <p className="convenienceFee">:{props.conFee}</p>
                    </div>
                    <div className="taxAmount d-flex justify-content-between">
                        <p className="taxTitle">Tax 0%</p>
                        <p className="taxPrice">:{props.taxPrice}</p>
                    </div>
                    <div className="grandTotal d-flex justify-content-between">
                        <p className="grandTitle">GrandTotal</p>
                        <p className="grandPrice">:{props.grandTotal}</p>
                    </div> */}
            </div>
            <h2 className="paymentTitle my-3 mt-5">Shipping Address</h2>
            <div className="od-box">
                <CustomSelect
                    //label={'Select Shipping Address'}
                    value={selectAddress}
                    onChange={(e) => setSelectAddress(e.target.value)}
                >
                    <option value={0}>Select Shipping Address</option>
                    {get_all_address.map((val) => (
                        <option value={val.id}>
                            {val.address +
                                ', ' +
                                val.country +
                                ', ' +
                                val.state +
                                ', ' +
                                val.city +
                                ', ' +
                                val.zipcode}
                        </option>
                    ))}
                </CustomSelect>
                <div className="form-group mt-2">
                    {/* <button
                        type="submit"
                        className="pink-btn"
                        // onClick={() => setAddAddressOpen(true)}
                    >
                        Add Address
                    </button> */}
                    <PrimaryButton
                        label={'Add New Address'}
                        onClick={() => setAddAddressOpen(true)}
                    />
                </div>
            </div>
            <div className="paymentCard mt-5">
                <div className="exsitingCard">
                    <div className="numRadio">
                        <RadioBox
                            items={selectedCard}
                            value={radvalue}
                            //onchange={handleChangeRadio}
                            name="savedcards"
                            int={1}
                            checkout={true}
                            cc={handleChangeRadio}
                        />
                        {/* {console.log(radvalue, 'radvalueeeeeeeeeee')} */}
                    </div>
                </div>
                <div className="cardText">
                    <CustomInput
                        label="Card Number"
                        placeholder="Enter Card Number"
                        variant="standard"
                        value={cardNumber}
                        name="cardnumber"
                        inputProps={{ maxLength: 16 }}
                        onChange={(e) => {
                            setCardNumber(e.target.value)
                        }}
                    />
                    <div className="errorMessage-1 errorTxt">
                        {error.cardNumber ? error.cardNumber : ''}
                    </div>
                    <CustomInput
                        label="Name on the Card"
                        placeholder="Enter Card Holder Name"
                        value={cardName}
                        variant="standard"
                        onChange={(e) => {
                            setCardName(e.target.value)
                        }}
                        error={error.cardName || ''}
                    />
                    <div className="errorMessage-1 errorTxt">
                        {error.cardName ? error.cardName : ''}
                    </div>
                    <div className="cardExpiry d-flex justify-content-between ">
                        <div className="w-100 mr-2">
                            <CustomInput
                                label="Expiry Month"
                                placeholder="MM"
                                value={expMonth}
                                variant="standard"
                                inputProps={{ maxLength: 2 }}
                                onChange={(e) => {
                                    setExpMonth(e.target.value)
                                }}
                                error={error.expMonth || ''}
                            />
                            <div className="errorMessage-1 errorTxt">
                                {error.expMonth ? error.expMonth : ''}
                            </div>
                        </div>
                        <div className="w-100 ml-2">
                            <CustomInput
                                label="Expiry Year"
                                placeholder="YYYY"
                                value={expYear}
                                variant="standard"
                                inputProps={{ maxLength: 4 }}
                                onChange={(e) => {
                                    setExpYear(e.target.value)
                                }}
                                error={error.expYear || ''}
                            />
                            <div className="errorMessage-1 errorTxt">
                                {error.expYear ? error.expYear : ''}
                            </div>
                        </div>
                    </div>
                    <CustomInput
                        label="CVV"
                        placeholder="Enter CVV"
                        value={cardCvv}
                        variant="standard"
                        inputProps={{ maxLength: 3 }}
                        onChange={(e) => {
                            setCardCvv(e.target.value)
                        }}
                        error={error.cvv || ''}
                    />
                    <div className="errorMessage-1 errorTxt">{error.cvv ? error.cvv : ''}</div>
                    <CustomInput
                        label="Address Line 1"
                        placeholder="Enter Address Line 1"
                        value={cardAddress1}
                        variant="standard"
                        onChange={(e) => {
                            setCardAddress1(e.target.value)
                        }}
                        error={error.cardAddress1 || ''}
                    />
                    <div className="errorMessage-1 errorTxt">
                        {error.cardAddress1 ? error.cardAddress1 : ''}
                    </div>
                    <CustomInput
                        label="Address Line 2"
                        placeholder="Enter Address Line 2"
                        value={cardAddress2}
                        variant="standard"
                        onChange={(e) => {
                            setCardAddress2(e.target.value)
                        }}
                        error={error.cardAddress2 || ''}
                    />
                    <div className="errorMessage-1 errorTxt">
                        {error.cardAddress2 ? error.cardAddress2 : ''}
                    </div>
                    <div className="mt-2">
                        <SimpleSelect
                            label="Country"
                            value={cardCountry}
                            dropCountr={Country}
                            onChange={(e) => {
                                setCardCountry(e.target.value)
                            }}
                            countryID={(data) => setID(data)}
                            ctrcode={(data) => setCountrycode(data)}
                            error={error.cardCountry || ''}
                        />
                        <div className="errorMessage-1 errorTxt">
                            {error.cardCountry ? error.cardCountry : ''}
                        </div>
                    </div>
                    <div className="mt-2">
                        <SimpleSelect1
                            label="State"
                            value={cardState}
                            onChange={(e) => {
                                setCardState(e.target.value)
                            }}
                            countryID={(data) => setID(data)}
                            ctrcode={(data) => setCountrycode(data)}
                            dropState={State.filter((data) => data.country_id === Id.toString())}
                            stateID={(data) => setStateId(data)}
                            error={error.cardState || ''}
                        />
                        <div className="errorMessage-1 errorTxt">
                            {error.cardState ? error.cardState : ''}
                        </div>
                    </div>
                    <div className="mt-2">
                        <SimpleSelect2
                            label="City"
                            value={cardCity}
                            onChange={(e) => {
                                setCardCity(e.target.value)
                            }}
                            dropCity={cities.filter((data) => data.state_id === sId.toString())}
                            error={error.cardCity || ''}
                        />
                        <div className="errorMessage-1 errorTxt">
                            {error.cardCity ? error.cardCity : ''}
                        </div>
                    </div>
                    <CustomInput
                        label="Zip Code"
                        placeholder="Enter Zip Code"
                        value={cardZip}
                        variant="standard"
                        onChange={(e) => {
                            setCardZip(e.target.value)
                        }}
                        error={error.cardZip || ''}
                    />
                    <div className="errorMessage-1 errorTxt">
                        {error.cardZip ? error.cardZip : ''}
                    </div>
                </div>
                {
                    <PrimaryButton
                        label={loading ? 'Loading' : 'Pay'}
                        disabled={loading}
                        onClick={paynow}
                    />
                }
            </div>
            <Popup
                modaltitle="Add New Address"
                open={addAddressOpen}
                handleClose={() => setAddAddressOpen(false)}
            >
                <div className="paymentCard_static payAdrsPopup">
                    <div className="cardText">
                        <CustomInput
                            label="Address Line 1*"
                            variant="standard"
                            value={formik.values.address}
                            name="address"
                            onChange={formik.handleChange}
                        />
                        <div className="errorMessage-1 errorTxt">
                            {formik.errors.address && formik.touched.address
                                ? formik.errors.address
                                : ''}
                        </div>
                        <CustomInput
                            label="Address Line 2*"
                            variant="standard"
                            value={formik.values.address1}
                            name="address1"
                            onChange={formik.handleChange}
                        />
                        <div className="errorMessage-1 errorTxt">
                            {formik.errors.address1 ? formik.errors.address1 : ''}
                        </div>
                        <div className="cardExpiry d-flex justify-content-between my-3">
                            <div className="w-100 mr-2">
                                <CustomSelect
                                    // label={'Country'}
                                    name="country"
                                    value={formik.values.country}
                                    onChange={(e) => {
                                        setCountry(e.target.value)
                                        formik.handleChange(e)
                                    }}
                                >
                                    {Country.map((val) => (
                                        <option value={val.id}>{val.name}</option>
                                    ))}
                                </CustomSelect>
                                <div className="errorMessage-1 errorTxt">
                                    {formik.errors.country && formik.touched.country
                                        ? formik.errors.country
                                        : ''}
                                </div>
                            </div>
                            <div className="w-100 ml-2">
                                <CustomSelect
                                    // label={'State'}
                                    value={formik.values.state}
                                    onChange={formik.handleChange}
                                    name="state"
                                >
                                    {State.filter(
                                        (x) => parseInt(x.country_id) == parseInt(Country_code),
                                    ).map((val) => (
                                        <option value={val.id}>{val.name}</option>
                                    ))}
                                </CustomSelect>
                                <div className="errorMessage-1 errorTxt">
                                    {formik.errors.state && formik.touched.state
                                        ? formik.errors.state
                                        : ''}
                                </div>
                            </div>
                        </div>
                        <div className="cardExpiry d-flex justify-content-between ">
                            <div className="w-100 mr-2">
                                <CustomInput
                                    label="City*"
                                    variant="standard"
                                    value={formik.values.city}
                                    name="city"
                                    onChange={formik.handleChange}
                                />
                                <div className="errorMessage-1 errorTxt">
                                    {formik.errors.city && formik.touched.city
                                        ? formik.errors.city
                                        : ''}
                                </div>
                            </div>
                            <div className="w-100 ml-2">
                                <CustomInput
                                    label="Zip*"
                                    variant="standard"
                                    value={formik.values.zipcode}
                                    name="zipcode"
                                    onChange={formik.handleChange}
                                />
                                <div className="errorMessage-1 errorTxt">
                                    {formik.errors.zipcode && formik.touched.zipcode
                                        ? formik.errors.zipcode
                                        : ''}
                                </div>
                            </div>
                        </div>
                        <div className="form-group mt-2">
                            <PrimaryButton
                                disabled={addressLoading}
                                label={'Add Address'}
                                onClick={formik.handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default CheckoutPayment
