import React, { useState, useEffect } from 'react'
import SecondaryButton from '../../components/atoms/SecondaryButton/index'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import { isEmpty, findIndex } from 'lodash'
import Pagination from '@material-ui/lab/Pagination'
import { getPaginationCount } from '../../Utils/Utility'
import { useHistory } from 'react-router-dom'
import './wonAuction.css'
import { handleRedirectInternal } from '../../common/components'

const WonAuction = (props) => {
    const data = ['Paid', 'Unpaid', 'Unpaid', 'Unpaid', 'Paid', 'Paid']
    const history = useHistory()
    const [invoice_id, setInvoice] = useState([])
    const [unpaid_count, setInvoicecount] = useState(0)
    const add_to_card = (data) => {
        var arr = invoice_id
        var temp = []
        if (arr.includes(data.common_invoice)) {
            temp = arr.filter((val) => val != data.common_invoice)
        } else {
            temp = [...arr, data.common_invoice]
        }
        setInvoice(temp)
    }

    useEffect(() => {
        if (!isEmpty(props.data.results)) {
            var temp = [],
                arr = [],
                unpaid_count_local = 0
            props.data.results.map((data) => {
                arr = temp
                if (data.paid == 0) {
                    unpaid_count_local = unpaid_count_local + 1
                    if (arr.includes(data.common_invoice)) {
                        // temp = arr.filter((val) => val != data.common_invoice)
                    } else {
                        temp = [...arr, data.common_invoice]
                    }
                }
            })
            setInvoice(temp)
            setInvoicecount(unpaid_count_local)
        }
    }, [props.data])

    return (
        <div>
            <div className="wonAuction-Table">
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Auction Name</th>
                            <th scope="col">Title</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Current Price</th>
                            <th scope="col"> Date Ended</th>
                            <th scope="col">Action</th>
                            {/* <th scope="col">Invoice</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {!isEmpty(props.data.results) ? (
                            <>
                                {props.data.results.map((data, index) => (
                                    <tr>
                                        <th scope="row" className="frstHdrRow">
                                            {data.sku}
                                        </th>
                                        <td>{data.auctionlot_title}</td>
                                        <td>
                                            {' '}
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: data.title,
                                                }}
                                            ></p>
                                        </td>
                                        <td>{data.qty}</td>
                                        <td>${data.latestbid}</td>
                                        {/* <td className={data}>{data.dateclosing}</td> */}
                                        <td>{data.dateclosing}</td>
                                        <td>
                                            {data.paid === 0 ? (
                                                <SecondaryButton
                                                    btnSize="addsbBtn"
                                                    label={'Added To Checkout'}
                                                    disabled={true}
                                                    onClick={() => {
                                                        add_to_card(data)
                                                    }}
                                                />
                                            ) : (
                                                <SecondaryButton
                                                    label="Invoice"
                                                    onClick={() => {
                                                        history.push(
                                                            `/Invoice/${data.common_invoice}`,
                                                        )
                                                    }}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan="6"></td>
                                    <td colSpan="2">
                                        <SecondaryButton
                                            label={`Checkout (${unpaid_count})`}
                                            onClick={() => {
                                                // history.push({
                                                //     pathname: `/Checkout?id=${invoice_id.join('&id=')}`,
                                                //     state: {},
                                                // })
                                                window.location.href = `Checkout?id=${invoice_id.join(
                                                    '&id=',
                                                )}`
                                                // handleRedirectInternal(
                                                //     history,
                                                //     `Checkout?id=${invoice_id.join('&id=')}`,
                                                // )
                                            }}
                                        />
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <tr>
                                <td colspan="12" className="border-none">
                                    <div className="card card-body text-center mt-2">
                                        <b>No Auction Found</b>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="won-pgn">
                <Pagination
                    onChange={(e, value) => {
                        props.paramsupd(value)
                    }}
                    count={
                        props.data.total_pagecnt
                            ? getPaginationCount(props.data.total_pagecnt, 10)
                            : '1'
                    }
                />
            </div>
        </div>
    )
}

export default WonAuction
