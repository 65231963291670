import React, { useState, useRef } from 'react'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import { Button } from '@material-ui/core'
import Popup from '../../components/organisms/Popup/index'
import { Formik } from 'formik'
import * as yup from 'yup'
import valid from 'card-validator'
import _, { isEmpty } from 'lodash'
import payAmountStripe from '../../Utils/API/payamountstripe'
import Swal from 'sweetalert2'
import './Addnewcard.css'
import Country from '../Signup/Country.json'
import State from '../Signup/State.json'
import cities from '../Signup/cities.json'
import SimpleSelect from '../../components/atoms/DropDown/index'
import SimpleSelect1 from '../../components/atoms/DropDown/Dropdown1'
import SimpleSelect2 from '../../components/atoms/DropDown/Dropdown2'

const Addnewcard = (props) => {
    const Data = ['Card Number', 'MM', 'YYYY', 'Name on the Card', 'Cvv']

    const [modalOpen, setmodalOpen] = useState(false)
    const [Id, setID] = useState('')
    const [sId, setStateId] = useState('')
    const [Countrycode, setCountrycode] = useState('')

    const modalClose = () => {
        setmodalOpen(false)
    }

    const formRef = useRef()

    const addcardapi = async () => {
        try {
            let body = {
                account_id: process.env.REACT_APP_AUCTIONPAYKEY,
                card_number: formRef.current.values.cardnumber,
                card_name: formRef.current.values.nameonthecard,
                card_cvc: formRef.current.values.cvv,
                card_exp_month: formRef.current.values.mm,
                card_exp_year: formRef.current.values.yyyy,
                card_address_line1: formRef.current.values.cardaddress1,
                card_address_line2: formRef.current.values.cardaddress2,
                card_address_city: formRef.current.values.cardcity,
                card_address_state: formRef.current.values.cardstate,
                card_address_zip: formRef.current.values.cardzip,
                card_address_country: formRef.current.values.cardcountry,
                mode: process.env.REACT_APP_PAYMENTMODE,
                isSave: true,
            }

            const isSuccess = await payAmountStripe(body)
            if (isSuccess.data.status === 'success') {
                Swal.fire({
                    title: 'Card Saved Successfully',
                    icon: 'success',
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                })
                props.upd()
                setmodalOpen(false)
            } else {
                Swal.fire({
                    title: isSuccess.data.message,
                    icon: 'error',
                    timer: 2500,
                    showConfirmButton: false,
                    position: 'center',
                })
                setmodalOpen(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const addcard = yup.object({
        cardnumber: yup
            .string()
            .required('Card number is required')
            .max(16, 'Please check card number'),
        mm: yup
            .string()
            .typeError('Not a valid expiration date')
            .matches(/^(0?[1-9]|1[012])$/, 'Not a valid expiration date')
            .required('Expiration month is required'),
        yyyy: yup
            .string()
            .typeError('Not a valid expiration date')
            .matches(/^(20)\d{2}$/, 'Not a valid Expiration year')
            .required('Expiration year is required'),
        nameonthecard: yup.string().required(),
        cvv: yup.number().required(),
        cardaddress1: yup.string().required(),
        cardcity: yup.string().required(),
        cardstate: yup.string().required(),
        cardzip: yup.string().required(),
        cardcountry: yup.string().required(),
    })

    return (
        <div className="addNewcard-parent">
            <Button onClick={() => setmodalOpen(true)}>
                <div className="Addcard-Payment">
                    <img src="/images/cards.png" alt="bank" />
                    <h6>ADD A NEW CARD</h6>
                    <img src="/images/ccard.png" alt="bank" />
                </div>
            </Button>
            <Popup modaltitle="Add Card" open={modalOpen} handleClose={() => modalClose()}>
                <Formik
                    initialValues={{
                        cardnumber: '',
                        mm: '',
                        yyyy: '',
                        nameonthecard: '',
                        cvv: '',
                        cardaddress1: '',
                        cardaddress2: '',
                        cardcity: '',
                        cardstate: '',
                        cardzip: '',
                        cardcountry: '',
                    }}
                    validationSchema={addcard}
                    innerRef={formRef}
                    onSubmit={(values) => {
                        addcardapi(values)
                    }}
                >
                    {({ errors, touched, values, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="AddCard-Popup-Banner">
                                <div className="Addcard-Popup-Input">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <CustomInput
                                                    label="Card Number"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="cardnumber"
                                                    inputProps={{ maxLength: 16 }}
                                                    helperText={
                                                        errors.cardnumber && touched.cardnumber
                                                            ? 'Incorrect Card Number'
                                                            : ''
                                                    }
                                                    type="number"
                                                    maxLength={16}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <CustomInput
                                                    label="Name on the Card"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="nameonthecard"
                                                    helperText={
                                                        errors.nameonthecard &&
                                                        touched.nameonthecard
                                                            ? 'Please Enter your Name'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6 form-group">
                                                    <CustomInput
                                                        label="MM"
                                                        variant="standard"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="mm"
                                                        inputProps={{ maxLength: 2 }}
                                                        helperText={
                                                            errors.mm && touched.mm
                                                                ? 'Invalid Expiry Month'
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <CustomInput
                                                        label="YYYY"
                                                        variant="standard"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="yyyy"
                                                        inputProps={{ maxLength: 4 }}
                                                        helperText={
                                                            errors.yyyy && touched.yyyy
                                                                ? 'Invalid Expiry Year'
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <CustomInput
                                                    label="cvv"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="cvv"
                                                    inputProps={{ maxLength: 3 }}
                                                    helperText={
                                                        errors.cvv && touched.cvv
                                                            ? 'Invalid cvv'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <CustomInput
                                                    label="Address Line 1"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="cardaddress1"
                                                    helperText={
                                                        errors.cardaddress1 && touched.cardaddress1
                                                            ? 'Please Enter Address Line 1'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <CustomInput
                                                    label="Address Line 2"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="cardaddress2"
                                                    helperText={
                                                        errors.cardaddress2 && touched.cardaddress2
                                                            ? 'Please Enter Address Line 2'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <SimpleSelect
                                                    dropCountr={Country}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    countryID={(data) => setID(data)}
                                                    ctrcode={(data) => setCountrycode(data)}
                                                    name="cardcountry"
                                                    label="Country"
                                                    helperText={
                                                        errors.cardcountry && touched.cardcountry
                                                            ? 'Please Enter Country'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <SimpleSelect1
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    countryID={(data) => setID(data)}
                                                    ctrcode={(data) => setCountrycode(data)}
                                                    name="cardstate"
                                                    label="State"
                                                    dropState={State.filter(
                                                        (data) => data.country_id === Id.toString(),
                                                    )}
                                                    stateID={(data) => setStateId(data)}
                                                    helperText={
                                                        errors.cardstate && touched.cardstate
                                                            ? 'Please Enter State'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <SimpleSelect2
                                                    onChange={handleChange}
                                                    name="cardcity"
                                                    label="City"
                                                    dropCity={cities.filter(
                                                        (data) => data.state_id === sId.toString(),
                                                    )}
                                                    helperText={
                                                        errors.cardcity && touched.cardcity
                                                            ? 'Please Enter City'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <CustomInput
                                                    label="Zip Code"
                                                    variant="standard"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="cardzip"
                                                    helperText={
                                                        errors.cardzip && touched.cardzip
                                                            ? 'Please Enter Zip Code'
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <PrimaryButton label="Add" type="submit" />
                            </div>
                        </form>
                    )}
                </Formik>
            </Popup>
        </div>
    )
}

export default Addnewcard
