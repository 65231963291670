import React from 'react'
import Slider from 'react-slick'
import HomeAuctionCard from '../../components/molecules/ProductCard/HomeAuctionCard'

const HomeAuction = (props) => {
    const settings = {
        dots: true,
        fade: false,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        // adaptiveHeight: true,
        // variableWidth: true,
        // autoplay: true,
        speed: 500,
        // autoplaySpeed: 4000,
        cssEase: 'linear',
        nextArrow: <img src="/images/rightIcon.svg" />,
        prevArrow: <img src="/images/leftIcon.svg" />,
        responsive: [
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }
    return (
        <div className="hmActnSlider">
            {console.log(props.auctionSearch, 'props.auctionSearch')}
            {props.auctionSearch.length < 1 ? (
                <h6 className="noRecordsFound">No Records Found</h6>
            ) : (
                <Slider {...settings}>
                    {props.auctionSearch.map((data, index) => (
                        <HomeAuctionCard
                            imgSrc={process.env.REACT_APP_PRODUCT_IMG + data.file_name}
                            Name={data.ptitle}
                            descrp={data.description}
                            label="View Lots"
                            startTime={data.date_added}
                            endTime={data.date_closed}
                            id={data.id}
                            highbid_user_id={data.highbid_user_id}
                            userDetails={props.User}
                            marketStatus={data.market_status}
                        />
                    ))}
                </Slider>
            )}

            {/* {props.auctionSearch.map((data, index) => (
                <div>
                    <HomeAuctionCard
                        imgSrc={process.env.REACT_APP_PRODUCT_IMG + data.file_name}
                        Name={data.ptitle}
                        descrp={data.description}
                        label="View Lots"
                        startTime={data.date_added}
                        endTime={data.date_closed}
                        id={data.id}
                        highbid_user_id={data.highbid_user_id}
                        userDetails={props.User}
                    />
                </div>
            ))} */}
        </div>
    )
}

export default HomeAuction
